import React, {Component} from 'react';
import {getNonPriorityFounders, getPriorityFounders} from '../../../store/services/investingServices';
import StartupCard from '../../../components/Cards/StartupCard';
import './index.scss';

class PriorityFoundersGroupedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 500,
      priorityFounders: null,
      nonPriorityFounders: null,
    }
  }

  componentDidMount() {
    const {page, limit} = this.state;
    getPriorityFounders({page, limit}).then(res => res && res.success && this.setState(prevState => ({...prevState, priorityFounders: res.data})));
    getNonPriorityFounders({page, limit}).then(res => res && res.success && this.setState(prevState => ({...prevState, nonPriorityFounders: res.data})));
  }


  render() {
    const {priorityFounders, nonPriorityFounders} = this.state;
    const isEmptyPriority = !priorityFounders || !(priorityFounders && priorityFounders.total_count);
    const isEmptyNonPriority = !nonPriorityFounders || !(nonPriorityFounders && nonPriorityFounders.total_count);

    return (
      <div className="priority-founders-grouped-list__container">
        <h2 className="f-26 f-500">Priority founders</h2>
        <p className="f-16 f-400">You've prioritised these founders to get disproportionate share of your cash.</p>
        {isEmptyPriority
          ? <div className="priority-founders-grouped-list__empty f-16 f-400">No founders</div>
          : (
            <div className="priority-founders-grouped-list">
              {priorityFounders.list.map(founder => (
                <StartupCard
                  key={founder.id}
                  founders={founder.co_founders}
                  company={founder.company}
                  fundraisingStatus={founder.fundraising_status}
                  className="priority-founders-grouped-list__item"
                  active
                />
              ))}
            </div>
          )
        }
        {!isEmptyNonPriority && (
          <>
            <h2 className="f-26 f-500">Other founders</h2>
            <p className="f-16 f-400">Other awesome founders will receive the remainder.</p>
            {isEmptyNonPriority
              ? <div className="priority-founders-grouped-list__empty f-16 f-400">No founders</div>
              : (
                <div className="priority-founders-grouped-list">
                  {nonPriorityFounders.list.map(founder => (
                    <StartupCard
                      key={founder.id}
                      founders={founder.co_founders}
                      company={founder.company}
                      fundraisingStatus={founder.fundraising_status}
                      className="priority-founders-grouped-list__item"
                      active
                    />
                  ))}
                </div>
              )
            }
          </>
        )}
      </div>
    );
  }
}

export default PriorityFoundersGroupedList;