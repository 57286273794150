import React, { useRef, useState } from 'react';
import * as classnames from 'classnames';
import { SpecTriangleIcon, TooltipIcon } from '../UI/Icons';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import './index.scss';

const Tooltip = ({
  renderContent,
  clickMode,
  icon: Component,
  className,
  position = 'bottom',
}) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef();

  const ref = useRef();

  useOutsideClick(containerRef, () => setOpen(false), clickMode && open);

  return (
    <div
      ref={containerRef}
      className={classnames(
        'tooltip',
        clickMode ? 'click_mode' : 'hover_mode',
        clickMode && open && 'active',
        className
      )}
    >
      {Component ? (
        <Component
          className="tooltip__icon"
          onClick={() => clickMode && setOpen(!open)}
        />
      ) : (
        <TooltipIcon
          className="tooltip__icon"
          onClick={() => clickMode && setOpen(!open)}
        />
      )}

      {renderContent && (
        <div className={classnames('tooltip__content', position)} ref={ref}>
          <SpecTriangleIcon className="tooltip__content-triangle" />

          <div className="tooltip__inner">{renderContent()}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
