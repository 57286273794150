import * as React from 'react';
import {logoutUser} from '../../store/actions/userActions';
import {useDispatch, useSelector} from 'react-redux';
import {Header} from '../../containers/Header';
import PromoFooter from '../../components/Footer/PromoFooter/PromoFooter';
import MobFooter from '../../components/Footer/MobFooter/MobFooter';
import './index.scss';

const BasicLayout = ({children}) => {
  const user = useSelector(state => state.userStore.user);
  const dispatch = useDispatch();

  const [mobileFooter, setMobileFooter] = React.useState(false);
  const promoFooterRef = React.createRef();

  React.useEffect(() => {
    if (promoFooterRef.current.offsetWidth <= 1024) {
      setMobileFooter(true);
    } else {
      setMobileFooter(false);
    }
  }, []);

  return (
    <div className="basic-layout" ref={promoFooterRef}>
      <Header user={user} logout={() => dispatch(logoutUser())} />
      <main>
        {children}
      </main>
      {mobileFooter ? <MobFooter /> : <PromoFooter />}
    </div>
  )
}

export default BasicLayout