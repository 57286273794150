import {METADATA} from '../../helpers/metadata';
import {GET_HELP, GET_MINI_FAQ, SET_FAQ} from "../types/helpTypes";

const initialState = {
  helpList: { ...METADATA.default, data: null },
  selectedFAQ: [], // list of slugs to selected FAQ (last slug is selected question)
  miniFaqs: null,
};

const helpReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HELP.REQUEST:
      return { ...state, helpList: { ...state.helpList, ...METADATA.request }};
    case GET_HELP.SUCCESS:
      return { ...state, helpList: { ...METADATA.success, data: action.payload }};
    case GET_HELP.FAILURE:
      return { ...state, helpList: { ...state.helpList, ...METADATA.error, error: action.error }};
    case GET_MINI_FAQ.SUCCESS:
      return { ...state, miniFaqs: action.payload };
    case SET_FAQ:
      return { ...state, selectedFAQ: action.payload };

    default:
      return state;
  }
};

export default helpReducer;