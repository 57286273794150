import React, { useState } from 'react';
import * as classnames from 'classnames';
import { RoundButton } from '../RoundButton';
import Notify from '../../Notification';
import './index.scss';

export const CopyRoundButton = ({ text, label, successLabel, onClick }) => {
  const [copied, setCopied] = useState(false);

  const onSuccess = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const onFailure = () => {
    Notify.error({ text: 'Could not copy' });
  };

  return (
    <RoundButton
      label={copied ? successLabel : label}
      fillBackground
      className={classnames('copy_round_button', copied && 'copied')}
      onClick={() => {
        navigator.clipboard.writeText(text).then(onSuccess, onFailure);
        onClick && onClick();
      }}
    />
  );
};
