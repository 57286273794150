import React from 'react';

export const SpecTriangleIcon = ({ ...other }) => (
  <svg
    width="14"
    height="28"
    viewBox="0 0 14 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-6.11959e-07 14C7.73199 14 14 20.268 14 28L14 -6.11959e-07C14 7.73199 7.73199 14 -6.11959e-07 14Z"
      fill="white"
    />
  </svg>
);
