import React from 'react';
import { Formik } from 'formik';
import { EarningCalculator } from '../../EarningCalculator';
import { LOAN_GROUPS } from '../../../helpers/constants';
import { EarningResult } from './result';
import s from './index.module.scss';

const DEFAULT_AMOUNT = 1000;
const DEFAULT_TOP_UP = 100;

export const CalculatorLongTerm = ({ onJoin, joinDisabled }) => (
  <Formik
    onSubmit={() => null}
    initialValues={{
      amount: DEFAULT_AMOUNT.toString(),
      loan: LOAN_GROUPS.conservative.id,
      topUp: DEFAULT_TOP_UP.toString(),
    }}
  >
    {formikBag => (
      <div className={s.content}>
        <EarningCalculator className={s.form} formikBag={formikBag} />
        <EarningResult
          formikBag={formikBag}
          onSubmit={onJoin}
          joinDisabled={joinDisabled}
        />
      </div>
    )}
  </Formik>
);
