import React from 'react';
import { ProgressLine } from './line';
import { prettyMoney } from '../../helpers/utils';
import s from './index.module.scss';

const InvestProgressBar = ({ stats }) => {
  let percent = 0;
  if (stats.total_goal !== 0) {
    percent = parseInt((stats.currently_allocated * 100) / stats.total_goal);
  }

  return (
    <div className={s.wrapper}>
      <div className={s.info}>
        <div className={s.block}>
          <div>{prettyMoney(stats.currently_allocated || 0)}</div>
          <div>Invested to the round</div>
        </div>
        <div className={s.block}>
          <div>{prettyMoney(stats.total_goal || 0)}</div>
          <div>Investing goal</div>
        </div>
      </div>
      <div className={s.progress}>
        <ProgressLine percent={percent} />
      </div>
    </div>
  );
};

export default InvestProgressBar;
