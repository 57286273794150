import React from 'react';
import LoanGroupCard from '../../../components/Cards/LoanGroupCard/v1';
import { LOAN_GROUPS_V1 } from './data';
import './index.scss';

export const LoanTermsPage = () => (
  <div className="loan_terms_v1_page">
    <div className="container-inner">
      <h1 className="f-42 f-500">Loan Terms</h1>
      <p className="loan_terms_v1_page__desc f-20 f-500">
        All loan groups available at the same time, allocate your capital based
        on your risk appetite.
      </p>
      <div className="loan_terms_v1_page__cards">
        {LOAN_GROUPS_V1.map(group => (
          <LoanGroupCard
            key={group.id}
            group={group}
            className="loan_terms_v1_page__card"
          />
        ))}
      </div>
    </div>
  </div>
);

export default LoanTermsPage;
