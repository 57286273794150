import React, { useMemo } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import { getCountries } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { getFlagURL } from '../../../../helpers/utils';
import { EXCLUDED_COUNTRIES } from './exclude';
import { Control } from '../components/control';
import { SingleValueCountry as SingleValue } from '../components/value';
import { OptionCountry as Option } from '../components/option';
import '../components/styles/default.scss';
import s from './index.module.scss';

export const CountrySelectField = ({
  label,
  value,
  error,
  className,
  onChange,
  exclude = [],
  ...props
}) => {
  const countries = useMemo(
    () =>
      getCountries()
        .filter(
          countryCode =>
            ![...EXCLUDED_COUNTRIES, ...exclude].includes(countryCode)
        )
        .map(countryCode => ({
          value: countryCode,
          code: countryCode,
          label: en[countryCode] || 'Unknown',
          flag: getFlagURL(countryCode),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );

  return (
    <div className={className}>
      <Select
        value={value}
        onChange={onChange}
        name="country"
        classNamePrefix="scr_select"
        className={classnames(
          s.country_select,
          error && s.country_select_error
        )}
        options={countries}
        components={{ Control, Option, SingleValue }}
        placeholder=""
        isSearchable
        aria-errormessage={error}
        aria-label={label}
        {...props}
      />
      {error && <div className={s.error_message}>{error}</div>}
    </div>
  );
};
