import * as React from 'react';
import * as classnames from 'classnames';
import './index.scss';

export const RoundButton = ({
  label,
  fillBackground,
  fullWidth,
  withArrow,
  type,
  className,
  disabled,
  ...other
}) => (
  <button
    type={type || 'button'}
    className={classnames(
      'round-button',
      fillBackground && 'round-button_filled',
      fullWidth && 'round-button_fullwidth',
      className
    )}
    disabled={disabled}
    {...other}
  >
    {typeof label === 'string' ? (
      <span className="round-button__label f-500">
        {label} {withArrow && <Arrow disabled={disabled} />}
      </span>
    ) : (
      label()
    )}
  </button>
);

const Arrow = ({ disabled }) => (
  <svg
    style={{ marginLeft: '8px' }}
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9416 0.217336C10.8878 0.152843 10.8211 0.100263 10.7459 0.0629492C10.6706 0.0256355 10.5884 0.00440673 10.5045 0.000616153C10.4205 -0.00317443 10.3368 0.0105564 10.2584 0.0409333C10.1801 0.0713101 10.109 0.117667 10.0496 0.177047C9.99019 0.236428 9.94382 0.30753 9.91343 0.38582C9.88304 0.46411 9.8693 0.54787 9.8731 0.631761C9.87689 0.715653 9.89812 0.797834 9.93545 0.873065C9.97278 0.948297 10.0254 1.01493 10.0899 1.06871L14.4352 5.41754H1.0728C0.917757 5.42576 0.771773 5.49312 0.664932 5.60574C0.558092 5.71836 0.498535 5.86765 0.498535 6.02286C0.498535 6.17807 0.558092 6.32737 0.664932 6.43999C0.771773 6.55261 0.917757 6.61997 1.0728 6.62819H14.4352L10.0831 10.9719C9.97354 11.0866 9.91242 11.239 9.91242 11.3976C9.91242 11.5562 9.97354 11.7086 10.0831 11.8233C10.1389 11.8793 10.2053 11.9237 10.2784 11.9541C10.3515 11.9844 10.4298 12 10.5089 12C10.588 12 10.6664 11.9844 10.7395 11.9541C10.8125 11.9237 10.8789 11.8793 10.9348 11.8233L16.3174 6.44259C16.3747 6.38747 16.4202 6.32135 16.4514 6.24821C16.4825 6.17506 16.4985 6.09639 16.4985 6.0169C16.4985 5.93742 16.4825 5.85874 16.4514 5.7856C16.4202 5.71245 16.3747 5.64634 16.3174 5.59122L10.9416 0.217336Z"
      fill={disabled ? '#929293' : 'black'}
    />
  </svg>
);
