import * as React from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import TopHeader from '../../components/TopHeader';
import { getBalance } from '../../store/actions/dashboardActions';
import { getAndRegisterVeriffStatuses } from '../../store/services/userServices';
import DesktopNavigation from '../../components/Navigation/DesktopNavigation';
import TabletNavigation from '../../components/Navigation/TabletNavigation';
import MobileNavigation from '../../components/Navigation/MobileNavigation';
import AuthorizedFooter from '../../components/Footer/AuthorisedFooter';
import { APP_LINKS } from '../../helpers/links';
import './index.scss';

class RootPage extends React.Component {
  constructor(props) {
    super(props);
    this.intervalID = null;
  }

  componentDidMount() {
    const { user, history, getBalance } = this.props;
    if (!user) {
      return history.push(APP_LINKS.signIn);
    }
    getAndRegisterVeriffStatuses(user);

    // Getting updated balance every minute
    getBalance();
    this.intervalID = setInterval(() => user && getBalance(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  allowedRoutes(user) {
    const { routes } = this.props && this.props.route;
    return routes
      ? routes.filter(route => (route.auth ? route.auth(user) : true))
      : [];
  }

  render() {
    const { user, innerWidth } = this.props;

    return (
      <div className="root_page">
        {innerWidth >= 1380 && <DesktopNavigation />}
        {innerWidth < 1380 && innerWidth >= 992 && <TabletNavigation />}
        {innerWidth < 992 && <MobileNavigation />}
        <main>
          <div className="root_page__content">
            <TopHeader />
            {renderRoutes(this.allowedRoutes(user), { user })}
          </div>
          {innerWidth >= 1380 && <AuthorizedFooter />}
        </main>
        {innerWidth < 1380 && <AuthorizedFooter />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  innerWidth: state.commonStore.innerWidth,
});

const mapDispatchToProps = dispatch => ({
  getBalance: () => dispatch(getBalance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
