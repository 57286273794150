import {METADATA} from '../../helpers/metadata';
import {GET_FOUNDER_INFO, SET_INNER_WIDTH, SET_MODALS} from '../actions/actionTypes';

const initialState = {
  founderInfo: { ...METADATA.default, data: null },
  innerWidth: window.innerWidth,
  modals: [],
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOUNDER_INFO.REQUEST:
      return { ...state, founderInfo: { data: null, ...METADATA.request }};
    case GET_FOUNDER_INFO.SUCCESS:
      return { ...state, founderInfo: { ...state.founderInfo, ...METADATA.success, data: action.founder }};
    case GET_FOUNDER_INFO.FAILURE:
      return { ...state, founderInfo: { ...state.founderInfo, ...METADATA.error, error: action.error, data: null }};
    case SET_INNER_WIDTH:
      return { ...state, innerWidth: action.width }
    case SET_MODALS:
      return { ...state, modals: action.modals }
    default:
      return state;
  }
};

export default commonReducer;