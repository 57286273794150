import React, { useEffect, useState } from 'react';
import qs from 'qs';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APP_LINKS } from '../../../helpers/links';
import { prettyFloatMoney, scrollTop } from '../../../helpers/utils';
import { getInvestingStatus } from '../../../store/actions/investActions';
import WarningBlock from '../../../components/UI/WarningBlock';
import { RoundLink } from '../../../components/UI/RoundLink';
import { getStripePaymentInfo } from '../../../store/services/stripeServices';
import { STRIPE_PAYMENT_TYPES } from '../../../components/Forms/PaymentForm/PaymentMethod';
import { roundStarts } from '../../../helpers/date';
import { PendingTransactionsWarning } from '../../../components/Warnings/PendingTransactionsWarning';
import s from './index.module.scss';

export const PaymentSuccess = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [info, setInfo] = useState(null);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus
  );

  useEffect(() => {
    scrollTop();
    dispatch(getInvestingStatus());
  }, []);

  useEffect(() => {
    getPaymentInfo();
  }, []);

  const getPaymentInfo = async () => {
    const { session_id } = qs.parse(location.search.replace('?', ''));
    if (session_id) {
      const res = await getStripePaymentInfo(session_id);
      res && res.success && setInfo(res.data);
    }
  };

  if (!info) {
    return null;
  }

  const { payment_method, net_amount } = info;

  return (
    <div className={s.wrapper}>
      <h1 className={classnames(s.title, 'f-26 f-500')}>Payment successful!</h1>

      {[STRIPE_PAYMENT_TYPES.sepa_debit, STRIPE_PAYMENT_TYPES.sofort].includes(
        payment_method
      ) ? (
        <PendingTransactionsWarning
          className={s.message}
          transactionDetail={info}
        />
      ) : (
        <WarningBlock withBorder className={s.message} isSuccess>
          {net_amount ? (
            <div className="f-16 f-400">
              Your payment of{' '}
              <span className="f-500">{prettyFloatMoney(net_amount)}</span> was
              successfully transferred to your Scramble account.
            </div>
          ) : (
            <div className="f-16 f-400">
              Your payment is in process, so funds are not immediately
              available.
            </div>
          )}
        </WarningBlock>
      )}

      <p className={classnames(s.desc, 'f-16 f-500')}>
        {[
          STRIPE_PAYMENT_TYPES.sepa_debit,
          STRIPE_PAYMENT_TYPES.sofort,
        ].includes(payment_method)
          ? `Congratulations!`
          : `Congratulations! You are ready to start investing and grow your wealth!`}
      </p>

      {status &&
        (status.is_active ? (
          <p className="f-16 f-400">
            You need to do some setting adjustments to take part in the round.
          </p>
        ) : (
          <p className="f-16 f-400">
            {`The round starts on ${roundStarts(
              status.start_date
            )}. We will notify you in advance. In the meantime, check the
            startups participating in the upcoming round or learn more about
            loan terms.`}
          </p>
        ))}

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.investing}
          label="Go to investing"
          fillBackground
          className={s.button}
        />
        <RoundLink
          path={APP_LINKS.addFunds}
          label="Add more cash"
          className={s.button}
        />
      </div>
    </div>
  );
};
