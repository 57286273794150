import React from 'react';
import {NavLink} from 'react-router-dom';
import {NAV_ROUTES} from '../routes';
import {MyLink} from '../../MyLink';
import {APP_LINKS} from '../../../helpers/links';
import {ReactComponent as Logo} from '../../../assets/images/logo_white.svg';
import MenuUser from '../../MenuUser';
import './index.scss';

const TabletNavigation = () => (
  <div className="tablet_navigation__wrap">
    <div className="container-inner">
      <div className="tablet_navigation">
        <MyLink href={APP_LINKS.dashboard} className="tablet_navigation__logo" >
          <Logo />
        </MyLink>
        <div className="tablet_navigation__links">
          {NAV_ROUTES.map((route) => (
            <NavLink
              key={route.label}
              to={route.path}
              className="tablet_navigation__link f-16 f-500"
              activeClassName="tablet_navigation__link-active"
            >
              {route.label}
            </NavLink>
          ))}
        </div>
        <MenuUser showName={false} className="tablet_navigation__user" />
      </div>
    </div>
  </div>
);

export default TabletNavigation;