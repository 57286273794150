import React from 'react';
import classnames from 'classnames';
import { BonusBanner } from '../Banners/bonus';
import { CalculatorLongTerm } from './CalculatorLongTerm';
import { CalculatorSingleLoan } from './CalculatorSingleLoan';
import { LOAN_GROUPS } from '../../helpers/constants';
import s from './index.module.scss';

export const CALCULATOR_TYPES = {
  long_term: 'long_term',
  single_loan: 'single_loan',
};

const TABS = {
  long_term: 'Long-term calculator',
  single_loan: 'Single loan calculator',
};

export const LoanCalculator = ({
  groupID,
  setGroupID,
  onJoin,
  joinDisabled,
  disableBonusBanner,
}) => {
  const type = groupID
    ? CALCULATOR_TYPES.single_loan
    : CALCULATOR_TYPES.long_term;

  return (
    <div className={s.wrapper} id="calculator">
      <h2 className="f-26 f-500">Make your money work</h2>
      <div className={s.tabs}>
        {Object.keys(TABS).map(key => (
          <button
            key={key}
            type="button"
            onClick={() =>
              key === CALCULATOR_TYPES.single_loan
                ? setGroupID(LOAN_GROUPS.conservative.id)
                : setGroupID(null)
            }
            className={classnames(s.tab, type === key && 'active')}
          >
            {TABS[key]}
          </button>
        ))}
      </div>

      {type === CALCULATOR_TYPES.long_term && (
        <p className={classnames(s.desc, 'f-16-24')}>
          Calculate your potential investment earnings over 10 years.
          Reinvesting income significantly boosts your initial investment over a
          long period.
        </p>
      )}

      {type === CALCULATOR_TYPES.single_loan && (
        <p className={classnames(s.desc, 'f-16-24')}>
          Calculate your potential investment earnings over 6 months
        </p>
      )}

      {!disableBonusBanner && <BonusBanner className={s.bonus} />}

      <div className={s.calculator}>
        {type === CALCULATOR_TYPES.long_term && (
          <CalculatorLongTerm joinDisabled={joinDisabled} onJoin={onJoin} />
        )}
        {type === CALCULATOR_TYPES.single_loan && (
          <CalculatorSingleLoan
            groupID={groupID}
            joinDisabled={joinDisabled}
            onJoin={onJoin}
            disableBonusBanner={disableBonusBanner}
          />
        )}
      </div>
    </div>
  );
};
