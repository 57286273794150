import React from 'react';
import classnames from 'classnames';
import { MyLink } from '../../components/MyLink';
import { ReactComponent as XIcon } from '../../assets/icons/icon_close_grey_24.svg';
import s from './index.module.scss';

export const FileName = ({ file, className, onRemove }) => (
  <MyLink
    href={file.file || '#'}
    className={classnames(s.file, className)}
    underline={!!file.file}
    isExternal
    style={{ cursor: file.file ? 'pointer' : 'unset' }}
  >
    <div className="tl" title={file.name}>
      {file.name}
    </div>

    <div className={s.controls}>
      {onRemove && (
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            onRemove();
          }}
          className={s.remove}
        >
          <XIcon />
        </button>
      )}
    </div>
  </MyLink>
);
