import React from 'react';
import { connect } from 'react-redux';
import * as classnames from 'classnames';
import RadioButton from '../../../components/UI/RadioButton';
import { RoundButton } from '../../../components/UI/RoundButton';
import PriorityFoundersList from './priority';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import { setInvestmentStrategy } from '../../../store/services/investingServices';
import { setInvestingStatus } from '../../../store/actions/investActions';
import withScroll from '../../../hoc/withScroll';
import Dropdown from '../../../components/Dropdown';
import { USER_STATUSES } from '../../../helpers/constants';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
  isSubmittedVeriff,
} from '../../../helpers/user';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import WarningBlock from '../../../components/UI/WarningBlock';
import { MinInvestAmountWarning } from '../../../components/Warnings/MinInvestAmountWarning';
import './index.scss';

export const STRATEGIES = {
  standard: 'standard',
  priority: 'priority',
  not_selected: 'not_selected',
};

class InvSelectStrategy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStrategy:
        props.status.option === STRATEGIES.not_selected
          ? STRATEGIES.standard
          : props.status.option,
      selectedFounders: [],
    };
  }

  setStrategy = strategy => {
    this.setState(prevState => ({ ...prevState, selectedStrategy: strategy }));
  };

  onSubmit = e => {
    e.preventDefault();
    const payload = {
      option: this.state.selectedStrategy,
      founders: this.state.selectedFounders,
    };
    setInvestmentStrategy(payload).then(res => {
      if (res && res.success) {
        this.props.setInvestingStatus(res.data);
        this.props.setStep(2);
      }
    });
  };

  render() {
    const { selectedStrategy } = this.state;
    const { status, setStep, innerWidth, disabled, profile, veriffStatusCode } =
      this.props;

    return (
      <form className="inv-select-strategy" onSubmit={this.onSubmit}>
        <h1 className="f-42 f-500">Select an investment strategy</h1>

        {profile?.country_code === 'US' &&
          status?.min_investment_amount === 1000 && (
            <MinInvestAmountWarning className="inv-select-strategy__warning" />
          )}

        {profile && profile.status !== USER_STATUSES.verified && (
          <WarningBlock className="inv-select-strategy__warning" withBorder>
            {hasRequiredPersonalSectionFields(profile) &&
            hasRequiredProfileSectionFields(profile) &&
            isSubmittedVeriff(veriffStatusCode) ? (
              <div className="f-16 f-400">
                Investing is available only to verified investors. Your profile
                information is being checked, it may take up to two business
                days.
              </div>
            ) : (
              <div className="f-16 f-400">
                Complete the verification process to start investing.{' '}
                <MyLink href={APP_LINKS.dashboard} className="c-grey">
                  Complete verification&nbsp;→
                </MyLink>
              </div>
            )}
          </WarningBlock>
        )}

        <div className="inv-select-strategy__investment">
          <h2 className="f-26 f-500">How would you like to invest?</h2>
          <p className={classnames('f-16 f-400', disabled && 'disabledBlock')}>
            You have two options: allocate cash among all startups in the batch
            or prioritize allocation to the particular startups.
          </p>
          <div
            className={classnames(
              'inv-select-strategy__investment-group',
              disabled && 'disabledBlock'
            )}
          >
            <RadioButton
              className={classnames(
                'inv-select-strategy__investment-item',
                selectedStrategy === STRATEGIES.standard && 'active'
              )}
              checked={selectedStrategy === STRATEGIES.standard}
              disabled={disabled}
              onChange={() => this.setStrategy(STRATEGIES.standard)}
            >
              <h5 className="f-20 f-500">Standard allocation – recommended</h5>
              <p className="f-16 f-400">
                Your cash is allocated proportionally to all startups in the
                batch. We recommend investing in the batch as a whole – it
                diversifies your portfolio and makes it a good choice.
              </p>
            </RadioButton>

            <RadioButton
              className={classnames(
                'inv-select-strategy__investment-item',
                selectedStrategy === STRATEGIES.priority && 'active',
                !status.priority_strategy_enabled && 'disabled'
              )}
              checked={selectedStrategy === STRATEGIES.priority}
              disabled={disabled || !status.priority_strategy_enabled}
              onChange={() => this.setStrategy(STRATEGIES.priority)}
            >
              <h5 className="f-20 f-500">
                Priority allocation{' '}
                {!status.priority_strategy_enabled &&
                  ' - currently unavailable'}
              </h5>
              {!status.priority_strategy_enabled && (
                <p className="f-16 f-500 c-grey">
                  The priority strategy is unavailable if a brands batch
                  contains less than {status.priority_required_batch_size}{' '}
                  participants
                </p>
              )}
              <p className="f-16 f-400">
                Your cash is allocated with priority consideration given to the
                startups you select. Cash is allocated to all the startups in
                the batch, though in different proportions. Your future success
                return will be 1% less for choosing the priority strategy.
              </p>
            </RadioButton>
          </div>

          {selectedStrategy === STRATEGIES.standard && (
            <Dropdown
              title="Consumer Goods Brands batch"
              className={classnames(
                'inv-select-strategy__standard-founders',
                disabled && 'disabledBlock'
              )}
              disableToggle={disabled}
            >
              <ParticipatingStartupsList />
            </Dropdown>
          )}

          {selectedStrategy === STRATEGIES.priority && (
            <div className="inv-select-strategy__priority-founders">
              <PriorityFoundersList
                selectedStrategy={selectedStrategy}
                setSelections={selections =>
                  this.setState(prevState => ({
                    ...prevState,
                    selectedFounders: selections,
                  }))
                }
              />
            </div>
          )}

          <div className="inv-select-strategy__buttons">
            <RoundButton
              label={innerWidth < 600 ? 'Back' : 'Go back'}
              type="button"
              onClick={() => setStep(0)}
              className="inv-select-strategy__buttons-back"
            />
            <RoundButton
              label="Continue to the next step"
              fullWidth
              fillBackground
              type="submit"
              onSubmit={this.onSubmit}
              disabled={disabled}
              className="inv-select-strategy__buttons-continue"
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  innerWidth: state.commonStore.innerWidth,
  profile: state.profileStore.profile.data,
  veriffStatusCode: state.profileStore.veriffStatusCode,
});

const mapDispatchToProps = dispatch => ({
  setInvestingStatus: status => dispatch(setInvestingStatus(status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(InvSelectStrategy));
