import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../../analytics';
import ProfileSummaryForm from '../../components/Forms/ProfileSummaryForm';
import { updateProfileSummary } from '../../store/services/userServices';
import { getUserProfile } from '../../store/actions/profileActions';

const ProfileSummaryModal = ({ onClose, preSaved, onOutsideClick }) => {
  const user = useSelector(state => state.profileStore.profile.data);
  const userLocation = useSelector(state => state.userStore.userLocation);
  const dispatch = useDispatch();

  const onSubmit = values => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      company_name: values.company_name,
      position: values.position,
    };

    updateProfileSummary(payload).then(res => {
      if (res && res.success) {
        Analytics.profileEditProfile();
        dispatch(getUserProfile());
        onClose();
      }
    });
  };

  const handleOutsideClick = values => {
    onOutsideClick && onOutsideClick(values);
    onClose();
  };

  return (
    <ProfileSummaryForm
      user={user}
      location={userLocation}
      onSubmit={onSubmit}
      onCancel={onClose}
      preSaved={preSaved}
      onOutsideClick={handleOutsideClick}
    />
  );
};

export default ProfileSummaryModal;
