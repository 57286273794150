import React from 'react';
import UserCard from '../../components/Cards/UserCard';
import './index.scss';

const FoundersList = ({ founders, getUser }) => {
  return (
    <div className="founders-list__container">
      <h2 className="founders-list__title f-16">All founders</h2>
      <div className="founders-list">
        {founders.map((user) => (
          <UserCard key={user.id} user={user} onClick={() => getUser(user)} />
        ))}
      </div>
    </div>
  );
};

export default FoundersList;