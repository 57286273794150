import React from 'react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { DATE_FORMAT_DD_MMM, DATE_REQUEST } from '../../helpers/common';
import { MyLink } from '../MyLink';
import { APP_LINKS } from '../../helpers/links';
import { CONTACT_EMAIL, TIMEZONE_ESTONIA } from '../../helpers/constants';
import { resultProcess } from '../../helpers/date';
import {
  countdownForNotification,
  prettyFloatMoney,
} from '../../helpers/utils';

export const NOTIFICATIONS = {
  next_round_starts: start_date => idx =>
    (
      <li key={idx}>
        The next round starts{' '}
        {countdownForNotification(
          momentTZ.tz(start_date, TIMEZONE_ESTONIA).add(12, 'hours')
        )}
      </li>
    ),
  loan_disbursement_starts: end_date => idx =>
    (
      <li key={idx}>
        Loan disbursement starts{' '}
        {moment(end_date).add(1, 'day').format(DATE_FORMAT_DD_MMM)}, 00:00 GMT
      </li>
    ),
  change_allocation_settings_until: end_date => idx =>
    (
      <li key={idx}>
        You can change cash allocation settings{' '}
        {countdownForNotification(
          moment(end_date, DATE_REQUEST).add(1, 'day').format(DATE_REQUEST)
        )}
      </li>
    ),
  change_investment_strategy: end_date => idx =>
    (
      <li key={idx}>
        You can change the investment strategy until{' '}
        {moment(end_date).format(DATE_FORMAT_DD_MMM)}, 23:59 GMT
      </li>
    ),
  change_settings_until_end: () => idx =>
    (
      <li key={idx}>
        You can add more funds to the investment until the end of the round.
      </li>
    ),
  add_cash_to_account: () => idx =>
    (
      <li key={idx}>
        Add cash to your account and then allocate it among loan groups.
        <MyLink href={APP_LINKS.addFunds} className="c-grey">
          Add cash →
        </MyLink>
      </li>
    ),
  founders_reached_goal: () => idx =>
    (
      <li key={idx}>
        Founders reached their fundraising goals. It means that investing is not
        available in this round anymore. Please take part in the next round.
      </li>
    ),
  result_being_processed: () => idx =>
    (
      <li key={idx}>
        The results of the round are being processed, please wait a bit.
      </li>
    ),
  investment_strategy_restrictive: () => idx =>
    (
      <li key={idx}>
        Could your investment strategy be too restrictive? Email to our support
        team at&nbsp;
        <MyLink className="email" href={`mailto:${CONTACT_EMAIL}`} isExternal>
          {CONTACT_EMAIL}
        </MyLink>
      </li>
    ),
  unallocated_cash_reinvested: amount => idx =>
    (
      <li key={idx}>
        Unallocated cash of {prettyFloatMoney(amount)} will be automatically
        reinvested in businesses within the same loan group in the next round.{' '}
        <MyLink
          href={`${APP_LINKS.allocateCash}/?to=reinvest`}
          className="c-grey"
        >
          Change preferences →
        </MyLink>
      </li>
    ),
  round_results: end_date => idx =>
    (
      <li key={idx}>
        The round results will be processed{' '}
        <span className="f-500">
          {countdownForNotification(
            moment(end_date, DATE_REQUEST).add(1, 'day').format(DATE_REQUEST)
          )}
        </span>
        .
      </li>
    ),
  results_will_be_processed: end_date => idx =>
    (
      <li key={idx}>
        Now you just need to wait for the end of a round. The results will be
        processed on <span className="f-500">{resultProcess(end_date)}</span>.
      </li>
    ),
  transactions_available_to_verified_users: () => idx =>
    (
      <li key={idx}>
        Transactions are only available to verified users.{' '}
        <MyLink href={APP_LINKS.profile} className="c-grey underline">
          Complete the verification process →
        </MyLink>
      </li>
    ),
  checking_transactions_available_to_verified_users: () => idx =>
    (
      <li key={idx}>
        Transactions are only available to verified users. We are currently
        checking your profile information – it may take up to two business days.
        We will notify you by email when the results are ready.
      </li>
    ),
  provide_your_details_for_transactions: () => idx =>
    (
      <li key={idx}>
        Provide your details for transactions.{' '}
        <MyLink href={APP_LINKS.dashboard} className="c-grey underline">
          Provide details →
        </MyLink>
      </li>
    ),
  verify_phone_number: () => idx =>
    (
      <li key={idx}>
        Verify your phone number to complete a registration process.{' '}
        <MyLink
          href={APP_LINKS.profile + '/#phone_verification'}
          className="c-grey underline"
        >
          Verify →
        </MyLink>
      </li>
    ),
  verify_phone_number_to_join_round: () => idx =>
    (
      <li key={idx}>
        Verify your phone number to join the round.{' '}
        <MyLink
          href={APP_LINKS.profile + '/#phone_verification'}
          className="c-grey underline"
        >
          Verify →
        </MyLink>
      </li>
    ),
  bank_accounts_available_to_verified_users: () => idx =>
    (
      <li key={idx}>
        Bank accounts are only available to verified users.{' '}
        <MyLink href={APP_LINKS.profile} className="c-grey underline">
          Complete the verification process →
        </MyLink>
      </li>
    ),
};
