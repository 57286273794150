import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as classnames from 'classnames';
import { getRepaymentSchedule } from '../../store/actions/loanActions';
import Preloader from '../../components/Preloader';
import RepScheduleTable from './table';
import RepScheduleList from './list';
import { MyLink } from '../../components/MyLink';
import { APP_LINKS } from '../../helpers/links';
import Checkbox from '../../components/UI/Checkbox';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { getUserProfile } from '../../store/actions/profileActions';
import { USER_STATUSES } from '../../helpers/constants';
import './index.scss';

export const REPAYMENT_STATUSES = {
  scheduled: {
    label: 'Scheduled',
    color: '#000000',
    className: '',
  },
  paid: {
    label: 'Paid',
    color: '#9E98A3',
    className: 'c-grey',
  },
  paid_overdue: {
    label: 'Paid after the due date',
    color: '#9E98A3',
    className: 'c-grey',
  },
  paid_partially: {
    label: 'Partially paid',
    color: '#000000',
    className: '',
  },
  paid_partially_late: {
    label: 'Late - Partially paid',
    color: '#F93C10',
    className: 'c-red',
  },
  late: {
    label: 'Late',
    color: '#F93C10',
    className: 'c-red',
  },
};

const RepaymentScheduleModule = () => {
  const dispatch = useDispatch();
  const [excludePaid, setExcludePaid] = useState(true);
  const { repaymentSchedule } = useSelector(state => state.loanStore);
  const { innerWidth } = useSelector(state => state.commonStore);
  const profile = useSelector(state => state.profileStore.profile.data);

  useEffect(() => {
    dispatch(getRepaymentSchedule({ exclude_paid: excludePaid ? 1 : 0 }));
  }, [excludePaid]);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  const { data, loading } = repaymentSchedule;
  const isEmpty = !data?.list.length;

  if (profile?.status === USER_STATUSES.suspended && isEmpty) {
    return (
      <div className="repayment_schedule_module">
        <SuspendResidenceWarning />
      </div>
    );
  }

  return (
    <div className="repayment_schedule_module">
      {profile?.status === USER_STATUSES.suspended && (
        <SuspendResidenceWarning className="repayment_schedule_module__warning" />
      )}
      {!isEmpty && data?.show_extend_disclaimer && (
        <div className="repayment_schedule_module__disclaimer f-16-24 f-400">
          The repayment schedule is subject to changes in case a startup extends
          loan terms (to a maximum of 24 months) or moves a monthly repayment to
          the last month of the current loan term. Please find more details in
          the{' '}
          <MyLink href={APP_LINKS.helpLoanTerms} underline>
            Loan terms
          </MyLink>
          .
        </div>
      )}

      <div className="repayment_schedule_module__controls">
        <Checkbox
          name="excludePaid"
          isChecked={excludePaid}
          onChange={() => setExcludePaid(!excludePaid)}
          className="repayment_schedule_module__exclude"
          render={() => (
            <span className={classnames('f-16')}>Hide paid payments</span>
          )}
        />
      </div>

      {isEmpty && !loading && (
        <div className="repayment_schedule_module__empty f-16">
          No records yet.
        </div>
      )}

      {loading ? (
        <Preloader className="repayment_schedule_module__preloader" />
      ) : (
        !isEmpty &&
        (innerWidth < 992 ? (
          <RepScheduleList data={data.list} />
        ) : (
          <RepScheduleTable data={data.list} />
        ))
      )}
    </div>
  );
};

export default RepaymentScheduleModule;
