import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { APP_LINKS } from '../../helpers/links';
import { USER_STATUSES } from '../../helpers/constants';
import NotificationBlock from '../NotificationBlock';
import { NOTIFICATIONS } from '../../components/Notification/list';
import { BankDetailsTable } from './table';
import { BankDetailCreate } from './create';
import { BankDetailUpdate } from './edit';
import s from './index.module.scss';

const BankAccountsModule = () => {
  const { user } = useSelector(state => state.userStore);
  const notifications = [];

  if (user?.status !== USER_STATUSES.verified) {
    notifications.push(
      NOTIFICATIONS.bank_accounts_available_to_verified_users()
    );
  }

  return (
    <div className={s.wrapper}>
      <NotificationBlock
        className={s.notifications}
        notifications={notifications}
      />

      {user?.status === USER_STATUSES.verified && (
        <>
          <Route
            path={APP_LINKS.bankAccountsCreate}
            component={BankDetailCreate}
            exact
          />
          <Route
            path={APP_LINKS.bankAccountsEdit(':id')}
            component={BankDetailUpdate}
            exact
          />
          <Route
            path={APP_LINKS.bankAccounts}
            component={BankDetailsTable}
            exact
          />
        </>
      )}
    </div>
  );
};

export default BankAccountsModule;
