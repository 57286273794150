import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as classnames from 'classnames';
import { RoundButton } from '../../components/UI/RoundButton';
import { prettyFloatMoney } from '../../helpers/utils';
import { STRATEGIES } from '../InvestingModule/InvSelectStrategy';
import {
  getInvestingResults,
  setInvestingStatus,
} from '../../store/actions/investActions';
import {
  getInvestmentGroups,
  updateAutoInvestStatus,
} from '../../store/services/investingServices';
import './index.scss';

class InvestingInfo extends Component {
  constructor(props) {
    super(props);
    this.initialAutoInvest = props.status.auto_invest;
    this.state = {
      loanGroups: null,
    };
  }

  componentDidMount() {
    const { status, skipResultFetch, getInvestingResults } = this.props;
    const isResult = status.step === 5;
    if (isResult) {
      !skipResultFetch && getInvestingResults();
    } else {
      getInvestmentGroups().then(
        res =>
          res &&
          res.success &&
          this.setState(prevState => ({
            ...prevState,
            loanGroups: res.data,
          }))
      );
    }
  }

  render() {
    const { loanGroups } = this.state;
    const {
      status,
      onLoansEdit,
      onStrategyEdit,
      investingResult,
      setInvestingStatus,
      isDashboard,
      hasLoans,
      className,
    } = this.props;
    const isDisabled = status.step === 666;
    const isResult = status.step === 5;
    const hasReachedGoal = status.total_remaining_amount <= 0;
    const hasAllocated = !!Number(status.earmarked);

    const groups = [];
    isResult
      ? investingResult.data &&
        investingResult.data.groups
          .filter(group => group.is_active)
          .map(group =>
            groups.push({ title: group.title, amount: group.invested_in_group })
          )
      : loanGroups &&
        loanGroups
          .filter(item => item.group.is_active)
          .map(item =>
            groups.push({ title: item.group.title, amount: item.amount })
          );

    return (
      <div
        className={classnames(
          'investing-info',
          isDisabled && 'disabled',
          className
        )}
      >
        <div className="investing-info__content">
          <div className="investing-info__strategy">
            <div className="investing-info__strategy-header">
              <div className="investing-info__strategy-approach">
                <p className="f-16">Investment strategy</p>
                <p className="f-26 f-500">
                  {status.option === STRATEGIES.not_selected && '-'}
                  {status.option === STRATEGIES.standard && 'Standard strategy'}
                  {status.option === STRATEGIES.priority && 'Priority strategy'}
                </p>
                {status.option === STRATEGIES.priority && (
                  <div className="investing-info__strategy-priority f-16 f-400">
                    Your future success return will be 1% less for the
                    prioritization of some founders and their respective
                    businesses over others in the same batch.
                  </div>
                )}
              </div>
              {onStrategyEdit &&
                status.step < 4 &&
                status.option !== STRATEGIES.not_selected &&
                !hasReachedGoal && (
                  <RoundButton
                    label="Edit"
                    type="button"
                    onClick={onStrategyEdit}
                    className="investing-info__strategy-edit"
                  />
                )}
            </div>
          </div>

          <div className="investing-info__summary">
            <div className="investing-info__baskets">
              {isResult ? (
                <div className="investing-info__result-header">
                  <div className="investing-info__result-invested">
                    <p className="f-16">Invested</p>
                    <p className="f-42 f-500 tl">
                      {prettyFloatMoney(
                        investingResult.data &&
                          investingResult.data.total_invested
                      )}
                    </p>
                  </div>
                  {/* <div className="investing-info__result-earned"> */}
                  {/*  <p className="f-16">Earned</p> */}
                  {/*  <p className="f-42 f-500 tl">{prettyFloatMoney(investingResult.data && investingResult.data.total_earned)}</p> */}
                  {/* </div> */}
                </div>
              ) : (
                <div className="investing-info__baskets-header">
                  <div className="investing-info__baskets-earmarked">
                    <p className="f-16">Total allocated for investing</p>
                    <p className="f-42 f-500 tl">
                      {prettyFloatMoney(status.earmarked)}
                    </p>
                  </div>
                  {onLoansEdit &&
                    status.step < 4 &&
                    hasAllocated &&
                    !hasReachedGoal && (
                      <RoundButton
                        type="button"
                        label="Edit"
                        onClick={onLoansEdit}
                        className="investing-info__baskets-edit"
                      />
                    )}
                </div>
              )}

              {!!groups.length && (
                <div className="investing-info__baskets-list">
                  {groups.map((group, index) => (
                    <div className="investing-info__baskets-item" key={index}>
                      <p className="f-16">{group.title}</p>
                      <p className="f-26 f-500 tl">
                        {prettyFloatMoney(group.amount)}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              {onLoansEdit &&
                status.step < 4 &&
                hasAllocated &&
                !hasReachedGoal && (
                  <RoundButton
                    type="button"
                    label="Edit"
                    onClick={onLoansEdit}
                    className="investing-info__baskets-edit bottom"
                    fullWidth
                  />
                )}

              {/*
              {!isResult && (
                <>
                  <p className="investing-info__baskets-reinvest f-16">
                    {status.reinvest
                      ? 'Repayments are returned to the same risk groups.'
                      : 'Repayments are moved to the Available cash.'}
                  </p>

                  <p className="investing-info__baskets-autoinvest f-16">
                    {status.auto_invest
                      ? 'Autoinvest is on.'
                      : 'Autoinvest is off.'}
                  </p>
                </>
              )}
              */}
            </div>

            {/*
            {!isDashboard && hasLoans && !this.initialAutoInvest && (
              <div className="investing-info__autoinvest">
                <p className="f-16 f-400">
                  Great results! Activate autoinvest option and the remaining unallocated funds will be
                  {' '}
                  automatically invested in future rounds according to the standard strategy.
                </p>
                <RoundButton
                  label={status.auto_invest ? 'Turn off autoinvest' : 'Turn on autoinvest'}
                  onClick={() => {
                    updateAutoInvestStatus({ auto_invest: !status.auto_invest })
                      .then(res => res && res.success && setInvestingStatus({
                        ...status,
                        auto_invest: res.data.auto_invest,
                      }));
                  }}
                />
              </div>
            )}
            */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investingResult: state.investStore.investingResult,
});

const mapDispatchToProps = dispatch => ({
  getInvestingResults: () => dispatch(getInvestingResults()),
  setInvestingStatus: status => dispatch(setInvestingStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestingInfo);
