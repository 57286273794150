import React from 'react';
import { RoundLink } from '../../components/UI/RoundLink';
import { prettyFloatMoney } from '../../helpers/utils';
import { APP_LINKS } from '../../helpers/links';
import { MyLink } from '../../components/MyLink';
import './index.scss';

const PrivateInvestorPage = ({ user }) => (
  <div className="private-investor-page">
    <div className="container-inner">
      <h1 className="private-investor-page__title f-42 f-500">Scramble 99</h1>
      <p className="private-investor-page__desc f-16">
        5-year program for the first 99 key account customers
      </p>
      <div className="private-investor-page__box">
        <div className="private-investor-page__box-title f-16">
          You’re investor number
        </div>
        <div className="private-investor-page__box-number f-42">
          {user.unique_id}
        </div>
        <div className="private-investor-page__box-title f-16">
          Bonus duration
        </div>
        <div className="private-investor-page__box-value f-32">5 years</div>
      </div>
      <div className="private-investor-page__works">
        <h2 className="f-26 f-500">How it works?</h2>
        <p>
          Special terms provided to the early 99 Scramble investors customers.{' '}
          Your membership in Scramble 99 is considered active once you have
          invested at least €1’000 within the first 6 months following your
          registration date. Bonuses are accrued during the month following each
          subsequent 6-months period. Program is valid for 5 years.
        </p>
        <MyLink href={APP_LINKS.privateInvestorPDF} isExternal>
          Terms and Conditions
        </MyLink>
      </div>
      <div className="private-investor-page__terms-container">
        <div className="private-investor-page__terms row">
          <h2 className="f-26 f-500">Terms</h2>
          <RoundLink
            path={APP_LINKS.addFunds}
            label="Add Cash"
            fillBackground
            className="private-investor-page__terms-fund"
          />
        </div>
        <div className="private-investor-page__cards">
          <div className="private-investor-page__card">
            <div className="f-26">+1%</div>
            <div className="f-16">Extra annual return paid by Scramble</div>
            <div className="private-investor-page__card-amount f-26">
              If {prettyFloatMoney(9000)}+
            </div>
            <div className="f-16">
              Loans outstanding over any subsequent six calendar months
            </div>
          </div>
          <div className="private-investor-page__card">
            <div className="f-26">+2%</div>
            <div className="f-16">Extra annual return paid by Scramble</div>
            <div className="private-investor-page__card-amount f-26">
              If {prettyFloatMoney(18000)}+
            </div>
            <div className="f-16">
              Loans outstanding over any subsequent six calendar months
            </div>
          </div>
          <div className="private-investor-page__card">
            <div className="f-26">+3%</div>
            <div className="f-16">Extra annual return paid by Scramble</div>
            <div className="private-investor-page__card-amount f-26">
              If {prettyFloatMoney(24000)}+
            </div>
            <div className="f-16">
              Loans outstanding over any subsequent six calendar months
            </div>
          </div>
        </div>
        <p className="private-investor-page__note">
          *The bonus of 1-3% p.a. is accrued for the investment amount up to
          €100’000
        </p>
      </div>
    </div>
  </div>
);

export default PrivateInvestorPage;
