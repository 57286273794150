import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { getTrackBackground, Range } from 'react-range';
import s from './index.module.scss';

const getStep = value => {
  if (Number(value) <= 100) {
    return 10;
  }
  if (Number(value) <= 1000) {
    return 50;
  }
  return 100;
};

export const RangeStepField = ({ value, min, max, className, onChange }) => {
  const [step, setStep] = useState(getStep(value));

  useEffect(() => {
    setStep(getStep(value));
  }, [value]);

  return (
    <div className={classnames(s.wrapper, className)}>
      <Range
        values={[value]}
        step={step}
        min={min}
        max={max}
        onChange={values => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className={s.track_wrapper}
          >
            <div
              ref={props.ref}
              className={s.track}
              style={{
                background: getTrackBackground({
                  values: [value],
                  colors: ['#FFD200', '#F5F5F5'],
                  min,
                  max,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={Pointer}
      />
    </div>
  );
};

const Pointer = ({ props }) => <div {...props} className={s.pointer} />;
