import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipatingFounders } from '../../store/actions/founderActions';
import FounderCard from '../../components/Cards/FounderCard';

const FoundersActive = () => {
  const dispatch = useDispatch();
  const founders = useSelector(
    state => state.founderStore.participatingFounders
  );

  useEffect(() => {
    dispatch(getParticipatingFounders({ page: 1, limit: 40 }));
  }, []);

  const hasFounders = !!(founders && founders.list.length);

  if (!hasFounders) {
    return null;
  }

  return (
    <div className="founders-active">
      <h2 className="f-26 f-500">
        Active founders — started their fundraising
      </h2>
      {hasFounders ? (
        <div className="founders-active__list">
          {founders.list.map(founder => (
            <FounderCard key={founder.id} founder={founder} active hideFR />
          ))}
        </div>
      ) : (
        <div className="founders-active__empty">
          <span className="founders-active__empty-title f-16 f-400">
            No active founders yet.
          </span>
        </div>
      )}
    </div>
  );
};

export default FoundersActive;
