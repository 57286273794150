import React from 'react';
import { connect } from 'react-redux';
import NotificationBlock from '../../NotificationBlock';
import { RoundLink } from '../../../components/UI/RoundLink';
import { getInvestingResults } from '../../../store/actions/investActions';
import InvestingInfo from '../../InvestingInfo';
import withScroll from '../../../hoc/withScroll';
import InvHappyStartups from './happyStartups';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import InviteFriends from '../../../components/InviteFriends';
import { APP_LINKS } from '../../../helpers/links';
import { WriteReview } from '../../../components/WriteReview';
import './index.scss';

class InvResult extends React.Component {
  constructor(props) {
    super(props);
    this.initialAutoInvestStatus = props.status.auto_invest;
  }

  componentDidMount() {
    this.props.getInvestingResults();
  }

  render() {
    const { investingResult, status } = this.props;
    const { data: result, loading } = investingResult;

    if (loading) {
      return null;
    }

    if (!result) {
      return (
        <h1 className="f-42 f-500">
          Could not get investing results. Please try later
        </h1>
      );
    }

    const notifications = [];
    const hasLoans = !!result.total_invested;
    const notAllocatedAmount =
      Number(result.initially_allocated) - Number(result.total_invested);

    !hasLoans &&
      notifications.push(NOTIFICATIONS.investment_strategy_restrictive());

    this.initialAutoInvestStatus &&
      !!notAllocatedAmount &&
      notifications.push(
        NOTIFICATIONS.unallocated_cash_reinvested(notAllocatedAmount)
      );

    return (
      <div className="inv-result">
        <h1 className="f-42 f-500">
          {hasLoans
            ? 'Congrats! You have made some promising investments'
            : 'Ouch! Something went wrong - you haven’t made any new investments'}
        </h1>
        <NotificationBlock
          className="inv-result__notifications"
          notifications={notifications}
        />
        <InvestingInfo
          status={status}
          skipResultFetch
          hasLoans={hasLoans}
          className="inv-result__summary"
        />

        {hasLoans && (
          <RoundLink
            label="See details"
            path={APP_LINKS.loans}
            fullWidth
            className="inv-result__button"
          />
        )}

        <InviteFriends analyticSource="results" />

        <WriteReview className="inv-result__trustpilot" />

        <InvHappyStartups />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investingResult: state.investStore.investingResult,
});

const mapDispatchToProps = dispatch => ({
  getInvestingResults: () => dispatch(getInvestingResults()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(InvResult));
