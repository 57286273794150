import React from 'react';
import classnames from 'classnames';
import { STEPS } from '../index';
import s from '../index.module.scss';

export const AccountCreateStep = ({ passed }) => {
  const active = passed.includes(STEPS.account_create);

  return (
    <li
      id={`vpw_${STEPS.account_create}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400'
          )}
        >
          01
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_title, 'f-26 f-400')}>
          Create an investor account
        </div>
      </div>
    </li>
  );
};
