import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import Notify from '../../components/Notification';

export const uploadPhoto = fileObj => {
  let file;
  if (fileObj instanceof FormData) {
    file = fileObj;
  } else {
    file = new FormData();
    file.append('file', fileObj);
  }

  return axios
    .post(Pathes.Common.photo, file)
    .then(response => {
      const { data, status } = response;
      if (status === 201) {
        return { data, success: true };
      }
      Notify.error({ text: 'Could not upload file' });
      throw new Error('Could not upload file');
    })
    .catch(e => ({ error: e.message, success: false }));
};

export const uploadFile = fileObj => {
  let file;
  if (fileObj instanceof FormData) {
    file = fileObj;
  } else {
    file = new FormData();
    file.append('file', fileObj);
  }

  return axios
    .post(Pathes.Common.file, file)
    .then(
      response =>
        response &&
        response.status === 201 && { data: response.data, success: true }
    )
    .catch(() => Notify.error({ text: 'Could not upload file' }));
};
