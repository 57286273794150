import React from 'react';

export const InstagramEmbed = ({ link, className }) => (
  <div className={className}>
    <iframe
      width="320"
      src={link}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded instagram"
    />
  </div>
);
