import { push } from 'react-router-redux';
import axios from '../../axios-api';
import Notify from '../../components/Notification';
import { MESSAGES } from '../../helpers/toaster';
import { APP_LINKS } from '../../helpers/links';
import Pathes from '../../helpers/pathes';
import Analytics from '../../analytics';
import {
  getLastRoute,
  getMessage,
  removeLastRoute,
  userObject,
} from '../../helpers/utils';
import { CONTACT_EMAIL } from '../../helpers/constants';
import { getReferralCodeFromCookie } from '../../helpers/referral';
import { collectDetailsUTM } from '../../helpers/utm';
import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  SET_USER,
  SET_USER_LOCATION,
  UPDATE_USER,
} from './actionTypes';

const registerUserRequest = () => ({ type: REGISTER_USER.REQUEST });
const registerUserSuccess = payload => ({
  type: REGISTER_USER.SUCCESS,
  payload,
});
const registerUserFailure = error => ({ type: REGISTER_USER.FAILURE, error });

const loginUserRequest = () => ({ type: LOGIN_USER.REQUEST });
const loginUserSuccess = payload => ({ type: LOGIN_USER.SUCCESS, payload });
const loginUserFailure = error => ({ type: LOGIN_USER.FAILURE, error });

export const setUser = user => ({
  type: SET_USER,
  user: userObject(user),
});

// Send login code
export const loginUser = data => {
  return dispatch => {
    dispatch(loginUserRequest());
    return axios
      .post(Pathes.Auth.login, data)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          data.key && Analytics.login(data.user);
          dispatch(
            loginUserSuccess({
              token: data.key || null,
              temp_token: data.temp_token || null,
              user: userObject(data.user),
            })
          );
          return { data, success: true };
        }

        message &&
          !message.includes(CONTACT_EMAIL) &&
          Notify.error({ text: message });
        throw new Error(message);
      })
      .catch(e => {
        dispatch(loginUserFailure(e.message));
      });
  };
};

// Submit login code
export const submitLoginCode = payload => {
  return dispatch => {
    return axios
      .post(Pathes.Auth.submitLoginCode, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(
            loginUserSuccess({
              token: data.key,
              temp_token: null,
              user: userObject(data.user),
            })
          );
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(() => {
        return { success: false, error: 'Wrong code! Try again.' };
      });
  };
};

export const registerUser = userData => {
  return dispatch => {
    dispatch(registerUserRequest());
    return axios
      .post(Pathes.User.register, {
        ...userData,
        ...collectDetailsUTM(),
      })
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200 || status === 201) {
          Analytics.register(data.user);
          dispatch(
            registerUserSuccess({
              token: data.key,
              user: userObject(data.user),
            })
          );
          Notify.success({ text: 'Account created' });
          return { data, success: true };
        }
        if (status === 406) {
          sessionStorage.setItem('existingEmail', userData.email);
          dispatch(loginUser(userData)).then(response => {
            response.user
              ? dispatch(push(APP_LINKS.dashboard))
              : dispatch(push(APP_LINKS.twoFactorAuth));
          });
          throw new Error(message);
        }

        Notify.error({ text: message });
      })
      .catch(e => dispatch(registerUserFailure(e.message)));
  };
};

export const logoutUser = () => {
  return dispatch => {
    dispatch({ type: LOGOUT_USER });
    dispatch(push(APP_LINKS.signIn));
  };
};

export const contactInfoUpdate = userData => {
  return dispatch => {
    return axios.post(Pathes.User.stepTwo, userData).then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Analytics.regProfile();
        dispatch(setUser(data));
        return { data, success: true };
      }
      Notify.error({ text: message });
      throw new Error(message);
    });
  };
};

export const personalInfoUpdate = userData => {
  return dispatch => {
    return axios.post(Pathes.User.stepThree, userData).then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Analytics.regPersonal();
        dispatch(setUser(data));
        Notify.success({ text: 'Contact information updated successfully' });
        return { success: true };
      }
      Notify.error({ text: message });
      throw new Error(message);
    });
  };
};

export const facebookLogin = (facebookResponse, callback, inviteCode) => {
  return dispatch => {
    if (facebookResponse && facebookResponse.id) {
      const { accessToken } = facebookResponse;
      const reqPayload = {
        access_token: accessToken,
        code: '',
      };

      if (inviteCode) {
        reqPayload.promo_code = inviteCode;
      }

      dispatch(loginUserRequest());
      return axios
        .post(Pathes.User.facebook, reqPayload)
        .then(response => {
          const { data, status } = response;
          if (status === 200) {
            dispatch(
              loginUserSuccess({
                token: data.key,
                user: {
                  id: data.user.id,
                  role_id: data.user.role_id,
                  first_name: data.user.first_name,
                  last_name: data.user.last_name,
                  email: data.user.email,
                  photo: data.user.photo,
                  unique_id: data.user.unique_id,
                  status: data.user.status,
                },
              })
            );

            callback && callback(2);
            Notify.success({
              text: `Welcome, ${response.data.user.first_name} ${response.data.user.last_name}`,
            });
          }
        })
        .catch(e => {
          loginUserFailure(e.message);
          Notify.error({ text: e.message });
          throw new Error(e.message);
        });
    }
  };
};

export const googleLogin = (googleResponse, callback, inviteCode) => {
  return dispatch => {
    if (googleResponse && googleResponse.googleId) {
      const { accessToken } = googleResponse;
      const referralCode = getReferralCodeFromCookie();
      const reqPayload = {
        access_token: accessToken,
        code: '',
        ...collectDetailsUTM(),
      };

      if (inviteCode) {
        reqPayload.promo_code = inviteCode;
      }

      if (referralCode) {
        reqPayload.referral_code = referralCode;
      }

      dispatch(loginUserRequest());
      return axios
        .post(Pathes.User.google, reqPayload)
        .then(response => {
          const { data, status } = response;

          if (status === 406) {
            // Option when User already registered with login & password. (Not social).
            Notify.error({
              text: 'User is already registered with this e-mail address.',
            });
          }

          if (status === 200) {
            if (data.temp_token) {
              dispatch(
                loginUserSuccess({
                  token: null,
                  temp_token: data.temp_token,
                  user: null,
                })
              );
              dispatch(push(APP_LINKS.twoFactorAuth));
              return { data, success: true };
            }

            if (data.key) {
              dispatch(
                loginUserSuccess({
                  token: data.key,
                  temp_token: null,
                  user: userObject(data.user),
                })
              );
            }

            const lastRoute = getLastRoute();

            if (data.user.is_new_user) {
              if (callback) {
                callback();
              } else {
                dispatch(push(lastRoute || APP_LINKS.dashboard));
                removeLastRoute();
                Notify.success({
                  text: `Welcome, ${response.data.user.first_name} ${response.data.user.last_name}`,
                });
              }
              Analytics.signUpGoogle(data.user);
            } else {
              Analytics.signInGoogle(data.user);
              dispatch(push(lastRoute || APP_LINKS.dashboard));
              removeLastRoute();
              Notify.success({
                text: `Welcome, ${response.data.user.first_name} ${response.data.user.last_name}`,
              });
            }
          }
        })
        .catch(e => {
          loginUserFailure(e.message);
          Notify.error({ text: e.message });
          throw new Error(e.message);
        });
    }
  };
};

export const appleLogin = (appleResponse, callback, inviteCode) => {
  return dispatch => {
    if (appleResponse && appleResponse.authorization) {
      const { authorization } = appleResponse;
      const referralCode = getReferralCodeFromCookie();
      const reqPayload = {
        id_token: authorization.id_token,
        code: authorization.code,
        ...collectDetailsUTM(),
      };

      if (inviteCode) {
        reqPayload.promo_code = inviteCode;
      }

      if (referralCode) {
        reqPayload.referral_code = referralCode;
      }

      dispatch(loginUserRequest());
      return axios
        .post(Pathes.User.apple, reqPayload)
        .then(response => {
          const { data, status } = response;
          if (status === 200) {
            if (data.temp_token) {
              dispatch(
                loginUserSuccess({
                  token: null,
                  temp_token: data.temp_token,
                  user: null,
                })
              );
              dispatch(push(APP_LINKS.twoFactorAuth));
              return { data, success: true };
            }

            if (data.key) {
              dispatch(
                loginUserSuccess({
                  token: data.key,
                  temp_token: null,
                  user: userObject(data.user),
                })
              );
            }

            const lastRoute = getLastRoute();

            if (data.user.is_new_user) {
              if (callback) {
                callback();
              } else {
                dispatch(push(lastRoute || APP_LINKS.dashboard));
                removeLastRoute();
                Notify.success({
                  text: `Welcome, ${response.data.user.first_name} ${response.data.user.last_name}`,
                });
              }
              Analytics.signUpApple(data.user);
            } else {
              Analytics.signInApple(data.user);
              dispatch(push(lastRoute || APP_LINKS.dashboard));
              removeLastRoute();
              Notify.success({
                text: `Welcome, ${response.data.user.first_name} ${response.data.user.last_name}`,
              });
            }
          }
        })
        .catch(e => {
          loginUserFailure(e.message);
          Notify.error({ text: e.message });
          throw new Error(e.message);
        });
    }
  };
};

export const linkedinLogin = (linkedInResponse, callback) => {
  return dispatch => {
    if (linkedInResponse && linkedInResponse.googleId) {
      const { accessToken } = linkedInResponse;
      const reqPayload = {
        access_token: accessToken,
        code: '',
      };

      dispatch(loginUserRequest());
      return axios.post(Pathes.User.linkedin, reqPayload).then(
        response => {
          if (response && response.data && response.data.key) {
            dispatch(loginUserSuccess(response.data));
            callback && callback(2);
            Notify.success({
              text: `Welcome, ${response.data.user.first_name} ${response.data.user.last_name}`,
            });
          }
        },
        error => {
          dispatch(loginUserFailure(error.response));
          Notify.error({ text: MESSAGES.somethingwrong });
        }
      );
    }
  };
};

export const getUserLocation = () => {
  return async dispatch => {
    try {
      const response = await axios.get(Pathes.User.myLocation);
      if (response && response.status === 200) {
        const { data } = response;
        const location = {
          city: data.city,
          country: data.country_name,
          countryCode: data.country_code,
          postal: data.postal,
          state: data.state,
          ip: data.IPv4,
          lat: data.latitude,
          lon: data.longitude,
        };

        dispatch({ type: SET_USER_LOCATION, location });
      }
    } catch (e) {
      // do nothing
    }
  };
};

export const forgotPassword = email => {
  return () => {
    return axios.post(Pathes.Auth.forgotPassword, email).then(response => {
      const { status } = response;
      if (status === 404) {
        Notify.error({ text: 'This email is not registered' });
      }

      if (status === 200) {
        Notify.success({ text: 'Please, check your email' });
        return { success: true };
      }
    });
  };
};

export const verifyToken = token => {
  return axios
    .post(Pathes.Auth.verifyToken, token)
    .then(r => r && r.data && r.data.token);
};

export const resetPassword = data => {
  return async dispatch => {
    try {
      const response = await axios.post(Pathes.Auth.resetPassword, data);
      if (response && response.status === 200) {
        Analytics.resetPassword();
        Notify.success({
          text: (response.data && response.data.message) || 'Password changed',
        });
        return dispatch(push(APP_LINKS.signIn));
      }
      const message = getMessage(response.data);
      throw new Error(message);
    } catch (e) {
      // do nothing
    }
  };
};

export const updateUser = userData => {
  return (dispatch, getState) => {
    const prevData = getState().userStore.user;
    if (prevData) {
      dispatch({
        type: UPDATE_USER,
        user: userObject({ ...prevData, ...userData }),
      });
    }
  };
};
