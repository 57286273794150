import React from 'react';
import qs from 'qs';
import Decimal from 'decimal.js-light';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { MESSAGES } from './toaster';
import { CURRENCY, LOAN_GROUPS_SHORT, TIMEZONE_ESTONIA } from './constants';
import { STRATEGIES } from '../containers/InvestingModule/InvSelectStrategy';
import { APP_LINKS } from './links';
import config from '../config';
import {
  DATE_FORMAT_D,
  DATE_FORMAT_D_MMMM,
  DATE_FORMAT_DD_MMMM,
  DATE_FORMAT_M,
  DATE_REQUEST,
} from './common';

Decimal.config({ rounding: Decimal.ROUND_DOWN });

export const padNumber = (id, totalLength = 6, prefix = 'L') => {
  const zeros = new Array(totalLength).fill(0).join('');
  return !id
    ? zeros
    : `${id}`.length >= totalLength
    ? `${prefix} + ${id}`
    : `${prefix}${(zeros + id).slice(totalLength * -1)}`;
};

export const getQuery = (params, exclude = []) => {
  let datasets = '';
  if (params) {
    const filteredParams = {
      ...params,
      search: params.search ? params.search : null,
    };
    const excludeList = [...exclude, 'hasMore'];
    excludeList.map(key => delete filteredParams[key]);
    datasets = qs.stringify(
      { ...filteredParams },
      { strictNullHandling: true, arrayFormat: 'comma', skipNulls: true }
    );
    if (datasets) {
      return `?${datasets}`;
    }
  }
  return '';
};

export const getSignUpErrorMessage = res => res && res.message;

export const getMessage = (res, customMsg) =>
  (res && res.message) || customMsg || MESSAGES.somethingwrong;

export const getFullname = (firstname, lastname, defaultName = '') => {
  if (firstname || lastname) {
    return `${firstname || ''} ${lastname || ''}`.trim();
  }
  return defaultName;
};

export const getNameInitials = (firstname = '', lastname = '') =>
  [firstname, lastname]
    .map(name => name.charAt(0))
    .filter(initial => initial)
    .join('')
    .toUpperCase()
    .trim();

export const numberWithSpaces = x =>
  x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : 0;

export const prettyMoney = (value, options = {}) => {
  const { disableCurrency } = options;

  const x = parseInt(value);
  if (x) {
    const digit = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return `${
      !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}` : ''
    }${digit}`;
  }
  return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}0` : 0;
};

export const prettyFloatMoney = (value, options = {}) => {
  const {
    disableCurrency = false,
    roundUpper = false,
    roundUpperFixed = false,
  } = options;

  try {
    if (!value || !Number(value)) {
      return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}0` : 0;
    }
    const x = Number(round(value, { roundUpper, roundUpperFixed }));
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return `${
      !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}` : ''
    }${parts.join('.')}`;
  } catch (e) {
    return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}0` : 0;
  }
};

export const prettyMoneyHybrid = (value, options = {}) => {
  const { disableCurrency, floatOnMin = 10000, ...other } = options;
  const x = Number(value);
  if (x) {
    return x < floatOnMin
      ? prettyFloatMoney(value, { disableCurrency, ...other })
      : prettyMoney(value, { disableCurrency });
  }
  return !disableCurrency ? `${CURRENCY} 0` : 0;
};

export const round = (num, options = {}) => {
  const {
    decimalPlaces = 2,
    roundUpper = false,
    roundUpperFixed = false,
  } = options;

  try {
    if (roundUpperFixed) {
      const obrezok = new Decimal(num).toFixed(decimalPlaces);
      const ostatok = new Decimal(num).sub(obrezok).toNumber();
      if (ostatok !== 0) {
        return Number(new Decimal(num).add(0.01).toFixed(decimalPlaces));
      }
    }
    return Number(
      new Decimal(num).toFixed(decimalPlaces, roundUpper ? 8 : undefined) || 0
    );
  } catch (e) {
    return 0;
  }
};

export const checkActiveFundraising = (startDate, endDate) => {
  if (startDate && endDate) {
    const currentDate = moment.utc();
    const fundraisingStart = moment.utc(startDate);
    const fundraisingEnd = moment.utc(endDate);
    return !!(
      currentDate.isAfter(fundraisingStart) &&
      currentDate.isBefore(fundraisingEnd)
    );
  }
  return false;
};

export const shortenDigit = (value, disableCurrency) => {
  const x = Number(value);
  if (x) {
    if (x < 1000) {
      return !disableCurrency
        ? `${CURRENCY}${String.fromCharCode(160)}${x}`
        : x;
    }
    if (x < 1000000) {
      return !disableCurrency
        ? `${CURRENCY}${String.fromCharCode(160)}${Number(
            (x / 1000).toFixed(1)
          )}k`
        : `${Number((x / 1000).toFixed(1))}k`;
    }
    if (x < 1000000000) {
      return !disableCurrency
        ? `${CURRENCY}${String.fromCharCode(160)}${Number(
            (x / 1000000).toFixed(1)
          )}m`
        : `${Number((x / 1000000).toFixed(1))}m`;
    }
    return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}${x}` : x;
  }
  return !disableCurrency ? `${CURRENCY}${String.fromCharCode(160)}0` : 0;
};

export const checkForValidFile = (
  file,
  allowedTypes,
  allowedMaxSize,
  allowedMinSize
) => {
  const result = { isValid: true };
  if (file) {
    const { type, size } = file;
    if (allowedTypes && !allowedTypes.includes(type)) {
      result.isValid = false;
      result.type = 'File type is invalid';
    }
    if (allowedMaxSize && size >= allowedMaxSize) {
      result.isValid = false;
      result.size = 'File size is too big';
    }
    if (allowedMinSize && size <= allowedMinSize) {
      result.isValid = false;
      result.size = 'File size is too small';
    }
  }
  return result;
};

export const dataURItoBlob = (dataURI, mime) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = mime || dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const secondsToDhms = seconds => {
  seconds = Number(seconds);
  const w = Math.floor(seconds / (3600 * 24 * 7));
  const d = Math.floor((seconds % (3600 * 24 * 7)) / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  // let s = Math.floor(seconds % 60);

  const wDisplay = w > 0 ? w + (w === 1 ? ' week' : ' weeks') : '';
  const dDisplay = d > 0 ? d + (d === 1 ? ' day' : ' days') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
  // let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return [wDisplay, dDisplay, hDisplay, mDisplay]
    .filter(item => item)
    .slice(0, 2)
    .join(' ')
    .trim();
};

export const countdownForNotification = endDate => {
  const now = moment();
  const end = momentTZ.tz(endDate, TIMEZONE_ESTONIA);
  if (moment(end).isAfter(now)) {
    const date = moment(end, DATE_REQUEST);
    const seconds = Number(date.diff(now, 'seconds'));

    const w = Math.floor(seconds / (3600 * 24 * 7));
    const d = Math.floor((seconds % (3600 * 24 * 7)) / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const wDisplay = w > 0 ? w + (w === 1 ? ' week' : ' weeks') : '';
    const dDisplay = d > 0 ? d + (d === 1 ? ' day' : ' days') : '';
    const hDisplay = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';

    if (wDisplay) {
      return `on ${moment(end).format('DD MMM')}`;
    }

    if (dDisplay) {
      return `in ${dDisplay}`;
    }

    if (hDisplay) {
      return `in ${[hDisplay, mDisplay]
        .filter(i => i)
        .join(' ')
        .trim()}`;
    }

    if (mDisplay && m >= 5 && sDisplay) {
      return `in ${mDisplay}`;
    }

    return `in ${[mDisplay, sDisplay]
      .filter(item => item)
      .join(' ')
      .trim()}`;
  }
  return '';
};

export const prettyJob = (company, position) =>
  [position, company].filter(item => !!item).join(' at ');

export const getLoansShortNames = groups => {
  if (!groups || (groups && groups.length === 0)) {
    return '-';
  }
  return groups
    .map(groupID => LOAN_GROUPS_SHORT[groupID])
    .sort()
    .join('+');
};

export const canGoBack = history => history && history.action !== 'POP';

export const getLoanName = loanGroups => {
  if (!loanGroups || (loanGroups && loanGroups.length === 0)) {
    return '-';
  }
  if (loanGroups.length === 1) {
    return `Group ${LOAN_GROUPS_SHORT[loanGroups[0]]}`;
  }

  if (loanGroups.length === 2) {
    return `Groups ${LOAN_GROUPS_SHORT[loanGroups[0]]} and ${
      LOAN_GROUPS_SHORT[loanGroups[1]]
    }`;
  }

  if (loanGroups.length === 3) {
    return `Groups ${LOAN_GROUPS_SHORT[loanGroups[0]]}, ${
      LOAN_GROUPS_SHORT[loanGroups[1]]
    } and ${LOAN_GROUPS_SHORT[loanGroups[2]]}`;
  }
};

export const isParticipatingInvesting = status => {
  // TODO replace with is_active
  return !!(
    status &&
    status.step >= 0 &&
    status.step < 4 &&
    status.has_accepted_terms_a &&
    status.has_accepted_terms_b &&
    !!status.earmarked &&
    status.earmarked > 25 &&
    status.option !== STRATEGIES.not_selected
  );
};

export const convertStringToDate = dateStr => {
  if (dateStr) {
    const result = dateStr.split('-');
    return {
      month: Number(result[1]),
      year: Number(result[0]),
      day: Number(result[2]),
    };
  }
  return null;
};

export const convertDateToString = dateObj => {
  if (dateObj) {
    return `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
  }
  return null;
};

export const convertDateToСontractFormat = dateObj => {
  if (dateObj) {
    const result = dateObj.split('-');
    return `${result[2]}.${result[1]}.${result[0]}`;
  }
  return null;
};

export const parseWithLinks = text => {
  if (!text) {
    return null;
  }

  const DOMAIN_URL_REGEX = new RegExp('^' + config.baseURL, 'g');
  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs.href && DOMAIN_URL_REGEX.test(attribs.href)) {
        return (
          <Link to={attribs.href.replace(config.baseURL, '')}>
            {domToReact(children)}
          </Link>
        );
      }
    },
  };

  return parse(text, options);
};

export const withHTTP = url =>
  /^(https|http)/.test(url) ? url : `https://${url}`;

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const bodyScrollLock = isOpen => {
  if (isOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
};

export const getFundraisingState = fundraisingDates => {
  if (!fundraisingDates) {
    return 'Next round – dates to be announced';
  }

  if (fundraisingDates.is_active && fundraisingDates.end_date) {
    return `Active round until ${moment(fundraisingDates.end_date).format(
      DATE_FORMAT_DD_MMMM
    )}`;
  }

  if (
    !fundraisingDates.is_active &&
    fundraisingDates.start_date &&
    fundraisingDates.end_date
  ) {
    if (
      moment(fundraisingDates.end_date).format(DATE_FORMAT_M) ===
      moment(fundraisingDates.start_date).format(DATE_FORMAT_M)
    ) {
      return `Next round: ${moment(fundraisingDates.start_date).format(
        DATE_FORMAT_D
      )} - ${moment(fundraisingDates.end_date).format(DATE_FORMAT_D_MMMM)}`;
    }
    return `Next round: ${moment(fundraisingDates.start_date).format(
      DATE_FORMAT_D_MMMM
    )} - ${moment(fundraisingDates.end_date).format(DATE_FORMAT_D_MMMM)}`;
  }

  // Fundraising not set on Database
  return 'Next round – dates to be announced';
};

export const shortenUserInfo = (userData, prevUserData) => {
  if (!userData && !prevUserData) {
    return null;
  }

  const user = { ...prevUserData, ...userData };
  const {
    id,
    email,
    first_name,
    last_name,
    photo,
    status,
    role_id,
    unique_id,
  } = user;

  return {
    id,
    email: email || '',
    first_name: first_name || '',
    last_name: last_name || '',
    photo,
    status,
    role_id,
    unique_id,
  };
};

export const isOutOfViewport = elem => {
  const bounding = elem.getBoundingClientRect();

  // Check if it's out of the viewport on each side
  const out = {};
  out.top = bounding.top < 0;
  out.left = bounding.left < 0;
  out.bottom =
    bounding.bottom >
    (window.innerHeight || document.documentElement.clientHeight);
  out.right =
    bounding.right >
    (window.innerWidth || document.documentElement.clientWidth);
  out.any = out.top || out.left || out.bottom || out.right;
  out.all = out.top && out.left && out.bottom && out.right;

  return out;
};

export const isNumber = value => !Number.isNaN(Number(value));

export const scrollTop = () => {
  const main = document.querySelector('main');
  main && main.scrollTo(0, 0);
  window.scrollTo(0, 0);
};

export const getLastRoute = () => {
  const lastRoute = localStorage.getItem('last_route') || '';
  if (
    /^\/password\/set\//.test(lastRoute) ||
    /^\/forgot-password\//.test(lastRoute)
  ) {
    return '';
  }
  return lastRoute || '';
};

export const saveLastRoute = ({ pathname, search }) => {
  const fullPath = pathname + search;
  const EXCLUDED_LIST = [
    APP_LINKS.signIn,
    APP_LINKS.signUp,
    APP_LINKS.twoFactorAuth,
  ];
  if (!EXCLUDED_LIST.includes(pathname)) {
    localStorage.setItem('last_route', fullPath);
  }
};

export const removeLastRoute = () => {
  localStorage.removeItem('last_route');
};

export const calculateCash = balance => {
  if (balance) {
    const bonus = balance.available_bonus_amount || 0;
    return balance.available + balance.on_hold + bonus || 0;
  }
  return 0;
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const round10 = (num, isUpper) =>
  isUpper ? Math.ceil(num / 10) * 10 : Math.round(num / 10) * 10;

export const getFlagURL = countryCode =>
  countryCode ? `https://flagcdn.com/${countryCode.toLowerCase()}.svg` : '';

export const userObject = userData => {
  if (!userData) {
    return null;
  }

  return {
    id: userData.id,
    email: userData.email,
    first_name: userData.first_name,
    last_name: userData.last_name,
    photo: userData.photo,
    status: userData.status,
    role: userData.role,
    country_code: userData.country_code,
    role_id: userData.role_id,
    unique_id: userData.unique_id,
  };
};
