import { useGoogleEvent } from './google';
import { useFacebookEvent } from './facebook';
import { SEO_EVENTS } from './events';

const google = 'google';
const facebook = 'facebook';
const SEO_SERVICES = [google, facebook];

export default class Analytics {
  static login = user =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_login, { user });
  static register = user =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_create, { user });
  static signUpGoogle = user =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_create_google, {
      user,
    });
  static signInGoogle = user =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_login_google, { user });
  static signUpApple = user =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_create_apple, {
      user,
    });
  static signInApple = user =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_login_apple, { user });
  static regPersonal = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_contacts);
  static regProfile = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_profile);
  static regVeriff = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_veriff);
  static regVeriffSkipLater = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_veriff_skip_later);
  static regVeriffDontWant = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_registration_veriff_skip_dontwant);
  static wizardStep0Join = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_investing_start);
  static wizardStep1SelectStrategy = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_investing_select_strategy);
  static wizardStep2AllocateCash = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_investing_allocate_cash);
  static allocateCashTransfer = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_investing_transfer_cash);
  static wizardStep3SettingsEditStrategy = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_investing_edit_strategy);
  static wizardStep3SettingsEditAllocation = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_investing_edit_cash_allocation);
  static wizardResetSettings = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_investing_reset_settings);
  static verificationWizardProfile = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_verificationwiz_profile);
  static verificationWizardContacts = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_verificationwiz_contacts);
  static verificationWizardVeriff = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_verificationwiz_veriff);
  static verificationWizardVeriffNewSession = () =>
    registerEvent(
      SEO_SERVICES,
      SEO_EVENTS.i_verificationwiz_veriff_new_session
    );
  static verificationWizardAddCash = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_verificationwiz_add_funds);
  static verificationWizardCompleteLater = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_verificationwiz_verifylater);
  static verificationWizardGoToInvesting = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_verificationwiz_investing);
  static resetPassword = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_restore_password);
  static loansInfo = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_loan_details_open);
  static loansDownloadAgreement = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_loan_download_agreement);
  static logout = () => registerEvent(SEO_SERVICES, SEO_EVENTS.i_logout);
  static profileEditProfile = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_profile_profile);
  static profileEditContacts = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_profile_contacts);
  static profileVeriff = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.i_profile_veriff);
  static withdraw = () => registerEvent(SEO_SERVICES, SEO_EVENTS.i_withdraw);
  static veriffSuccess = (user, onSuccess) =>
    registerEvent(
      SEO_SERVICES,
      SEO_EVENTS.u_veriff_success,
      { user },
      onSuccess
    );
  static veriffError = (user, onSuccess) =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.u_veriff_error, { user }, onSuccess);
  static investingCalculateALoan = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_calculateAloan);
  static investingCalculateBLoan = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_calculateBloan);
  static investingCalculateLoanMobile = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_calculateloan_mobile);
  static investingJoinCalculator = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_calculator);
  static investingJoinTooltipEarnings = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_tooltip_earnings);
  static investingJoinTooltipNat = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_tooltip_nar);
  static investingJoinTooltipTerm = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_tooltip_term);
  static investingJoinTooltipRepayment = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_tooltip_repayments);
  static investingJoinTooltipSkin = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_tooltip_skin);
  static investingJoinCheckCalculator = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_checkcalculator);
  static investingRegLongtermCalculator = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_reg_longterm_calculator);
  static investingJoinRepaymentScheduleA = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_repaymentschedule_A);
  static investingJoinRepaymentScheduleB = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_repaymentschedule_B);
  static investingJoinProgressBar = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_progressbar);
  static investingJoinStartupDetails = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_join_startupdetails);
  static investingInviteFriends = eventName =>
    registerEvent(SEO_SERVICES, SEO_EVENTS[eventName]);
  static investingResultGotoTrustpilot = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_results_gototrustpilot);
  static investingInfluencerVideoStart = () =>
    registerEvent(SEO_SERVICES, SEO_EVENTS.investing_influ_video_start);
}

const registerEvent = (services, eventType, data = {}, onSuccess, onError) => {
  services.map(service => {
    switch (service) {
      case google:
        return useGoogleEvent(eventType, data, onSuccess, onError);
      case facebook:
        return useFacebookEvent(eventType, data, onSuccess, onError);
      default:
        return console.error(
          `Warning. Could not load '${service}' tag manager service.`
        );
    }
  });
};
