import React from 'react';
import * as classnames from 'classnames';
import { MyLink } from '../../../MyLink';
import Tooltip from '../../../Tooltip';
import './index.scss';

const LoanGroupCard = ({ loanTerms, defaultValues, className }) => {
  const { id, detailsLink } = defaultValues;

  return (
    <div className={classnames('loan-group-card', className)}>
      <MyLink
        href={detailsLink}
        className="loan-group-card__title hov f-26 f-500"
      >
        {(loanTerms && loanTerms[id] && loanTerms[id].title) || '-'}
      </MyLink>
      {loanTerms && loanTerms[id] && loanTerms[id].is_active ? (
        <>
          <div className="loan-group-card__block">
            <div className="f-18 f-500">
              <span>Target annual return</span>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className="loan-group-card__tooltip f-16  f-400">
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <p className="f-18 f-500 tl">
              {(loanTerms &&
                loanTerms[id] &&
                loanTerms[id].investor_annual_rate) ||
                defaultValues.targetAnnualReturn}
            </p>
          </div>

          <div className="loan-group-card__block">
            <div className="f-18 f-500">Term</div>
            <p className="f-18 f-500 tl">
              {(loanTerms && loanTerms[id] && loanTerms[id].info_term) ||
                defaultValues.repaymentPeriod}
            </p>
          </div>

          <div className="loan-group-card__block">
            <div className="f-18 f-500">Flat fee for 6 months</div>
            <p className="f-18 f-500 tl">
              {(loanTerms && loanTerms[id] && loanTerms[id].flat_fee) ||
                defaultValues.flatFee}
            </p>
          </div>

          <div className="loan-group-card__block">
            <div className="f-18 f-500">Extra flat fee beyond 6 months</div>
            <p className="f-18 f-500 tl">
              {(loanTerms && loanTerms[id] && loanTerms[id].extra_flat_fee) ||
                defaultValues.extraFlatFee}
            </p>
          </div>

          <div className="loan-group-card__block">
            <div className="f-18 f-500">Downside protection</div>
            <p className="f-18 f-500 tl">
              {(loanTerms &&
                loanTerms[id] &&
                loanTerms[id].downside_protection) ||
                '-'}
            </p>
          </div>

          <MyLink
            href={detailsLink}
            className="loan-group-card__link-details f-500"
          >
            See details
          </MyLink>
        </>
      ) : (
        <p className="loan-group-card__unavailable f-18 f-500">
          {loanTerms && loanTerms[id] && loanTerms[id].title} is temporarily
          unavailable.
        </p>
      )}
    </div>
  );
};

export default LoanGroupCard;
