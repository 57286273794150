import React from 'react';
import moment from 'moment';
import * as classnames from 'classnames';
import ProgressBar from '../../../components/ProgressBar';
import { prettyFloatMoney } from '../../../helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../helpers/common';
import s from './index.module.scss';

export const LoansTable = ({ data, onLoanOpen }) => (
  <div className={s.wrapper}>
    <div className={s.inner}>
      <div className={s.table}>
        <div className={s.thead}>
          <div className={s.thead_col}>Round</div>
          <div className={s.thead_col}>Origination date</div>
          <div className={s.thead_col}>Loan amount</div>
          <div className={s.thead_col}>Repayment progress, %</div>
          <div className={s.thead_col}>Repaid</div>
          <div className={s.thead_col}>Your earnings</div>
        </div>

        <div className={s.tbody}>
          <div className={s.tbody_row_summary}>
            <div className={s.tbody_col}>
              <p className="f-18 f-500">
                Total:{' '}
                <span>
                  {data.total_count === 1
                    ? `1 round`
                    : `${data.total_count} rounds`}
                </span>
              </p>
            </div>
            <div className={s.tbody_col}>
              <span className="f-18 f-500">—</span>
            </div>
            <div className={classnames(s.tbody_col, 'tl')}>
              <span className="f-18 f-500">
                {prettyFloatMoney(data.total_amount)}
              </span>
            </div>
            <div className={s.tbody_col}>
              <ProgressBar
                value={data.repayment_average}
                text={`avg. ${data ? data.repayment_average : 0}%`}
              />
            </div>
            <div className={classnames(s.tbody_col, 'tl')}>
              <span className="f-18 f-500">
                {prettyFloatMoney(data.total_repayments)}
              </span>
            </div>
            <div className={s.tbody_col}>
              <span className="f-18 f-500">
                +{prettyFloatMoney(data.total_earnings)}
              </span>
            </div>
          </div>

          {data.list.map(round => {
            const {
              fundraising_name,
              start_date,
              loan_amount,
              repayment_status,
              repaid_amount,
              earnings,
            } = round;

            return (
              <div
                className={classnames(s.tbody_row, 'hov')}
                key={fundraising_name}
                onClick={() => onLoanOpen(round)}
              >
                <div className={s.tbody_col}>
                  <span className="f-16 f-400">{fundraising_name}</span>
                </div>
                <div className={s.tbody_col}>
                  <span className="f-16 f-400">
                    {moment(start_date).format(DATE_FORMAT_DD_MMM_YYYY)}
                  </span>
                </div>
                <div className={classnames(s.tbody_col, s.loan)}>
                  <span className="f-16 f-400 tl">
                    {prettyFloatMoney(loan_amount)}
                  </span>
                </div>
                <div className={s.tbody_col}>
                  <ProgressBar value={repayment_status} />
                </div>
                <div className="loans_table__tbody_col tl">
                  <span className="f-16 f-400">
                    {prettyFloatMoney(repaid_amount)}
                  </span>
                </div>
                <div className={classnames(s.tbody_col, 'tl')}>
                  <span className={classnames(s.earnings, 'f-16 f-400')}>
                    {`+ ${prettyFloatMoney(earnings)}`}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </div>
);
