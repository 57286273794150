import React from 'react';
import classnames from 'classnames';
import { LOAN_GROUPS } from '../../helpers/constants';
import { TERM_TOOLTIPS } from './tooltips';
import { RoundButton } from '../UI/RoundButton';
import Analytics from '../../analytics';
import s from './desktop.module.scss';

export const DesktopTermView = ({ onCalculate }) => (
  <div className={s.list}>
    {[LOAN_GROUPS.conservative, LOAN_GROUPS.moderate].map(group => (
      <div key={group.id} className={s.item}>
        <h3 className={classnames(s.item_title, 'f-36 f-500')}>
          {group.full_name}
        </h3>
        <div className={s.item_info}>
          <div className={s.item_row}>
            <div className={classnames(s.item_row_left, s.item_header)}>
              <span>Investor earnings</span>
              {TERM_TOOLTIPS.earnings({ className: s.d_earning })}
            </div>
            <div className={s.item_row_right}>
              {group.flat_fee_six_month} for 6 month
            </div>
          </div>

          <div className={s.item_row}>
            <div className={classnames(s.item_row_left, s.item_header)}>
              <span>Net annual return</span>
              {TERM_TOOLTIPS.net_annual_return({ className: s.d_return })}
            </div>
            <div className={s.item_row_right}>{group.target_annual_return}</div>
          </div>

          <div className={s.item_row}>
            <div className={classnames(s.item_row_left, s.item_header)}>
              <span>Term</span>
              {TERM_TOOLTIPS.term({ className: s.d_term })}
            </div>
            <div className={s.item_row_right}>6 months</div>
          </div>

          <div className={s.item_row}>
            <div className={classnames(s.item_row_left, s.item_header)}>
              <span>Repayments</span>
              {TERM_TOOLTIPS.repayments({ className: s.d_repayments })}
            </div>
            <div className={s.item_row_right}>{group.repayment_type}</div>
          </div>

          <div className={s.item_row}>
            <div className={classnames(s.item_row_left, s.item_header)}>
              <span>Skin in the game</span>
              {TERM_TOOLTIPS.skin({ className: s.d_skin })}
            </div>
            <div className={s.item_row_right}>{group.skin_in_the_game}</div>
          </div>

          <div className={s.item_row}>
            <div className={classnames(s.item_row_left, s.item_header)}>
              <span>Protection</span>
              {TERM_TOOLTIPS.protection({
                group: group.id,
                className: s.d_protection,
              })}
            </div>
            <div className={s.item_row_right}>{group.protection}</div>
          </div>
        </div>

        <div className={s.item_buttons}>
          <RoundButton
            label={() => <span>Calculate my earnings</span>}
            className={s.item_create}
            onClick={() => {
              group.id === LOAN_GROUPS.conservative.id &&
                Analytics.investingCalculateALoan();
              group.id === LOAN_GROUPS.moderate.id &&
                Analytics.investingCalculateBLoan();
              onCalculate(group.id);
            }}
            fillBackground
          />
        </div>
      </div>
    ))}
  </div>
);
