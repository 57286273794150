import React from 'react';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../../helpers/utils';
import { REFERRAL_FRIEND_STATUSES } from '../../../containers/ReferralsModule';
import './index.scss';

const ReferralCard = ({ data, className }) => (
  <div className={classnames('referral_card', className)}>
    <div className="referral_card__info">
      <div className="referral_card__row">
        <div className="c-grey f-14 f-400">User</div>
        <div className="f-18 f-500">{data.investor_id}</div>
      </div>

      <div className="referral_card__row">
        <div className="c-grey f-14 f-400">User status</div>
        <div className="f-18 f-500">{REFERRAL_FRIEND_STATUSES[data.friend_status]}</div>
      </div>

      <div className="referral_card__row">
        <div className="c-grey f-14 f-400">Days left</div>
        <div className="f-18 f-500">
          {data.remaining_period ? `${data.remaining_period} days` : '-'}
        </div>
      </div>

      <div className="referral_card__row">
        <div className="c-grey f-14 f-400">Your reward</div>
        <div className="f-18 f-500">{prettyFloatMoney(data.commission)}</div>
      </div>

      <div className="referral_card__row">
        <div className="c-grey f-14 f-400">Rewards status</div>
        <div className="f-18 f-500">{data.commission_status || '—'}</div>
      </div>
    </div>
  </div>
);

export default ReferralCard;
