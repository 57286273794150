import * as React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputTextField } from '../../UI/InputTextField';
import { RoundButton } from '../../UI/RoundButton';
import { RoundLink } from '../../UI/RoundLink';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .max(50, 'Email is not valid')
    .required('Please fill in the field'),
});

const ForgotPasswordForm = ({ onSubmit }) => (
  <Formik
    initialValues={{ email: '' }}
    validationSchema={VALIDATION_SCHEMA}
    onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
  >
    {({ values, errors, touched, handleChange, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <h1 className="forgot-password_form__title f-500">Restore password</h1>
        <p className="forgot-password_form__description">
          Please enter your registered email to receive instructions on how to
          restore your password.
        </p>
        <InputTextField
          label="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          className="forgot-password_form__email"
          error={errors.email && touched.email && errors.email}
        />
        <RoundButton
          label="Send email"
          onSubmit={handleSubmit}
          className="forgot-password_form__submit"
          fillBackground
          fullWidth
          type="submit"
        />
        <div className="forgot-password_form__bottom">
          <div>Remember your password now?</div>
          <RoundLink label="Sign in" path="/sign-in" />
        </div>
      </form>
    )}
  </Formik>
);

export default ForgotPasswordForm;
