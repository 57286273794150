import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { APP_LINKS } from './helpers/links';
import RootPage from './pages/RootPage';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import RecoverPasswordPage from './pages/RecoverPasswordPage';
import DevPage from './pages/DevPage';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from './pages/PageNotFound';
import ThemesPage from './pages/ThemesPage';
import ThemeDetailPage from './pages/ThemeDetailPage';
import FundsPage from './pages/FundsPage';
import FundsAddPage from './pages/FundsAddPage';
import FundsWithdrawPage from './pages/FundsWithdrawPage';
import ProfilePage from './pages/ProfilePage';
import ActivityPage from './pages/ActivityPage';
import LoanTermsPage from './pages/LoanTermsPage/v2';
import { LoanTermsPage as LoanTermsV1Page } from './pages/LoanTermsPage/v1';
import LoanGroupDetailPage from './pages/LoanGroupDetailPage/v2';
import { LoanGroupDetailPage as LoanGroupDetailV1Page } from './pages/LoanGroupDetailPage/v1';
import InvestingPage from './pages/InvestingPage';
import FounderDetailPage from './pages/FounderDetailPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import InvestorsPage from './pages/InvestorsPage';
import PrivateInvestorPage from './pages/PrivateInvestorPage';
import UnsubscribePage from './pages/UnsubscribePage';
import HelpPage from './pages/HelpPage';
import LoansPage from './pages/LoansPage';
import LoansLoanPage from './pages/LoansLoanPage';
import LoansSchedulePage from './pages/LoansSchedulePage';
import AffiliatePage from './pages/AffiliatePage';
import StartupsPage from './pages/StartupsPage';
import FoundersPage from './pages/FoundersPage';
import StartupDetailPage from './pages/StartupDetailPage';
import ClientQuestionsPage from './pages/ClientQuestionsPage';
import TwoFactorAuthPage from './pages/TwoFactorAuthPage';
import FundsBankAccountsPage from './pages/FundsBankDetailsPage';

const ROUTES = [
  {
    path: APP_LINKS.signUp,
    component: SignUpPage,
    pageTitle: 'Scramble - Sign Up',
    exact: true,
  },
  {
    path: APP_LINKS.signIn,
    component: SignInPage,
    pageTitle: 'Scramble - Sign In',
    exact: true,
  },
  {
    path: APP_LINKS.twoFactorAuth,
    component: TwoFactorAuthPage,
    pageTitle: 'Scramble - Two Factor Authentication',
    exact: true,
  },
  {
    path: '/unsubscribe/:token',
    component: UnsubscribePage,
    pageTitle: 'Scramble - Unsubscribe from emails',
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    pageTitle: 'Scramble - Forgot Password',
    exact: true,
  },
  {
    path: '/password/set/:key',
    component: RecoverPasswordPage,
    pageTitle: 'Scramble - Password Recovery',
    exact: true,
  },
  {
    path: '/legal/terms-of-use/',
    component: TermsOfUsePage,
    pageTitle: 'Scramble - Terms of Use',
    exact: true,
  },
  {
    path: APP_LINKS.root,
    component: RootPage,
    routes: [
      {
        path: APP_LINKS.root,
        render: () => <Redirect to={APP_LINKS.dashboard} />,
        pageTitle: 'Scramble - Dashboard Page',
        exact: true,
      },
      {
        path: APP_LINKS.dashboard,
        component: DashboardPage,
        pageTitle: 'Scramble - Dashboard',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.investing,
        component: InvestingPage,
        pageTitle: 'Scramble - Investing',
        auth: user => !!user,
        exact: true,
      },
      {
        path: '/themes',
        component: ThemesPage,
        pageTitle: 'Scramble - Themes',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.activity,
        component: ActivityPage,
        pageTitle: 'Scramble - Activity',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.affiliate,
        component: AffiliatePage,
        pageTitle: 'Scramble - Affiliate Page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.profile,
        component: ProfilePage,
        pageTitle: 'Profile Page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.founderDetail(':id'),
        component: FounderDetailPage,
        pageTitle: 'Scramble - Founder Detail page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.founders,
        component: FoundersPage,
        pageTitle: 'Scramble - Founders page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.startups,
        component: StartupsPage,
        pageTitle: 'Scramble - Startups page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.startupDetail(':slug'),
        component: StartupDetailPage,
        pageTitle: 'Scramble - Startup Detail page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.investors,
        component: InvestorsPage,
        pageTitle: 'Scramble - Investors page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.cash,
        component: FundsPage,
        auth: user => !!user,
        pageTitle: 'Funds',
        routes: [
          {
            path: APP_LINKS.cash,
            render: () => <Redirect to={APP_LINKS.addFunds} />,
            pageTitle: 'Scramble - Allocate Page',
            exact: true,
          },
          {
            path: APP_LINKS.addFunds,
            component: FundsAddPage,
            pageTitle: 'Scramble - Cash',
            exact: true,
          },
          {
            path: APP_LINKS.withdraw,
            component: FundsWithdrawPage,
            pageTitle: 'Scramble - Withdraw',
            exact: true,
          },
          {
            path: APP_LINKS.bankAccounts,
            component: FundsBankAccountsPage,
            pageTitle: 'Scramble - Bank accounts',
            exact: false,
          },
        ],
      },
      {
        path: APP_LINKS.helpLoanTermsRoot,
        component: LoanTermsPage,
        pageTitle: 'Scramble - Loan Terms',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTerms,
        component: LoanTermsPage,
        pageTitle: 'Scramble - Loan Terms',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsGroupA,
        component: LoanGroupDetailPage,
        pageTitle: 'Scramble - Loan Group A',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsGroupB,
        component: LoanGroupDetailPage,
        pageTitle: 'Scramble - Loan Group B',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsGroupC,
        render: () => <Redirect to={APP_LINKS.helpLoanTerms} />,
        pageTitle: 'Scramble - Loan Group C',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsV1,
        component: LoanTermsV1Page,
        pageTitle: 'Scramble - Loan Terms',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsGroupAV1,
        component: LoanGroupDetailV1Page,
        pageTitle: 'Scramble - Loan Group A',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsGroupBV1,
        component: LoanGroupDetailV1Page,
        pageTitle: 'Scramble - Loan Group B',
        exact: true,
      },
      {
        path: APP_LINKS.helpLoanTermsGroupCV1,
        render: () => <Redirect to={APP_LINKS.helpLoanTermsV1} />,
        pageTitle: 'Scramble - Loan Group C',
        exact: true,
      },
      {
        path: APP_LINKS.helpPrivateInvestors,
        component: PrivateInvestorPage,
        pageTitle: 'Scramble - Private Investor Page',
        auth: user => !!(user && user.unique_id),
        exact: true,
      },
      {
        path: '/help/:slugLevelOne?/:slugLevelTwo?/:slugLevelThree?',
        component: HelpPage,
        pageTitle: 'Scramble - Help Page',
        exact: true,
      },
      {
        path: APP_LINKS.help,
        component: HelpPage,
        pageTitle: 'Scramble - Help Page',
        exact: true,
      },
      {
        path: '/themes/:id',
        component: ThemeDetailPage,
        pageTitle: 'Themes',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.loans,
        component: LoansPage,
        pageTitle: 'Loans',
        auth: user => !!user,
        routes: [
          {
            path: APP_LINKS.loans,
            render: () => <Redirect to={APP_LINKS.loansList} />,
            pageTitle: 'Scramble - Loans Page',
            exact: true,
          },
          {
            path: APP_LINKS.loansList,
            component: LoansLoanPage,
            pageTitle: 'Scramble - Loans Page',
            exact: true,
          },
          {
            path: APP_LINKS.loansSchedule,
            component: LoansSchedulePage,
            pageTitle: 'Scramble - Repayments Schedule',
            exact: true,
          },
        ],
      },
      {
        path: '/help',
        component: HelpPage,
        pageTitle: 'Help page',
        auth: user => !!user,
        exact: true,
      },
      {
        path: APP_LINKS.clientQuestions,
        component: ClientQuestionsPage,
        pageTitle: 'Scramble - Client Questions',
        auth: user => !!user,
        exact: true,
      },
      {
        path: '/dev',
        component: DevPage,
        pageTitle: 'Development Page - To be removed',
        exact: true,
      },
      {
        path: '**',
        component: PageNotFound,
        pageTitle: 'Not found',
      },
    ],
  },
];

export default ROUTES;
