import axios from '../../axios-api';
import {GET_FOUNDER_FR_STATS, SET_INACTIVE_FOUNDERS, SET_PARTICIPATING_FOUNDERS} from '../types/founderTypes';
import {getMessage, getQuery} from '../../helpers/utils';
import Pathes from '../../helpers/pathes';

const setParticipatingFounders = payload => ({type: SET_PARTICIPATING_FOUNDERS, payload});
const setInactiveFounders = payload => ({type: SET_INACTIVE_FOUNDERS, payload});

const getFounderFundraisingStatusRequest = () => ({ type: GET_FOUNDER_FR_STATS.REQUEST });
const getFounderFundraisingStatusSuccess = payload => ({type: GET_FOUNDER_FR_STATS.SUCCESS, payload});
const getFounderFundraisingStatusFailure = error => ({type: GET_FOUNDER_FR_STATS.FAILURE, error});

export const getParticipatingFounders = params => {
  return dispatch => {
    return axios.get(Pathes.Founders.participatingFounders + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setParticipatingFounders(data));
          return {data, success: true};
        }

        throw new Error(message)
      }).catch(e => ({error: e.message, success: false}));
  };
}

export const getInactiveFounders = (params, isNext) => {
  return (dispatch, getState)  => {
    return axios.get(Pathes.Founders.inactiveFounders + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().founderStore.inactiveFounders;
          if (!isNext || !prevData) {
            dispatch(setInactiveFounders(data));
            return {data, success: true, message};
          }
          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [ ...prevData.list, ...data.list]
          }
          dispatch(setInactiveFounders(updatedData));
          return {data: updatedData, success: true, message};
        }
        throw new Error(message)
      }).catch(e => ({error: e.message, success: false}));
  };
}

export const getFounderFundraisingStatus = founderID => {
  return dispatch => {
    dispatch(getFounderFundraisingStatusRequest());
    return axios.get(Pathes.Founders.fundraisingStatus(founderID)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getFounderFundraisingStatusSuccess(data));
          return {data, success: true};
        }
        throw new Error(message)
      }).catch(e => {
        dispatch(getFounderFundraisingStatusFailure(e.message));
        return {success: false, message: e.message};
    });
  }
}