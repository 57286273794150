export const modalCustomStyles = {
  overlay: {
    backgroundColor       : 'rgba(0, 0, 0, 0.9)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    padding               : '15px',
    transform             : 'translate(-50%, -50%)',
    border                : 'unset',
    borderRadius          : '16px',
    scrollbarWidth: 'none',
    height                : 'auto', // <-- This sets the height
    maxHeight             : '90vh',
    overlfow              : 'scroll' // <-- This tells the modal to scrol
  }
};