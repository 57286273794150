import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PersonalInformationForm from '../../components/Forms/PersonalInformationForm';
import { updateProfileContacts } from '../../store/services/userServices';
import Analytics from '../../analytics';
import { setUserProfile } from '../../store/actions/profileActions';

const WizardPersonalInfoModal = ({ initialValues, onSave, onBack }) => {
  const dispatch = useDispatch();
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const userLocation = useSelector(state => state.userStore.userLocation);

  const onPersonalInfoSubmit = values => {
    const payload = {
      address: values.address,
      city: values.city,
      country: values.country,
      country_code: values.country_code,
      postal_code: values.postal_code,
      phone: values.phone,
      citizenship: values.citizenship,
      citizenship_code: values.citizenship_code,
      origin_of_funds: values.origin_of_funds,
      monthly_investment: values.planned_monthly_investment,
    };

    updateProfileContacts(payload).then(res => {
      if (res && res.success) {
        Analytics.verificationWizardContacts();
        dispatch(setUserProfile(res.data));
        onSave && onSave(values, res.data);
      }
    });
  };

  return (
    <PersonalInformationForm
      user={profile}
      location={userLocation}
      initialValues={initialValues}
      onSubmit={onPersonalInfoSubmit}
      onBack={onBack}
    />
  );
};

export default WizardPersonalInfoModal;
