import React, { Component } from 'react';
import { SmallHeartIcon } from '../../components/UI/Icons';
import { THEME_STATES } from '../../helpers/constants';
import { prettyFloatMoney } from '../../helpers/utils';
import './index.scss';

class ThemeDetail extends Component {
  toggleTheme = () => {
    const { data, toggleFavouriteThemes } = this.props;
    if (data && data.id) {
      toggleFavouriteThemes(
        [data.id], data.is_included ? THEME_STATES.unlike : THEME_STATES.like, { updateDetail: true },
      );
    }
  }

  render() {
    const {
      title, description, invested, is_included,
    } = this.props.data;

    return (
      <div className="theme-detail">
        <h1 className="theme-detail__title f-500">{title}</h1>
        <p className="theme-detail__description">{description}</p>
        <div className="theme-detail__summary">
          <div className="theme-detail__summary-left">
            <div className="f-16">Invested loans</div>
            <div className="f-500">{prettyFloatMoney(invested)}</div>
          </div>
          {/* <div className="theme-detail__summary-middle"> */}
          {/*  <div className="f-16">Discount</div> */}
          {/*  <div className="theme-detail__discounts"> */}
          {/*    <DiscountLabel value={0} selected /> */}
          {/*    <DiscountLabel value={5} /> */}
          {/*    <DiscountLabel value={15} /> */}
          {/*  </div> */}
          {/* </div> */}
          <SmallHeartIcon
            color={is_included ? '#F93C10' : '#EBEAEC'}
            onClick={this.toggleTheme}
            className="theme-detail__summary-right"
          />
        </div>
      </div>
    );
  }
}

export default ThemeDetail;
