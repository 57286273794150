import React from 'react';
import { renderRoutes } from 'react-router-config';
import { NavLink } from 'react-router-dom';
import { APP_LINKS } from '../../helpers/links';
import './index.scss';

const TABS = [
  {
    label: 'Your  loans',
    path: APP_LINKS.loansList,
  },
  {
    label: 'Repayment schedule',
    path: APP_LINKS.loansSchedule,
  },
];

const LoansPage = ({ route, user }) => (
  <div className="loans-page">
    <div className="container-inner">
      <h1 className="loans-page__title f-500 f-42">Loans</h1>
      <div className="loans-page__links">
        {TABS.map(tab => (
          <NavLink
            key={tab.path}
            to={tab.path}
            className="loans-page__link"
            activeClassName="loans-page__link-selected"
            exact
          >
            {tab.label}
          </NavLink>
        ))}
      </div>

      {renderRoutes(route.routes, { user })}
    </div>
  </div>
);

export default LoansPage;
