import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { prettyFloatMoney } from '../../../helpers/utils';
import { getFundsInRecoveryByFundraisingID } from '../../../store/actions/loanActions';
import s from './index.module.scss';

export const FundsRecovery = ({ fundraisingID }) => {
  const dispatch = useDispatch();
  const { fundsInRecoveryByFundraisingID } = useSelector(
    state => state.loanStore
  );

  useEffect(() => {
    dispatch(getFundsInRecoveryByFundraisingID(fundraisingID));
  }, [fundraisingID]);

  const funds = fundsInRecoveryByFundraisingID.data || [];

  if (!funds.length) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">Funds in recovery</h2>

      <div className={s.table}>
        <div className={classnames(s.thead)}>
          <div className={s.thead_col}>Company name</div>
          <div className={s.thead_col}>Loan amount</div>
          <div className={s.thead_col}>Outstanding</div>
          <div className={s.thead_col}>In recovery</div>
        </div>
        <div className={s.tbody}>
          {funds.map((loan, idx) => (
            <div className={classnames(s.tbody_row)} key={idx}>
              <div className={s.tbody_col}>{loan.company_name}</div>
              <div className={s.tbody_col}>
                {prettyFloatMoney(loan.total_loan_amount)}
              </div>
              <div className={s.tbody_col}>
                {prettyFloatMoney(loan.total_repaid_amount)}
              </div>
              <div className={s.tbody_col}>
                {prettyFloatMoney(loan.fund_in_recovery)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
