import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import { GET_REFERRALS_LIST } from '../types/referralTypes';

export const getReferralsListRequest = () => ({
  type: GET_REFERRALS_LIST.REQUEST,
});
export const getReferralsListSuccess = payload => ({
  type: GET_REFERRALS_LIST.SUCCESS,
  payload,
});
export const getReferralsListFailure = error => ({
  type: GET_REFERRALS_LIST.FAILURE,
  error,
});

export const getReferralsList = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getReferralsListRequest());
    return axios
      .get(Pathes.Referrals.list + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().referralStore.referralsList.data;
          if (!isNext || !prevData) {
            dispatch(getReferralsListSuccess(data));
            return { data, success: true, message };
          }

          const prevValues = prevData || {};
          const updatedData = {
            ...prevValues,
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getReferralsListSuccess(updatedData));
          return { ...updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getReferralsListFailure(e.message));
        return { success: false, error: e.message };
      });
  };
};
