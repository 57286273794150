import * as React from 'react';
import nl2br from 'react-nl2br';
import Avatar from '../../components/UI/Avatar';
import GroupedAvatars from '../../components/UI/GroupedAvatars';
import {getFullname, getNameInitials} from '../../helpers/utils';
import Preloader from '../../components/Preloader';
import './index.scss';

const FounderPopup = ({ founder, onClose }) => {
  const { data, loading } = founder;

  if (loading) {
    return <Preloader className="founder-popup__container" />
  }
  if (!data) {
    return  <div className="founder-popup__container">Founder information not founded</div>;
  }

  const { company, company_description, description, first_name, last_name, themes } = data;
  const fullname = getFullname(first_name, last_name);
  return (
    <div className="founder-popup__container">
      <svg onClick={onClose} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="founder-popup__close">
        <path d="M7.99926 9.41609L14.2902 15.7072C14.6811 16.0982 15.3163 16.097 15.7063 15.7072C16.097 15.3163 16.0972 14.6821 15.706 14.2911L9.41537 8.00023L15.7063 1.70932C16.097 1.31837 16.097 0.68392 15.7063 0.293212C15.3153 -0.0977374 14.6811 -0.0977374 14.2902 0.293212L7.99926 6.58412L1.70835 0.293212C1.3174 -0.0977374 0.683197 -0.0977374 0.292489 0.293212C0.0970138 0.488687 0.000482931 0.744011 0.000724258 1.00078C2.77177e-07 1.25804 0.0970138 1.51384 0.292489 1.70932L6.5834 8.00023L0.293212 14.2902C0.0977376 14.4859 -0.000240882 14.7422 4.44708e-07 14.9985C0.000483098 15.255 0.0977377 15.511 0.292971 15.7065C0.683679 16.0972 1.31813 16.0975 1.70908 15.7065L7.99926 9.41609Z" fill="#9E98A3"/>
      </svg>
      <Avatar
        src={data.photo && data.photo.file}
        alt="Avatar"
        size={120}
        nameInitials={getNameInitials(first_name, last_name)}
      />
      <h1 className="founder-popup__fullname f-500">{fullname}</h1>
      <p className="founder-popup__occupation">{company || ''}</p>

      <div className="founder-popup__about">
        <h2 className="f-26 f-500">About</h2>
        <h3 className="f-16 f-500">Personal info</h3>
        <p className="f-16">{(description && nl2br(description)) || `It looks like ${fullname || 'this user'} has nothing to share.`}</p>
        {company_description && (
          <React.Fragment>
            <h3 className="f-16 f-500">Startup details</h3>
            <p className="f-16">{nl2br(company_description)}</p>
          </React.Fragment>
        )}
      </div>

      <div className="founder-popup__group">
        <div className="founder-popup__group-items">
          {themes.map(theme => (
            <div className="founder-popup__group-item" key={theme.id}>
              <GroupedAvatars data={theme.founders.map(founder => ({
                src: founder.photo && founder.photo.file,
                alt: getFullname(founder.first_name, founder.last_name),
                nameInitials: getNameInitials(founder.first_name, founder.last_name)
              }))} />
              <span className="founder-popup__group-item-title f-500">{theme.title}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FounderPopup;


