import React from 'react';
import * as classnames from 'classnames';
import { MyLink } from '../../../MyLink';
import Tooltip from '../../../Tooltip';
import './index.scss';

const LoanGroupCard = ({ group, className }) => (
  <div className={classnames('loan_group_v1_card', className)}>
    <MyLink
      href={group.link}
      className="loan_group_v1_card__title hov f-26 f-500"
    >
      {group.title}
    </MyLink>
    {group.isActive ? (
      <>
        <div className="loan_group_v1_card__block">
          <div className="f-18 f-500">
            <span>Target annual return</span>
            <Tooltip
              position="bottom center"
              renderContent={() => (
                <div className="loan_group_v1_card__tooltip f-16  f-400">
                  Target annual return calculations include reinvested
                  repayments. Reinvesting significantly boosts your returns over
                  time.
                </div>
              )}
            />
          </div>
          <p className="f-18 f-500 tl">{group.rate}</p>
        </div>

        <div className="loan_group_v1_card__block">
          <div className="f-18 f-500">Term</div>
          <p className="f-18 f-500 tl">{group.repaymentPeriod}</p>
        </div>

        <div className="loan_group_v1_card__block">
          <div className="f-18 f-500">Downside protection</div>
          <p className="f-18 f-500 tl">{group.downsideProtection}</p>
        </div>

        <MyLink
          href={group.link}
          className="loan_group_v1_card__link-details f-500"
        >
          See details
        </MyLink>
      </>
    ) : (
      <p className="loan_group_v1_card__unavailable f-18 f-500">
        {group.title} is temporarily unavailable.
      </p>
    )}
  </div>
);

export default LoanGroupCard;
