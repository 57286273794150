import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { RoundButton } from '../../components/UI/RoundButton';
import { getInactiveFounders } from '../../store/actions/founderActions';
import Preloader from '../../components/Preloader';
import FounderCard from '../../components/Cards/FounderCard';

class FoundersAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      limit: 30,
    };
  }

  componentDidMount() {
    this.getFounders();
  }

  getFounders = (extraParams, isNext) => {
    const { page, limit } = this.state;
    this.props
      .getInactiveFounders({ page, limit, ...extraParams }, isNext)
      .finally(() =>
        this.setState(prevState => ({ ...prevState, loading: false }))
      );
  };

  getNext = totalPages => {
    if (this.state.page < totalPages) {
      const nextPage = this.state.page + 1;
      this.getFounders({ page: nextPage }, true);
      return this.setState(prevState => ({ ...prevState, page: nextPage }));
    }
  };

  render() {
    const { page, loading } = this.state;
    const { founders, participatingFounders } = this.props;
    const haveFounders = !!(founders && founders.list.length);
    const haveParticipatingFounders = !!(
      participatingFounders && participatingFounders.total_count > 0
    );

    if (!haveFounders) {
      return null;
    }

    return (
      <div
        className={classnames(
          'founders-all',
          haveParticipatingFounders && 'active'
        )}
      >
        {haveParticipatingFounders && (
          <h2 className="f-26 f-500">
            {haveFounders
              ? `All ${founders.total_count} awesome founders`
              : 'All awesome founders'}
          </h2>
        )}

        {loading ? (
          <Preloader className="founders-all__preloader" />
        ) : haveFounders ? (
          <div className="founders-all__list">
            {founders.list.map(founder => (
              <FounderCard key={founder.id} founder={founder} hideFR />
            ))}
          </div>
        ) : (
          <div className="founders-all__empty">
            <span className="founders-all__empty-title f-16 f-400">
              No founders yet.
            </span>
          </div>
        )}

        {haveFounders && page < founders.total_pages && (
          <RoundButton
            type="button"
            label="Show more founders"
            className="founders-all__btn"
            onClick={() => this.getNext(founders.total_pages)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  founders: state.founderStore.inactiveFounders,
  participatingFounders: state.founderStore.participatingFounders,
});

const mapDispatchToProps = dispatch => ({
  getInactiveFounders: (params, isNext) =>
    dispatch(getInactiveFounders(params, isNext)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FoundersAll);
