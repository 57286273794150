import React, { useState, useEffect } from 'react';
import * as classnames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import {
  bodyScrollLock,
  calculateCash,
  getFundraisingState,
  prettyFloatMoney,
} from '../../../helpers/utils';
import { NAV_ROUTES } from '../routes';
import { MyLink } from '../../MyLink';
import { APP_LINKS } from '../../../helpers/links';
import Logo from '../../UI/Icons/Logo';
import MenuUser from '../../MenuUser';
import { CONTACT_PHONE } from '../../../helpers/constants';
import './index.scss';

const MobileNavigation = () => {
  const [open, setOpen] = useState(false);
  const balance = useSelector(state => state.dashboardStore.balance);
  const cash = calculateCash(balance);
  const fundraisingDates = useSelector(
    state => state.dashboardStore.fundraisingDates
  );

  const history = useHistory();

  useEffect(() => {
    bodyScrollLock(open);
  }, [open]);

  useEffect(() => {
    history.listen(() => {
      setOpen(false);
    });
  }, []);

  return (
    <>
      <div className="mobile_navigation__wrap">
        <div className="container-inner">
          <div className="mobile_navigation">
            <MyLink
              href={APP_LINKS.dashboard}
              className="mobile_navigation__logo"
            >
              <Logo />
            </MyLink>
            {open && (
              <MyLink
                className="mobile_navigation__phone"
                href={APP_LINKS.phone}
                isExternal
              >
                {CONTACT_PHONE}
              </MyLink>
            )}
            <div
              className="mobile_navigation__clickable"
              onClick={() => setOpen(!open)}
            >
              <div
                className={classnames(
                  'mobile_navigation__burger',
                  open && 'mobile_navigation__burger-active'
                )}
              >
                <div className="mobile_navigation__burger-inner" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classnames(
          'mobile_navigation__menu',
          open && 'mobile_navigation__menu-open'
        )}
      >
        <div className="container-inner">
          <div className="mobile_navigation__menu-inner">
            <div className="mobile_navigation__summary">
              <div className="mobile_navigation__summary-top">
                <div>
                  <p className="f-12 f-400">Total</p>
                  <p className="f20 f-500">
                    {prettyFloatMoney(balance?.total)}
                  </p>
                </div>

                <div>
                  <p className="f-12 f-400">Invested</p>
                  <p className="f20 f-500">
                    {prettyFloatMoney(balance?.invested)}
                  </p>
                </div>

                <div>
                  <p className="f-12 f-400">Cash</p>
                  <p className="f20 f-500">{prettyFloatMoney(cash)}</p>
                </div>
              </div>
              <div className="mobile_navigation__round f-12 f-400">
                {getFundraisingState(fundraisingDates)}
              </div>
            </div>
            <div className="mobile_navigation__links">
              {NAV_ROUTES.map(route => (
                <NavLink
                  key={route.label}
                  to={route.path}
                  className="mobile_navigation__link f-16 f-500"
                  activeClassName="mobile_navigation__link-active"
                >
                  {route.label}
                </NavLink>
              ))}
            </div>
            <MenuUser className="mobile_navigation__user" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNavigation;
