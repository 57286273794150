import { USER_STATUSES, VERIFF_CODES } from './constants';

// Check if user passed successfully Veriff
export const isPassedVeriff = user => !!user?.is_verified;

// Check if user submitted Veriff
export const isSubmittedVeriff = code =>
  [VERIFF_CODES.submitted, VERIFF_CODES.reSubmitted].includes(code);

// Check if user passed manual moderation and verified by Administrator
export const isVerifiedByAdmin = user =>
  user?.status === USER_STATUSES.verified;

// Check if user verified phone number
export const isVerifiedPhoneNumber = user => !!user?.is_phone_number_verified;

// Check if user has filled required fields at Profile Section (First name and Last name)
export const hasRequiredProfileSectionFields = user =>
  !!(user?.first_name && user?.last_name);

// Check if user has filled required fields at Personal Section (Citizenship, Origin of Funds, Monthly Investment, Phone, country, Address, City)
export const hasRequiredPersonalSectionFields = user => {
  if (!user) {
    return false;
  }
  const { citizenship, investor, phone, country, address, city } = user;

  return !!(
    citizenship &&
    investor?.origin_of_funds &&
    investor?.monthly_investment &&
    phone &&
    country &&
    address &&
    city
  );
};

// Check if user filled all required fields and passed Veriff but waiting for Administration verification
export const isReadyForVerification = user =>
  !!(
    user.status !== USER_STATUSES.verified &&
    user.status !== USER_STATUSES.blocked &&
    isPassedVeriff(user) &&
    hasRequiredProfileSectionFields(user) &&
    hasRequiredPersonalSectionFields(user)
  );
