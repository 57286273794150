import React from 'react';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../helpers/utils';
import s from './table.module.scss';

export const ScheduleTable = ({ data }) => {
  if (!data) {
    return null;
  }
  const { list } = data;
  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div className={s.table}>
          <div className={s.table__thead}>
            <div className={s.table__thead_col}>Month</div>
            <div className={s.table__thead_col}>Your earnings</div>
            <div className={s.table__thead_col}>Principal</div>
            <div className={classnames(s.table__thead_col, s.table__hide)}>
              Total
            </div>
          </div>

          <div className={s.table__tbody}>
            {list.map((loan, index) => (
              <div key={index} className={classnames(s.table__tbody_row)}>
                <div className={s.table__tbody_col}>
                  <span style={{ whiteSpace: 'nowrap' }}>{loan.month}</span>
                </div>
                <div className={s.table__tbody_col}>
                  {loan.earnings
                    ? prettyFloatMoney(loan.earnings, { roundUpper: true })
                    : '–'}
                </div>
                <div className={s.table__tbody_col}>
                  {loan.principal
                    ? prettyFloatMoney(loan.principal, { roundUpper: true })
                    : '–'}
                </div>
                <div className={classnames(s.table__tbody_col, s.table__hide)}>
                  {loan.total
                    ? prettyFloatMoney(loan.total, { roundUpper: true })
                    : '–'}
                </div>
              </div>
            ))}

            <div
              className={classnames(
                s.table__tbody_row,
                s.table__tbody_row_result,
                'f-500'
              )}
            >
              <div className={s.table__tbody_col}>Total:</div>
              <div className={s.table__tbody_col}>
                {data.total_earning
                  ? prettyFloatMoney(data.total_earning, { roundUpper: true })
                  : '–'}
              </div>
              <div className={s.table__tbody_col}>
                {data.total_principal
                  ? prettyFloatMoney(data.total_principal, { roundUpper: true })
                  : '–'}
              </div>
              <div className={classnames(s.table__tbody_col, s.table__hide)}>
                {data.total
                  ? prettyFloatMoney(data.total, { roundUpper: true })
                  : '–'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
