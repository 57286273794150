import React from 'react';
import { RoundButton } from '../../components/UI/RoundButton';
import config from '../../config';
import './index.scss';

const ReferralEmailModal = ({
  values,
  onSubmit,
  errors,
  inviteLink,
  emailSent,
}) => {
  const { name } = values;
  const link = `${config.apiURL}invitation_email_preview/?first_name=${
    name || 'Hey!'
  }&invitation_link=${inviteLink}`;

  return (
    <div className="referral_email_modal">
      <div className="referral_email_modal__inner">
        <iframe title="affiliate_email_modal" id="affiliate_email_modal" src={link} />
      </div>

      <RoundButton
        label={emailSent ? 'Email sent' : 'Send email'}
        fillBackground
        fullWidth
        className={
          emailSent
            ? 'referral_email_modal__send referral_program_page__sent'
            : 'referral_email_modal__send'
        }
        disabled={emailSent || !values.email || (errors && errors.email)}
        onClick={onSubmit}
      />
    </div>
  );
};

export default ReferralEmailModal;
