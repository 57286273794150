import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { InputTextField } from '../../UI/InputTextField';
import { RoundButton } from '../../UI/RoundButton';
import PhotoUpload from '../../PhotoUpload';
import { PHOTO_ALLOWED_FORMATS } from '../../../helpers/common';
import { checkForValidFile } from '../../../helpers/utils';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object({
  first_name: Yup.string()
    .max(40, 'First name exceeds limit of 40')
    .required('First name is required'),
  last_name: Yup.string()
    .max(40, 'Last name exceeds limit of 40')
    .required('Last name is required'),
});

class ProfileDetailsForm extends React.Component {
  getInitialValues = user => ({
    first_name: (user && user.first_name) || '',
    last_name: (user && user.last_name) || '',
    photo: null,
    croppedPhoto: '',
    editorRef: null,
    company_name: (user && user.investor && user.investor.company_name) || '',
    position: (user && user.investor && user.investor.position) || '',
  });

  render() {
    const { userLocation, user, onSubmit, initialValues } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={
          /* eslint-disable no-unneeded-ternary */
          initialValues
            ? initialValues
            : this.getInitialValues(user, userLocation)
        }
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          values,
          errors,
          touched,
          setFieldError,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className="profile-details-form" onSubmit={handleSubmit}>
            <h1 className="profile-details-form__title f-500">
              Profile details
            </h1>
            <p className="profile-details-form__subTitle f-400">
              This information will remain strictly confidential. It is required
              for further investment operations.
            </p>
            <div className="profile-details-form__grid">
              <div className="profile-details-form__grid-left">
                <InputTextField
                  label="First name *"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('first_name', '')}
                  error={
                    errors.first_name && touched.first_name && errors.first_name
                  }
                  className="profile-details-form__row"
                />
                <InputTextField
                  label="Last name *"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('last_name', '')}
                  error={
                    errors.last_name && touched.last_name && errors.last_name
                  }
                  className="profile-details-form__row"
                />
              </div>
              <div className="profile-details-form__grid-right">
                <InputTextField
                  label="Company name"
                  name="company_name"
                  value={values.company_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('company_name', '')}
                  error={
                    errors.company_name &&
                    touched.company_name &&
                    errors.company_name
                  }
                  className="profile-details-form__row"
                />
                <InputTextField
                  label="Position"
                  name="position"
                  value={values.position}
                  onChange={handleChange}
                  onClear={() => setFieldValue('position', '')}
                  error={errors.position && touched.position && errors.position}
                  className="profile-details-form__row"
                />
              </div>
            </div>

            <PhotoUpload
              label="Upload your photo"
              name="photo"
              value={values.photo}
              onChange={e => {
                const file = e.target.files[0];
                const { isValid, type, size } = checkForValidFile(
                  file,
                  PHOTO_ALLOWED_FORMATS,
                  12582912
                );
                !isValid &&
                  size &&
                  setFieldError('photo', 'File size is too big');
                !isValid &&
                  type &&
                  setFieldError(
                    'photo',
                    'Incorrect file type. Upload your photo in jpg or png format.'
                  );
                isValid && setFieldValue('photo', file);
              }}
              setFieldValue={setFieldValue}
              onRemove={() => setFieldValue('photo', '')}
              error={errors.photo}
              className="profile-details-form__photo"
            />

            <RoundButton
              label="Continue"
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              className="profile-details-form__button"
            />
          </form>
        )}
      </Formik>
    );
  }
}

export default ProfileDetailsForm;
