import React from 'react';
import * as classnames from 'classnames';
import { prettyFloatMoney, prettyMoneyHybrid } from '../../helpers/utils';
import './index.scss';

const CashDetailBox = ({ balance, className }) => (
  <div className={classnames('cash_detail_box', className)}>
    <div className="cash_detail_box__item">
      <div className="cash_detail_box__item_title f-16 f-500">
        Available cash
      </div>
      <div className="cash_detail_box__item_value tl f-26 f-500">
        {prettyMoneyHybrid(balance?.available)}
      </div>
    </div>

    <div className="cash_detail_box__item">
      <div className="cash_detail_box__item_title f-16 f-500">
        Allocated cash
      </div>
      <div className="cash_detail_box__item_value tl f-26 f-500">
        {prettyFloatMoney(balance?.on_hold)}
      </div>
    </div>

    {balance?.available_bonus_amount !== null && (
      <div className="cash_detail_box__item">
        <div className="cash_detail_box__item_title f-16 f-500">
          Scramble bonus
        </div>
        <div className="cash_detail_box__item_value tl f-26 f-500">
          {prettyMoneyHybrid(balance?.available_bonus_amount)}
        </div>
      </div>
    )}
  </div>
);

export default CashDetailBox;
