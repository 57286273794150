import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import * as classnames from 'classnames';
import Decimal from 'decimal.js-light';
import BalanceSelect from '../../UI/BalanceSelect';
import { RoundButton } from '../../UI/RoundButton';
import { DATE_FORMAT_MMM_D, MONEY_REGEX } from '../../../helpers/common';
import { MoneyField } from '../../UI/MoneyField';
import { RoundLink } from '../../UI/RoundLink';
import RadioButton from '../../UI/RadioButton';
import {
  isNumber,
  prettyFloatMoney,
  prettyMoney,
  round10,
} from '../../../helpers/utils';
import { MyLink } from '../../MyLink';
import { APP_LINKS } from '../../../helpers/links';
import {
  ACCOUNT_TYPES,
  CURRENCY,
  LOAN_GROUPS,
} from '../../../helpers/constants';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  from: Yup.object().shape({
    group: Yup.string().required(),
    type: Yup.string().required(),
  }),
  to: Yup.object().shape({
    group: Yup.string().required(),
    type: Yup.string().required(),
  }),
  amount: Yup.string()
    .matches(MONEY_REGEX, 'Enter valid amount')
    .required('Please enter amount'),
});

const AllocateCashForm = ({
  accounts,
  reinvest,
  preSelectedID,
  onReinvestChange,
  onSubmit,
  allocateOnly,
  isActiveFundraising,
  endDate,
  className,
}) => {
  const status = useSelector(state => state.investStore.investingStatus.data);
  const allocateInfo = useSelector(state => state.fundStore.allocateInfo.data);
  const [remaining, setRemaining] = useState(0);

  const disabled =
    !accounts ||
    (accounts &&
      !accounts.reduce((acc, item) => {
        acc += parseFloat(item.amount);
        return acc;
      }, 0));

  const validate = values => {
    const errors = {};
    const amount = new Decimal(values.amount || 0).toNumber();
    const maxAmount = remaining;

    if (values.from && values.to) {
      if (values.to.type === ACCOUNT_TYPES.cash) {
        if (amount > maxAmount) {
          errors.amount = 'There are no funds to transfer';
        }
      }

      if (values.to.type === ACCOUNT_TYPES.loan) {
        if (amount > maxAmount) {
          errors.amount =
            maxAmount === 0
              ? 'Remaining amount already reached'
              : `Amount should be less than ${prettyFloatMoney(maxAmount)}`;
        }

        if (values.from && amount > values.from.amount) {
          errors.amount = 'There are no funds to transfer';
        }
      }
    } else {
      errors.amount = 'Please check selected accounts';
    }

    return errors;
  };

  const preSelectedAccount =
    preSelectedID &&
    accounts &&
    accounts.find(item => item.group === preSelectedID);

  const calculateRemainingAmount = (toAccount, fromAccount) => {
    const account = allocateInfo?.accounts.find(
      acc => acc.group === toAccount.group
    );
    if (account) {
      if (toAccount.type === ACCOUNT_TYPES.cash) {
        return fromAccount.amount;
      }
      return new Decimal(account.remaining_amount)
        .add(account.amount)
        .sub(toAccount.amount)
        .toNumber();
    }
    return 0;
  };

  const updateRemaining = (to, from) => {
    setRemaining(calculateRemainingAmount(to, from));
  };

  useEffect(() => {
    updateRemaining(preSelectedAccount || (accounts && accounts[1]));
  }, []);

  const cash =
    accounts.find(acc => acc.type === ACCOUNT_TYPES.cash)?.amount || 0;

  const getAmountPresets = (amount = 0, groupID) => {
    let presets = [];
    switch (groupID) {
      case LOAN_GROUPS.conservative.id:
        presets = [amount, amount * 0.5, amount * 0.2]; // для A - 100%, 50%, 20%
        break;
      case LOAN_GROUPS.moderate.id:
        presets = [amount, amount * 0.8, amount * 0.5]; // для В - 100%, 80%, 50%
        break;
      default:
        break;
    }

    return presets.reduce((acc, amount, index) => {
      let roundedAmount;
      if (index === 0) {
        roundedAmount = parseInt(amount);
      } else if (round10(amount, true) >= presets[0]) {
        roundedAmount = parseInt(amount);
      } else {
        roundedAmount = round10(amount, true);
      }
      if (roundedAmount && !acc.includes(roundedAmount)) {
        acc.push(roundedAmount);
      }
      return acc;
    }, []);
  };

  const renderPresets = (values, onClick) => {
    if (values.to.type === ACCOUNT_TYPES.loan) {
      const presets = getAmountPresets(
        parseInt(cash > remaining ? remaining : cash),
        values.to.group
      );

      return (
        !!presets.length && (
          <div className="alc-cash-form__presets">
            {presets.map((amount, index) => (
              <div
                key={index}
                onClick={() => onClick('amount', `${amount}`)}
                className={classnames(
                  'alc-cash-form__preset',
                  Number(values.amount) === amount && 'selected',
                  'f-20',
                  'f-400'
                )}
              >
                {prettyMoney(amount)}
              </div>
            ))}
          </div>
        )
      );
    }
  };

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        from: accounts && accounts[0],
        to: preSelectedAccount || (accounts && accounts[1]),
        amount: '',
        reinvest: !!reinvest,
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setValues,
      }) => (
        <>
          {isActiveFundraising && endDate && (
            <div className="alc-cash-form__hold f-20">
              Funds are unavailable for allocation until the end of the
              fundraising round on {moment(endDate).format(DATE_FORMAT_MMM_D)},
              23:59 GMT.{' '}
              <MyLink
                href={APP_LINKS.investing}
                className="alc-cash-form__learn-link"
              >
                See details
              </MyLink>
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className={classnames(
              'alc-cash-form',
              isActiveFundraising && 'disabled',
              className
            )}
          >
            <h2 className="alc-cash-form__title f-26 f-500">Transfer</h2>
            {!allocateOnly && (
              <div className="alc-cash-form__desc f-20">
                Allocate your funds among risk groups. Funds must be allocated
                prior to start of a fundraising round to be eligible for
                lending.{' '}
                <MyLink
                  href={APP_LINKS.helpLoanTerms}
                  className="alc-cash-form__learn-link"
                >
                  Learn more about loan terms{' '}
                </MyLink>
              </div>
            )}
            {disabled && (
              <RoundLink
                label="Add cash"
                path={APP_LINKS.addFunds}
                style={{ marginTop: '15px' }}
              />
            )}
            <p className="alc-cash-form__from f-16">From</p>
            <BalanceSelect
              value={values.from}
              options={accounts || []}
              onSelect={balance => {
                const toAccount = accounts.filter(
                  item => item.group !== balance.group
                )[0];
                updateRemaining(toAccount, balance);
                setValues({
                  ...values,
                  amount: '',
                  from: balance,
                  to: toAccount,
                });
              }}
              disabled={disabled}
              className="alc-cash-form__select"
            />

            <p className="alc-cash-form__to f-16">To</p>
            <BalanceSelect
              value={values.to}
              options={
                accounts
                  ? accounts.filter(item => item.group !== values.from.group)
                  : []
              }
              onSelect={balance => {
                updateRemaining(balance, values.from);
                setValues({ ...values, to: balance, amount: '' });
              }}
              className="alc-cash-form__select"
            />

            {status?.is_active &&
              values.to &&
              isNumber(values.to.remaining_amount) && (
                <p className="f-16 alc-cash-form__remaining">
                  {remaining < 0 ? (
                    <span className="c-red">
                      Remaining amount has been changed by some others
                      investors. Please review and update loan groups. Maximum
                      limit is {CURRENCY}&nbsp;
                      {
                        allocateInfo?.accounts.find(
                          acc => acc.group === values.to.group
                        )?.remaining_amount
                      }
                    </span>
                  ) : (
                    <span>
                      {remaining === 0
                        ? `The full amount in ${values.to.group_title} has been raised`
                        : `Remains to be raised in ${
                            values.to.group_title
                          }: ${prettyFloatMoney(remaining)}`}
                    </span>
                  )}
                </p>
              )}

            <MoneyField
              label="Amount"
              onChange={(_, value) => setFieldValue('amount', value)}
              value={values.amount}
              name="amount"
              onClear={() => setFieldValue('amount', '')}
              className="alc-cash-form__amount"
              disabled={remaining <= 0 || isActiveFundraising}
              error={
                !(remaining <= 0 || isActiveFundraising) &&
                errors.amount &&
                touched.amount &&
                errors.amount
              }
            />

            {renderPresets(values, setFieldValue)}

            <RoundButton
              type="submit"
              label="Transfer"
              onSubmit={handleSubmit}
              disabled={remaining <= 0 || isActiveFundraising}
              className="alc-cash-form__submit hov"
              fillBackground
            />

            {onReinvestChange && (
              <div className="alc-cash-form__bottom">
                <h2 className="alc-cash-form__title f-500">Reinvest</h2>
                <p className="alc-cash-form__reinvest-desc f-16">
                  You can either automatically reinvest returns in new loans
                  within the same risk group or have them transferred to your
                  Available cash
                </p>

                <h5 className="alc-cash-form__reinvest-title f-16">
                  What should we do with your investment returns?
                </h5>
                <div className="alc-cash-form__reinvest" id="reinvest">
                  <RadioButton
                    value="on"
                    name="reinvest"
                    checked={values.reinvest}
                    disabled={isActiveFundraising}
                    onChange={() => {
                      onReinvestChange(true).finally(() => {
                        setFieldValue('reinvest', true);
                      });
                    }}
                    className="alc-cash-form__reinvest-radio"
                  >
                    <p className="f-16">
                      Reinvest in new loans within the same risk group
                    </p>
                  </RadioButton>

                  <RadioButton
                    value="off"
                    name="reinvest"
                    disabled={isActiveFundraising}
                    onChange={() => {
                      onReinvestChange(false).finally(() => {
                        setFieldValue('reinvest', false);
                      });
                    }}
                    checked={!values.reinvest}
                    className="alc-cash-form__reinvest-radio"
                  >
                    <p className="f-16">
                      Transfer to your Available cash account
                    </p>
                  </RadioButton>
                </div>
              </div>
            )}
          </form>
        </>
      )}
    </Formik>
  );
};

export default AllocateCashForm;
