import { METADATA } from '../../helpers/metadata';
import {
  GET_USER_PROFILE,
  SET_PROFILE_MISSING_DETAILS,
  SET_USER_PROFILE, SET_VERIFF_STATUS_CODE,
  UPDATE_USER_PROFILE
} from '../types/profileTypes';

const initialState = {
  profile: { ...METADATA.default, data: null },
  profileMissingDetails: {},
  veriffStatusCode: 0,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE.REQUEST:
    case UPDATE_USER_PROFILE.REQUEST:
      return { ...state, profile: { ...state.profile, ...METADATA.request } };
    case GET_USER_PROFILE.SUCCESS:
    case UPDATE_USER_PROFILE.SUCCESS:
      return { ...state, profile: { ...state.profile, ...METADATA.success, data: action.payload } };
    case GET_USER_PROFILE.FAILURE:
    case UPDATE_USER_PROFILE.FAILURE:
      return { ...state, profile: { ...state.profile, ...METADATA.error, error: action.error } };
    case SET_PROFILE_MISSING_DETAILS:
      return { ...state, profileMissingDetails: action.payload };
    case SET_USER_PROFILE:
      return { ...state, profile: {...state.profile, data: action.profile}};
    case SET_VERIFF_STATUS_CODE:
      return { ...state, veriffStatusCode: action.code };

    default:
      return state;
  }
};

export default profileReducer;
