import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { LOAN_GROUPS } from '../../../helpers/constants';
import s from './index.module.scss';

export const Filter = ({ group, onClick }) => {
  const { loansByFundraisingID } = useSelector(state => state.loanStore);

  const loans = loansByFundraisingID.data || [];

  if (loans.length < 2) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">More details about:</h2>
      <ul className={s.list}>
        <li
          onClick={() => onClick()}
          className={classnames(s.item, !group && 'active')}
        >
          All loan groups
        </li>
        {[LOAN_GROUPS.conservative, LOAN_GROUPS.moderate].map(i => (
          <li
            key={i.id}
            onClick={() => onClick(i.id)}
            className={classnames(s.item, i.id === group && 'active')}
          >
            {i.full_name}
          </li>
        ))}
      </ul>
    </div>
  );
};
