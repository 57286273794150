import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { QUESTION_STATUSES, USER_STATUSES } from '../../../helpers/constants';
import { getFlagURL, getNameInitials } from '../../../helpers/utils';
import { remainingTime } from '../../../helpers/date';
import { APP_LINKS } from '../../../helpers/links';
import {
  createNewQuestion,
  getQuestionList,
} from '../../../store/actions/questionActions';
import Notify from '../../../components/Notification';
import Avatar from '../../../components/UI/Avatar';
import { DropdownBase } from '../../../components/Dropdown/base';
import { WriteMessage } from '../../../components/WriteMessage';
import { RoundLink } from '../../../components/UI/RoundLink';
import s from './index.module.scss';

export const QuestionCard = ({ question }) => {
  const dispatch = useDispatch();
  const [replyMessageID, setReplyMessageID] = useState(null);
  const { user } = useSelector(state => state.userStore);

  const onReply = async text => {
    const payload = {
      reply_to: replyMessageID,
      comment_text: text,
    };

    const res = await dispatch(createNewQuestion(payload));
    if (res?.success) {
      setReplyMessageID(null);
      dispatch(getQuestionList({ page: 1, limit: 1000 }));
      Notify.success({ text: 'Message created' });
    }
  };

  return (
    <DropdownBase
      render={({ open, setOpen }) => (
        <div className={classnames(s.wrapper, `level_${question.level}`)}>
          <div key={question.id} className={s.card}>
            <div
              className={classnames(
                s.card_avatar,
                question.status === QUESTION_STATUSES.draft &&
                  s.card_avatar_disabled
              )}
            >
              <Avatar
                size={50}
                nameInitials={getNameInitials(
                  question.user.first_name,
                  question.user.last_name
                )}
                src={question.user.photo?.file}
                alt={question.user.full_name}
              />
            </div>
            <div className={s.card_content}>
              <div className={s.card_name}>
                <div>{question.user.full_name}</div>
                {question.status !== QUESTION_STATUSES.draft && (
                  <div className={s.card_flag}>
                    <img
                      src={getFlagURL(question.user.country_code)}
                      alt={question.user.country_code}
                    />
                  </div>
                )}
              </div>
              <div
                className={classnames(
                  s.card_question,
                  question.status === QUESTION_STATUSES.draft &&
                    s.card_question_disabled
                )}
              >
                {question.comment_text}
              </div>
              {question.status === QUESTION_STATUSES.draft ? (
                <div className={s.card_moderation}>
                  <div className={s.card_moderation_title}>Moderation </div>
                  {/* <button */}
                  {/*   type="button" */}
                  {/*   onClick={() => null} */}
                  {/*   className={s.card_delete_btn} */}
                  {/* > */}
                  {/*   Delete */}
                  {/* </button> */}
                </div>
              ) : (
                <div className={s.card_controls}>
                  <div className={s.card_time}>
                    {remainingTime(question.created_at)}
                  </div>

                  {!!question.children.length && question.level === 1 && (
                    <button
                      type="button"
                      onClick={() => setOpen(!open)}
                      className={s.card_view_btn}
                    >
                      {open ? 'Close replies' : 'View replies'}
                    </button>
                  )}

                  <button
                    type="button"
                    onClick={() => setReplyMessageID(question.id)}
                    className={s.card_view_btn}
                  >
                    Reply
                  </button>
                </div>
              )}
            </div>
          </div>

          {replyMessageID &&
            (user.status === USER_STATUSES.verified ? (
              <WriteMessage
                onCancel={() => setReplyMessageID(null)}
                onReply={onReply}
                className={s.card_message}
              />
            ) : (
              <div className={s.card_verify}>
                <div className={s.card_verify_text}>
                  To answer the question, please verify yourself
                </div>
                <RoundLink
                  path={APP_LINKS.profile}
                  label="Verify yourself"
                  className={s.card_verify_link}
                  fillBackground
                  fullWidth
                />
              </div>
            ))}

          <div
            className={classnames(
              s.card_drop,
              (open || question.level > 1) && 'open'
            )}
          >
            {question.children.map(item => (
              <QuestionCard key={item.id} question={item} />
            ))}
          </div>
        </div>
      )}
    />
  );
};
