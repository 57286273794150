import React from 'react';
import ChangePassForm from '../../components/Forms/ChangePassForm';
import { changePassword } from '../../store/services/userServices';

const ChangePasswordModal = ({ onClose }) => {
  const onPasswordChange = values => {
    if (values.password1 === values.password2) {
      const payload = {
        old_password: values.oldPassword,
        new_password: values.password1,
      };
      changePassword(payload).then(res => res && res.success && onClose());
    }
  };

  return <ChangePassForm onSubmit={onPasswordChange} onCancel={onClose} />;
};

export default ChangePasswordModal;
