import * as React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import config from '../../../config';
import { RoundButton } from '../../UI/RoundButton';
import Analytics from '../../../analytics';
import './index.scss';

class VeriffForm extends React.Component {
  componentDidMount() {
    const { dispatch, user, onSuccess } = this.props;

    const veriff = Veriff({
      host: config.veriffHostURL,
      apiKey: config.veriffApiKey,
      parentId: 'veriff-root',
      onSession(err, response) {
        Analytics.verificationWizardVeriffNewSession();
        createVeriffFrame({
          url: response.verification.url,
          onEvent: () => {
            onSuccess ? onSuccess(response, err) : dispatch(push('/dashboard'));
          },
        });
      },
    });

    veriff.setParams({
      person: {
        givenName: user.first_name,
        lastName: user.last_name,
      },
      vendorData: user.email,
    });
    veriff.mount({
      submitBtnText: 'Start verification',
    });
  }

  render() {
    const { onBack, children, backLabel } = this.props;
    return (
      <div className="veriff-form">
        <h1 className="veriff-form__title f-32 f-500">Verify your identity</h1>
        <p className="veriff-form__desc f-16 f-400">
          To provide a safe marketplace and prevent financial crime, we are
          bound to verify the identity of our investors.
        </p>
        <p className="veriff-form__desc f-16 f-400">
          To start verification, you’ll need to choose the verification
          assistance option and take the following steps:
        </p>
        <ul className="veriff-form__list f-16 f-400">
          <li>
            Prepare a valid and undamaged original identity document: passport,
            national ID card, driver&apos;s license or residence permit.
          </li>
          <li>Check if your device’s camera is uncovered and working.</li>
          <li>Be prepared to take a photo of your ID and a selfie.</li>
        </ul>

        <div className="veriff-form__information">
          <p className="f-14 f-400" style={{ marginTop: '10px' }}>
            Identity verification is provided by{' '}
            <a
              href="https://www.veriff.com/"
              className="link-u"
              target="__blank"
            >
              Veriff
            </a>
            ’s highly trusted and secure solution.
          </p>

          <p className="veriff-support f-14 f-400">
            During identity verification, all media materials are stored for a
            limited time by Veriff and aren&apos;t transferred to Scramble.
          </p>
        </div>

        {children}

        <div className="veriff-form__bottom">
          <RoundButton
            label={backLabel || 'Back'}
            type="button"
            onClick={onBack}
            className="veriff-form__back"
          />
          <div id="veriff-root" />
        </div>

        <p className="veriff-form__veriff-description">
          <a title="Veriff" href="https://www.veriff.com/" target="_blank">
            Veriff
          </a>{' '}
          is an identity verification provider that helps companies connect with
          customers.
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
});

export default connect(mapStateToProps)(VeriffForm);
