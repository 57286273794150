import React from 'react';
import classnames from 'classnames';
import './index.scss';

export const TrustpilotStars = () => {
  return (
    <div
      className={classnames('trustpilot-widget', 'trs_stars')}
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="61faa1aa4e2b01025a9e808a"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="dark"
    >
      <a
        href="https://uk.trustpilot.com/review/scrambleup.com"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  );
};
