import React from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useToggle } from '../../../hooks/useToggle';

export const Dropdown = ({ defaultShow, render }) => {
  const [open, setOpen] = useToggle(!!defaultShow);
  const ref = React.useRef(null);

  useOutsideClick(ref, setOpen, ref ? open : false);

  return render({ open, setOpen, ref });
};
