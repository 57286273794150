import React, { Component } from 'react';
import * as classnames from 'classnames';
import Decimal from 'decimal.js-light';
import AllocateCashForm from '../../components/Forms/AllocateCashForm';
import { connect } from 'react-redux';
import {
  allocateFunds,
  getAllocateInfo,
  setLocalAccounts,
} from '../../store/actions/fundActions';
import { setInvestmentConfirmation } from '../../store/actions/investActions';
import Preloader from '../../components/Preloader';
import {
  getInvestingLoanGroups,
  getInvestingStatus,
} from '../../store/actions/investActions';
import { getBalance } from '../../store/actions/dashboardActions';
import Analytics from '../../analytics';
import { ACCOUNT_TYPES } from '../../helpers/constants';
import Notify from '../../components/Notification';
import './index.scss';

class AllocateFundModal extends Component {
  componentDidMount() {
    this.props.getInvestingStatus();
    this.props.getAllocateInfo().then(res => {
      if (res && res.success) {
        if (!this.props.localAccounts.length) {
          this.props.setInvestmentConfirmation({
            hasConfirmed:
              !!this.props.investingStatus.data?.has_accepted_necessary_terms,
            has_accepted_terms_a:
              !!this.props.investingStatus.data?.has_accepted_terms_a,
            has_accepted_terms_b:
              !!this.props.investingStatus.data?.has_accepted_terms_b,
          });
          this.props.setLocalAccounts(res.data.accounts);
        }
      }
    });
  }

  onAllocate = async (values, formik) => {
    this.props.getAllocateInfo();
    const { setValues, touched, setTouched } = formik;
    try {
      const enteredAmount = new Decimal(values.amount).toNumber();

      if (enteredAmount) {
        const fromAccount = {
          ...values.from,
          amount: new Decimal(values.from.amount).sub(enteredAmount).toNumber(),
        };

        const toAccount = {
          ...values.to,
          amount: new Decimal(values.to.amount).add(enteredAmount).toNumber(),
        };

        if (values.from.type === ACCOUNT_TYPES.loan) {
          fromAccount.remaining_amount = new Decimal(
            values.from.remaining_amount
          ).add(enteredAmount);
        }

        if (values.to.type === ACCOUNT_TYPES.loan) {
          toAccount.remaining_amount = new Decimal(
            values.to.remaining_amount
          ).sub(enteredAmount);
        }

        setValues({
          ...values,
          amount: '',
          from: fromAccount,
          to: toAccount,
        });

        this.props.setInvestmentConfirmation({
          hasConfirmed: false,
          has_accepted_terms_a: false,
          has_accepted_terms_b: false,
        });

        const accounts = this.props.localAccounts.map(account => {
          if (account.group === fromAccount.group) {
            return {
              ...account,
              ...fromAccount,
            };
          }
          if (account.group === toAccount.group) {
            return {
              ...account,
              ...toAccount,
            };
          }
          return account;
        });

        this.props.setLocalAccounts(accounts);

        Analytics.allocateCashTransfer();
        setTouched({ ...touched, amount: false });
      }

      return this.props.onClose();
    } catch (e) {
      Notify.error({ text: 'Something went wrong' });
    }
  };

  render() {
    const { className, localAccounts, selected } = this.props;

    return (
      <div className={classnames('allocate-fund-modal', className)}>
        {!!localAccounts.length ? (
          <AllocateCashForm
            preSelectedID={selected}
            accounts={localAccounts}
            className="allocate-fund-modal__form"
            onSubmit={this.onAllocate}
            allocateOnly
          />
        ) : (
          <Preloader className="allocate-fund-modal__preloader" />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investingStatus: state.investStore.investingStatus,
  localAccounts: state.fundStore.localAccounts,
  investmentConfirmation: state.investStore.investmentConfirmation,
});

const mapDispatchToProps = dispatch => ({
  getInvestingLoanGroups: () => dispatch(getInvestingLoanGroups()),
  getInvestingStatus: () => dispatch(getInvestingStatus()),
  allocateFunds: payload => dispatch(allocateFunds(payload)),
  getAllocateInfo: () => dispatch(getAllocateInfo()),
  setLocalAccounts: data => dispatch(setLocalAccounts(data)),
  setInvestmentConfirmation: data => dispatch(setInvestmentConfirmation(data)),
  getBalance: () => dispatch(getBalance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllocateFundModal);
