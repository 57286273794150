import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { NavLink } from 'react-router-dom';
import { APP_LINKS } from '../../helpers/links';
import { getInvestingStatus } from '../../store/actions/investActions';
import {
  getMissingProfileDetails,
  getUserProfile,
} from '../../store/actions/profileActions';
import './index.scss';

const TABS = [
  {
    label: 'Add cash',
    path: APP_LINKS.addFunds,
  },
  {
    label: 'Withdraw',
    path: APP_LINKS.withdraw,
  },
  {
    label: 'Bank accounts',
    path: APP_LINKS.bankAccounts,
  },
];

const FundsPage = ({ route, user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMissingProfileDetails());
    dispatch(getUserProfile());
    dispatch(getInvestingStatus());
  }, []);

  return (
    <div className="funds-page">
      <div className="container-inner">
        <h1 className="funds-page__title f-500 f-42">Cash</h1>
        <div className="funds-page__links">
          {TABS.map(tab => (
            <NavLink
              key={tab.path}
              to={tab.path}
              className="funds-page__link"
              activeClassName="funds-page__link-selected"
            >
              {tab.label}
            </NavLink>
          ))}
        </div>
        {renderRoutes(route.routes, { user })}
      </div>
    </div>
  );
};

export default FundsPage;
