import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SignUp from '../../containers/SignUp';
import BasicLayout from '../../layouts/BasicLayout';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import { APP_LINKS } from '../../helpers/links';

const SignUpPage = props => {
  const { history } = props;
  const { user } = useSelector(state => state.userStore);

  useEffect(() => {
    user && history.replace(APP_LINKS.dashboard);
  }, []);

  return (
    <BasicLayout>
      <div className="container">
        <SignUp />
        <MiniFaqs source={MINIFAQ_SOURCES.signUp} />
      </div>
    </BasicLayout>
  );
};

export default SignUpPage;
