export const GET_LOANS_LIST = {
  REQUEST: 'GET_LOANS_LIST_REQUEST',
  SUCCESS: 'GET_LOANS_LIST_SUCCESS',
  FAILURE: 'GET_LOANS_LIST_FAILURE',
};

export const GET_LOAN_TERMS = {
  REQUEST: 'GET_LOAN_TERMS_REQUEST',
  SUCCESS: 'GET_LOAN_TERMS_SUCCESS',
  FAILURE: 'GET_LOAN_TERMS_FAILURE',
};

export const GET_PAYMENT_HISTORY = {
  REQUEST: 'GET_PAYMENT_HISTORY_REQUEST',
  SUCCESS: 'GET_PAYMENT_HISTORY_SUCCESS',
  FAILURE: 'GET_PAYMENT_HISTORY_FAILURE',
};

export const GET_REPAYMENT_SCHEDULE = {
  REQUEST: 'GET_REPAYMENT_SCHEDULE_REQUEST',
  SUCCESS: 'GET_REPAYMENT_SCHEDULE_SUCCESS',
  FAILURE: 'GET_REPAYMENT_SCHEDULE_FAILURE',
};

export const GET_REPAYMENT_SCHEDULE_BY_LOAN_ID = {
  REQUEST: 'GET_REPAYMENT_SCHEDULE_BY_LOAN_ID_REQUEST',
  SUCCESS: 'GET_REPAYMENT_SCHEDULE_BY_LOAN_ID_SUCCESS',
  FAILURE: 'GET_REPAYMENT_SCHEDULE_BY_LOAN_ID_FAILURE',
};

export const GET_LOANS_BY_FUNDRAISING_ID = {
  REQUEST: 'GET_LOANS_BY_FUNDRAISING_ID_REQUEST',
  SUCCESS: 'GET_LOANS_BY_FUNDRAISING_ID_SUCCESS',
  FAILURE: 'GET_LOANS_BY_FUNDRAISING_ID_FAILURE',
};

export const GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID = {
  REQUEST: 'GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID_REQUEST',
  SUCCESS: 'GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID_SUCCESS',
  FAILURE: 'GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID_FAILURE',
};

export const GET_STARTUPS_BY_FUNDRAISING_ID = {
  REQUEST: 'GET_STARTUPS_BY_FUNDRAISING_ID_REQUEST',
  SUCCESS: 'GET_STARTUPS_BY_FUNDRAISING_ID_SUCCESS',
  FAILURE: 'GET_STARTUPS_BY_FUNDRAISING_ID_FAILURE',
};

export const GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID = {
  REQUEST: 'GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID_REQUEST',
  SUCCESS: 'GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID_SUCCESS',
  FAILURE: 'GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID_FAILURE',
};

export const GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID = {
  REQUEST: 'GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID_REQUEST',
  SUCCESS: 'GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID_SUCCESS',
  FAILURE: 'GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID_FAILURE',
};

export const GET_AGREEMENTS_BY_FUNDRAISING_ID = {
  REQUEST: 'GET_AGREEMENTS_BY_FUNDRAISING_ID_REQUEST',
  SUCCESS: 'GET_AGREEMENTS_BY_FUNDRAISING_ID_SUCCESS',
  FAILURE: 'GET_AGREEMENTS_BY_FUNDRAISING_ID_FAILURE',
};
