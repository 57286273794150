import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { mergeCalculations } from '../../../../helpers/calculator';
import { ScheduleTable } from '../../../../components/LoanSchedule/table';
import { MyLink } from '../../../../components/MyLink';
import { APP_LINKS } from '../../../../helpers/links';
import s from './index.module.scss';

export const LoansPreSchedule = () => {
  const { data } = useSelector(state => state.fundStore.allocateInfo);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus
  );

  const allocatedGroups = data?.accounts
    ?.map(acc => ({
      amount: acc.amount,
      groupID: acc.group,
    }))
    .filter(i => i.groupID !== 'cash' && i.amount);

  if (!allocatedGroups) {
    return null;
  }

  const tableData = mergeCalculations(allocatedGroups, status?.end_date);

  if (!tableData) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">Repayment schedule</h2>
      <div className={s.schedule} style={{ marginTop: '20px' }}>
        <div className={s.schedule_title}>
          {tableData.group} loan repayment schedule
        </div>
        <ScheduleTable data={tableData} />
      </div>

      <div className={classnames(s.hint, 'f-16 f-400')}>
        The repayment schedule is subject to changes in case a startup extends
        loan terms (to a maximum of 24 months) or moves a monthly repayment to
        the last month of the current loan term. Please find more details in the{' '}
        <MyLink href={APP_LINKS.helpLoanTerms} underline>
          Loan terms
        </MyLink>
        .
      </div>
    </div>
  );
};
