export const COOKIE_NAMES = {
  referralCodes: 'refcodes',
};

export const createCookie = params => {
  const keyPair = params.name + '=' + params.value;

  let expires = '';
  if (params.days) {
    const date = new Date();
    date.setTime(date.getTime() + params.days * 24 * 60 * 60 * 1000);
    expires = 'expires=' + date.toGMTString();
  }
  const path = `path=${params.path || '/'}`;
  const domain = params.domain ? `domain=${params.domain}` : '';

  document.cookie = [keyPair, expires, path, domain].join('; ');
};

export const readCookie = name => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = name => {
  createCookie(name, '', -1);
};
