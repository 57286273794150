import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AffiliateSteps from '../../components/AffiliateSteps';
import { RoundButton } from '../../components/UI/RoundButton';
import { InputTextField } from '../../components/UI/InputTextField';
import ReferralsModule from '../../containers/ReferralsModule';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import {
  acceptPartnerTermsOfUse,
  getReferralCode,
  inviteReferralByEmail,
} from '../../store/services/referralService';
import config from '../../config';
import { APP_LINKS } from '../../helpers/links';
import ShareButtons from '../../components/ShareButtons';
import { setModals } from '../../store/actions/commonActions';
import { MODAL_TYPES } from '../../components/ModalProvider';
import { CopyRoundButton } from '../../components/UI/CopyRoundButton';
import { MyLink } from '../../components/MyLink';
import { getUserProfile } from '../../store/actions/profileActions';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { USER_STATUSES } from '../../helpers/constants';
import './index.scss';

export const ACCEPT_PARTNER_TERMS_SOURCES = {
  copyButton: 1,
  sendEmail: 2,
  copyByMouse: 3,
  ctrlCopyPress: 4,
  shareBySocial: 5,
};

const AffiliatePage = () => {
  const [code, setCode] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const profile = useSelector(state => state.profileStore.profile.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
    getReferralCode().then(res => res && res.success && setCode(res.data));
  }, []);

  if (!profile) {
    return null;
  }

  if (profile?.status === USER_STATUSES.suspended) {
    return (
      <div className="referral_program_page">
        <div className="container-inner">
          <h1 className="help-page__title f-42 f-500">
            Scramble Referral program
          </h1>
          <SuspendResidenceWarning className="referral_program_page__suspend" />
        </div>
      </div>
    );
  }

  const onPreviewClick = (values, onSubmit, errors, inviteLink) => {
    dispatch(
      setModals({
        type: MODAL_TYPES.referral_email,
        values,
        errors,
        onSubmit,
        emailSent,
        inviteLink,
      })
    );
  };

  const onSendEmail = (values, formikBag) => {
    const payload = {
      email: values.email,
      first_name: values.name,
    };
    inviteReferralByEmail(payload).then(r => {
      if (r && r.success) {
        formikBag.resetForm();
        setEmailSent(true);
        acceptPartnerTermsOfUse({
          has_accepted: true,
          source: ACCEPT_PARTNER_TERMS_SOURCES.sendEmail,
          extra_data: {},
        });
      }
    });
  };

  const inviteLink = code && config.mainPromoDomain + '/round?ref=' + code.code;

  const sharedText = `Investing in secured loans has never been easier. Sign up today and get a €5 cash bonus to start growing your passive income`;

  return (
    <div className="referral_program_page">
      <div className="container-inner">
        <h1 className="help-page__title f-42 f-500">
          Scramble Referral program
        </h1>
        <AffiliateSteps className="referral_program_page__steps" />

        <section className="referral_program_page__how">
          <h2 className="f-26 f-500">How it works</h2>
          <div className="referral_program_page__how_desc">
            <p className="f-400">
              Share your unique Scramble referral link with your friends via
              email, social and more. When your friend invests on Scramble
              within a 60 days period, you receive a reward equaling 5% of your
              friend’s invested amount.
            </p>
            <p className="f-400">
              Copy and share your referral link below. All new registrations
              made with this link will be associated with your Scramble account.
            </p>
          </div>
        </section>

        {code && (
          <section className="referral_program_page__share">
            <h2 className="f-26 f-500">Share your link</h2>
            <div className="referral_program_page__share_link">
              <div className="referral_program_page__share_link_label f-14 f-400 c-grey">
                Copy the link below and send it to your friends
              </div>
              <div className="referral_program_page__share_link_content">
                <div className="referral_program_page__share_link_value f-16 f-500">
                  {inviteLink}
                </div>
                <CopyRoundButton
                  text={inviteLink}
                  label="Copy link"
                  successLabel="Link copied"
                  onClick={() => {
                    acceptPartnerTermsOfUse({
                      has_accepted: true,
                      source: ACCEPT_PARTNER_TERMS_SOURCES.copyButton,
                      extra_data: {},
                    });
                  }}
                />
              </div>
            </div>

            <div className="referral_program_page__share_methods">
              <Formik
                onSubmit={onSendEmail}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Invalid email')
                    .max(50, 'Invalid email')
                    .required('Email is required'),
                })}
                initialValues={{
                  name: '',
                  email: '',
                }}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="referral_program_page__share_email"
                  >
                    <div>Invite by email</div>
                    <InputTextField
                      label="Your friend name"
                      onChange={handleChange}
                      value={values.name}
                      name="name"
                      className="referral_program_page__share_email_input"
                    />
                    <InputTextField
                      label="Your friend email *"
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={errors && errors.email}
                      className="referral_program_page__share_email_input"
                    />
                    <div className="referral_program_page__share_email_buttons">
                      <RoundButton
                        label="Preview"
                        onClick={() =>
                          onPreviewClick(
                            values,
                            handleSubmit,
                            errors,
                            inviteLink
                          )
                        }
                      />
                      <RoundButton
                        type="submit"
                        fullWidth
                        fillBackground
                        label={emailSent ? 'Email was sent' : 'Send email'}
                        disabled={
                          emailSent || !values.email || (errors && errors.email)
                        }
                        className={
                          emailSent ? 'referral_program_page__sent' : ''
                        }
                        onSubmit={handleSubmit}
                      />
                    </div>
                  </form>
                )}
              </Formik>
              <div className="referral_program_page__share_social">
                <div>Or share you link via social media</div>
                <ShareButtons
                  url={inviteLink}
                  options={{
                    facebook: {
                      quote: sharedText,
                    },
                    twitter: {
                      title: sharedText,
                    },
                    linkedin: {
                      title: sharedText,
                      source: inviteLink,
                    },
                  }}
                  onClick={() => {
                    acceptPartnerTermsOfUse({
                      has_accepted: true,
                      source: ACCEPT_PARTNER_TERMS_SOURCES.shareBySocial,
                      extra_data: {},
                    });
                  }}
                  className="referral_program_page__share_social_icons"
                />
              </div>
            </div>

            <div className="referral_program_page__share_disclaimer f-16 f-400">
              <div>
                By inviting a friend, you acknowledge that you have read,
                understood and accepted the{' '}
                <MyLink href={APP_LINKS.docTermsAndConditions} isExternal>
                  Terms and Conditions
                </MyLink>{' '}
                of this Programme.
              </div>
              <br />
              <div>
                You must include this text in your message when you send the
                link: &quot;Please read carefully Scramble General Terms of
                Using the Platform and consult your financial professional if
                necessary, before investing.&quot;
              </div>
            </div>
          </section>
        )}

        <section className="referral_program_page__referrals">
          <h2 className="f-26 f-500">Your referrals</h2>
          <ReferralsModule />
        </section>

        <MiniFaqs source={MINIFAQ_SOURCES.affiliate} />
      </div>
    </div>
  );
};

export default AffiliatePage;
