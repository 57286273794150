import config from '../config';
import { CONTACT_EMAIL, CONTACT_PHONE } from './constants';

export const APP_LINKS = {
  root: '/',
  dashboard: '/dashboard',
  investing: '/investing',
  forgotPassword: '/forgot-password',
  signIn: '/sign-in',
  twoFactorAuth: '/2fa',
  signUp: '/sign-up',
  cash: '/funds',
  addFunds: '/funds/add-funds',
  withdraw: '/funds/withdraw',
  bankAccounts: '/funds/bank-accounts',
  bankAccountsCreate: '/funds/bank-accounts/new',
  bankAccountsEdit: id => `/funds/bank-accounts/${id}`,
  allocateCash: '/funds/allocate-cash',
  loans: '/loans',
  loansList: '/loans/list',
  loansSchedule: '/loans/schedule',
  startups: '/startups',
  founders: '/founders',
  investors: '/investors',
  activity: '/activity',
  profile: '/profile',
  affiliate: '/affiliate',
  help: '/help',
  clientQuestions: '/client-questions',
  helpLoanTermsRoot: '/help/loan-terms',
  helpLoanTerms: '/help/loan-terms/v2',
  helpLoanTermsV1: '/help/loan-terms/v1',
  helpLoanTermsGroupA: '/help/loan-terms/v2/group-a',
  helpLoanTermsGroupB: '/help/loan-terms/v2/group-b',
  helpLoanTermsGroupC: '/help/loan-terms/v2/group-c',
  helpLoanTermsGroupAV1: '/help/loan-terms/v1/group-a',
  helpLoanTermsGroupBV1: '/help/loan-terms/v1/group-b',
  helpLoanTermsGroupCV1: '/help/loan-terms/v1/group-c',
  helpPrivateInvestors: '/help/private-investors',
  support: `mailto:ask@scrambleup.com`,
  home: config.investorPromoDomain,
  promoRound: `${config.mainPromoDomain}/round`,
  promoRoundsHistory: `${config.mainPromoDomain}/rounds-history`,
  blog: config.blogDomain,
  privateInvestorPDF:
    'https://99.scrambleup.com/static/media/Scramble300.7b818638.pdf',
  privacyPolicy: `${config.mainPromoDomain}/docs/Privacy_policy.pdf`,
  termOfUse: `${config.mainPromoDomain}/docs/Terms_of_use.pdf`,
  unsubordinatedFinancialAgreement:
    config.mainPromoDomain +
    '/docs/scramble_financing_agreement_unsubordinated_loans.pdf',
  subordinatedFinancialAgreement:
    config.mainPromoDomain +
    '/docs/scramble_financing_agreement_subordinated_loans.pdf',
  subordinatedGuaranteeLetter:
    config.mainPromoDomain + '/docs/scramble_guarantee_letter_subordinated.pdf',
  foundersPromo: config.founderPromoDomain,
  investorsPromo: config.investorPromoDomain,
  mainPromo: config.mainPromoDomain,
  promoProduct: config.investorPromoDomain + '/product',
  promoHowItWorks: config.investorPromoDomain + '/how-it-works',
  promoAbout: config.investorPromoDomain + '/about',
  promoContacts: config.investorPromoDomain + '/contacts',
  promoHelp: config.investorPromoDomain + '/help',
  docTermsOfUse: config.mainPromoDomain + '/docs/Terms_of_use.pdf',
  docTermsAndConditions:
    config.mainPromoDomain +
    '/docs/scramble_affiliate_programme_terms_and_conditions.pdf',
  docPrivacyAndPolicy: config.mainPromoDomain + '/docs/Privacy_policy.pdf',
  docBonusProgram: config.mainPromoDomain + '/docs/scramble_5_euro_program.pdf',
  trustpilot: 'https://www.trustpilot.com/evaluate/scrambleup.com',
  phone: `tel:${CONTACT_PHONE.replace(' ', '')}`,
  email: `mailto:${CONTACT_EMAIL}`,
  startupDetail: slug => `/startups/${slug}`,
  founderDetail: id => `/founders/${id}`,
};
