import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import {
  GET_STARTUP_DETAIL,
  SET_INACTIVE_STARTUPS,
  SET_PARTICIPATING_STARTUPS,
} from '../types/startupTypes';

const setParticipatingStartups = payload => ({
  type: SET_PARTICIPATING_STARTUPS,
  payload,
});

const setInactiveStartups = payload => ({
  type: SET_INACTIVE_STARTUPS,
  payload,
});

export const getStartupDetailRequest = () => ({
  type: GET_STARTUP_DETAIL.REQUEST,
});

export const getStartupDetailSuccess = payload => ({
  type: GET_STARTUP_DETAIL.SUCCESS,
  payload,
});

export const getStartupDetailFailure = error => ({
  type: GET_STARTUP_DETAIL.FAILURE,
  error,
});

export const getParticipatingStartups = params => {
  return dispatch => {
    return axios
      .get(Pathes.Company.participatingStartups + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setParticipatingStartups(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message, success: false }));
  };
};

export const getInactiveStartups = () => {
  return (dispatch, getState) => {
    const prevData = getState().startupStore.inactiveStartups;
    const params = prevData
      ? { page: prevData.page + 1, limit: 8 }
      : { page: 1, limit: 8 };

    return axios
      .get(Pathes.Company.inactiveStartups + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          let payload;
          if (prevData) {
            payload = {
              ...data,
              ...params,
              list: [...prevData.list, ...data.list],
            };
          } else {
            payload = {
              ...data,
              ...params,
            };
          }
          dispatch(setInactiveStartups(payload));
          return { data: payload, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => ({ error: e.message, success: false }));
  };
};

export const getStartupDetail = slug => {
  return dispatch => {
    dispatch(getStartupDetailRequest());
    return axios
      .get(Pathes.Company.detailBySlug(slug))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getStartupDetailSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getStartupDetailFailure(e.message));
        return { error: e.message, success: false };
      });
  };
};
