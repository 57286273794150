import React from 'react';
import { CountrySelectField } from '../../../../kit/form/select/CountrySelect';
import { checkCountryForSepa } from '../../../../store/services/fundServices';

export const SepaZoneCountrySelect = ({ onChange, ...props }) => {
  const checkForSepaZone = async country => {
    const res = await checkCountryForSepa(country.code);
    if (res?.success) {
      onChange({
        country,
        in_sepa_zone: res.data.in_sepa_zone,
      });
    }
  };
  return (
    <CountrySelectField
      exclude={['RU', 'BY']}
      onChange={checkForSepaZone}
      {...props}
    />
  );
};
