import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { MobileTermView } from './mobile';
import { DesktopTermView } from './desktop';
import s from './index.module.scss';

export const LoanTerms = ({ onCalculate }) => {
  const { innerWidth } = useSelector(state => state.commonStore);

  return (
    <section className={s.wrapper}>
      <div className={s.inner}>
        <h2 className="f-26 f-500">Investment Loan Terms</h2>
        <p className={classnames(s.desc, 'f-16 f-400')}>
          All loan groups available at the same time, allocate your capital
          based on your risk appetite.
        </p>
        {innerWidth < 1120 ? (
          <MobileTermView onCalculate={onCalculate} />
        ) : (
          <DesktopTermView onCalculate={onCalculate} />
        )}
      </div>
    </section>
  );
};
