import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCard } from '../card';
import { getQuestionList } from '../../../store/actions/questionActions';
import s from './index.module.scss';

export const QuestionsList = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.questionStore.questions);
  const [params, setParams] = useState({
    page: 1,
    limit: 1000,
  });

  useEffect(() => {
    dispatch(getQuestionList(params));
  }, []);

  return (
    <div className={s.list}>
      {data?.list?.map(question => (
        <div key={question.id} className={s.list_item}>
          <QuestionCard question={question} />
        </div>
      ))}
    </div>
  );
};
