import React from 'react';
import UserCard from '../../components/Cards/UserCard';
import './index.scss';

const LoanersList = ({ loaners, getUser }) => {
  if (loaners && !loaners.length) {
    return null;
  }

  return (
    <div className="loaners-list__container">
      <h2 className="loaners-list__title f-16">Loaners</h2>
      <div className="loaners-list">
        {loaners.map((user) => (
          <UserCard key={user.id} user={user} onClick={() => getUser(user)} />
        ))}
      </div>
    </div>
  );
};

export default LoanersList;