const EVENTS = {
  add_funds_stuck: 'add_funds_stuck',
  veriff_not_completed: 'veriff_not_completed',
};

export const TidioChat = {
  setVisitor: user => {
    const chat = window.tidioChatApi;
    if (chat) {
      chat.setVisitorData({
        email: user.email,
        city: '',
        country: '',
        tags: [],
      });
    }
  },
  onSkipVeriff: () => {
    const chat = window.tidioChatApi;
    if (chat) {
      chat.track(EVENTS.veriff_not_completed);
      chat.open();
    }
  },
  onStuckAddFunds: () => {
    const chat = window.tidioChatApi;
    if (chat) {
      chat.track(EVENTS.add_funds_stuck);
      chat.open();
    }
  },
  open: () => {
    const chat = window.tidioChatApi;
    if (chat) {
      chat.open();
    }
  },
};
