import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../components/Dropdown';
import { StartupCard } from '../../../components/Cards/StartupCard/v3';
import { getStartupsByFundraisingID } from '../../../store/actions/loanActions';
import s from './index.module.scss';

export const Startups = ({ fundraisingID }) => {
  const dispatch = useDispatch();
  const { startupsByFundraisingID: startups } = useSelector(
    state => state.loanStore
  );

  useEffect(() => {
    dispatch(getStartupsByFundraisingID(fundraisingID));
  }, []);

  const { data } = startups;

  return (
    <Dropdown title="Consumer Goods Brands batch" className={s.wrapper}>
      <StartupsList startups={data || []} />
    </Dropdown>
  );
};

const StartupsList = ({ startups, onMoreDetails }) => (
  <div className={s.container}>
    <div className={s.list}>
      {startups.map(startup => (
        <StartupCard
          key={startup.id}
          startup={{
            ...startup,
            fundraising_goal: startup.loan_amount,
          }}
          className={s.item}
          onMoreDetails={onMoreDetails}
        />
      ))}
    </div>
  </div>
);
