import IconVisa from './icons/icon_visa_white.svg';
import IconMasterCard from './icons/icon_master_card_black.svg';
import IconApplePay from './icons/icon_apple_pay.svg';
import IconSepa from './icons/icon_sepa.png';

export const OPTIONS = [
  {
    title: 'VISA',
    icon: IconVisa,
    withBorder: true,
  },
  {
    title: 'Master Card',
    icon: IconMasterCard,
    withBorder: true,
  },
  {
    title: 'Sepa',
    icon: IconSepa,
    withBorder: true,
    style: {
      background: '#ffffff',
    },
  },
  {
    title: 'Apple Pay',
    icon: IconApplePay,
    withBorder: true,
    style: {
      background: '#ffffff',
      padding: '0',
    },
  },
  {
    title: 'Google Pay',
    icon: 'https://developers.google.com/static/pay/api/images/brand-guidelines/google-pay-mark.png?hl=ru',
    withBorder: false,
  },
];
