import React, { useState } from 'react';
import * as classnames from 'classnames';
import { ArrowUp } from '../UI/Icons';
import { MyLink } from '../MyLink';
import { prettyMoneyHybrid } from '../../helpers/utils';
import { LOAN_GROUPS } from '../../helpers/constants';
import './index.scss';

const LoanBasket = ({ data }) => {
  const [show, showMenu] = useState(false);
  if (!data) {
    return null;
  }

  const { group, earnings, amount } = data;
  const on_hold = parseFloat(amount);
  const invested = parseFloat(data.invested);
  const isActive = !!(on_hold || invested);
  const disabled = !group.is_active;
  const link = LOAN_GROUPS[group.id]?.detailLink;

  return (
    <div
      className={classnames(
        'loan-basket',
        show && 'loan-basket__active',
        !isActive && 'loan-basket__disabled'
      )}
    >
      <h3
        className="loan-basket__title f-26 f-500 hov"
        onClick={() => !disabled && showMenu(!show)}
      >
        {group.title}
        {!disabled && <ArrowUp />}
      </h3>

      {disabled ? (
        <div className="loan-basket__unavailable f-16 f-400">
          {group.title} is temporarily unavailable
        </div>
      ) : (
        <>
          <div className="loan-basket__extra">
            <div className="loan-basket__info">
              <div className="loan-basket__info-item">
                <span className="f-16">All-time invested</span>
                <span className="f-16 f-500 tl">
                  {prettyMoneyHybrid(earnings.invested)}
                </span>
              </div>

              <div className="loan-basket__info-item">
                <span className="f-16">All-time earned</span>
                <span className="f-16 f-500 tl">
                  {prettyMoneyHybrid(earnings.earned)}
                </span>
              </div>
            </div>
          </div>

          <div className="loan-basket__invested">
            <div className="loan-basket__invested-left">
              <div className="loan-basket__invested-left1">
                <span className="f-16">Invested</span>
                <span className="f-26 f-500 tl">
                  {prettyMoneyHybrid(invested)}
                </span>
              </div>
              {!!on_hold && isActive && (
                <div className="loan-basket__invested-left2">
                  <span className="f-16">Allocated</span>
                  <span className="f-26 f-500 tl">
                    {prettyMoneyHybrid(on_hold)}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {!disabled && (
        <MyLink href={link} className="loan-basket__link">
          {group.title} loan terms
        </MyLink>
      )}
    </div>
  );
};

export default LoanBasket;
