import React, { useEffect } from 'react';
import IconSepa from '../../../../assets/icons/icon_sepa.svg';
import { InputTextField } from '../../../../components/UI/InputTextField';
import { checkIBAN } from '../../../../store/services/fundServices';
import s from './index.module.scss';

export const BankAccountNumberField = ({
  countryCode,
  onValidate,
  showSepa,
  ...props
}) => {
  useEffect(() => {
    checkNumber({
      country_code: countryCode,
      iban: props.value,
    });
  }, [countryCode, props.value]);

  const checkNumber = async payload => {
    const res = await checkIBAN(payload);
    if (res && res.success) {
      onValidate(res.data);
    }
  };

  return (
    <div className={s.wrapper}>
      <InputTextField {...props} className={s.input} disabled={!countryCode} />
      {showSepa && <img src={IconSepa} alt="Sepa" className={s.icon} />}
    </div>
  );
};
