import React, {useState} from 'react';
import * as classnames from 'classnames';
import {useDispatch} from 'react-redux';
import {CONTACT_EMAIL, PROMOTION_STATUSES} from '../../helpers/constants';
import {setModals} from '../../store/actions/commonActions';
import {acceptMarketingPromo, declineMarketingPromo} from '../../store/services/userServices';
import {RoundButton} from '../../components/UI/RoundButton';
import {MODAL_TYPES} from '../../components/ModalProvider';
import Checkbox from '../../components/UI/Checkbox';
import {RoundLink} from '../../components/UI/RoundLink';
import './index.scss';

const MarketingPromotion = ({promotion}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const onAccept = () => {
    if (checked) {
      acceptMarketingPromo(promotion.id).then(res => res && res.success && setAccepted(true));
    } else {
      setError(true);
    }
  };

  const onDecline = () => {
    dispatch(setModals({
      type: MODAL_TYPES.standard_confirm,
      onAccept: () => {
        declineMarketingPromo(promotion.id)
          .then(res => {
            if (res && res.success) {
              setDeclined(true);
              dispatch(setModals([]));
            }
          })
      },
    }));
  };

  if ([PROMOTION_STATUSES.accepted, PROMOTION_STATUSES.declined].includes(promotion.status)) {
    return null;
  }

  return (
    <div className={classnames("marketing-promotion", promotion.status)}>
      <div className="marketing-promotion__content">
        {declined ? (
          <>
            <h5 className="marketing-promotion__set-company-title f-24 f-500">You have just declined an invitation to participate in the ‘Scramble Welcome’ rewards program.</h5>
            <p className="marketing-promotion__set-company-desc declined f-16 f-400">If you changed your mind, please let us know about it at <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.</p>
          </>
        ) : (
          <>
            {accepted ? (
              <>
                <h5 className="marketing-promotion__set-company-title f-24 f-500">Congratulations! You have just joined the ‘Scramble Welcome’ rewards program!</h5>
                <p className="marketing-promotion__set-company-desc f-16 f-400">We sent you an email containing information about your next steps.</p>
              </>
            ) : (
              <>
                {promotion.status === PROMOTION_STATUSES.set_company && (
                  <>
                    <h5 className="marketing-promotion__set-company-title f-24 f-500">You're invited to join the ‘Scramble Welcome’ rewards program!</h5>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">The program offers you a bonus of €500 to make your first investment easier and enjoyable. The bonus will immediately double your funds up to €500. All you need to get the bonus is:</p>
                    <ul className="marketing-promotion__set-company-list f-16 f-400">
                      <li>1. Specify the name of your company & position that belongs to a {promotion.name}.</li>
                      <li>2. Accept program Terms & Conditions.</li>
                      <li>3. Add at least €{promotion.min_amount} to your Scramble cash account.</li>
                    </ul>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">After that, Scramble will allocate the bonus equal to the size of your deposited investment but not exceeding €{promotion.max_amount} to your virtual Scramble account.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">This special offer is available only for the first 25 investors and is valid for {promotion.months_count} months from the date of accepting the Terms & Conditions of the Program.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">To participate in the ‘Scramble Welcome’ program, specify the name of your company&position that belongs to a {promotion.name}.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">If you decide not to join the program, please click the “Decline” button below.</p>
                    <div className="marketing-promotion__set-company-buttons">
                      <RoundLink
                        label="Specify my company"
                        path="/profile"
                        fillBackground
                        fullWidth
                      />
                      <RoundButton
                        label="Decline an invitation"
                        fullWidth
                        onClick={onDecline}
                      />
                    </div>
                  </>
                )}

                {promotion.status === PROMOTION_STATUSES.check_company && (
                  <>
                    <h5 className="marketing-promotion__set-company-title f-24 f-500">You're invited to join the ‘Scramble Welcome’ rewards program!</h5>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">The program offers you a bonus of €{promotion.min_amount} to make your first investment easier and enjoyable. The bonus will immediately double your funds up to €{promotion.max_amount}. All you need to get the bonus is:</p>
                    <ul className="marketing-promotion__set-company-list f-16 f-400">
                      <li>1. Wait until your company & position get checked.</li>
                      <li>2. Accept program Terms & Conditions.</li>
                      <li>3. Add at least €{promotion.min_amount} to your Scramble cash account.</li>
                    </ul>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">After that, Scramble will allocate the bonus equal to the size of your deposited investment but not exceeding €{promotion.max_amount} to your virtual Scramble account.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">This special offer is available only for the first 25 investors and is valid for {promotion.months_count} months from the date of accepting the Terms & Conditions of the Program.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">We are currently checking your company & position – it may take up to two business days.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">We will notify you by email when the results are ready.</p>
                  </>
                )}

                {promotion.status === PROMOTION_STATUSES.not_answered && (
                  <>
                    <h5 className="marketing-promotion__title f-24 f-500">You're invited to join the ‘Scramble Welcome’ rewards program!</h5>
                    <p className="marketing-promotion__desc f-16 f-400">The program offers you a bonus of €{promotion.min_amount} to make your first investment easier and enjoyable. The bonus will immediately double your funds up to €{promotion.max_amount}. All you need to get the bonus is:</p>
                    <ul className="marketing-promotion__set-company-list f-16 f-400">
                      <li>1. Accept Terms & Conditions.</li>
                      <li>3. Add at least €{promotion.min_amount} to your Scramble cash account.</li>
                    </ul>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">After that, Scramble will allocate the bonus equal to the size of your deposited investment but not exceeding €{promotion.max_amount} to your virtual Scramble account.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">This special offer is available only for the first 25 investors and is valid for {promotion.months_count} months from the date of accepting the Terms & Conditions of the Program.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">To participate in the ‘Scramble Welcome’ program, please accept the following Terms and Conditions.</p>
                    <p className="marketing-promotion__set-company-desc f-16 f-400">If you decide not to join the program, please click the “Decline” button below</p>
                    <Checkbox
                      name="termsAccept"
                      isChecked={checked}
                      onChange={() => {
                        setError(false);
                        setChecked(!checked);
                      }}
                      error={error}
                      render={() => (
                        <p className={classnames("marketing-promotion__confirm f-16 f-400", error && "error")}>
                          I confirm that I have read, understood and accept the <a href="https://scrambleup.com/docs/Terms_of_use.pdf" className="email" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> of ‘Scramble Welcome’ rewards program
                        </p>
                      )}
                    />
                    <div className="marketing-promotion__buttons">
                      <RoundButton
                        label="Accept"
                        fullWidth
                        fillBackground
                        onClick={onAccept}
                      />
                      <RoundButton
                        label="Decline"
                        fullWidth
                        onClick={onDecline}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MarketingPromotion;