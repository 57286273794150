import React from 'react';
import classnames from 'classnames';
import { TrustpilotReviewButton } from '../Trustpilot/review';
import Analytics from '../../analytics';
import s from './index.module.scss';

export const WriteReview = ({ className }) => (
  <div className={classnames(s.wrapper, className)}>
    <h2 className="f-26 f-500">Write a review about Scramble</h2>
    <div className={s.desc}>
      Help other users by sharing your experience with Scramble.
      <div>
        <TrustpilotReviewButton
          className={s.button}
          onClick={() => Analytics.investingResultGotoTrustpilot()}
        />
      </div>
    </div>
  </div>
);
