import { METADATA } from '../../helpers/metadata';
import { GET_REFERRALS_LIST } from '../types/referralTypes';

const initialState = {
  referralsList: { ...METADATA.default, data: null },
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRALS_LIST.REQUEST:
      return {
        ...state,
        referralsList: { ...state.referralsList, ...METADATA.request },
      };
    case GET_REFERRALS_LIST.SUCCESS:
      return {
        ...state,
        referralsList: { ...METADATA.success, data: action.payload },
      };
    case GET_REFERRALS_LIST.FAILURE:
      return {
        ...state,
        referralsList: {
          ...state.referralsList,
          ...METADATA.error,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default referralReducer;
