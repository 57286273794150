import Cookies from 'js-cookie';
import config from '../config';

const COOKIE_FIRST_VISIT_NAME = 'first_visit_utm_data';
const COOKIE_REGISTRATION_VISIT_NAME = 'registration_visit_utm_data';
const COOKIE_PARENT_DOMAIN =
  config.environment === 'development' ? 'localhost' : 'scrambleup.com';
const COOKIE_EXPIRE_DAYS = 10000000;
const COOKIE_SETTINGS = {
  expires: COOKIE_EXPIRE_DAYS,
  domain: COOKIE_PARENT_DOMAIN,
};

export const saveUTMtoCookie = params => {
  try {
    const current = {
      utm_medium:
        typeof params.utm_medium === 'string' ? params.utm_medium : null,
      utm_source:
        typeof params.utm_source === 'string' ? params.utm_source : null,
      utm_campaign:
        typeof params.utm_campaign === 'string' ? params.utm_campaign : null,
    };

    const payload = JSON.stringify({
      ...current,
      created_date: new Date().toISOString(),
    });

    const data = Cookies.get(COOKIE_FIRST_VISIT_NAME, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    const prevData = data && JSON.parse(data);

    if (!prevData) {
      // Cookie first time save
      Cookies.remove(COOKIE_FIRST_VISIT_NAME, { domain: COOKIE_PARENT_DOMAIN });
      Cookies.remove(COOKIE_REGISTRATION_VISIT_NAME, {
        domain: COOKIE_PARENT_DOMAIN,
      });
      Cookies.set(COOKIE_FIRST_VISIT_NAME, payload, COOKIE_SETTINGS);
      Cookies.set(COOKIE_REGISTRATION_VISIT_NAME, payload, COOKIE_SETTINGS);
    } else {
      // Update registration visit cookie
      Cookies.remove(COOKIE_REGISTRATION_VISIT_NAME, {
        domain: COOKIE_PARENT_DOMAIN,
      });
      Cookies.set(COOKIE_REGISTRATION_VISIT_NAME, payload, COOKIE_SETTINGS);
    }
  } catch (e) {
    console.error(e.message);
  }
};

export const getFirstVisitUTM = () => {
  try {
    const data = Cookies.get(COOKIE_FIRST_VISIT_NAME, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    return data && JSON.parse(data);
  } catch (e) {
    // Do nothing
  }
};

export const getRegistrationVisitUTM = () => {
  try {
    const data = Cookies.get(COOKIE_REGISTRATION_VISIT_NAME, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    return data && JSON.parse(data);
  } catch (e) {
    // Do nothing
  }
};

export const collectDetailsUTM = () => {
  const first_visit_utm_data = getFirstVisitUTM();
  const registration_visit_utm_data = getRegistrationVisitUTM();

  const firstVisitValid =
    first_visit_utm_data &&
    !Object.keys(first_visit_utm_data).some(
      key => typeof first_visit_utm_data[key] === 'object'
    );

  const lastVisitValid =
    registration_visit_utm_data &&
    !Object.keys(registration_visit_utm_data).some(
      key => typeof registration_visit_utm_data[key] === 'object'
    );

  return {
    first_visit_utm_data: (firstVisitValid && getFirstVisitUTM()) || null,
    registration_visit_utm_data:
      (lastVisitValid && getRegistrationVisitUTM()) || null,
  };
};
