import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { MyLink } from '../MyLink';
import { APP_LINKS } from '../../helpers/links';
import { getFullname, getNameInitials } from '../../helpers/utils';
import UserIcon from '../UI/Icons/UserIcon';
import Avatar from '../UI/Avatar';

import './index.scss';

const MenuUser = ({ className, showName = true }) => {
  const user = useSelector(state => state.userStore.user);
  const isParticipating = useSelector(
    state => state.investStore.isParticipating
  );

  if (!user) {
    return null;
  }

  return (
    <MyLink
      href={APP_LINKS.profile}
      className={classnames('menu_user', className)}
    >
      <div
        className={classnames('menu_user__avatar', isParticipating && 'active')}
      >
        <Avatar
          size={32}
          src={user.photo?.file}
          alt={getFullname(user.first_name, user.last_name)}
          nameInitials={getNameInitials(user.first_name, user.last_name)}
          errorRender={() => (
            <UserIcon
              className={classnames(
                'menu_user__icon',
                isParticipating && 'active'
              )}
              strokeColor={isParticipating && '#FFD200'}
            />
          )}
        />
      </div>
      {showName && (
        <div className="menu_user__name f-16 f-500 tl">
          {getFullname(user.first_name, user.last_name, 'Profile')}
        </div>
      )}
    </MyLink>
  );
};

export default MenuUser;
