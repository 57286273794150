import React, { useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getNameInitials } from '../../helpers/utils';
import { TextareaField } from '../UI/TextareaField';
import Avatar from '../UI/Avatar';
import { RoundButton } from '../UI/RoundButton';
import s from './index.module.scss';

export const WriteMessage = ({ className, onCancel, onReply }) => {
  const [text, setText] = useState('');
  const { user } = useSelector(state => state.userStore);

  if (!user) {
    return null;
  }

  return (
    <div className={classnames(s.message, className)}>
      <div className={s.message_content}>
        <Avatar
          size={50}
          src={user.photo?.file}
          alt={user.first_name}
          nameInitials={getNameInitials(user.first_name, user.last_name)}
          className="write_message_avatar"
        />
        <div className={s.message_input}>
          <TextareaField
            placeholder="Type here to reply"
            value={text}
            onChange={e => setText(e.target.value)}
            className={classnames(s.message_textarea, 'write_message_input')}
          />
        </div>
      </div>

      <div className={classnames(s.message_controls, 'write_message_controls')}>
        <RoundButton label={() => 'Cancel'} onClick={onCancel} />
        <RoundButton
          label={() => 'Reply'}
          fillBackground
          onClick={() => onReply(text)}
        />
      </div>
    </div>
  );
};
