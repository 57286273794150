import React from 'react';
import ReactModal from 'react-modal';
import FounderPopup from '../../containers/FounderPopup';
import { modalCustomStyles } from '../../assets/styles';
import EarningGraphic from '../../components/EarningGraphic';

const DevPage = () => {
  const [show, setShow] = React.useState(false);

  return (
    <div style={{ marginTop: '150px' }}>
      <div className="container">
        <EarningGraphic />
      </div>

      <button onClick={() => null} style={{ border: '3px solid black' }}>
        Reset local allocation
      </button>
      <ReactModal
        isOpen={show}
        onAfterOpen={() => null}
        onRequestClose={() => setShow(false)}
        style={modalCustomStyles}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        contentLabel="Example Modal"
      >
        <FounderPopup onClose={() => setShow(false)} />
      </ReactModal>
    </div>
  );
};
export default DevPage;
