import React from 'react';
import classnames from 'classnames';
import Analytics from '../../../analytics';
import { RoundLink } from '../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import s from '../index.module.scss';
import { STEPS } from '../index';

export const AddCashStep = ({ passed }) => {
  const active = passed.includes(STEPS.add_cash);

  return (
    <li
      id={`vpw_${STEPS.add_cash}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400'
          )}
        >
          04
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_title, 'f-26 f-400')}>
          Add cash
        </div>
        <div
          className={classnames(
            s.list_item_content,
            active &&
              !passed.includes(STEPS.start_investing) &&
              s.list_item_content_active,
            'f-16-24 f-400'
          )}
        >
          <div className={s.list_item_content_inner}>
            <div className="f-16 f-400">
              You can start investing as little as 10 euros. Add cash via bank
              transfer, or use other convenient alternatives. The processing
              time of the cash transfer will depend on the transfer method you
              choose. Usually, it takes 1-3 business days. <span> </span>
            </div>
            <div className="f-16 f-400" style={{ marginTop: '8px' }}>
              We will notify you by email when your cash is added to your
              Scramble account.
            </div>
            <RoundLink
              label="Add cash"
              fillBackground
              fullWidth
              onClick={() => Analytics.verificationWizardAddCash()}
              path={APP_LINKS.addFunds}
              className={s.button}
            />
          </div>
        </div>
      </div>
    </li>
  );
};
