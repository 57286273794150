import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { ReactComponent as IconStar } from '../../assets/icons/icon_bonus_start.svg';
import { setModals } from '../../store/actions/commonActions';
import { MODAL_TYPES } from '../ModalProvider';
import { prettyMoney } from '../../helpers/utils';
import s from './index.module.scss';

export const BonusAmount = ({ amount, mini, className }) => {
  const [bonus, setBonus] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    calculateBonus(Number(amount));
  }, [amount]);

  const calculateBonus = value => {
    const bonus = parseInt(value / 100) * 5;
    setBonus(bonus);
  };

  const onClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.bonus_banner,
      })
    );
  };

  return (
    <div
      className={classnames(
        mini ? s.bonus_amount_mini : s.bonus_amount,
        className
      )}
      onClick={onClick}
    >
      <span>+</span>
      <IconStar />
      <span>{`${prettyMoney(bonus)}${!mini ? ' bonus' : ''}`}</span>
    </div>
  );
};
