import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage } from '../../helpers/utils';
import {
  GET_USER_PROFILE,
  SET_PROFILE_MISSING_DETAILS,
  SET_USER_PROFILE, SET_VERIFF_STATUS_CODE,
  UPDATE_USER_PROFILE
} from '../types/profileTypes';
import Notify from '../../components/Notification';
import {updateUser} from './userActions';

const getUserProfileRequest = () => ({ type: GET_USER_PROFILE.REQUEST });
const getUserProfileSuccess = payload => ({ type: GET_USER_PROFILE.SUCCESS, payload });
const getUserProfileFailure = error => ({ type: GET_USER_PROFILE.FAILURE, error });

const updateUserProfileRequest = () => ({ type: UPDATE_USER_PROFILE.REQUEST });
const updateUserProfileSuccess = payload => ({ type: UPDATE_USER_PROFILE.SUCCESS, payload });
const updateUserProfileFailure = error => ({ type: UPDATE_USER_PROFILE.FAILURE, error });

const setProfileMissingDetails = payload => ({ type: SET_PROFILE_MISSING_DETAILS, payload });

export const getUserProfile = () => {
  return dispatch => {
    dispatch(getUserProfileRequest());
    return axios.get(Pathes.User.profile).then(
      response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(updateUser(data));
          dispatch(getUserProfileSuccess(data));
          return {data, success: true};
        }
        throw new Error(message);
      }).catch(e => dispatch(getUserProfileFailure(e.message)));
  };
};

export const setUserProfile = profile => {
  return dispatch => {
    dispatch({type: SET_USER_PROFILE, profile});
  };
};

export const updateUserProfile = (data) => {
  return dispatch => {
    dispatch(updateUserProfileRequest());
    return axios.put(Pathes.User.profile, data).then(
      response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(updateUserProfileSuccess(data));
          Notify.success({ text: 'Profile successfully updated' });
          return { ...data, success: true };
        }
        throw new Error(message);
      }).catch(e => dispatch(updateUserProfileFailure(e.message)));
  };
};

export const getMissingProfileDetails = () => {
  return (dispatch, getState) => {
    return axios.get(Pathes.User.missingInfo).then(
      response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const userStatus = getState().userStore.user && getState().userStore.user.status;
          data.status !== userStatus && dispatch(updateUser({ status: data.status }));
          dispatch(setProfileMissingDetails(data));
          return { data, success: true };
        }
        throw new Error(message);
      }).catch(() => {
      setProfileMissingDetails({});
      return { success: false };
    });
  };
};

// Veriff status
export const getVeriffStatus = () => {
  return (dispatch, getState) => {
    const code = getState().profileStore.veriffStatusCode;
    return axios.get(Pathes.User.veriffStatus).then(
      response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          code !== data.code && dispatch({type: SET_VERIFF_STATUS_CODE, code: data.code});
          return {data, success: true};
        }
        throw new Error(message);
      }).catch(() => {
      return {success: false };
    });
  };
};