import axios from '../../axios-api';
import {getMessage, getQuery} from '../../helpers/utils';
import {SET_INACTIVE_INVESTORS, SET_PARTICIPATING_INVESTORS} from '../types/investorTypes';
import Pathes from '../../helpers/pathes';

const setParticipatingInvestors = payload => ({type: SET_PARTICIPATING_INVESTORS, payload});
const setInactiveInvestors = payload => ({type: SET_INACTIVE_INVESTORS, payload});

export const getParticipatingInvestors = params => {
  return dispatch => {
    return axios.get(Pathes.Investors.participatingInvestors + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setParticipatingInvestors(data));
          return {data, success: true};
        }

        throw new Error(message)
      }).catch(e => ({error: e.message, success: false}));
  };
}

export const getInactiveInvestors = params => {
  return dispatch => {
    return axios.get(Pathes.Investors.inactiveInvestors + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setInactiveInvestors(data));
          return {data, success: true};
        }

        throw new Error(message)
      }).catch(e => ({error: e.message, success: false}));
  };
}