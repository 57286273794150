import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { APP_LINKS } from '../../helpers/links';
import { AppleButton, GoogleButton } from './buttons';
import { appleLogin, googleLogin } from '../../store/actions/userActions';
import './index.scss';

const SocialNetworks = ({ className, nextStep, inviteCode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSignUp = location.pathname === APP_LINKS.signUp;

  // const onFacebookResponse = response => {
  //   dispatch(facebookLogin(response, nextStep, inviteCode));
  // };

  const onGoogleResponse = response => {
    dispatch(googleLogin(response, nextStep, inviteCode));
  };

  const onAppleResponse = response => {
    if (response?.authorization) {
      dispatch(appleLogin(response, nextStep, inviteCode));
    }
  };

  return (
    <div className={classnames('social-networks', className)}>
      <div className="social-networks__inner">
        <GoogleButton
          label={isSignUp ? 'Sign up with Google' : 'Sign in with Google'}
          onGoogleResponseSuccess={onGoogleResponse}
          onGoogleResponseFailure={() => null}
        />
        <AppleButton
          label={isSignUp ? 'Sign up with Apple' : 'Sign in with Apple'}
          onResponse={onAppleResponse}
          redirectURL={`${window.location.origin}${
            isSignUp ? APP_LINKS.signUp : APP_LINKS.signIn
          }`}
        />
      </div>
    </div>
  );
};

export default SocialNetworks;
