import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { InputTextField } from '../../UI/InputTextField';
import CountrySelectField from '../../UI/CountrySelectField';
import { RoundButton } from '../../UI/RoundButton';
import { RoundLink } from '../../UI/RoundLink';
import SelectField from '../../UI/SelectField';
import {
  FUNDS_ORIGIN,
  PLANNED_MONTHLY_INVESTMENT,
} from '../../../helpers/constants';
import PhoneNumberField from '../../UI/PhoneNumberField';
import { TidioChat } from '../../../chat/tidio';
import { APP_LINKS } from '../../../helpers/links';
import { setUserVeriffSkipReason } from '../../../store/services/userServices';
import { SKIP_REASON_TYPES } from '../../VerifyForm';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object({
  phone: Yup.number().required('Phone number is required'),
  citizenship: Yup.string('Citizenship is required').required(
    'Citizenship is required'
  ),
  origin_of_funds: Yup.string('Origin of funds is required')
    .nullable()
    .required('Origin of funds is required'),
  planned_monthly_investment: Yup.string(
    'Planned monthly investment is required'
  )
    .nullable()
    .required('Planned monthly investment is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  address: Yup.string()
    .max(255, 'Address exceeds limit of 255')
    .required('Address is required'),
  postal_code: Yup.string()
    .min(4, 'Invalid postal code')
    .max(10, 'Invalid postal code'),
  is_valid_phone: Yup.bool().oneOf([true], 'Invalid phone number').required(),
});

class PersonalInfoForm extends React.Component {
  getInitialValues = (user, location) => ({
    phone: (user && user.phone) || '',
    is_valid_phone: true,
    citizenship: (user && user.citizenship) || '',
    citizenship_code: (user && user.citizenship_code) || '',
    origin_of_funds: null,
    planned_monthly_investment: null,
    editorRef: null,
    country: (user && user.country) || (location && location.country) || '',
    country_code:
      (user && user.country_code) || (location && location.countryCode) || '',
    city: (user && user.city) || (location && location.city) || '',
    address: (user && user.address) || '',
    postal_code: (user && user.postal_code) || '',
  });

  render() {
    const { userLocation, user, onSubmit, defaultSubmit, onCancel } =
      this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues(user, userLocation)}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className="contact-info-form" onSubmit={handleSubmit}>
            <h1 className="contact-info-form__title f-500">
              Personal information
            </h1>
            <p className="contact-info-form__subTitle f-400">
              This information will remain strictly confidential. It is required
              for further investment operations.
            </p>
            <CountrySelectField
              label="Citizenship *"
              name="citizenship"
              value={values.citizenship_code}
              onChange={(country, code) => {
                setFieldValue('citizenship', country);
                setFieldValue('citizenship_code', code);
              }}
              error={
                errors.citizenship && touched.citizenship && errors.citizenship
              }
              className="contact-info-form__row"
            />
            <PhoneNumberField
              label="Phone number *"
              name="phone"
              value={values.phone}
              countryCode={values.country_code}
              onChange={phone => setFieldValue('phone', phone)}
              onClear={() => setFieldValue('phone', '')}
              error={
                (errors.phone || errors.is_valid_phone) &&
                (touched.phone || touched.is_valid_phone) &&
                (errors.phone || errors.is_valid_phone)
              }
              containerID="phone-input"
              isValid={values.is_valid_phone}
              formikBag={{ setFieldValue, touched }}
              className="contact-info-form__row"
            />
            <CountrySelectField
              label="Residence country *"
              name="country"
              value={values.country_code}
              onChange={(country, code) => {
                setFieldValue('country', country);
                setFieldValue('country_code', code);
              }}
              error={errors.country && touched.country && errors.country}
              className="contact-info-form__row"
            />
            <InputTextField
              label="Residence city *"
              name="city"
              value={values.city}
              onChange={handleChange}
              onClear={() => setFieldValue('city', '')}
              error={errors.city && touched.city && errors.city}
              className="contact-info-form__row"
            />
            <InputTextField
              label="Residence address *"
              name="address"
              value={values.address}
              onChange={handleChange}
              onClear={() => setFieldValue('address', '')}
              error={errors.address && touched.address && errors.address}
              className="contact-info-form__row"
            />
            <InputTextField
              label="Residence postal code"
              name="postal_code"
              value={values.postal_code}
              onChange={handleChange}
              onClear={() => setFieldValue('postal_code', '')}
              error={
                errors.postal_code && touched.postal_code && errors.postal_code
              }
              className="contact-info-form__row"
            />
            <SelectField
              label="Origin of funds *"
              name="origin_of_funds"
              value={values.origin_of_funds || ''}
              onChange={handleChange}
              error={
                errors.origin_of_funds &&
                touched.origin_of_funds &&
                errors.origin_of_funds
              }
              className="contact-info-form__row"
              options={Object.keys(FUNDS_ORIGIN).map(key => ({
                label: FUNDS_ORIGIN[key],
                value: key,
              }))}
            />
            <SelectField
              label="Planned monthly investment *"
              name="planned_monthly_investment"
              value={values.planned_monthly_investment || ''}
              onChange={handleChange}
              error={
                errors.planned_monthly_investment &&
                touched.planned_monthly_investment &&
                errors.planned_monthly_investment
              }
              className="contact-info-form__row"
              options={Object.keys(PLANNED_MONTHLY_INVESTMENT).map(key => ({
                label: PLANNED_MONTHLY_INVESTMENT[key],
                value: key,
              }))}
            />
            <RoundButton
              label={defaultSubmit ? 'Save' : 'Continue'}
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              withArrow={!defaultSubmit}
              className="contact-info-form__button"
            />
            <RoundLink
              label="Continue Later"
              path={APP_LINKS.dashboard}
              className="contact-info-form__link"
              onClick={() => {
                TidioChat.onSkipVeriff();
                setUserVeriffSkipReason(SKIP_REASON_TYPES.skipped_later);
              }}
            />
            {onCancel && (
              <RoundButton
                label="Cancel"
                type="button"
                onClick={onCancel}
                className="contact-info-form__button"
              />
            )}
          </form>
        )}
      </Formik>
    );
  }
}

export default PersonalInfoForm;
