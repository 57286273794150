import React from 'react';
import InvestorsActive from './active';
import InvestorsAll from './all';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import './index.scss';

const InvestorsPage = () => (
  <div className="founders-page">
    <div className="container-inner">
      <h1 className="founders-page__title f-42 f-500">Investors</h1>
      <InvestorsActive />
      <InvestorsAll />

      <MiniFaqs source={MINIFAQ_SOURCES.investors} />
    </div>
  </div>
);

export default InvestorsPage;
