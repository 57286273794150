import React from 'react';
import { Tooltip } from '../Tooltip/bottom';
import { MyLink } from '../MyLink';
import { APP_LINKS } from '../../helpers/links';
import Analytics from '../../analytics';
import { LOAN_GROUPS } from '../../helpers/constants';

export const TERM_TOOLTIPS = {
  earnings: ({ className }) => (
    <Tooltip
      onClick={() => Analytics.investingJoinTooltipEarnings()}
      className={className}
    >
      <div className="t_earning">
        This is your 6-month loan income. It is calculated based on the amount
        of your loan investment.
      </div>
    </Tooltip>
  ),
  net_annual_return: ({ className }) => (
    <Tooltip
      onClick={() => Analytics.investingJoinTooltipNat()}
      className={className}
    >
      <div className="t_return">
        Net annualised return (NAR) measures the actual rate of all your
        investments return since you started investing on Scramble. It includes
        anything that affects your returns, such as delays and bonuses.
      </div>
    </Tooltip>
  ),
  term: ({ className }) => (
    <Tooltip
      onClick={() => Analytics.investingJoinTooltipTerm()}
      className={className}
    >
      <div className="t_term">
        The amount of time investors give startups to repay a loan. Please note
        that loan terms can be extended up to 24 months. Learn more about{' '}
        <MyLink href={APP_LINKS.helpLoanTerms} underline>
          Scramble loan terms
        </MyLink>
        .
      </div>
    </Tooltip>
  ),
  repayments: ({ className }) => (
    <Tooltip
      onClick={() => Analytics.investingJoinTooltipRepayment()}
      className={className}
    >
      <div className="t_repayments">
        Type of repayments:
        <br />
        <span style={{ fontWeight: '500' }}>Monthly</span> – you will receive
        your earnings and the first repayments right after loan disbursement
        followed up by regular monthly repayments.
        <br />
        <span style={{ fontWeight: '500' }}>Last month</span> – you will receive
        your earnings and all repayments at the end of the loan term.
      </div>
    </Tooltip>
  ),
  skin: ({ className }) => (
    <Tooltip
      onClick={() => Analytics.investingJoinTooltipSkin()}
      className={className}
    >
      <div className="t_skin">
        We invest 20% or more of our own capital in a brands batch every round
        because we believe in the amazing potential of startups we work with.
      </div>
    </Tooltip>
  ),
  protection: ({ group, className }) => (
    <Tooltip className={className}>
      <div className="t_protection">
        {group === LOAN_GROUPS.conservative.id && (
          <ul className="scr-circle-list">
            <li>Fee payment upfront, regular monthly repayments</li>
            <li>Co-founder team guarantees</li>
            <li>First loss capital</li>
          </ul>
        )}

        {group === LOAN_GROUPS.moderate.id && (
          <ul className="scr-circle-list">
            <li>Co-founder team guarantees</li>
          </ul>
        )}
        <MyLink href={APP_LINKS.promoProduct} isExternal underline>
          More details
        </MyLink>
      </div>
    </Tooltip>
  ),
};
