import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import config from '../../config';
import { APP_LINKS } from '../../helpers/links';
import {
  generateStripeSessionID,
  getStripePaymentMethods,
} from '../../store/services/stripeServices';
import PaymentForm from '../../components/Forms/PaymentForm';
import { PaymentSuccess } from './success';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripeApiKey);
  }
  return stripePromise;
};

const DEFAULT_AMOUNT = 100;

const PaymentModule = ({ onBack, redirectLocation, children, className }) => {
  const location = useLocation();
  const { status, amount, method } = qs.parse(location.search.replace('?', ''));
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = async () => {
    const res = await getStripePaymentMethods();
    if (res && res.success) {
      setPaymentMethods(res.data);
    }
  };

  const onSubmit = async values => {
    setLoading(true);
    const res = await generateStripeSessionID({
      amount: values.amount,
      payment_method: values.method,
      success_url: `${window.location.origin}${
        redirectLocation || APP_LINKS.addFunds
      }?session_id={CHECKOUT_SESSION_ID}&status=success`,
      cancel_url: `${window.location.origin}${
        redirectLocation || APP_LINKS.addFunds
      }?session_id={CHECKOUT_SESSION_ID}&status=cancel&amount=${
        values.amount
      }&method=${values.method}`,
    });
    if (res && res.success) {
      setLoading(true);
      const stripe = await getStripe();
      const { error } = await stripe.redirectToCheckout({
        sessionId: res.data.session_id,
      });
      if (error) {
        console.error(error.message);
      }
    }
    setLoading(false);
  };

  if (status === 'success' && location.pathname === APP_LINKS.addFunds) {
    return <PaymentSuccess amount={amount} />;
  }

  return (
    <PaymentForm
      onSubmit={onSubmit}
      loading={loading}
      paymentMethods={paymentMethods}
      className={className}
      onBack={onBack}
      initialValues={{
        amount: `${Number(amount) || DEFAULT_AMOUNT}`,
        method: method || 'bank',
        commission: 0,
        hasConfirmed: false,
      }}
    >
      {children}
    </PaymentForm>
  );
};

export default PaymentModule;
