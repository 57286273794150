import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import classnames from 'classnames';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { CONTACT_EMAIL } from '../../../helpers/constants';
import { RoundButton } from '../../UI/RoundButton';
import { APP_LINKS } from '../../../helpers/links';
import { CountdownHour } from '../../CountdownHour';
import { MyLink } from '../../MyLink';
import {
  getPhoneSentCodes,
  sendVerificationCode,
} from '../../../store/services/userServices';
import { VerificationCodeInput } from '../../UI/VerificationCodeInput';
import s from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object({
  code: Yup.array(),
});

const SMS_LIMIT = 3;

const PhoneVerificationForm = ({
  onSubmit,
  phone = '',
  onLater,
  onBack,
  defaultSubmit,
  className,
}) => {
  const location = useLocation();
  const [expireTime, setExpireTime] = useState(null);
  const [status, setStatus] = useState({
    count: 0,
    last_created_at: null,
  });

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    if (status.last_created_at) {
      const currentTime = moment();
      const expireTime = moment(status.last_created_at).add(
        status.count >= SMS_LIMIT ? 10 : 1,
        'minutes'
      );

      currentTime.isBefore(expireTime)
        ? setExpireTime(expireTime.toISOString())
        : setExpireTime(null);
    } else {
      setExpireTime(null);
    }
  }, [status]);

  const getStatus = async () => {
    const res = await getPhoneSentCodes();
    res && res.success && setStatus(res.data);
  };

  const resendCode = async () => {
    const res = await sendVerificationCode(phone);
    if (res && res.success) {
      setStatus(res.data.data);
    }
  };

  const onCounterFinish = () => {
    setExpireTime(null);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        code: [],
      }}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <form
          className={classnames(s.wrapper, className)}
          onSubmit={handleSubmit}
        >
          <h1 className={classnames(s.title, 'f-500')}>
            Verify your phone number
          </h1>
          <div className={classnames(s.desc, 'f-18-24 f-400')}>
            To continue, please enter the 6-digit verification code sent to your
            phone{' '}
            <span className="f-500">
              ending in {phone.substring(phone.length - 4)}
            </span>
            .
          </div>

          <div className={classnames(s.desc, 'f-18-24 f-400')}>
            Didn&apos;t receive a code?{' '}
            {expireTime ? (
              <CountdownHour
                endTime={expireTime}
                onFinish={onCounterFinish}
                label={time => `Resend code in ${time}`}
                className={s.countdown}
              />
            ) : (
              <button
                type="button"
                onClick={() => {
                  setFieldValue('code', []);
                  resendCode();
                }}
                className={classnames(s.button_resend, 'underline')}
              >
                Resend
              </button>
            )}
          </div>

          {status.count >= SMS_LIMIT && (
            <div className="f-18-24 f-400" style={{ marginTop: '8px' }}>
              If you have any technical problems, please contact our support
              team at{' '}
              <MyLink href={APP_LINKS.email} isExternal underline>
                {CONTACT_EMAIL}
              </MyLink>
              .
            </div>
          )}

          <VerificationCodeInput
            values={values.code}
            className={s.input}
            error={errors.code && touched.code}
            onChange={value => setFieldValue('code', value.split(''))}
            onComplete={() => {
              handleSubmit();
            }}
          />

          {errors.code && touched.code && (
            <div className={classnames(s.error_message, 'f-16-24 f-400')}>
              {errors.code}
            </div>
          )}

          <div
            className={classnames(
              s.buttons,
              location.pathname === APP_LINKS.dashboard && s.buttons_row
            )}
          >
            {onBack && (
              <RoundButton
                label="Back"
                type="button"
                fullWidth
                onClick={onBack}
                className={s.button_back}
              />
            )}

            <RoundButton
              label={defaultSubmit ? 'Verify' : 'Continue'}
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              withArrow={!defaultSubmit}
              className={s.button_submit}
            />

            {onLater && (
              <button
                type="button"
                onClick={onLater}
                className={classnames(
                  s.button_later,
                  'f-16-24 f-400',
                  'underline'
                )}
              >
                Verify later
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default PhoneVerificationForm;
