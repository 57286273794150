import { PASSWORD_SPECIAL_CHARS, MIN_PASSWORD_LENGTH } from './constants';

const charSet = new Set(PASSWORD_SPECIAL_CHARS);

export function hasSpecialChar(value) {
  for (const char of value) {
    if (charSet.has(char)) {
      return true;
    }
  }
  return false;
}

export function hasLowercaseChar(value) {
  return /[a-z]/.test(value);
}

export function hasUppercaseChar(value) {
  return /[A-Z]/.test(value);
}

export function hasNumber(value) {
  return /\d/.test(value);
}

export function hasMinLength(value) {
  return value.length >= MIN_PASSWORD_LENGTH;
}

export function getBadChars(value) {
  const badChars = new Set();
  for (const char of value) {
    if (charSet.has(char)) {
      continue;
    }
    if (/[a-zA-Z\d]/.test(char)) {
      continue;
    }
    badChars.add(char);
  }
  return Array.from(badChars);
}

export function hasNoBadChars(value) {
  return getBadChars(value).length === 0;
}

export function isStrongPassword(value) {
  return (
    // hasSpecialChar(value) &&
    hasLowercaseChar(value) &&
    hasUppercaseChar(value) &&
    hasNumber(value) &&
    hasMinLength(value) &&
    hasNoBadChars(value)
  );
}
