import React from 'react';
import { useSelector } from 'react-redux';
import { MyLink } from '../../MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { CONTACT_EMAIL, CONTACT_PHONE } from '../../../helpers/constants';
import { PaymentMethodLogos } from '../../PaymentMethodLogos';
import { TrustpilotStars } from '../../Trustpilot/stars';
import { WhatsAppChatLink } from '../../WhatsAppChatLink';
import { SocialButtonsGrey } from '../../SocialButtonsGrey';
import './index.scss';

const AuthorizedFooter = () => {
  const { user } = useSelector(state => state.userStore);

  return (
    <footer className="auth-footer">
      <div className="container-inner">
        <nav className="auth-footer__top">
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">Company</h4>
            <ul>
              <li>
                <MyLink href={APP_LINKS.home} isExternal newWindow={false}>
                  Home
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.investing}>Investment round</MyLink>
              </li>
              <li>
                <MyLink
                  href={APP_LINKS.promoRoundsHistory}
                  isExternal
                  newWindow={false}
                >
                  Rounds history
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.blog} isExternal newWindow={false}>
                  Blog
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.affiliate}>Affiliate Programme</MyLink>
              </li>
            </ul>
          </div>
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">Legal</h4>
            <ul>
              <li>
                <MyLink href={APP_LINKS.privacyPolicy} isExternal>
                  Privacy Policy
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.termOfUse} isExternal>
                  Terms of Use
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={APP_LINKS.unsubordinatedFinancialAgreement}
                  isExternal
                >
                  Financing Agreement
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={APP_LINKS.subordinatedFinancialAgreement}
                  isExternal
                >
                  Subordinated Financing <br /> Agreement
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.subordinatedGuaranteeLetter} isExternal>
                  Subordinated Guarantee <br /> Letter
                </MyLink>
              </li>
            </ul>
          </div>
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">Help</h4>
            <ul>
              <li>
                <MyLink href={APP_LINKS.help}>Help</MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.helpLoanTerms}>Loan terms</MyLink>
              </li>
              {user?.unique_id && (
                <li>
                  <MyLink href={APP_LINKS.helpLoanTerms}>
                    Private investors
                  </MyLink>
                </li>
              )}
            </ul>
          </div>
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">Support</h4>
            <div className="auth-footer__contact">Contact us</div>
            <WhatsAppChatLink className="auth-footer__whatsapp" />
            <div>
              <MyLink
                className="auth-footer__email"
                href={`mailto:${CONTACT_EMAIL}`}
                isExternal
              >
                {CONTACT_EMAIL}
              </MyLink>
            </div>
            <div>
              <MyLink
                className="auth-footer__phone"
                href={APP_LINKS.phone}
                isExternal
              >
                {CONTACT_PHONE}
              </MyLink>
            </div>
            <SocialButtonsGrey className="auth-footer__social" />
          </div>
        </nav>

        <div className="auth-footer__middle">
          <PaymentMethodLogos className="auth-footer__payments" />
          <TrustpilotStars className="auth-footer__trustpilot" />
        </div>

        <div className="auth-footer__copyright">
          <div className="f-12 f-400">
            © {new Date().getFullYear()}, Scramble OÜ. All rights reserved.
          </div>
          <div className="f-12 f-400">
            Scramble Platform (“Scramble OÜ”) is a private limited company
            registered in the Commercial Register of Estonia under registration
            No. 14991448, with legal address at Pärnu mnt 22 Kesklinna linnaosa,
            Harju maakond 10141, Tallinn, Estonia.
            <br />
            Scramble is a global online lending platform currently in the
            process of obtaining a crowdfunding license covering the EU.
            Investment through Scramble involves lending to businesses,
            consequently, your capital may be at risk. There is no guarantee to
            get back the invested amount. We advise carefully evaluating the
            risks and diversifying investments.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AuthorizedFooter;
