import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoanGroupCard from '../../../components/Cards/LoanGroupCard/v2';
import { getLoanTerms } from '../../../store/actions/loanActions';
import Preloader from '../../../components/Preloader';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

const LOAN_GROUPS = [
  {
    id: 'conservative',
    targetAnnualReturn: '12%',
    loanDescription: 'Repaid first',
    repaymentPeriod: '6-24 months',
    flatFee: '5%',
    extraFlatFee: '+ up to 4.4%',
    detailsLink: APP_LINKS.helpLoanTermsGroupA,
  },
  {
    id: 'moderate',
    targetAnnualReturn: 'up to 30%',
    loanDescription: 'Repaid after A',
    repaymentPeriod: '6-24 months',
    flatFee: '9%',
    extraFlatFee: '+ up to 49%',
    detailsLink: APP_LINKS.helpLoanTermsGroupB,
  },
  {
    id: 'growth',
    targetAnnualReturn: '-',
    loanDescription: 'Repaid after A and B',
    repaymentPeriod: '-',
    flatFee: '-',
    extraFlatFee: '-',
    detailsLink: APP_LINKS.helpLoanTermsGroupC,
  },
];

const LoanTermsPage = ({ getLoanTerms, loanTerms }) => {
  useEffect(() => {
    getLoanTerms();
  }, []);

  const { data, loading } = loanTerms;

  return (
    <div className="loan-terms-page">
      <div className="container-inner">
        <h1 className="f-42 f-500">Loan Terms</h1>
        <p className="loan-terms-page__desc f-20 f-500">
          All loan groups available at the same time, allocate your capital
          based on your risk appetite.
        </p>
        {!data && loading && (
          <Preloader className="loan-terms-page__preloader" />
        )}
        {data && (
          <div className="loan-terms-page__cards">
            {LOAN_GROUPS.map(group => (
              <LoanGroupCard
                key={group.id}
                loanTerms={data}
                defaultValues={group}
                className="loan-terms-page__card"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loanTerms: state.loanStore.loanTerms,
});

const mapDispatchToProps = dispatch => ({
  getLoanTerms: () => dispatch(getLoanTerms()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanTermsPage);
