import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipatingStartups } from '../../store/actions/startupActions';
import { StartupCard } from '../../components/Cards/StartupCard/v3';
import { APP_LINKS } from '../../helpers/links';
import s from './index.module.scss';

export const StartupsActive = () => {
  const dispatch = useDispatch();
  const { participatingStartups: startups } = useSelector(
    state => state.startupStore
  );

  useEffect(() => {
    dispatch(getParticipatingStartups({ page: 1, limit: 100 }));
  }, []);

  const hasStartups = !!startups?.length;

  if (!hasStartups) {
    return null;
  }

  return (
    <div className={s.startups_active}>
      <h2 className="f-26 f-500">Current round batch</h2>
      {hasStartups ? (
        <div className={s.startups_active__list}>
          {startups.map(startup => (
            <StartupCard
              key={startup.id}
              startup={startup}
              href={APP_LINKS.startupDetail(startup.slug)}
              className={s.startups_active__card}
            />
          ))}
        </div>
      ) : (
        <div className={s.startups_active__empty}>
          <span
            className={classnames(s.startups_active__empty_title, 'f-16 f-400')}
          >
            No active startups yet.
          </span>
        </div>
      )}
    </div>
  );
};
