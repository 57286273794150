import React from 'react';
import {METADATA} from '../../helpers/metadata';
import {GET_COMPANY_INFO} from '../types/companyTypes';

const initialState = {
  companyInfo: { ...METADATA.default, data: null },
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_INFO.REQUEST:
      return { ...state, companyInfo: { ...state.companyInfo, ...METADATA.request }};
    case GET_COMPANY_INFO.SUCCESS:
      return { ...state, companyInfo: { ...METADATA.success, data: action.payload }};
    case GET_COMPANY_INFO.FAILURE:
      return { ...state, companyInfo: { ...state.companyInfo, ...METADATA.error, error: action.error }};

    default:
      return state;
  }
};

export default companyReducer;