import { APP_LINKS } from '../../../helpers/links';

export const LOAN_GROUPS_V1 = [
  {
    id: 'conservative',
    rate: '12%',
    title: 'Group A',
    loanDescription: 'Repaid first',
    repaymentPeriod: '18 months',
    downsideProtection: 'Triple-secured',
    link: APP_LINKS.helpLoanTermsGroupAV1,
    isActive: true,
  },
  {
    id: 'moderate',
    rate: '18+%',
    title: 'Group B',
    loanDescription: 'Repaid after A',
    repaymentPeriod: '24 months',
    downsideProtection: 'Double-secured',
    link: APP_LINKS.helpLoanTermsGroupBV1,
    isActive: true,
  },
  {
    id: 'growth',
    rate: '-',
    title: 'Group C',
    loanDescription: 'Repaid after A and B',
    repaymentPeriod: '-',
    downsideProtection: '-',
    link: APP_LINKS.helpLoanTermsGroupCV1,
    isActive: false,
  },
];
