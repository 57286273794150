import { METADATA } from '../../helpers/metadata';
import {
  CREATE_WITHDRAW_REQUEST,
  GET_ALLOCATE_INFO,
  GET_WITHDRAW_HISTORY,
  GET_WITHDRAW_INFO,
  SET_BANK_DETAILS_LIST,
  SET_LOCAL_ACCOUNTS,
  SET_PENDING_TRANSACTIONS,
} from '../types/fundTypes';
import { LOGOUT_USER } from '../actions/actionTypes';
import { RESET_INVESTING } from '../types/investTypes';

export const initialState = {
  allocateInfo: { ...METADATA.default, data: null },
  withdrawInfo: { ...METADATA.default, data: null },
  withdrawHistory: { ...METADATA.default, data: null },
  localAccounts: [],
  pendingTransactions: null,
  bankDetailsList: [],
};

const fundReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOCATE_INFO.REQUEST:
      return {
        ...state,
        allocateInfo: { ...state.allocateInfo, ...METADATA.request },
      };
    case GET_ALLOCATE_INFO.SUCCESS:
      return {
        ...state,
        allocateInfo: { ...METADATA.success, data: action.payload },
      };
    case GET_ALLOCATE_INFO.FAILURE:
      return {
        ...state,
        allocateInfo: {
          ...state.allocateInfo,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_WITHDRAW_INFO.REQUEST:
      return {
        ...state,
        withdrawInfo: { ...state.withdrawInfo, ...METADATA.request },
      };
    case GET_WITHDRAW_INFO.SUCCESS:
      return {
        ...state,
        withdrawInfo: { ...METADATA.success, data: action.payload },
      };
    case GET_WITHDRAW_INFO.FAILURE:
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          ...METADATA.error,
          error: action.error,
        },
      };
    case CREATE_WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawInfo: { ...state.withdrawInfo, data: action.data },
      };
    case SET_LOCAL_ACCOUNTS:
      return { ...state, localAccounts: action.payload || [] };
    case RESET_INVESTING:
    case LOGOUT_USER:
      return {
        ...state,
        allocateInfo: { ...METADATA.default, data: null },
        localAccounts: [],
      };
    case GET_WITHDRAW_HISTORY.REQUEST:
      return {
        ...state,
        withdrawHistory: { ...state.withdrawHistory, ...METADATA.request },
      };
    case GET_WITHDRAW_HISTORY.SUCCESS:
      return {
        ...state,
        withdrawHistory: { ...METADATA.success, data: action.payload },
      };
    case GET_WITHDRAW_HISTORY.FAILURE:
      return {
        ...state,
        withdrawHistory: {
          ...state.withdrawHistory,
          ...METADATA.error,
          error: action.error,
        },
      };
    case SET_PENDING_TRANSACTIONS:
      return {
        ...state,
        pendingTransactions: action.payload,
      };
    case SET_BANK_DETAILS_LIST:
      return {
        ...state,
        bankDetailsList: action.payload,
      };

    default:
      return state;
  }
};

export default fundReducer;
