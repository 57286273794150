import React from 'react';
import {connect} from 'react-redux';
import {RoundButton} from '../../components/UI/RoundButton';
import Preloader from '../../components/Preloader';
import {getInactiveInvestors} from '../../store/actions/investorActions';
import InvestorBigCard from '../../components/Cards/InvestorBigCard';

class InvestorsAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      limit: 300
    }
  }

  componentDidMount() {
    this.getInvestors();
  }

  getInvestors = (extraParams, isNext) => {
    const {page, limit} = this.state;
    this.props.getInactiveInvestors({page, limit, ...extraParams}, isNext)
      .finally(() => this.setState(prevState => ({...prevState, loading: false})));
  }

  getNext = totalPages => {
    if (this.state.page < totalPages) {
      const nextPage = this.state.page + 1;
      this.getInvestors({page: nextPage}, true);
      return this.setState(prevState => ({...prevState, page: nextPage}))
    }
  }

  render() {
    const {page, loading} = this.state;
    const {investors} = this.props;
    const hasInvestors = !!(investors && investors.list.length);

    return (
      <div className="investors-all">
        <h2 className="f-26 f-500">All other investors</h2>
        {loading
          ? <Preloader className="investors-all__preloader" />
          : hasInvestors ? (
            <div className="investors-all__list">
              {investors.list.map(investor => (
                <InvestorBigCard
                  key={investor.id}
                  investor={investor}
                  disablePrivateNumber={true}
                  className="investors-active__item"
                />
              ))}
            </div>
          ) : (
          <div className="investors-all__empty">
            <span className="investors-all__empty-title f-16 f-400">No investors yet.</span>
          </div>
        )}

        {hasInvestors && page < investors.total_pages && (
          <RoundButton
            type="button"
            label="Show more investors"
            className="investors-all__btn"
            onClick={() => this.getNext(investors.total_pages)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investors: state.investorStore.inactiveInvestors,
})

const mapDispatchToProps = dispatch => ({
  getInactiveInvestors: (params, isNext) => dispatch(getInactiveInvestors(params, isNext)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestorsAll);