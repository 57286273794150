import React from 'react';
import { Formik } from 'formik';
import { LOAN_GROUPS } from '../../../helpers/constants';
import { EarningCalculator } from './form';
import { EarningResult } from './result';
import s from './index.module.scss';

const DEFAULT_AMOUNT = 1000;

export const CalculatorSingleLoan = ({
  groupID,
  onJoin,
  joinDisabled,
  disableBonusBanner,
}) => (
  <Formik
    onSubmit={() => null}
    enableReinitialize
    initialValues={{
      amount: DEFAULT_AMOUNT.toString(),
      loan: groupID || LOAN_GROUPS.conservative.id,
    }}
  >
    {formikBag => (
      <div className={s.content}>
        <EarningCalculator className={s.form} formikBag={formikBag} />
        <EarningResult
          formikBag={formikBag}
          onJoin={onJoin}
          joinDisabled={joinDisabled}
          disableBonusBanner={disableBonusBanner}
        />
      </div>
    )}
  </Formik>
);
