import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart } from 'react-charts';
import './index.scss';

const EarningGraphic = ({ graphData }) => {
  if (!graphData) {
    return null;
  }

  const data = ['Top-ups', 'My earnings'].map(label => ({
    label,
    data: graphData.chart_data.map(i => ({
      primary: `${i.year}`,
      secondary: label === 'Top-ups' ? i.top_up : i.earnings,
    })),
  }));

  const primaryAxis = React.useMemo(
    () => ({
      getValue: datum => datum.primary,
    }),
    []
  );
  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: datum => datum.secondary,
        stacked: true,
        elementType: 'bar',
      },
    ],
    []
  );

  return (
    <ResponsiveContainer>
      <Chart
        className="chart"
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          defaultColors: ['#FFD11C', '#FFEDA0'],
          secondaryCursor: false,
        }}
      />
    </ResponsiveContainer>
  );
};

export default EarningGraphic;

const ResponsiveContainer = ({ children }) => {
  const { innerWidth } = useSelector(state => state.commonStore);
  const ref = useRef();
  const [style, setStyle] = useState({});

  useEffect(() => {
    setStyle({
      width: `${ref.current.offsetWidth}px`,
      height: '400px',
      position: 'relative',
    });

    ref.current.width = '200px';
  }, [innerWidth, ref]);

  return (
    <div ref={ref} className="earning_graph" style={style}>
      {children}
      <div className="year">year</div>
    </div>
  );
};
