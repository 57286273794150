import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  calculateCash,
  getFundraisingState,
  prettyMoneyHybrid,
} from '../../helpers/utils';
import CashDetailBox from '../CashDetailBox';
import { getFundraisingDates } from '../../store/actions/dashboardActions';
import { SpecTriangleIcon } from '../UI/Icons';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { RoundLink } from '../UI/RoundLink';
import { APP_LINKS } from '../../helpers/links';
import './index.scss';

const TopHeader = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { balance, fundraisingDates } = useSelector(
    state => state.dashboardStore
  );
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false), show);

  useEffect(() => {
    dispatch(getFundraisingDates());
  }, []);

  const fundraisingState = getFundraisingState(fundraisingDates);
  const cash = calculateCash(balance);

  return (
    <div className="top-header">
      <div className="container-inner">
        <div className="top-header__inner">
          <p className="top-header__dates f-16 f-400">{fundraisingState}</p>
          <div className="top-header__summary">
            <div className="top-header__item">
              <span className="f-16 f-400">Total:</span>
              <span className="f-500">
                {prettyMoneyHybrid(balance && balance.total)}
              </span>
            </div>
            <div className="top-header__item">
              <span className="f-16 f-400">Invested:</span>
              <span className="f-500">
                {prettyMoneyHybrid(balance && balance.invested)}
              </span>
            </div>
            <div
              className="top-header__item cash row"
              ref={ref}
              onClick={() => setShow(!show)}
            >
              <span className="f-16 f-400">Cash:</span>
              <span className="f-500" style={{ marginLeft: '5px' }}>
                {prettyMoneyHybrid(cash)}
              </span>
              {show && (
                <div
                  className="top-header__popup"
                  onClick={e => e.stopPropagation()}
                >
                  <SpecTriangleIcon className="top-header__popup_triangle" />
                  <CashDetailBox balance={balance} />
                </div>
              )}
            </div>
            <RoundLink
              label="Invite friends"
              className="top-header__invite"
              path={APP_LINKS.affiliate}
              fillBackground
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
