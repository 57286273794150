import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as classnames from 'classnames';
import { Link } from 'react-router-dom';
import { STRATEGIES } from '../InvestingModule/InvSelectStrategy';
import BalanceInfo from '../../components/BalanceInfo';
import IncomeBlock from './IncomeBlock';
import HappyFounders from '../../components/HappyFounders';
import LoanBasket from '../../components/LoanBasket';
import { RoundLink } from '../../components/UI/RoundLink';
import NotificationBlock from '../NotificationBlock';
import InvestingInfo from '../InvestingInfo';
import { getInvestingStatus } from '../../store/actions/investActions';
import Avatar from '../../components/UI/Avatar';
import { getNameInitials, isParticipatingInvesting } from '../../helpers/utils';
import {
  getMissingProfileDetails,
  getUserProfile,
  getVeriffStatus,
} from '../../store/actions/profileActions';
import Preloader from '../../components/Preloader';
import VerificationProcessWizard from '../VeriffProcessWizard';
import MarketingPromotion from '../MarketingPromotion';
import MiniFaqs, { MINIFAQ_SOURCES } from '../MiniFaqs';
import { NOTIFICATIONS } from '../../components/Notification/list';
import { APP_LINKS } from '../../helpers/links';
import { MyLink } from '../../components/MyLink';
import AffiliateSteps from '../../components/AffiliateSteps';
import { BonusBanner } from '../../components/Banners/bonus';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { MinInvestAmountWarning } from '../../components/Warnings/MinInvestAmountWarning';
import { USER_STATUSES } from '../../helpers/constants';
import { isVerifiedPhoneNumber } from '../../helpers/user';
import {
  getBalance,
  getEarnings,
  getHappyFounders,
  getLoanGroups,
  getNotificationInfo,
} from '../../store/actions/dashboardActions';
import './index.scss';

const NOTIFICATION_TYPES = {
  missing_contacts: 'missing_contacts',
  missing_verification: 'missing_verification',
  no_funds: 'no_funds',
  funds_not_allocated: 'funds_not_allocated',
  no_themes_chosen: 'no_themes_chosen',
  fundraising_started: 'fundraising_started',
  fundraising_finished: 'fundraising_finished',
  registration_bonus: 'registration_bonus',
};

class DashboardModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: false,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.getUserProfile(),
      this.props.getInvestingStatus(),
      this.props.getBalance(),
      this.props.getMissingProfileDetails(),
    ]).finally(() =>
      this.setState(prevState => ({ ...prevState, pageLoaded: true }))
    );
    this.props.getEarnings();
    this.props.getHappyFounders();
    this.props.getLoanGroups();
    this.props.getNotificationInfo();
    this.props.getVeriffStatus();
  }

  render() {
    const { pageLoaded } = this.state;
    const {
      user,
      balance,
      earnings,
      happyFounders,
      loanGroups,
      investingResult,
      notificationInfo,
      investingStatus,
      fundraisingDates,
      missingInfo,
      history,
    } = this.props;

    const { data: status } = this.props.investingStatus;
    const { data: profile } = this.props.profile;

    if (!pageLoaded) {
      return (
        <div className="dashboard-module">
          <div className="container-inner">
            <Preloader style={{ height: '50vh' }} />
          </div>
        </div>
      );
    }

    const hasLoans = !!investingResult.data?.total_invested;

    const isActiveFR = !!(
      investingStatus.data &&
      investingStatus.data.step >= 0 &&
      investingStatus.data.step < 4
    );

    const hideVerificationWizard =
      (missingInfo && missingInfo.has_past_fundraisings) ||
      (investingStatus.data && investingStatus.data.has_opened_investing);

    const isSuspended =
      profile?.status === USER_STATUSES.suspended &&
      !missingInfo?.has_past_fundraisings;

    const notifications = [];

    fundraisingDates &&
      !fundraisingDates.is_active &&
      notifications.unshift(
        NOTIFICATIONS.next_round_starts(fundraisingDates.start_date)
      );

    notificationInfo &&
      missingInfo?.status === 'verified' &&
      notificationInfo.map(item => {
        if (
          item.notification_type === NOTIFICATION_TYPES.fundraising_finished
        ) {
          notifications.unshift(idx => (
            <li key={idx}>
              Congratulations! The round has been successfully completed.
              <Link to={APP_LINKS.loans} style={{ color: '#9E98A3' }}>
                {' '}
                You can view the results here →
              </Link>
            </li>
          ));
        }

        if (item.notification_type === NOTIFICATION_TYPES.registration_bonus) {
          notifications.unshift(idx => <li key={idx}>{item.title}</li>);
        }
      });

    status &&
      status.is_active &&
      (status.step === 3
        ? notifications.push(NOTIFICATIONS.round_results(status.end_date))
        : notifications.push(idx => (
            <li key={idx}>
              The round has started!
              <Link to={APP_LINKS.investing} style={{ color: '#9E98A3' }}>
                {' '}
                Get to know this month&apos;s batch →
              </Link>
            </li>
          )));

    !isVerifiedPhoneNumber(profile) &&
      notifications.push(NOTIFICATIONS.verify_phone_number());

    return (
      <div className="dashboard-module">
        <div className="container-inner">
          {!isSuspended && !status.has_previously_invested && (
            <BonusBanner className="dashboard-module__banner" />
          )}
          <section className="dashboard-module__header">
            <div className="row">
              <MyLink
                href={APP_LINKS.profile}
                className="dashboard-module__header-avatar-wrap"
              >
                <Avatar
                  src={user.photo && user.photo.file}
                  size={65}
                  alt={getNameInitials(user.first_name, user.last_name)}
                  nameInitials={getNameInitials(
                    user.first_name,
                    user.last_name
                  )}
                  className="dashboard-module__header-avatar"
                  uniqueID={user.unique_id}
                  active={isParticipatingInvesting(investingStatus.data)}
                />
              </MyLink>

              {hideVerificationWizard && (
                <RoundLink
                  label="Add cash"
                  path={APP_LINKS.addFunds}
                  fillBackground
                />
              )}
            </div>

            {hideVerificationWizard || isSuspended ? (
              <div className="dashboard-module__header-title f-42 f-500">
                {user.first_name
                  ? `Hey ${user.first_name} — Welcome to Scramble!`
                  : 'Hi there! Nice to see you!'}
              </div>
            ) : (
              <div className="dashboard-module__header-title f-42 f-500">
                {user.first_name
                  ? `Hey ${user.first_name}, \n complete this checklist to start investing`
                  : 'Hi there, \n complete this checklist to start investing'}
              </div>
            )}

            {profile?.country_code === 'US' &&
              status?.min_investment_amount === 1000 && (
                <MinInvestAmountWarning className="dashboard-module__amount_warning" />
              )}

            {!isSuspended && profile?.promotion && (
              <MarketingPromotion promotion={profile.promotion} />
            )}

            {!isSuspended && hideVerificationWizard && (
              <div className="dashboard-module__notifications-block">
                <NotificationBlock
                  className="dashboard-module__notifications"
                  notifications={notifications}
                />
              </div>
            )}
          </section>

          {isSuspended ? (
            <SuspendResidenceWarning className="dashboard-module__suspended" />
          ) : (
            <>
              {!hideVerificationWizard ? (
                profile &&
                balance && <VerificationProcessWizard isActiveFR={isActiveFR} />
              ) : (
                <>
                  {profile?.status === USER_STATUSES.suspended && (
                    <SuspendResidenceWarning className="dashboard-module__warning" />
                  )}
                  <section className="dashboard-module__summary">
                    <BalanceInfo balance={balance} />
                    <IncomeBlock earnings={earnings} />
                    <HappyFounders founders={happyFounders} />
                  </section>

                  {investingStatus.data && (
                    <section className="dashboard-module__investing">
                      <h3 className="dashboard-module__investing-header f-26 f-500">
                        Your investment settings
                      </h3>
                      <InvestingInfo
                        status={investingStatus.data}
                        onLoansEdit={() =>
                          history.push('/investing?to=funds-allocation')
                        }
                        onStrategyEdit={() =>
                          history.push('/investing?to=strategy-selection')
                        }
                        isDashboard
                        className={classnames(
                          'dashboard-module__investing-summary',
                          !isActiveFR &&
                            investingStatus.data.step !== 5 &&
                            (!investingStatus.data.auto_invest ||
                              !investingStatus.data.earmarked ||
                              investingStatus.data.earmarked <= 25) &&
                            'hidden',
                          (isActiveFR || investingStatus.data.step === 4) &&
                            (investingStatus.data.option ===
                              STRATEGIES.not_selected ||
                              !investingStatus.data.earmarked ||
                              investingStatus.data.earmarked <= 25 ||
                              !investingStatus.data
                                .has_accepted_necessary_terms) &&
                            'hidden'
                        )}
                      />
                      <RoundLink
                        label="Go to investing"
                        path="/investing"
                        className="dashboard-module__investing-loan"
                        fullWidth
                        fillBackground
                      />
                      {investingStatus.data.step === 5 && hasLoans && (
                        <RoundLink
                          label="See new loans"
                          path="/loans"
                          className="dashboard-module__investing-loan"
                          fullWidth
                        />
                      )}
                    </section>
                  )}

                  <section className="dashboard-module__baskets">
                    <div className="dashboard-module__baskets-top">
                      <h2 className="f-26 f-500">Your loan groups</h2>
                      <RoundLink
                        label="Learn more"
                        path={APP_LINKS.helpLoanTerms}
                        className="dashboard-module__baskets-link"
                      />
                    </div>
                    <div className="dashboard-module__baskets-cards">
                      {loanGroups.data &&
                        loanGroups.data.map(basket => (
                          <LoanBasket
                            key={basket.id}
                            data={basket}
                            isActiveFundraising={
                              !!investingStatus.data &&
                              investingStatus.data.active_fundraising
                            }
                          />
                        ))}
                    </div>
                    <RoundLink
                      label="Learn more"
                      path={APP_LINKS.helpLoanTerms}
                      className="dashboard-module__baskets-link-bottom"
                      fullWidth
                    />
                  </section>

                  <section className="dashboard-module__affiliate">
                    <h2 className="f-26 f-500">
                      Earn exclusive rewards when your friends invest
                    </h2>
                    <AffiliateSteps className="dashboard-module__affiliate_steps" />
                    <RoundLink
                      label="Invite friends"
                      path={APP_LINKS.affiliate}
                      className="dashboard-module__affiliate_invite"
                      fullWidth
                      fillBackground
                    />
                  </section>
                </>
              )}
              <MiniFaqs source={MINIFAQ_SOURCES.dashboard} />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  balance: state.dashboardStore.balance,
  earnings: state.dashboardStore.earnings,
  happyFounders: state.dashboardStore.happyFounders,
  loanGroups: state.dashboardStore.loanGroups,
  notificationInfo: state.dashboardStore.notificationInfo,
  investingStatus: state.investStore.investingStatus,
  investingResult: state.investStore.investingResult,
  fundraisingDates: state.dashboardStore.fundraisingDates,
  missingInfo: state.profileStore.profileMissingDetails,
  profile: state.profileStore.profile,
});

const mapDispatchToProps = dispatch => ({
  getBalance: () => dispatch(getBalance()),
  getEarnings: () => dispatch(getEarnings()),
  getHappyFounders: () => dispatch(getHappyFounders()),
  getLoanGroups: () => dispatch(getLoanGroups()),
  getNotificationInfo: () => dispatch(getNotificationInfo()),
  getInvestingStatus: () => dispatch(getInvestingStatus()),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
  getUserProfile: () => dispatch(getUserProfile()),
  getVeriffStatus: () => dispatch(getVeriffStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardModule);
