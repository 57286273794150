import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { InputTextField } from '../../UI/InputTextField';
import { RoundButton } from '../../UI/RoundButton';
import { RoundLink } from '../../UI/RoundLink';
import PhotoUpload from '../../PhotoUpload';
import { PHOTO_ALLOWED_FORMATS } from '../../../helpers/common';
import { checkForValidFile } from '../../../helpers/utils';
import { TidioChat } from '../../../chat/tidio';
import { setUserVeriffSkipReason } from '../../../store/services/userServices';
import { SKIP_REASON_TYPES } from '../../VerifyForm';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object({
  first_name: Yup.string()
    .max(40, 'First name exceeds limit of 40')
    .required('First name is required'),
  last_name: Yup.string()
    .max(40, 'Last name exceeds limit of 40')
    .required('Last name is required'),
  // citizenship: Yup.string('Citizenship is required')
  //   .required('Citizenship is required'),
  // origin_of_funds: Yup.string('Origin of funds is required')
  //   .nullable()
  //   .required('Origin of funds is required'),
  // planned_monthly_investment: Yup.string('Planned monthly investment is required')
  //   .nullable()
  //   .required('Planned monthly investment is required'),
});

class ContactInfoForm extends React.Component {
  getInitialValues = user => ({
    first_name: (user && user.first_name) || '',
    last_name: (user && user.last_name) || '',
    // citizenship: (user && user.citizenship) || (location && location.country) || '',
    // citizenship_code: (user && user.citizenship_code) || (location && location.countryCode) || '',
    // origin_of_funds: null,
    // planned_monthly_investment: null,
    photo: null,
    croppedPhoto: '',
    editorRef: null,
    company_name: (user && user.company_name) || '',
    position: (user && user.position) || '',
  });

  render() {
    const { userLocation, user, onSubmit, defaultSubmit, onCancel } =
      this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues(user, userLocation)}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          values,
          errors,
          touched,
          setFieldError,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className="contact-info-form" onSubmit={handleSubmit}>
            <h1 className="contact-info-form__title f-500">Profile details</h1>
            <p className="contact-info-form__subTitle f-400">
              This information will be displayed on your public profile and will
              be visible to other investors.
            </p>
            <InputTextField
              label="First name *"
              name="first_name"
              value={values.first_name}
              onChange={handleChange}
              onClear={() => setFieldValue('first_name', '')}
              error={
                errors.first_name && touched.first_name && errors.first_name
              }
              className="contact-info-form__row"
            />
            <InputTextField
              label="Last name *"
              name="last_name"
              value={values.last_name}
              onChange={handleChange}
              onClear={() => setFieldValue('last_name', '')}
              error={errors.last_name && touched.last_name && errors.last_name}
              className="contact-info-form__row"
            />
            <InputTextField
              label="Company name"
              name="company_name"
              value={values.company_name}
              onChange={handleChange}
              onClear={() => setFieldValue('company_name', '')}
              error={
                errors.company_name &&
                touched.company_name &&
                errors.company_name
              }
              className="contact-info-form__row"
            />
            <InputTextField
              label="Position"
              name="position"
              value={values.position}
              onChange={handleChange}
              onClear={() => setFieldValue('position', '')}
              error={errors.position && touched.position && errors.position}
              className="contact-info-form__row"
            />
            <PhotoUpload
              label="Upload your photo"
              name="photo"
              value={values.photo}
              onChange={e => {
                const file = e.target.files[0];
                const { isValid, type, size } = checkForValidFile(
                  file,
                  PHOTO_ALLOWED_FORMATS,
                  12582912
                );
                !isValid &&
                  size &&
                  setFieldError('photo', 'File size is too big');
                !isValid &&
                  type &&
                  setFieldError(
                    'photo',
                    'Incorrect file type. Upload your photo in jpg or png format.'
                  );
                isValid && setFieldValue('photo', file);
              }}
              setFieldValue={setFieldValue}
              onRemove={() => setFieldValue('photo', '')}
              error={errors.photo}
              className="contact-info-form__row"
            />
            <RoundButton
              label={defaultSubmit ? 'Save' : 'Continue'}
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              withArrow={!defaultSubmit}
              className="contact-info-form__button"
            />
            <RoundLink
              label="Continue Later"
              path="/"
              className="contact-info-form__link"
              onClick={() => {
                TidioChat.onSkipVeriff();
                setUserVeriffSkipReason(SKIP_REASON_TYPES.skipped_later);
              }}
            />
            {onCancel && (
              <RoundButton
                label="Cancel"
                type="button"
                onClick={onCancel}
                className="contact-info-form__button"
              />
            )}
          </form>
        )}
      </Formik>
    );
  }
}

export default ContactInfoForm;
