import React from 'react';
import { APP_LINKS } from '../../../helpers/links';
import Tooltip from '../../../components/Tooltip';
import Dropdown from '../../../components/Dropdown';
// import { DownloadOptions } from '../../components/DownloadOptions';
import ExampleList from './ExampleList';
import ExampleTable from './ExampleTable';
import { MyLink } from '../../../components/MyLink';
import {
  EXAMPLE_A_DATA_12,
  EXAMPLE_A_DATA_18,
  EXAMPLE_A_DATA_24,
  EXAMPLE_B_DATA_12,
  EXAMPLE_B_DATA_18,
  EXAMPLE_B_DATA_24,
  GroupAExample,
  GroupBExample,
} from './tableData';

import A_REGULAR_REPAYMENT from '../../../assets/images/a_regular_repayment_scenario.svg';
import A_LAST_MONTH from '../../../assets/images/a_last_month.svg';
import A_OPTION_1 from '../../../assets/images/a_option_1.svg';
import A_OPTION_2 from '../../../assets/images/a_option_2.svg';
import A_OPTION_3 from '../../../assets/images/a_option_3.svg';
import B_REGULAR_REPAYMENT from '../../../assets/images/b_regular_scenario.svg';
import B_OPTION_1 from '../../../assets/images/b_option_1.svg';
import B_OPTION_2 from '../../../assets/images/b_option_2.svg';
import B_OPTION_3 from '../../../assets/images/b_option_3.svg';

export const GROUP_DETAIL = {
  [APP_LINKS.helpLoanTermsGroupA]: (data, innerWidth) => ({
    title: data ? data.conservative.title : 'Group A',
    termsSection: (
      <section className="loan_group_detail_page__terms">
        <h2 className="f-26-32 f-500">Loan terms</h2>
        <div className="loan_group_detail_page__first_row">
          <div className="loan_group_detail_page__annual_return">
            <div className="loan_group_detail_page__annual_return_header">
              <div className="loan_group_detail_page__annual_return_title f-16-24 f-400">
                Target annual return
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className="loan-group-card__tooltip f-16 f-400">
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <div className="loan_group_detail_page__annual_return_value f-28-32 f-500">
              {data ? data.conservative.investor_annual_rate : '-'}
            </div>
          </div>
        </div>
        <div className="loan_group_detail_page__second_row">
          <div className="loan_group_detail_page__nominal_return_title f-16-24 f-400">
            Nominal return
          </div>
          <div className="loan_group_detail_page__nominal_return">
            <div className="loan_group_detail_page__nominal_return_item">
              <div className="loan_group_detail_page__nominal_return_item_desc f-16-24 f-400">
                for the standard 6-month term
              </div>
              <div className="loan_group_detail_page__nominal_return_item_circle">
                <div className="f-28-32 f-500">5% flat fee</div>
                <div className="f-16-24 f-400">
                  paid immediately on loan disbursement
                </div>
              </div>
            </div>
            <div className="loan_group_detail_page__nominal_return_item">
              <div className="loan_group_detail_page__nominal_return_item_desc f-16-24 f-400">
                if funds are used by a startup beyond 6 months (up to 24 months)
              </div>
              <div className="loan_group_detail_page__nominal_return_item_circle">
                <div className="f-28-32 f-500">+ up to 4.4% extra flat fee</div>
                <div className="f-16-24 f-400">
                  based on loan repayment timing
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    fundsUsageSection: (
      <section className="loan_group_detail_page__usage">
        <h2 className="loan_group_detail_page__usage_title f-26-32 f-500">
          Startups selection and funds usage
        </h2>
        <p className="loan_group_detail_page__usage_desc f-16-24 f-400">
          Loans are issued to a pre-selected group of consumer goods startups
          based in the UK or continental Europe. To qualify, each startup must
          demonstrate strong consumer affection for the product, have a
          qualified co-founders team, and not be in a distressed financial
          situation.
        </p>
        <p className="loan_group_detail_page__usage_desc f-16-24 f-400">
          Funds are used by startups for working capital (purchase of inventory,
          packaging materials and the like).
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className="loan_group_detail_page__protection">
        <h2 className="f-26-32 f-500">Investor funds protection</h2>
        <p className="f-16-24 f-400">
          Loans of group A – Senior loans – are the first ones to be repaid. So
          if a business fails, investors in loans A have the highest chances to
          be repaid.
        </p>

        <div className="loan_group_detail_page__protection_content">
          {[
            {
              label: 'Fee payment upfront, regular monthly repayments',
              content: (
                <div className="f-16-24 f-400">
                  Investors start receiving repayments from Day 1, and with each
                  month the total amount of capital at risk goes down.
                </div>
              ),
            },
            {
              label: 'Co-founder team guarantees',
              content: (
                <div className="f-16-24 f-400">
                  Each co-founder guarantees to repay up to 20% of the loan to
                  their business with personal lifetime income. So jointly two
                  or three co-founders guarantee 40% or even 60%.
                </div>
              ),
            },
            {
              label: 'First loss capital',
              content: (
                <div className="f-16-24 f-400">
                  Investors in loans B contribute 15% of total funding for each
                  startup batch but are only paid back after all investors in
                  loans A are repaid. Simply put, investors A are not affected
                  in any way in case up to 15% of all funds are lost.
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow
            >
              <div className="loan_group_detail_page__protection_dropdown">
                {item.content}
              </div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className="loan_group_detail_page__repayments">
        <h2 className="f-26-32 f-500">Loan repayments</h2>
        <p className="f-16-24 f-400">
          Monthly loan repayments are transferred to your Scramble cash account
          on the 5th day, the month after the loan is issued.
        </p>

        <div className="loan_group_detail_page__repayments_chapter">
          <h4 className="f-18-24 f-500">Regular repayment scenario:</h4>
          <img
            src={A_REGULAR_REPAYMENT}
            alt="Group A"
            style={{
              maxWidth: '500px',
              margin: '40px auto',
              display: 'block',
              width: '100%',
            }}
            className="loan_group_detail_page__repayments_image"
          />
          <ul className="loan_group_detail_page__repayments_list">
            <li>
              <div className="f-16-24 f-500">At the disbursement moment</div>
              <div className="f-16-24 f-400">
                Right at the end of the round, you get a 5% flat fee and 1.5%
                loan principal.
              </div>
            </li>
            <li>
              <div className="f-16-24 f-500">
                From the end of the 1st month to the end of the 5th month
              </div>
              <div className="f-16-24 f-400">
                During the first 5 months, you get 4.7% monthly loan principal
                repayments.
              </div>
            </li>
            <li>
              <div className="f-16-24 f-500">At the end of the 6th month</div>
              <div className="f-16-24 f-400">
                At the end of the 6th month, you get the remaining 75% of the
                loan principal.
              </div>
            </li>
          </ul>

          <Dropdown
            label={
              <span className="f-18 f-500">
                A regular repayment schedule example
              </span>
            }
            className="loan_group_detail_page__example"
            defaultShow
            disableToggle
          >
            <>
              <div className="loan_group_detail_page__example_summary">
                <div className="loan_group_detail_page__example_summary_left">
                  <div className="f-16">
                    <div>Loan amount</div>
                    <div className="f-500">
                      <b>€1’000</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>Origination date</div>
                    <div className="f-500">
                      <b>05.01.2022</b>
                    </div>
                  </div>
                </div>
                <div className="loan_group_detail_page__example_summary_right">
                  {/* <DownloadOptions */}
                  {/*   links={[ */}
                  {/*     { format: 'pdf', link: '#' }, */}
                  {/*     { format: 'xlsx', link: '#' }, */}
                  {/*     { format: 'csv', link: '#' }, */}
                  {/*   ]} */}
                  {/* /> */}
                </div>
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExample} />
              ) : (
                <ExampleTable data={GroupAExample} />
              )}
            </>
          </Dropdown>
        </div>

        <div className="loan_group_detail_page__repayments_chapter">
          <h4 className="f-18 f-500">
            A startup can move repayments to the last month
          </h4>
          <p className="f-16-24 f-400">
            Each startup can also move a monthly repayment to the last month of
            the current loan term (6th, 12th, 18th or 24th month).
          </p>
          <p className="f-16-24 f-400">
            What happens if a startup moves a monthly repayment to the last
            month of the current loan term?
          </p>
          <p className="f-16-24 f-400">
            For example, if a startup skips a repayment in the second month,
            then the repayment will automatically be moved to the last 6th
            month.
          </p>
          <img
            src={A_LAST_MONTH}
            alt="A startup can move repayments to the last month"
            style={{
              maxWidth: '500px',
              margin: '0 auto',
              display: 'block',
              width: '100%',
            }}
          />
        </div>

        <div className="loan_group_detail_page__repayments_chapter">
          <h4 className="f-18 f-500">
            A startup can extend a repayment schedule
          </h4>
          <p className="f-16-24 f-400">
            Each startup can extend the repayment period of the loan to 12, 18
            or 24 months.
          </p>

          <Dropdown
            label={
              <span className="f-16 f-500">
                Option 1: A startup extends a loan term to 12 months
              </span>
            }
            defaultShow={false}
            className="loan_group_detail_page__repayments_option"
          >
            <div className="f-16 f-400" style={{ marginTop: '10px' }}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              What happens if a startup doesn't pay off the remaining part of
              the loan principal in the 6th month?
              <br />
              Then the repayment schedule is automatically extended for an
              additional 6 months.
            </div>
            <img
              src={A_OPTION_1}
              alt="Option 1"
              className="loan_group_detail_page__repayments_image"
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className="loan_group_detail_page__repayments_list">
              <li>
                <div className="f-16-24 f-500">In the 6th month</div>
                <div className="f-16-24 f-400">
                  You will receive an additional 3% flat fee of the loan amount,
                  as well as a 6.6% monthly loan principal repayment.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">From 7th to 11th months</div>
                <div className="f-16-24 f-400">
                  Then from 7th to 11th months, you get 4.7% of the loan
                  principal each month.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">In the 12th month</div>
                <div className="f-16-24 f-400">
                  In the 12th month you get the remaining 44.9% of the loan
                  principal.
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  A 12-month repayment schedule example
                </span>
              }
              defaultShow
              disableToggle
              className="loan_group_detail_page__repayments_dropdown"
            >
              <>
                <div className="loan_group_detail_page__example_summary">
                  <div className="loan_group_detail_page__example_summary_left">
                    <div className="f-16">
                      <div>Loan amount</div>
                      <div className="f-500">
                        <b>€1’000</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>Origination date</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className="loan_group_detail_page__example_summary_right">
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className="loan_group_detail_page__example_data">
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_A_DATA_12} />
                  ) : (
                    <ExampleTable data={EXAMPLE_A_DATA_12} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                Option 2: A startup extends a loan term to 18 months
              </span>
            }
            defaultShow={false}
            className="loan_group_detail_page__repayments_option"
          >
            <div className="f-16 f-400" style={{ marginTop: '10px' }}>
              What happens if a startup doesn&apos;t pay off the remaining part
              of the loan principal in the 12th month?
              <br />
              Then the repayment schedule is automatically extended for an
              additional 6 months.
            </div>
            <img
              src={A_OPTION_2}
              alt="Option 2"
              className="loan_group_detail_page__repayments_image"
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className="loan_group_detail_page__repayments_list">
              <li>
                <div className="f-16-24 f-500">In the 12th month</div>
                <div className="f-16-24 f-400">
                  You will receive an additional 1.4% flat fee of the loan
                  amount, as well as a 7.5% monthly loan principal repayment.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">From 13th to 17th months</div>
                <div className="f-16-24 f-400">
                  Then from 13th to 17th months, you get 4.7% of the loan
                  principal each month.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">In the 18th month</div>
                <div className="f-16-24 f-400">
                  In the 18th month, you get the remaining 13.9% of the loan
                  principal.
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  An 18-month repayment schedule example
                </span>
              }
              defaultShow
              disableToggle
              className="loan_group_detail_page__repayments_dropdown"
            >
              <>
                <div className="loan_group_detail_page__example_summary">
                  <div className="loan_group_detail_page__example_summary_left">
                    <div className="f-16">
                      <div>Loan amount</div>
                      <div className="f-500">
                        <b>€1’000</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>Origination date</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className="loan_group_detail_page__example_summary_right">
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className="loan_group_detail_page__example_data">
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_A_DATA_18} />
                  ) : (
                    <ExampleTable data={EXAMPLE_A_DATA_18} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                Option 3: A startup extends a loan term to 24 months
              </span>
            }
            defaultShow={false}
            className="loan_group_detail_page__repayments_option"
          >
            <div className="f-16 f-400" style={{ marginTop: '10px' }}>
              What happens if a startup doesn&apos;t pay off the remaining part
              of the loan principal in the 18th month?
              <br />
              Then the repayment schedule is automatically extended for an
              additional 6 months.
            </div>
            <img
              src={A_OPTION_3}
              alt="Option 3"
              className="loan_group_detail_page__repayments_image"
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className="loan_group_detail_page__repayments_list">
              <li>
                <div className="f-16-24 f-500">In the 18th month</div>
                <div className="f-16-24 f-400">
                  You will receive an 8.5% monthly loan principal repayment.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">In the 19th month</div>
                <div className="f-16-24 f-400">
                  You get 4.7% of the loan principal.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">In the 20th month</div>
                <div className="f-16-24 f-400">
                  In the 20th month, you get the remaining 0.7% of the loan
                  principal.
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  A 24-month repayment schedule example
                </span>
              }
              defaultShow
              disableToggle
              className="loan_group_detail_page__repayments_dropdown"
            >
              <>
                <div className="loan_group_detail_page__example_summary">
                  <div className="loan_group_detail_page__example_summary_left">
                    <div className="f-16">
                      <div>Loan amount</div>
                      <div className="f-500">
                        <b>€1’000</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>Origination date</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className="loan_group_detail_page__example_summary_right">
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className="loan_group_detail_page__example_data">
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_A_DATA_24} />
                  ) : (
                    <ExampleTable data={EXAMPLE_A_DATA_24} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>
        </div>
      </section>
    ),
    agreementSection: (
      <section className="loan_group_detail_page__agreement">
        <h2 className="f-24 f-500">Loan agreement</h2>
        <p className="f-16 f-400">
          When you set up your investing settings, you accept the terms of the
          Financing Agreement. The Financing Agreement for each created loan is
          available for download inside group A loan cards on the{' '}
          <MyLink href={APP_LINKS.loans}>Loans page</MyLink>.
        </p>
        <MyLink
          isExternal
          href={APP_LINKS.unsubordinatedFinancialAgreement}
          className="loan_group_detail_page__agreement_link"
        >
          Download Financing Agreement (pdf)
        </MyLink>
      </section>
    ),
  }),

  [APP_LINKS.helpLoanTermsGroupB]: (data, innerWidth) => ({
    title: data ? data.moderate.title : 'Group B',
    termsSection: (
      <section className="loan_group_detail_page__terms">
        <h2 className="f-26-32 f-500">Loan terms</h2>
        <div className="loan_group_detail_page__first_row">
          <div className="loan_group_detail_page__annual_return">
            <div className="loan_group_detail_page__annual_return_header">
              <div className="loan_group_detail_page__annual_return_title f-16-24 f-400">
                Target annual return
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className="loan-group-card__tooltip f-16 f-400">
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <div className="loan_group_detail_page__annual_return_value f-28-32 f-500">
              Up to 30%
            </div>
          </div>
        </div>
        <div className="loan_group_detail_page__second_row">
          <div className="loan_group_detail_page__nominal_return_title f-16-24 f-400">
            Nominal return
          </div>
          <div className="loan_group_detail_page__nominal_return">
            <div className="loan_group_detail_page__nominal_return_item">
              <div className="loan_group_detail_page__nominal_return_item_desc f-16-24 f-400">
                for the standard 6-month term:
              </div>
              <div className="loan_group_detail_page__nominal_return_item_circle">
                <div className="f-28-32 f-500">9% flat fee</div>
                <div className="f-16-24 f-400">paid at the end of the term</div>
              </div>
            </div>
            <div className="loan_group_detail_page__nominal_return_item">
              <div className="loan_group_detail_page__nominal_return_item_desc f-16-24 f-400">
                if funds are used by a startup beyond 6 months (up to 24 months)
              </div>
              <div className="loan_group_detail_page__nominal_return_item_circle">
                <div className="f-28-32 f-500">+ up to 49% extra flat fee</div>
                <div className="f-16-24 f-400">
                  based on loan repayment timing
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    fundsUsageSection: (
      <section className="loan_group_detail_page__usage">
        <h2 className="loan_group_detail_page__usage_title f-26-32 f-500">
          Startups selection and funds usage
        </h2>
        <p className="loan_group_detail_page__usage_desc f-16-24 f-400">
          Loans issued to a pre-selected group of consumer goods startups based
          in the UK or continental Europe. To qualify, each startup must
          demonstrate strong consumer affection for the product, have a
          qualified co-founders team, and not be in a distressed financial
          situation.
        </p>
        <p className="loan_group_detail_page__usage_desc f-16-24 f-400">
          Funds are used by startups for working capital (purchase of inventory,
          packaging materials and the like).
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className="loan_group_detail_page__protection">
        <h2 className="f-26-32 f-500">Investor funds protection</h2>
        <p className="f-16-24 f-400">
          Loans of group B – Junior loans – are the last ones to be repaid. So
          if a business fails, investors in loans A must be repaid in full
          before investors in loans B get their money back.
        </p>

        <div className="loan_group_detail_page__protection_content">
          {[
            {
              label: 'Co-founder team guarantees',
              content: (
                <div className="f-16-24 f-400">
                  Each co-founder guarantees to repay up to 20% of the loan to
                  their business with personal lifetime income. So jointly two
                  or three co-founders guarantee 40% or even 60%.
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow
            >
              <div className="loan_group_detail_page__protection_dropdown">
                {item.content}
              </div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className="loan_group_detail_page__repayments">
        <h2 className="f-26-32 f-500">Loan repayments</h2>
        <p className="f-16-24 f-400">
          Monthly loan repayments are transferred to your Scramble cash account
          on the 5th day, the month after the loan is issued.
        </p>

        <div className="loan_group_detail_page__repayments_chapter">
          <h4 className="f-18 f-400">Regular repayment scenario:</h4>
          <img
            src={B_REGULAR_REPAYMENT}
            alt="Group B"
            className="loan_group_detail_page__repayments_image"
            style={{
              maxWidth: '400px',
              margin: '30px auto 40px',
              display: 'block',
              width: '100%',
            }}
          />
          <ul className="loan_group_detail_page__repayments_list">
            <li>
              <div className="f-16-24 f-500">At end of the 6th month</div>
              <div className="f-16-24 f-400">
                At the end of the 6th month, you get 100% of the loan principal
                and a 9% flat fee.
              </div>
            </li>
          </ul>

          <Dropdown
            label={
              <span className="f-18 f-500">
                A regular repayment schedule example
              </span>
            }
            className="loan_group_detail_page__example"
            defaultShow
            disableToggle
          >
            <>
              <div className="loan_group_detail_page__example_summary">
                <div className="loan_group_detail_page__example_summary_left">
                  <div className="f-16">
                    <div>Loan amount</div>
                    <div className="f-500">
                      <b>€1’500</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>Origination date</div>
                    <div className="f-500">
                      <b>05.01.2022</b>
                    </div>
                  </div>
                </div>
                <div className="loan_group_detail_page__example_summary_right">
                  {/* <DownloadOptions */}
                  {/*   links={[ */}
                  {/*     { format: 'pdf', link: '#' }, */}
                  {/*     { format: 'xlsx', link: '#' }, */}
                  {/*     { format: 'csv', link: '#' }, */}
                  {/*   ]} */}
                  {/* /> */}
                </div>
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupBExample} />
              ) : (
                <ExampleTable data={GroupBExample} />
              )}
            </>
          </Dropdown>
        </div>

        <div className="loan_group_detail_page__repayments_chapter">
          <h4 className="f-18 f-500">
            A startup can move repayments to the last month
          </h4>
          <p className="f-16-24 f-400">
            Each startup can also move a monthly repayment to the last month if
            the loan term is longer than 18 months.
          </p>
        </div>

        <div className="loan_group_detail_page__repayments_chapter">
          <h4 className="f-18 f-500">
            A startup can extend a repayment schedule
          </h4>
          <p className="f-16-24 f-400">
            Each startup can extend the repayment period of the loan to 12, 18
            or 24 months.
          </p>

          <Dropdown
            label={
              <span className="f-16 f-500">
                Option 1: A startup extends a loan term to 12 months
              </span>
            }
            defaultShow={false}
            className="loan_group_detail_page__repayments_option"
          >
            <div className="f-16 f-400" style={{ marginTop: '10px' }}>
              What happens if a startup doesn&apos;t pay off the remaining part
              of the loan principal in the 6th month?
              <br />
              Then the repayment schedule is automatically extended for an
              additional 6 months.
            </div>
            <img
              src={B_OPTION_1}
              alt="Option 1"
              className="loan_group_detail_page__repayments_image"
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
                maxWidth: '700px',
              }}
            />

            <ul className="loan_group_detail_page__repayments_list">
              <li>
                <div className="f-16-24 f-500">In the 6th month</div>
                <div className="f-16-24 f-400">
                  You do not receive repayments.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">In the 12th month</div>
                <div className="f-16-24 f-400">
                  In the 12th month, you get a 100% of the loan principal and a
                  20% flat fee.
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  A 12-month repayment schedule example
                </span>
              }
              defaultShow
              disableToggle
              className="loan_group_detail_page__repayments_dropdown"
            >
              <>
                <div className="loan_group_detail_page__example_summary">
                  <div className="loan_group_detail_page__example_summary_left">
                    <div className="f-16">
                      <div>Loan amount</div>
                      <div className="f-500">
                        <b>€1’500</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>Origination date</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className="loan_group_detail_page__example_summary_right">
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className="loan_group_detail_page__example_data">
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_B_DATA_12} />
                  ) : (
                    <ExampleTable data={EXAMPLE_B_DATA_12} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                Option 2: A startup extends a loan term to 18 months
              </span>
            }
            defaultShow={false}
            className="loan_group_detail_page__repayments_option"
          >
            <div className="f-16 f-400" style={{ marginTop: '10px' }}>
              What happens if a startup doesn&apos;t pay off the remaining part
              of the loan principal in the 12th month?
              <br />
              Then the repayment schedule is automatically extended for an
              additional 6 months.
            </div>
            <img
              src={B_OPTION_2}
              alt="Option 2"
              className="loan_group_detail_page__repayments_image"
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className="loan_group_detail_page__repayments_list">
              <li>
                <div className="f-16-24 f-500">In the 12th month</div>
                <div className="f-16-24 f-400">
                  You do not receive repayments.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">In the 18th month</div>
                <div className="f-16-24 f-400">
                  In the 18th month, you get 100% of the loan principal and a
                  36% flat fee.
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  An 18-month repayment schedule example
                </span>
              }
              defaultShow
              disableToggle
              className="loan_group_detail_page__repayments_dropdown"
            >
              <>
                <div className="loan_group_detail_page__example_summary">
                  <div className="loan_group_detail_page__example_summary_left">
                    <div className="f-16">
                      <div>Loan amount</div>
                      <div className="f-500">
                        <b>€1’500</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>Origination date</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className="loan_group_detail_page__example_summary_right">
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className="loan_group_detail_page__example_data">
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_B_DATA_18} />
                  ) : (
                    <ExampleTable data={EXAMPLE_B_DATA_18} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                Option 3: A startup extends a loan term to 24 months
              </span>
            }
            defaultShow={false}
            className="loan_group_detail_page__repayments_option"
          >
            <div className="f-16 f-400" style={{ marginTop: '10px' }}>
              What happens if a startup doesn&apos;t pay off the remaining part
              of the loan principal in the 18th month?
              <br />
              Then the repayment schedule is automatically extended for an
              additional 6 months.
            </div>
            <img
              src={B_OPTION_3}
              alt="Option 3"
              className="loan_group_detail_page__repayments_image"
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className="loan_group_detail_page__repayments_list">
              <li>
                <div className="f-16-24 f-500">From 20th to 23th months</div>
                <div className="f-16-24 f-400">
                  From 20th to 23th months, you get a corresponding portion of
                  the loan principal each month.
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">From 21th to 24th months</div>
                <div className="f-16-24 f-400">
                  From 21th to 24th months, you get your flat fee repayments.
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  A 24-month repayment schedule example
                </span>
              }
              defaultShow
              disableToggle
              className="loan_group_detail_page__repayments_dropdown"
            >
              <>
                <div className="loan_group_detail_page__example_summary">
                  <div className="loan_group_detail_page__example_summary_left">
                    <div className="f-16">
                      <div>Loan amount</div>
                      <div className="f-500">
                        <b>€1’500</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>Origination date</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className="loan_group_detail_page__example_summary_right">
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className="loan_group_detail_page__example_data">
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_B_DATA_24} />
                  ) : (
                    <ExampleTable data={EXAMPLE_B_DATA_24} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>
        </div>
      </section>
    ),
    agreementSection: (
      <section className="loan_group_detail_page__agreement">
        <h2 className="f-24 f-500">Loan agreement</h2>
        <p className="f-16 f-400">
          When you set up your investing settings, you accept the terms of the
          Subordinated Financing Agreement and the Guarantee Letter. These files
          for each created loan are available for download inside group B loan
          cards on the <MyLink href={APP_LINKS.loans}>Loans page</MyLink>.
        </p>
        <MyLink
          isExternal
          href={APP_LINKS.subordinatedFinancialAgreement}
          className="loan_group_detail_page__agreement_link"
        >
          Download Subordinated Financing Agreement (pdf)
        </MyLink>
        <MyLink
          isExternal
          href={APP_LINKS.subordinatedGuaranteeLetter}
          className="loan_group_detail_page__agreement_link"
        >
          Download Guarantee Letter (pdf)
        </MyLink>
      </section>
    ),
  }),
};
