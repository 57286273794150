export const GroupAExample = {
  total_interest: 46.68,
  total_success_charge: 40,
  total_principal: 1000,
  total: 1086.68,
  list: [
    {
      date: '2022-02-05',
      interest: 5.1,
      success_charge: 0,
      principal: 0,
      total: 5.1,
    },
    {
      date: '2022-03-05',
      interest: 4.6,
      success_charge: 0,
      principal: 0,
      total: 4.6,
    },
    {
      date: '2022-04-05',
      interest: 5.1,
      success_charge: 0,
      principal: 0,
      total: 5.1,
    },
    {
      date: '2022-05-05',
      interest: 4.93,
      success_charge: 0,
      principal: 0,
      total: 4.93,
    },
    {
      date: '2022-06-05',
      interest: 5.1,
      success_charge: 0,
      principal: 0,
      total: 5.1,
    },
    {
      date: '2022-07-05',
      interest: 4.93,
      success_charge: 0,
      principal: 0,
      total: 4.93,
    },
    {
      date: '2022-08-05',
      interest: 5.1,
      success_charge: 7.06,
      principal: 176.47,
      total: 188.63,
    },
    {
      date: '2022-09-05',
      interest: 4.2,
      success_charge: 7.06,
      principal: 176.47,
      total: 187.73,
    },
    {
      date: '2022-10-05',
      interest: 3.19,
      success_charge: 7.06,
      principal: 176.47,
      total: 186.72,
    },
    {
      date: '2022-11-05',
      interest: 2.4,
      success_charge: 7.06,
      principal: 176.47,
      total: 185.93,
    },
    {
      date: '2022-12-05',
      interest: 1.45,
      success_charge: 7.06,
      principal: 176.47,
      total: 184.98,
    },
    {
      date: '2023-01-05',
      interest: 0.6,
      success_charge: 4.7,
      principal: 117.65,
      total: 122.95,
    },
  ],
};

export const GroupBExample = {
  total_interest: 60,
  total_success_charge: 173.33,
  total_principal: 1000,
  total: 1233.33,
  list: [
    {
      date: '2022-02-05',
      interest: 5.1,
      success_charge: 0,
      principal: 0,
      total: 5.1,
    },
    {
      date: '2022-03-05',
      interest: 4.6,
      success_charge: 0,
      principal: 0,
      total: 4.6,
    },
    {
      date: '2022-04-05',
      interest: 5.1,
      success_charge: 0,
      principal: 0,
      total: 5.1,
    },
    {
      date: '2022-05-05',
      interest: 4.93,
      success_charge: 0,
      principal: 0,
      total: 4.93,
    },
    {
      date: '2022-06-05',
      interest: 5.1,
      success_charge: 0,
      principal: 0,
      total: 5.1,
    },
    {
      date: '2022-07-05',
      interest: 4.93,
      success_charge: 0,
      principal: 0,
      total: 4.93,
    },
    {
      date: '2022-08-05',
      interest: 5.1,
      success_charge: 20,
      principal: 0,
      total: 25.1,
    },
    {
      date: '2022-09-05',
      interest: 5.1,
      success_charge: 20,
      principal: 0,
      total: 25.1,
    },
    {
      date: '2022-10-05',
      interest: 4.93,
      success_charge: 20,
      principal: 0,
      total: 24.93,
    },
    {
      date: '2022-11-05',
      interest: 5.1,
      success_charge: 20,
      principal: 0,
      total: 25.1,
    },
    {
      date: '2022-12-05',
      interest: 4.93,
      success_charge: 20,
      principal: 0,
      total: 24.93,
    },
    {
      date: '2023-01-05',
      interest: 5.1,
      success_charge: 73.33,
      principal: 1000,
      total: 1078.43,
    },
  ],
};
