import React from 'react';
import { connect } from 'react-redux';
import WithdrawForm from '../../components/Forms/WithdrawForm';
import {
  createWithdrawRequest,
  getWithdrawInfo,
} from '../../store/actions/fundActions';
import Preloader from '../../components/Preloader';
import { getBalance } from '../../store/actions/dashboardActions';
import { RoundLink } from '../../components/UI/RoundLink';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import Analytics from '../../analytics';
import { USER_STATUSES } from '../../helpers/constants';
import { isReadyForVerification } from '../../helpers/user';
import { NOTIFICATIONS } from '../../components/Notification/list';
import NotificationBlock from '../../containers/NotificationBlock';
import { APP_LINKS } from '../../helpers/links';
import WithdrawHistory from '../../containers/WithdrawHistory';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import Decimal from 'decimal.js-light';
import './index.scss';

class FundsWithdrawPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyUpdater: new Date().toISOString(), // Used to force update Withdrawals history
    };
  }

  componentDidMount() {
    this.props.getWithdrawInfo();
  }

  onSubmit = (values, formikBag) => {
    const payload = {
      account: values.account.id,
      amount: new Decimal(values.amount).toNumber(),
    };

    this.props.createWithdrawRequest(payload).then(res => {
      if (res && res.success) {
        Analytics.withdraw();
        this.props.getBalance();
        this.setState(prevProps => ({
          ...prevProps,
          historyUpdater: new Date().toISOString(),
        })); // Force update Withdrawals History
        formikBag.resetForm();
        formikBag.setValues({ account: values.account, amount: '' });
      }
    });
  };

  render() {
    const { data, loading } = this.props.withdrawInfo;
    const { data: profile } = this.props.profile;
    const notifications = [];

    if (!profile || loading) {
      return (
        <div className="funds_withdraw_page">
          <Preloader
            className="funds_withdraw_page__preloader"
            style={{ marginTop: '20px', height: '40vh' }}
          />
        </div>
      );
    }

    if (profile?.status === USER_STATUSES.suspended) {
      return (
        <div className="funds_withdraw_page">
          <SuspendResidenceWarning />
        </div>
      );
    }

    if (profile.status === USER_STATUSES.new) {
      isReadyForVerification(profile)
        ? notifications.push(
            NOTIFICATIONS.checking_transactions_available_to_verified_users()
          )
        : notifications.push(
            NOTIFICATIONS.transactions_available_to_verified_users()
          );
    }

    const hasExternalAcc =
      data && data.external_accounts && !!data.external_accounts.length;

    return (
      <div className="funds_withdraw_page">
        <NotificationBlock
          className="funds_withdraw_page__notifications"
          notifications={notifications}
        />

        {profile.status === USER_STATUSES.verified && (
          <>
            {hasExternalAcc ? (
              <WithdrawForm
                balance={data && data.cash}
                accounts={data && data.external_accounts}
                onSubmit={this.onSubmit}
              >
                <WithdrawHistory
                  resetTime={this.state.historyUpdater}
                  className="funds_withdraw_page__history"
                />
              </WithdrawForm>
            ) : (
              <div className="funds_withdraw_page__empty">
                <div className="funds_withdraw_page__empty-title f-20 f-400">
                  You have no available bank accounts.
                </div>
                <RoundLink
                  label="Add a new bank account"
                  path={APP_LINKS.bankAccountsCreate}
                  className="funds_withdraw_page__empty-link"
                />
              </div>
            )}
          </>
        )}
        <MiniFaqs source={MINIFAQ_SOURCES.withdraw} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  withdrawInfo: state.fundStore.withdrawInfo,
  profile: state.profileStore.profile,
});

const mapDispatchToProps = dispatch => ({
  getWithdrawInfo: () => dispatch(getWithdrawInfo()),
  createWithdrawRequest: payload => dispatch(createWithdrawRequest(payload)),
  getBalance: () => dispatch(getBalance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundsWithdrawPage);
