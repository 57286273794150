import React from 'react';
import { connect } from 'react-redux';
import NotificationBlock from '../../NotificationBlock';
import {
  getFundraisingStats,
  setInvestmentConfirmation,
  updateInvestingStatus,
} from '../../../store/actions/investActions';
import { STRATEGIES } from '../InvSelectStrategy';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import PriorityFoundersGroupedList from '../InvAllocateFunds/priority';
import withScroll from '../../../hoc/withScroll';
import Dropdown from '../../../components/Dropdown';
import InvestProgressBar from '../../../components/InvestProgressBar';
import Analytics from '../../../analytics';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import InviteFriends from '../../../components/InviteFriends';
import InvestingSummary from '../../InvestingSummary';
import { WriteReview } from '../../../components/WriteReview';
import { isVerifiedPhoneNumber } from '../../../helpers/user';
import {
  isExpiredResetDate,
  resetInvestingExpireDate,
} from '../../../helpers/date';
import './index.scss';

class InvReady extends React.Component {
  componentDidMount() {
    this.props.getFundraisingStats();
  }

  render() {
    const {
      status,
      onReset,
      fundraisingStats,
      investmentConfirmation,
      setInvestmentConfirmation,
      setStep,
      profile,
    } = this.props;
    const hasReachedGoal = status.total_remaining_amount <= 0;
    const notifications = [];

    notifications.push(
      NOTIFICATIONS.results_will_be_processed(status.end_date)
    );

    !isVerifiedPhoneNumber(profile) &&
      notifications.push(NOTIFICATIONS.verify_phone_number_to_join_round());

    return (
      <div className="inv-ready">
        <h1 className="f-42 f-500">Your investment settings saved</h1>
        <NotificationBlock
          className="inv-ready__notifications"
          notifications={notifications}
        />
        <InvestProgressBar stats={fundraisingStats} />
        <h2 className="inv-ready__summary-header f-26 f-500">
          Your investment settings
        </h2>
        <InvestingSummary
          isReadyStep
          status={status}
          onStrategyEdit={() => {
            Analytics.wizardStep3SettingsEditStrategy();
            setInvestmentConfirmation({
              ...investmentConfirmation,
              hasConfirmed: false,
            });
            setStep(1);
          }}
          onLoansEdit={() => {
            Analytics.wizardStep3SettingsEditAllocation();
            setInvestmentConfirmation({
              ...investmentConfirmation,
              hasConfirmed: false,
            });
            setStep(2);
          }}
          className="inv-ready__summary"
        />

        <InviteFriends analyticSource="setsaved" />

        <WriteReview className="inv-ready__trustpilot" />

        <div className="inv-ready__founders">
          {status.option === STRATEGIES.standard && (
            <Dropdown title="Consumer Goods Brands batch">
              <ParticipatingStartupsList />
            </Dropdown>
          )}
          {status.option === STRATEGIES.priority && (
            <PriorityFoundersGroupedList />
          )}
        </div>

        {!hasReachedGoal &&
          !isExpiredResetDate(status.editable_until) &&
          status.committed_amount === 0 && (
            <div className="inv-ready__reset">
              <div>
                <h2 className="f-26 f-500">Changed your mind?</h2>
                <p className="f-16 f-400">
                  You can decide not to participate in the round before{' '}
                  {resetInvestingExpireDate(
                    status.editable_until,
                    status.end_date
                  )}
                </p>
              </div>
              <button
                type="button"
                className="f-16 f-400 hov"
                onClick={onReset}
              >
                Reset investing
              </button>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fundraisingStats: state.investStore.fundraisingStats,
  investmentConfirmation: state.investStore.investmentConfirmation,
  profile: state.profileStore.profile.data,
});

const mapDispatchToProps = dispatch => ({
  updateInvestingStatus: payload => dispatch(updateInvestingStatus(payload)),
  getFundraisingStats: () => dispatch(getFundraisingStats()),
  setInvestmentConfirmation: data => dispatch(setInvestmentConfirmation(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(InvReady));
