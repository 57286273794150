export const GET_HELP =  {
  REQUEST: 'GET_HELP_REQUEST',
  SUCCESS: 'GET_HELP_SUCCESS',
  FAILURE: 'GET_HELP_FAILURE',
}

export const GET_MINI_FAQ =  {
  REQUEST: 'GET_MINI_FAQ_REQUEST',
  SUCCESS: 'GET_MINI_FAQ_SUCCESS',
  FAILURE: 'GET_MINI_FAQ_FAILURE',
}

export const SET_FAQ = 'SET_FAQ';