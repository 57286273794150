import React from 'react';
import Select from 'react-select';

export const ReactSelectField = ({
  label,
  selected,
  onChange,
  options,
  ...props
}) => (
  <Select
    defaultValue={selected}
    onChange={onChange}
    options={options}
    {...props}
  />
);
