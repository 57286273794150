import React from 'react';
import { Link } from 'react-router-dom';
import { SmallHeartIcon } from '../UI/Icons';
import GroupedAvatars from '../UI/GroupedAvatars';
import { getFullname, getNameInitials } from '../../helpers/utils';
import './index.scss';

const existText =
  'Well done! You have helped founders receive funds for their businesses!';
const notExistText =
  'No founder has received funds from you yet :( Participate in the next round!';

const HappyFounders = ({ founders }) => {
  const count = (founders && founders.total) || 0;
  const images = founders
    ? founders.founders.map(f => ({
        id: f.id,
        src: f.photo && f.photo.file,
        alt: getFullname(f.first_name, f.last_name),
        nameInitials: getNameInitials(f.first_name, f.last_name),
      }))
    : [];

  return (
    <div className="happy-founders">
      <p className="f-16">Happy founders</p>
      <Link to="/loans" className="happy-founders__people f-500">
        {founders ? founders.total : 0} ppl. <SmallHeartIcon />
      </Link>
      <p className="happy-founders__desc f-16">
        {founders && founders.total ? existText : notExistText}
      </p>
      {founders && !!founders.founders.length && (
        <Link to="/loans">
          <GroupedAvatars
            data={images}
            limit={5}
            count={count}
            showCounter={5}
            size={50}
          />
        </Link>
      )}
    </div>
  );
};

export default HappyFounders;
