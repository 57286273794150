import React from 'react';
import BackButton from '../../components/BackButton';
import ThemeDetail from '../../containers/ThemeDetail';
import LoanersList from '../../containers/LoanersList';
import FoundersList from '../../containers/FoundersList';
import {modalCustomStyles} from '../../assets/styles';
import FounderPopup from '../../containers/FounderPopup';
import ReactModal from 'react-modal';
import {connect} from 'react-redux';
import {getThemeDetail, toggleFavouriteThemes} from '../../store/actions/themeActions';
import Preloader from '../../components/Preloader';
import {getFounderInfo} from '../../store/actions/commonActions';

class ThemeDetailPage extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getThemeDetail(id);
  }

  state = {
    show: false
  }

  setShow = show => this.setState({ show });

  getFounder = user => {
    this.setShow(true)
    this.props.getFounderInfo(user.id);
  }

  render() {
    const { history, themeDetail, toggleFavouriteThemes, founderInfo } = this.props;
    const { data, loading } = themeDetail;

    if (loading) {
      return <Preloader className="container-inner" />
    }

    if (!data) {
      return null;
    }

    return (
      <div className="container-inner">
        <div style={{margin: '20px 0'}}>
          <BackButton onClick={() => history.push('/themes')} />
          <ThemeDetail data={data} toggleFavouriteThemes={toggleFavouriteThemes} />
          <LoanersList loaners={data.loaners} getUser={this.getFounder} />
          <FoundersList founders={data.founders} getUser={this.getFounder} />

          <ReactModal
            isOpen={this.state.show}
            onAfterOpen={() => console.log('After open')}
            onRequestClose={() => this.setShow(false)}
            style={modalCustomStyles}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            contentLabel="Example Modal"
          >
            <FounderPopup
              founder={founderInfo}
              history={history}
              onClose={() => this.setShow(false)} />
          </ReactModal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  founderInfo: state.commonStore.founderInfo,
  themeDetail: state.themeStore.themeDetail,
})

const mapDispatchToProps = dispatch => ({
  getThemeDetail: id => dispatch(getThemeDetail(id)),
  getFounderInfo: id => dispatch(getFounderInfo(id)),
  toggleFavouriteThemes: (themes, action, params) => dispatch(toggleFavouriteThemes(themes, action, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThemeDetailPage);