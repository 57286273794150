import React from 'react';
import classnames from 'classnames';
import { WarningIcon, SuccessIcon } from '../Icons';
import './index.scss';

const WarningBlock = ({
  className,
  children,
  withBorder,
  isSuccess,
  ...props
}) => (
  <div
    className={classnames('warning_block', withBorder && 'border', className)}
    {...props}
  >
    {isSuccess ? (
      <SuccessIcon
        className="warning_block__icon"
        style={{ minWidth: '20px' }}
      />
    ) : (
      <WarningIcon
        className="warning_block__icon"
        style={{ minWidth: '20px' }}
      />
    )}
    <div className="warning_block__content">{children}</div>
  </div>
);

export default WarningBlock;
