import React, { useRef } from 'react';
import CashDetailBox from '../CashDetailBox';
import { calculateCash, prettyMoneyHybrid } from '../../helpers/utils';
import { SpecTriangleIcon } from '../UI/Icons';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import './index.scss';

const BalanceInfo = ({ balance }) => {
  const ref = useRef();
  const [show, toggle] = React.useState(false);
  useOutsideClick(ref, () => toggle(false), show);

  const cash = calculateCash(balance);

  return (
    <div className="balance-info">
      <p className="f-16">Your total balance</p>
      <p className="balance-info__balance f-500 f-36 tl">
        {prettyMoneyHybrid(balance?.total)}
      </p>
      <p className="balance-info__label f-16">Invested in loans</p>
      <p className="balance-info__white f-500 f-26 tl">
        {prettyMoneyHybrid(balance?.invested)}
      </p>
      <p className="balance-info__label f-16">Cash</p>
      <div
        className="balance-info__cash"
        ref={ref}
        onClick={() => toggle(!show)}
      >
        <div className="balance-info__cash_inner">
          <p
            className="balance-info__white f-500 f-26 tl"
            style={{ cursor: 'pointer' }}
          >
            {prettyMoneyHybrid(cash)}
          </p>
          {show && (
            <div
              className="balance-info__popup"
              onClick={e => e.stopPropagation()}
            >
              <SpecTriangleIcon className="balance-info__popup_triangle" />
              <CashDetailBox balance={balance} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BalanceInfo;
