import React, { useEffect, useState } from 'react';
import * as classnames from 'classnames';
import { prettyFloatMoney } from '../../../../helpers/utils';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import { RoundButton } from '../../../UI/RoundButton';
import { Dropdown } from '../../../UI/Dropdown';
import Analytics from '../../../../analytics';
import { BonusAmount } from '../../../BonusAmount';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/icon_arrow_line.svg';
import { ScheduleTable } from '../../../LoanSchedule/table';
import {
  calculateGroupA,
  calculateGroupB,
} from '../../../../helpers/calculator';
import s from './index.module.scss';

const CONSERVATIVE = 5;
const MODERATE = 9;

export const EarningResult = ({
  formikBag,
  onJoin,
  joinDisabled,
  disableBonusBanner,
}) => {
  const { loan } = formikBag.values;
  const amount = formikBag.values.amount || 10;
  const [data, setData] = useState(null);
  const [earning, setEarning] = useState(0);

  useEffect(() => {
    Analytics.investingJoinCheckCalculator();
    if (loan === LOAN_GROUPS.conservative.id) {
      setData(
        calculateGroupA(amount, {
          useMonthPrefix: true,
        })
      );
      setEarning((Number(amount) * CONSERVATIVE) / 100);
    } else {
      setData(
        calculateGroupB(amount, {
          useMonthPrefix: true,
        })
      );
      setEarning((Number(amount) * MODERATE) / 100);
    }
  }, [amount, loan]);

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div className="f-18-24 f-400">My earnings</div>
        <div className={s.title_wrap}>
          <span className={classnames(s.title, 'f-42 f-500')}>
            <span>{prettyFloatMoney(earning)}</span>
            <span className={s.title_end}> for 6 months</span>
          </span>
          <ArrowIcon />
        </div>
        {loan === LOAN_GROUPS.conservative.id ? (
          <ul className={s.list}>
            <li>Get your earnings right after the end of the round</li>
            <li>Triple-secured downside protection</li>
            <li>No fees on investor capital</li>
          </ul>
        ) : (
          <ul className={s.list}>
            <li>
              Get your earnings and loan principal in full in the 6th month
            </li>
            <li>Single-secured downside protection</li>
            <li>No fees on investor capital</li>
          </ul>
        )}
        {!disableBonusBanner && <BonusAmount amount={amount} />}
      </div>
      <Dropdown
        render={({ open, setOpen }) => (
          <>
            <button
              onClick={() => {
                !open &&
                  loan === LOAN_GROUPS.conservative.id &&
                  Analytics.investingJoinRepaymentScheduleA();
                !open &&
                  loan === LOAN_GROUPS.moderate.id &&
                  Analytics.investingJoinRepaymentScheduleB();
                setOpen(!open);
              }}
              className={s.dropdown}
            >
              Check the loan repayment schedule
              <svg
                style={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0)',
                  minWidth: '8px',
                }}
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 6L0.535898 0.75H7.4641L4 6Z" fill="black" />
              </svg>
            </button>

            <div className={classnames(s.schedule, open && 'open')}>
              <div className={s.schedule_inner}>
                <div>{data?.group} loan repayment schedule</div>
                <ScheduleTable data={data} />
              </div>
            </div>
          </>
        )}
      />

      {onJoin && (
        <div className={s.buttons}>
          <RoundButton
            label={() => <span>Join the round</span>}
            disabled={joinDisabled}
            fillBackground
            onClick={() => {
              Analytics.investingJoinCalculator();
              onJoin();
            }}
            className={s.button}
          />
        </div>
      )}
    </div>
  );
};
