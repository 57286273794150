import {
  GET_BALANCE,
  GET_DASHBOARD_THEMES,
  GET_EARNINGS, GET_FR_DATES,
  GET_HAPPY_FOUNDERS,
  GET_LOAN_GROUPS
} from '../actions/actionTypes';
import {GET_NOTIFICATION_INFO} from '../types/dashboardTypes';
import {METADATA} from '../../helpers/metadata';

const initialState = {
  balance: {total: 0, invested: 0, available: 0, on_hold: 0},
  fundraisingDates: null,
  earnings: null,
  happyFounders: null,
  themes: { ...METADATA.default, data: null },
  loanGroups: { ...METADATA.default, data: null },
  notificationInfo: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE:
      return { ...state, balance: action.balance };
    case GET_EARNINGS:
      return { ...state, earnings: action.earnings };
    case GET_FR_DATES:
      return { ...state, fundraisingDates: action.payload };
    case GET_HAPPY_FOUNDERS:
      return { ...state, happyFounders: action.founders };
    case GET_DASHBOARD_THEMES.REQUEST:
      return { ...state, themes: { ...state.themes, ...METADATA.request }};
    case GET_DASHBOARD_THEMES.SUCCESS:
      return { ...state, themes: { ...state.themes, ...METADATA.success, data: action.payload }};
    case GET_DASHBOARD_THEMES.FAILURE:
      return { ...state, themes: { ...state.themes, ...METADATA.error, error: action.error }};
    case GET_LOAN_GROUPS.REQUEST:
      return { ...state, loanGroups: { ...state.loanGroups, ...METADATA.request }};
    case GET_LOAN_GROUPS.SUCCESS:
      return { ...state, loanGroups: { ...state.loanGroups, ...METADATA.success, data: action.payload }};
    case GET_LOAN_GROUPS.FAILURE:
      return { ...state, loanGroups: { ...state.loanGroups, ...METADATA.error, error: action.error }};
    case GET_NOTIFICATION_INFO.REQUEST:
      return { ...state, notificationInfo: null };
    case GET_NOTIFICATION_INFO.SUCCESS:
      return { ...state, notificationInfo: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;