import React from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';
import qs from 'qs';
import { RoundButton } from '../../../components/UI/RoundButton';
import { RoundLink } from '../../../components/UI/RoundLink';
import {
  calculateCash,
  prettyFloatMoney,
  prettyMoney,
} from '../../../helpers/utils';
import { setInvestingStatus } from '../../../store/actions/investActions';
import { STRATEGIES } from '../InvSelectStrategy';
import PriorityFoundersGroupedList from './priority';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import withScroll from '../../../hoc/withScroll';
import Dropdown from '../../../components/Dropdown';
import { setModals } from '../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../../components/ModalProvider';
import {
  ACCOUNT_TYPES,
  LOAN_GROUPS,
  USER_STATUSES,
} from '../../../helpers/constants';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import Analytics from '../../../analytics';
import WarningBlock from '../../../components/UI/WarningBlock';
import { getLoanTerms } from '../../../store/actions/loanActions';
import PaymentModule from '../../PaymentModule';
import { getStripePaymentInfo } from '../../../store/services/stripeServices';
import s from '../../PaymentModule/success/index.module.scss';
import { BonusBanner } from '../../../components/Banners/bonus';
import { SuspendResidenceWarning } from '../../../components/Warnings/SuspendResidenceWarning';
import { getAllocateInfo } from '../../../store/actions/fundActions';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
  isSubmittedVeriff,
} from '../../../helpers/user';
import './index.scss';

class InvAllocateFunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: 0,
    };
  }

  componentDidMount() {
    this.props.getLoanTerms();
    this.props.getAllocateInfo();

    const { status, session_id } = qs.parse(location.search.replace('?', ''));
    if (status === 'success' && session_id) {
      getStripePaymentInfo(session_id).then(
        res =>
          res &&
          res.success &&
          this.setState(prevState => ({
            ...prevState,
            payment: res.data.net_amount,
          }))
      );
    }
  }

  onContinue = () => {
    Analytics.wizardStep2AllocateCash();
    this.props.setStep(3);
  };

  render() {
    const { payment } = this.state;
    const {
      status,
      loanTerms,
      balance,
      localAccounts,
      setStep,
      disabled,
      profile,
      veriffStatusCode,
      allocateInfo,
    } = this.props;
    const { bonus_amount, min_investment_amount } = status;
    const totalBalance = calculateCash(balance);
    const minInvestAmount =
      bonus_amount > min_investment_amount
        ? bonus_amount
        : min_investment_amount;

    const loanGroups =
      loanTerms.data &&
      Object.keys(loanTerms.data)
        .filter(key => loanTerms.data[key].is_active)
        .map(key => loanTerms.data[key]);

    const totalAllocated = localAccounts.reduce((total, account) => {
      if (account.type === ACCOUNT_TYPES.loan) {
        total += account.amount;
      }
      return total;
    }, 0);

    return (
      <div className="inv-allocate-funds">
        {totalBalance < minInvestAmount ? (
          <>
            <h1 className="f-42 f-500">Add cash to your Scramble account</h1>
            <div className={s.warnings}>
              {!!payment && (
                <WarningBlock withBorder className={s.message} isSuccess>
                  <div className="f-16 f-400">
                    Your payment of{' '}
                    <span className="f-500">{prettyFloatMoney(payment)}</span>{' '}
                    was successfully transferred to your Scramble account.
                  </div>
                </WarningBlock>
              )}

              {profile?.status === USER_STATUSES.suspended ? (
                <SuspendResidenceWarning className="inv-allocate-funds__warning_header" />
              ) : (
                profile?.status !== USER_STATUSES.verified && (
                  <WarningBlock
                    className="inv-allocate-funds__warning_header"
                    withBorder
                  >
                    {hasRequiredPersonalSectionFields(profile) &&
                    hasRequiredProfileSectionFields(profile) &&
                    isSubmittedVeriff(veriffStatusCode) ? (
                      <div className="f-16 f-400">
                        Investing is available only to verified investors. Your
                        profile information is being checked, it may take up to
                        two business days.
                      </div>
                    ) : (
                      <div className="f-16 f-400">
                        Complete the verification process to start investing.{' '}
                        <MyLink href={APP_LINKS.dashboard} className="c-grey">
                          Complete verification&nbsp;→
                        </MyLink>
                      </div>
                    )}
                  </WarningBlock>
                )
              )}

              {totalAllocated < minInvestAmount && (
                <WarningBlock
                  withBorder
                  className="inv-allocate-funds__warning_header"
                >
                  {min_investment_amount > bonus_amount ? (
                    <div className="f-16 f-500">
                      {profile?.country_code === 'US' &&
                      min_investment_amount === 1000
                        ? `The minimum investment amount for US customers is €1'000, due to EU and US regulatory requirements that impose additional reporting obligations to the relevant authorities.`
                        : `The minimum investment amount is ${prettyMoney(
                            min_investment_amount
                          )}`}
                    </div>
                  ) : (
                    <div className="f-16 f-500">
                      You can only invest the whole bonus amount of{' '}
                      {prettyMoney(bonus_amount)}
                    </div>
                  )}
                </WarningBlock>
              )}
            </div>

            <div className="inv-allocate-funds__cash">
              <p className="f-16">Available cash</p>
              <div className="inv-allocate-funds__cash-group">
                <p className="f-42 f-500">
                  {prettyFloatMoney(
                    localAccounts.find(acc => acc.type === ACCOUNT_TYPES.cash)
                      ?.amount
                  )}
                </p>
              </div>
            </div>

            <PaymentModule
              className="inv-allocate-funds__payment"
              redirectLocation={APP_LINKS.investing}
              onBack={() => setStep(1)}
            >
              <div
                className="inv-allocate-funds__payment_founders"
                id="founders"
              >
                {status.option === STRATEGIES.standard && (
                  <Dropdown title="Consumer Goods Brands batch">
                    <ParticipatingStartupsList />
                  </Dropdown>
                )}

                {status.option === STRATEGIES.priority && (
                  <PriorityFoundersGroupedList />
                )}
              </div>
            </PaymentModule>
          </>
        ) : (
          <>
            <h1 className="f-42 f-500">Allocate your cash among loan groups</h1>
            {!status?.has_previously_invested && (
              <BonusBanner className={s.banner} />
            )}
            <div className={s.warnings}>
              {!!payment && (
                <WarningBlock withBorder className={s.message} isSuccess>
                  <div className="f-16 f-400">
                    Your payment of{' '}
                    <span className="f-500">{prettyFloatMoney(payment)}</span>{' '}
                    was successfully transferred to your Scramble account.
                  </div>
                </WarningBlock>
              )}

              {profile?.status === USER_STATUSES.suspended ? (
                <SuspendResidenceWarning className="inv-allocate-funds__warning_veriff" />
              ) : (
                profile?.status !== USER_STATUSES.verified && (
                  <WarningBlock
                    className="inv-allocate-funds__warning_veriff"
                    withBorder
                  >
                    {hasRequiredPersonalSectionFields(profile) &&
                    hasRequiredProfileSectionFields(profile) &&
                    isSubmittedVeriff(veriffStatusCode) ? (
                      <div className="f-16 f-400">
                        Investing is available only to verified investors. Your
                        profile information is being checked, it may take up to
                        two business days.
                      </div>
                    ) : (
                      <div className="f-16 f-400">
                        Complete the verification process to start investing.{' '}
                        <MyLink href={APP_LINKS.dashboard} className="c-grey">
                          Complete verification&nbsp;→
                        </MyLink>
                      </div>
                    )}
                  </WarningBlock>
                )
              )}
            </div>

            <div className="inv-allocate-funds__allocation">
              <div className="inv-allocate-funds__cash">
                <p className="f-16">Available cash</p>
                <div className="inv-allocate-funds__cash-group">
                  <p className="f-42 f-500">
                    {prettyFloatMoney(
                      localAccounts.find(acc => acc.type === ACCOUNT_TYPES.cash)
                        ?.amount
                    )}
                  </p>
                  <RoundLink
                    path={APP_LINKS.addFunds}
                    label="Add cash"
                    className="inv-allocate-funds__cash-button"
                  />
                </div>
              </div>
            </div>

            <div className="inv-allocate-funds__info_desc f-16 f-400">
              All loan groups available at the same time, allocate your capital
              based on your risk appetite.
            </div>

            <div className="inv-allocate-funds__groups">
              {loanGroups?.map(group => {
                const disabled = !group.is_active;
                const amount = localAccounts.find(
                  acc => acc.group === group.id
                )?.amount;

                const hasRaised =
                  !!allocateInfo.data &&
                  !allocateInfo.data?.accounts.find(
                    acc => acc.group === group.id
                  )?.remaining_amount;

                const selfAmount =
                  allocateInfo.data?.accounts.find(
                    acc => acc.group === group.id
                  )?.amount || 0;

                return (
                  <div
                    key={group.id}
                    className={classnames('inv-group', group.id)}
                  >
                    <h5 className="f-26 f-500">{group.title}</h5>

                    {disabled ? (
                      <div className="inv-group__unavailable f-16 f-400">
                        {group.title} is temporarily unavailable
                      </div>
                    ) : (
                      <div className="inv-group__container">
                        <div className="inv-group__investing-amount">
                          <div className="f-16 f-400 c-grey tl">
                            Allocated for investing
                          </div>
                          <div className="f-26 f-500 c-black tl">
                            {prettyFloatMoney(amount)}
                          </div>
                        </div>

                        {hasRaised && !selfAmount && (
                          <div className="inv-group__investing-full f-500">
                            The full amount in {group.title} has been raised
                          </div>
                        )}

                        <RoundButton
                          label="Allocate cash"
                          fullWidth
                          fillBackground
                          className="inv-group__investing-btn hov"
                          disabled={hasRaised && !selfAmount}
                          onClick={() =>
                            this.props.setModals({
                              type: MODAL_TYPES.allocate_fund,
                              selected: group.id,
                            })
                          }
                        />

                        <div className="inv-group__investing-disclaimer f-16 f-400">
                          {LOAN_GROUPS[group.id].disclaimer}
                        </div>

                        <div className="inv-group__details">
                          <div className="inv-group__block">
                            <div className="f-12 f-400">
                              <span>Investor earnings</span>
                            </div>
                            <p className="f-16 f-400 tl">
                              {group.flat_fee_six_month ||
                                LOAN_GROUPS[group.id].flat_fee_six_month}{' '}
                              for 6 month
                            </p>
                          </div>

                          <div className="inv-group__block">
                            <div className="f-12 f-400">Term</div>
                            <p className="f-16 f-400 tl">
                              {group.info_term || '6 months'}
                            </p>
                          </div>

                          <div className="inv-group__block">
                            <div className="f-12 f-400">
                              <span>Net annual return</span>
                            </div>
                            <p className="f-16 f-400 tl">
                              {group.investor_annual_rate ||
                                LOAN_GROUPS[group.id].target_annual_return}
                            </p>
                          </div>

                          <div className="inv-group__block">
                            <div className="f-12 f-400">Repayments</div>
                            <p className="f-16 f-400 tl">
                              {group.repayment_type ||
                                LOAN_GROUPS[group.id].repayment_type}
                            </p>
                          </div>

                          <div className="inv-group__block">
                            <div className="f-12 f-400">
                              Downside protection
                            </div>
                            <p className="f-16 f-400 tl">
                              {group.downside_protection ||
                                LOAN_GROUPS[group.id].downside_protection}
                            </p>
                          </div>
                        </div>

                        <MyLink
                          href={LOAN_GROUPS[group.id].detailLink}
                          className="inv-group__details_link f-16 f-400"
                        >
                          Learn more →
                        </MyLink>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="inv-allocate-funds__earmarked">
              <p className="f-16">Total allocated for investing</p>
              <p className="f-42 f-500">{prettyFloatMoney(totalAllocated)}</p>
            </div>

            <div className="inv-allocate-funds__founders" id="founders">
              {status.option === STRATEGIES.standard && (
                <Dropdown title="Consumer Goods Brands">
                  <ParticipatingStartupsList />
                </Dropdown>
              )}

              {status.option === STRATEGIES.priority && (
                <PriorityFoundersGroupedList />
              )}
            </div>

            <div className="inv-allocate-funds__buttons_block">
              {totalAllocated < minInvestAmount && (
                <WarningBlock className="inv-allocate-funds__warning">
                  {min_investment_amount > bonus_amount ? (
                    <div className="f-16 f-500">
                      {profile?.country_code === 'US' &&
                      min_investment_amount === 1000
                        ? `The minimum investment amount for US customers is €1'000, due to EU and US regulatory requirements that impose additional reporting obligations to the relevant authorities.`
                        : `The minimum investment amount is ${prettyMoney(
                            min_investment_amount
                          )}`}
                    </div>
                  ) : (
                    <div className="f-16 f-500">
                      You can only invest the whole bonus amount of{' '}
                      {prettyMoney(bonus_amount)}
                    </div>
                  )}
                </WarningBlock>
              )}

              <div className="inv-allocate-funds__buttons">
                <RoundButton
                  label="Go back"
                  onClick={() => setStep(1)}
                  className="inv-allocate-funds__buttons-back"
                />
                <RoundButton
                  label="Continue to next step"
                  onClick={this.onContinue}
                  fillBackground
                  fullWidth
                  disabled={disabled || totalAllocated < minInvestAmount}
                  className="inv-allocate-funds__buttons-continue"
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  balance: state.dashboardStore.balance,
  localAccounts: state.fundStore.localAccounts,
  loanTerms: state.loanStore.loanTerms,
  profile: state.profileStore.profile.data,
  veriffStatusCode: state.profileStore.veriffStatusCode,
  allocateInfo: state.fundStore.allocateInfo,
});

const mapDispatchToProps = dispatch => ({
  setInvestingStatus: status => dispatch(setInvestingStatus(status)),
  setModals: modal => dispatch(setModals(modal)),
  getLoanTerms: () => dispatch(getLoanTerms()),
  getAllocateInfo: () => dispatch(getAllocateInfo()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(InvAllocateFunds));
