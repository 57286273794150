import axios from '../../axios-api';
import {getMessage} from '../../helpers/utils';
import Pathes from '../../helpers/pathes';
import {GET_HELP, GET_MINI_FAQ, SET_FAQ} from '../types/helpTypes';

const getHelpRequest = () => ({ type: GET_HELP.REQUEST });
const getHelpSuccess = payload => ({type: GET_HELP.SUCCESS, payload});
const getHelpFailure = error => ({type: GET_HELP.FAILURE, error});

const getMiniFaqSuccess = payload => ({type: GET_MINI_FAQ.SUCCESS, payload});

export const getHelp = () => {
  return dispatch => {
    dispatch(getHelpRequest());
    return axios.get(Pathes.Help.all).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          // Below we are adding extra key "slug_path" as identifier path to the question
          const reformattedData = data.map(parent => {
            return {
              ...parent,
              slug_path: [parent.slug],
              children: parent.children.map(child => {
                const slugs = [parent.slug, child.slug];
                return {
                  ...child,
                  faqs: child.faqs.map(faq => ({
                    ...faq,
                    slug_path: [...slugs, faq.slug]
                  })),
                  children: child.children, // here we stop at level 3 :)
                  slug_path: slugs,
                }
              }),
              faqs: parent.faqs.map(faq => ({
                ...faq,
                slug_path: [parent.slug, faq.slug]
              })),
            }
          });

          dispatch(getHelpSuccess(reformattedData));
          return {data: reformattedData, success: true};
        }

        throw new Error(message)
      }).catch(e => {
      dispatch(getHelpFailure(e.message));
    });
  };
}

export const setSelectedFAQ = slugs => {
  return (dispatch, getState) => {
    const currentSlugPath = slugs.join('-');
    const prevSlugPath = getState().helpStore.selectedFAQ.join('-');
    if (currentSlugPath === prevSlugPath) {
      return dispatch({type: SET_FAQ, payload: slugs.slice(0, -1)});
    }
    dispatch({type: SET_FAQ, payload: slugs });
  }
}

export const getMiniFaq = source => {
  return dispatch => {
    return axios.get(Pathes.Help.section(source)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getMiniFaqSuccess(data));
          return {data, success: true};
        }

        throw new Error(message)
      }).catch(e => ({error: e.message, success: false}));
  };
}
