import React from 'react';
import { useSelector } from 'react-redux';
import * as classnames from 'classnames';
import SimpleStepper from '../SimpleStepper';
import './index.scss';

const AFFILIATE_STEPS = [
  {
    label: 'Invite your friends',
    desc: 'Share your unique \n referral link with your friends',
  },
  {
    label: 'They get a starting bonus',
    desc: 'You friends will receive \n €5 when they sign up',
  },
  {
    label: 'Earn your rewards',
    desc: 'Get a reward depending on your \n friend’s invested amount',
  },
];

const AffiliateSteps = ({ className }) => {
  const { innerWidth } = useSelector(state => state.commonStore);
  return (
    <div className={classnames('affiliate_steps', className)}>
      <SimpleStepper
        stepNumber={AFFILIATE_STEPS.length}
        className="affiliate_steps__stepper"
        vertical={innerWidth <= 900}
      />
      <div className="affiliate_steps__steps">
        {AFFILIATE_STEPS.map(item => (
          <div key={item.label} className="affiliate_steps__step">
            <div className="f-18 f-500">{item.label}</div>
            <div className="f-16 f-400">{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AffiliateSteps;
