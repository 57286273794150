import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DATE_FORMAT_YYYY } from '../../../helpers/common';
import { MODAL_TYPES } from '../../ModalProvider';
import { setModals } from '../../../store/actions/commonActions';
import { prettyFloatMoney } from '../../../helpers/utils';
import EmptyImage from '../../../assets/images/startup_mock.svg';
import s from './index.module.scss';

export const StartupVerticalCard = ({ startup, disabled }) => {
  const dispatch = useDispatch();
  const onCompanyClick = () => {
    !disabled &&
      dispatch(
        setModals({
          type: MODAL_TYPES.company_info,
          companyID: startup.id,
        })
      );
  };

  return (
    <div className={s.container} onClick={onCompanyClick}>
      <div className={s.image}>
        <img src={startup.promo_image?.file || EmptyImage} alt={startup.name} />
      </div>
      <div className={s.inner}>
        <div className={s.inner_top}>
          <h3 className={classnames('f-26-32 f-500', s.title)}>
            {startup.name}
          </h3>
          <p className={classnames('f-16 f-400', s.description)}>
            {startup.promo_description}
          </p>
        </div>
        <div className={s.inner_bottom}>
          <div className={s.info}>
            <div className={classnames('f-16 f-400', s.country)}>
              {startup.country_code && (
                <img
                  src={`https://flagcdn.com/${startup.country_code.toLowerCase()}.svg`}
                  alt="flag"
                />
              )}
              {startup.country}
            </div>
            <div className={classnames('f-16 f-400', s.foundation)}>
              Started in{' '}
              {moment(startup.foundation_date).format(DATE_FORMAT_YYYY)}
            </div>
          </div>
        </div>
      </div>

      {startup.received && (
        <div className={classnames(s.invested, 'f-18-24')}>
          You invested:{' '}
          <span className="f-22 f-500">
            {prettyFloatMoney(startup.received)}
          </span>
        </div>
      )}
    </div>
  );
};
