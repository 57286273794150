import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {getMessage} from '../../helpers/utils';
import {GET_COMPANY_INFO} from '../types/companyTypes';

const getCompanyInfoRequest = () => ({ type: GET_COMPANY_INFO.REQUEST });
const getCompanyInfoSuccess = payload => ({ type: GET_COMPANY_INFO.SUCCESS, payload });
const getCompanyInfoFailure = error => ({ type: GET_COMPANY_INFO.FAILURE, error });

export const getCompanyInfo = (companyID) => {
  return dispatch => {
    dispatch(getCompanyInfoRequest());
    return axios.get(Pathes.Company.detail(companyID)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getCompanyInfoSuccess(data));
          return data;
        }
        throw new Error(message)
      }).catch(e => dispatch(getCompanyInfoFailure(e.message)));
  };
}