import React, { Component } from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';
import GroupedAvatars from '../../components/UI/GroupedAvatars';
import {
  getFinalThemes, getParentThemes, setParentTheme, toggleFavouriteThemes,
} from '../../store/actions/themeActions';
import { DEFAULT_ALL, THEME_STATES } from '../../helpers/constants';
import HeartButton from '../../components/HeartButton';
import { getNameInitials, prettyFloatMoney } from '../../helpers/utils';
import Preloader from '../../components/Preloader';
import './index.scss';

class ThemesModule extends Component {
  componentDidMount() {
    this.props.getParentThemes();
    this.props.getFinalThemes();
  }

  state = {
    markLiked: true,
  }

  toggleInvested = () => {
    const { data } = this.props.finalThemes;
    if (data) {
      const allThemes = data.map(theme => theme.id);
      this.props.toggleFavouriteThemes(
        allThemes, this.state.markLiked ? THEME_STATES.unlike : THEME_STATES.like, { updateList: true }
      )
        .finally(() => {
          this.setState({ markLiked: !this.state.markLiked });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTheme !== this.props.selectedTheme && this.props.selectedTheme !== null) {
      this.props.getFinalThemes();
    }
  }

  selectTheme = theme => {
    const { selectedTheme, setParentTheme } = this.props;
    selectedTheme === theme
      ? setParentTheme(null)
      : setParentTheme(theme);
  }

  render() {
    const {
      selectedTheme, history, parentThemes, finalThemes, toggleFavouriteThemes,
    } = this.props;

    return (
      <div className="themes-module">
        <div className="themes-module__category">
          <div
            onClick={() => this.selectTheme(DEFAULT_ALL)}
            className={classnames(
              'themes-module__category-item hov',
              selectedTheme === DEFAULT_ALL && 'themes-module__category-item--selected',
            )}
          >
            <h5 className="f-16 tl">All</h5>
            <p className="themes-module__category-item__value f-500 tl">
              {prettyFloatMoney(parentThemes.data && parentThemes.data.total_invested)}
            </p>
          </div>
          {parentThemes.data && parentThemes.data.categories.map(cat => (
            <div
              onClick={() => this.selectTheme(cat.id)}
              key={cat.id}
              className={classnames(
                'themes-module__category-item hov',
                selectedTheme === cat.id && 'themes-module__category-item--selected',
              )}
            >
              <h5 className="f-16 tl">{cat.title}</h5>
              <p className="themes-module__category-item__value f-500 tl">{prettyFloatMoney(cat.invested)}</p>
            </div>
          ))}
        </div>
        {!selectedTheme && (
          <div className="themes-module__warning">
            You have no investment themes selected. In order to participate in the next fundraising, you must select
            {' '}
            at least one theme.
          </div>
        )}
        {!!selectedTheme && (
          <div className="themes-module__table">
            <div className="themes-module__thead">
              <div className="themes-module__thead-col">Founders</div>
              <div className="themes-module__thead-col">Loans</div>
              {/* <div className="themes-module__thead-col">Discount</div> */}
              <div className="themes-module__thead-col">
                <HeartButton disabled={!this.state.markLiked} onClick={this.toggleInvested} />
              </div>
            </div>

            {finalThemes.loading ? <Preloader className="themes-module__preloader" /> : (
              <div className="themes-module__tbody">
                {finalThemes.data && finalThemes.data.map(theme => (
                  <div className="themes-module__tbody-row" key={theme.id}>
                    <div className="themes-module__tbody-col">
                      <GroupedAvatars
                        data={theme.founders.map(founder => ({
                          id: founder.id,
                          alt: [founder.first_name, founder.last_name].join(' '),
                          src: founder.photo && founder.photo.file,
                          nameInitials: getNameInitials(founder.first_name, founder.last_name),
                        }))}
                      />
                      <h6 onClick={() => history.push(`/themes/${theme.id}`)} className="f-18 f-500">{theme.title}</h6>
                    </div>
                    <div className="themes-module__tbody-col">
                      <span className="f-18 f-500">{prettyFloatMoney(theme.invested)}</span>
                    </div>
                    {/* <div className="themes-module__tbody-col"> */}
                    {/*  <DiscountSet options={[0, 5, 15]} selected={0} /> */}
                    {/* </div> */}
                    <div className="themes-module__tbody-col">
                      <HeartButton disabled={!theme.is_included} onClick={() => toggleFavouriteThemes([theme.id], theme.is_included ? THEME_STATES.unlike : THEME_STATES.like, { updateList: true })} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {/* {!!this.state.selected && <button className="f-24 themes-module__more f-500">Load more</button>} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  parentThemes: state.themeStore.parentThemes,
  finalThemes: state.themeStore.finalThemes,
  selectedTheme: state.themeStore.selectedTheme,
});

const mapDispatchToProps = dispatch => ({
  getParentThemes: () => dispatch(getParentThemes()),
  getFinalThemes: () => dispatch(getFinalThemes()),
  setParentTheme: theme => dispatch(setParentTheme(theme)),
  toggleFavouriteThemes: (themes, action, params) => dispatch(toggleFavouriteThemes(themes, action, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemesModule);
