import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getFullname, prettyMoney, scrollTop } from '../../../helpers/utils';
import { MoneyField } from '../../UI/MoneyField';
import { RoundButton } from '../../UI/RoundButton';
import { BankTransfer } from './BankTransfer';
import Preloader from '../../Preloader';
import { TotalPaymentAmount } from './TotalPaymentAmount';
import { PaymentMethod } from './PaymentMethod';
import { BonusBanner } from '../../Banners/bonus';
import { BonusAmount } from '../../BonusAmount';
import s from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  amount: Yup.number()
    .min(1, 'Minimum amount is 1 EUR')
    .required('Please enter amount'),
  method: Yup.string().required('Required filed'),
});

const AMOUNT_PRESETS = [50, 100, 300, 500];

const PaymentForm = ({
  onSubmit,
  paymentMethods,
  loading,
  initialValues,
  onBack,
  children,
  className,
}) => {
  const { user } = useSelector(state => state.userStore);
  const status = useSelector(state => state.investStore.investingStatus.data);
  const fullname = getFullname(user.first_name, user.last_name);

  const calculateCommission = (amount, method) => {
    if (!method) {
      return 0;
    }
    return (
      ((Number(amount) * (method.commission / 100) +
        method.fixedComission +
        Number(amount)) *
        method.commission) /
        100 +
      method.fixedComission
    );
  };

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        formikBag.setFieldValue('hasConfirmed', true);
        onSubmit && onSubmit(values, formikBag);
      }}
    >
      {formikBag => {
        const { values, errors, touched, setFieldValue, handleSubmit } =
          formikBag;

        const isBankTransfer = values.hasConfirmed && values.method === 'bank';

        return (
          <form
            className={classnames(s.form, className)}
            onSubmit={handleSubmit}
          >
            {isBankTransfer ? (
              <BankTransfer amount={values.amount} />
            ) : (
              <>
                <h1 className={classnames(s.title, 'f-26 f-500')}>
                  Add cash to your account
                </h1>
                {!status?.has_previously_invested && (
                  <BonusBanner className={s.bonus} />
                )}
                <section className={s.amount}>
                  <h2 className={classnames(s.subtitle, 'f-20 f-500')}>
                    Select the amount of your top-up
                  </h2>
                  <div className={s.amount_container}>
                    <div className={s.amount_presets}>
                      {AMOUNT_PRESETS.map((amount, index) => (
                        <div
                          key={index}
                          onClick={() => setFieldValue('amount', `${amount}`)}
                          className={classnames(
                            s.amount_preset,
                            Number(values.amount) === amount && 'selected',
                            'f-20',
                            'f-500'
                          )}
                        >
                          {prettyMoney(amount)}
                        </div>
                      ))}
                    </div>
                    <div className={s.amount_right}>
                      <div className={s.amount_field}>
                        <MoneyField
                          onChange={(_, value) =>
                            setFieldValue('amount', value)
                          }
                          name="amount"
                          label="or specify your own amount"
                          value={values.amount}
                          error={
                            errors.amount && touched.amount && errors.amount
                          }
                        />
                      </div>
                      {!status?.has_previously_invested && (
                        <BonusAmount
                          amount={values.amount}
                          className={s.bonus_amount}
                          mini
                        />
                      )}
                    </div>
                  </div>
                </section>

                <section className={s.method}>
                  <h2 className={classnames(s.subtitle, 'f-20 f-500')}>
                    Select payment method
                  </h2>
                  <div className={s.method_list}>
                    {paymentMethods.map(item => {
                      const commission =
                        item.type === values.method && values.commission
                          ? values.commission
                          : calculateCommission(Number(values.amount), item);

                      return (
                        <PaymentMethod
                          key={item.type}
                          item={item}
                          amount={Number(values.amount)}
                          commission={commission}
                          formikBag={formikBag}
                        />
                      );
                    })}
                  </div>
                </section>

                <TotalPaymentAmount
                  amount={Number(values.amount)}
                  method={values.method}
                  setCommission={commission =>
                    setFieldValue('commission', commission)
                  }
                />
              </>
            )}

            {children}

            <div className={s.buttons}>
              {!isBankTransfer && (
                <RoundButton
                  label={() =>
                    loading ? (
                      <Preloader style={{ height: '24px' }} white />
                    ) : values.method !== 'bank' ? (
                      <span className="f-500">Go to secure payment</span>
                    ) : (
                      <span className="f-500">Go to next step</span>
                    )
                  }
                  type="submit"
                  onSubmit={handleSubmit}
                  className={s.button_submit}
                  fillBackground
                  fullWidth
                  disabled={!values.amount || !values.method}
                />
              )}
            </div>

            {values.method !== 'bank' && !values.hasConfirmed && (
              <div
                className={classnames(
                  s.buttons_desc,
                  !onBack && !values.hasConfirmed && 'line'
                )}
              >
                <div />
                <div className="f-12 f-400">
                  Payment processing is provided by Stripe - highly trusted and
                  secure solution.{' '}
                </div>
              </div>
            )}

            <div className={classnames(s.description, 'f-16-24 f-400')}>
              <div>The currency of your account is EUR.</div>
              <div>
                Cash is accepted only from bank accounts that are in your name:{' '}
                {fullname}.
              </div>
              <div>
                Payments are provided by a secure payment platform Stripe, that
                can charge a transfer fee. Please note that in some cases your
                bank may charge additional transfer fees, so the amount that
                will be transferred to your Scramble account may be less. As for
                Scramble, we do not charge any fees and are not responsible for
                these actions.
              </div>
              <div>
                The transfer of funds depends on the payment method you choose,
                and usually takes up to 1 business day within the EU. If you are
                sending money from outside the EU, it can take up to 3 business
                days.
              </div>

              {(onBack || values.hasConfirmed) && (
                <RoundButton
                  label="Go back"
                  className={s.button_back}
                  onClick={() => {
                    if (onBack && !values.hasConfirmed) {
                      return onBack();
                    }
                    scrollTop();
                    setFieldValue('hasConfirmed', false);
                  }}
                />
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default PaymentForm;
