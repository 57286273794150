import moment from 'moment';
import Cookies from 'js-cookie';

import { COOKIE_NAMES } from './cookie';

const CODE_EXPIRE_DAYS = 30;
const COOKIE_EXPIRE_DAYS = 10000000;
const COOKIE_PARENT_DOMAIN = 'scrambleup.com';
const COOKIE_SETTINGS = {
  expires: COOKIE_EXPIRE_DAYS,
  domain: COOKIE_PARENT_DOMAIN,
};

export const getReferralCodeFromCookie = () => {
  const codes = getAllReferralCodes();
  const currentTime = moment();
  if (codes) {
    for (let i = 0; i < codes.length; i++) {
      const code = codes[i];
      if (currentTime.isBefore(code.expireDate)) {
        return code.id;
      }
    }
  }
  return null;
};

export const saveReferralCodeToCookie = codeID => {
  try {
    const prevCodes = getAllReferralCodes();

    if (prevCodes) {
      const codes = prevCodes.map(code => code.id);
      if (!codes.includes(codeID)) {
        const payload = JSON.stringify([
          ...prevCodes,
          {
            id: codeID,
            expireDate: moment().add(CODE_EXPIRE_DAYS, 'days').toISOString(),
          },
        ]);
        Cookies.set(COOKIE_NAMES.referralCodes, payload, COOKIE_SETTINGS);
      }
    } else {
      const payload = JSON.stringify([
        {
          id: codeID,
          expireDate: moment().add(CODE_EXPIRE_DAYS, 'days').toISOString(),
        },
      ]);
      Cookies.set(COOKIE_NAMES.referralCodes, payload, COOKIE_SETTINGS);
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const getAllReferralCodes = () => {
  try {
    let codes = null;
    const data = Cookies.get(COOKIE_NAMES.referralCodes, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    if (data) {
      codes = JSON.parse(data);
    }
    return codes;
  } catch (e) {
    return null;
  }
};
