import { SEO_EVENTS } from './events';
import { padNumber } from '../helpers/utils';

const GOOGLE_TAG_ACTIONS = {
  send_form: 'send_form',
  click: 'click',
};

const GOOGLE_TAG_CATEGORIES = {
  investor: 'investor',
};

export const useGoogleEvent = (eventType, data, onSuccess, onError) => {
  const params = {};
  if (data.user) {
    params.user_id = padNumber(data.user.id);
  }

  try {
    const event = GA_EVENTS[eventType];
    window.gtag('event', event.action, {
      event_category: event.category,
      event_label: event.label,
    });
    window.dataLayer.push({
      event: event.label,
      category: event.category,
      ...params,
    });
    onSuccess && onSuccess(data, params, event);
    console.info('GA Event --> ', event.action, event.label, event.category);
    return event;
  } catch (e) {
    onError && onError(e);
    console.error('Google Analytics not initialised');
  }
};

const eventGenerator = (action, category, label) => ({
  action,
  category,
  label,
});

const GA_EVENTS = {
  [SEO_EVENTS.i_registration_create]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_create
  ),
  [SEO_EVENTS.i_registration_create_google]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_create_google
  ),
  [SEO_EVENTS.i_registration_create_apple]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_create_apple
  ),
  [SEO_EVENTS.i_login]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_login
  ),
  [SEO_EVENTS.i_login_google]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_login_google
  ),
  [SEO_EVENTS.i_login_apple]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_login_apple
  ),
  [SEO_EVENTS.i_registration_contacts]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_contacts
  ),
  [SEO_EVENTS.i_registration_profile]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_profile
  ),
  [SEO_EVENTS.i_registration_veriff]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_veriff
  ),
  [SEO_EVENTS.i_investing_start]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_investing_start
  ),
  [SEO_EVENTS.i_investing_select_strategy]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_investing_select_strategy
  ),
  [SEO_EVENTS.i_investing_allocate_cash]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_investing_allocate_cash
  ),
  [SEO_EVENTS.i_investing_transfer_cash]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_investing_transfer_cash
  ),
  [SEO_EVENTS.i_investing_edit_strategy]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_investing_edit_strategy
  ),
  [SEO_EVENTS.i_investing_edit_cash_allocation]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_investing_edit_cash_allocation
  ),
  [SEO_EVENTS.i_investing_reset_settings]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_investing_reset_settings
  ),
  [SEO_EVENTS.i_verificationwiz_profile]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_verificationwiz_profile
  ),
  [SEO_EVENTS.i_verificationwiz_contacts]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_verificationwiz_contacts
  ),
  [SEO_EVENTS.i_verificationwiz_veriff]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_verificationwiz_veriff
  ),
  [SEO_EVENTS.i_verificationwiz_verifylater]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_verificationwiz_verifylater
  ),
  [SEO_EVENTS.i_verificationwiz_veriff_new_session]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_verificationwiz_veriff_new_session
  ),
  [SEO_EVENTS.i_verificationwiz_add_funds]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_verificationwiz_add_funds
  ),
  [SEO_EVENTS.i_verificationwiz_investing]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_verificationwiz_investing
  ),
  [SEO_EVENTS.i_restore_password]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_restore_password
  ),
  [SEO_EVENTS.i_loan_details_open]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_loan_details_open
  ),
  [SEO_EVENTS.i_loan_download_agreement]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_loan_download_agreement
  ),
  [SEO_EVENTS.i_logout]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_logout
  ),
  [SEO_EVENTS.i_profile_profile]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_profile_profile
  ),
  [SEO_EVENTS.i_profile_contacts]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_profile_contacts
  ),
  [SEO_EVENTS.i_profile_veriff]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_profile_veriff
  ),
  [SEO_EVENTS.i_withdraw]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_withdraw
  ),
  [SEO_EVENTS.u_veriff_success]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.u_veriff_success
  ),
  [SEO_EVENTS.u_veriff_error]: eventGenerator(
    GOOGLE_TAG_ACTIONS.send_form,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.u_veriff_error
  ),
  [SEO_EVENTS.i_registration_veriff_skip_later]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_veriff_skip_later
  ),
  [SEO_EVENTS.i_registration_veriff_skip_dontwant]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.i_registration_veriff_skip_dontwant
  ),
  [SEO_EVENTS.investing_calculateloan_mobile]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_calculateloan_mobile
  ),
  [SEO_EVENTS.investing_calculateAloan]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_calculateAloan
  ),
  [SEO_EVENTS.investing_calculateBloan]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_calculateBloan
  ),
  [SEO_EVENTS.investing_join_tooltip_earnings]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_tooltip_earnings
  ),
  [SEO_EVENTS.investing_join_tooltip_nar]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_tooltip_nar
  ),
  [SEO_EVENTS.investing_join_tooltip_term]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_tooltip_term
  ),
  [SEO_EVENTS.investing_join_tooltip_repayments]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_tooltip_repayments
  ),
  [SEO_EVENTS.investing_join_tooltip_skin]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_tooltip_skin
  ),
  [SEO_EVENTS.investing_join_checkcalculator]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_checkcalculator
  ),
  [SEO_EVENTS.investing_join_calculator]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_calculator
  ),
  [SEO_EVENTS.investing_reg_longterm_calculator]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_reg_longterm_calculator
  ),
  [SEO_EVENTS.investing_join_repaymentschedule_A]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_repaymentschedule_A
  ),
  [SEO_EVENTS.investing_join_repaymentschedule_B]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_repaymentschedule_B
  ),
  [SEO_EVENTS.investing_join_progressbar]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_progressbar
  ),
  [SEO_EVENTS.investing_join_startupdetails]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_join_startupdetails
  ),
  [SEO_EVENTS.investing_setsaved_affiliate_fbshare]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_setsaved_affiliate_fbshare
  ),
  [SEO_EVENTS.investing_setsaved_affiliate_linshare]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_setsaved_affiliate_linshare
  ),
  [SEO_EVENTS.investing_setsaved_affiliate_twishare]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_setsaved_affiliate_twishare
  ),
  [SEO_EVENTS.investing_setsaved_affiliate_copylink]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_setsaved_affiliate_copylink
  ),
  [SEO_EVENTS.investing_results_affiliate_fbshare]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_results_affiliate_fbshare
  ),
  [SEO_EVENTS.investing_results_affiliate_linshare]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_results_affiliate_linshare
  ),
  [SEO_EVENTS.investing_results_affiliate_twishare]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_results_affiliate_twishare
  ),
  [SEO_EVENTS.investing_results_affiliate_copylink]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_results_affiliate_copylink
  ),
  [SEO_EVENTS.investing_results_gototrustpilot]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_results_gototrustpilot
  ),
  [SEO_EVENTS.investing_influ_video_start]: eventGenerator(
    GOOGLE_TAG_ACTIONS.click,
    GOOGLE_TAG_CATEGORIES.investor,
    SEO_EVENTS.investing_influ_video_start
  ),
};
