import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as classnames from 'classnames';
import NotificationBlock from '../../NotificationBlock';
import { STRATEGIES } from '../InvSelectStrategy';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import PriorityFoundersGroupedList from '../InvAllocateFunds/priority';
import withScroll from '../../../hoc/withScroll';
import Dropdown from '../../../components/Dropdown';
import Analytics from '../../../analytics';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import InvestingSummary from '../../InvestingSummary';
import Checkbox from '../../../components/UI/Checkbox';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import {
  isExpiredResetDate,
  resetInvestingExpireDate,
} from '../../../helpers/date';
import {
  ACCOUNT_TYPES,
  LOAN_GROUPS,
  USER_STATUSES,
  VERIFF_CODES,
} from '../../../helpers/constants';
import { RoundButton } from '../../../components/UI/RoundButton';
import { confirmInvestment } from '../../../store/services/investingServices';
import {
  getInvestingStatus,
  setInvestmentConfirmation,
} from '../../../store/actions/investActions';
import WarningBlock from '../../../components/UI/WarningBlock';
import { round } from '../../../helpers/utils';
import { getBalance } from '../../../store/actions/dashboardActions';
import {
  getUserProfile,
  getVeriffStatus,
} from '../../../store/actions/profileActions';
import {
  isPassedVeriff,
  isSubmittedVeriff,
  isVerifiedPhoneNumber,
} from '../../../helpers/user';
import './index.scss';

const InvReview = ({ status, setStep, onReset }) => {
  const intervalID = useRef(null);
  const [errorMessages, setErrorMessages] = useState(null);
  const dispatch = useDispatch();
  const { localAccounts } = useSelector(state => state.fundStore);
  const { investmentConfirmation } = useSelector(state => state.investStore);
  const { veriffStatusCode } = useSelector(state => state.profileStore);
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const notifications = [];

  notifications.push(NOTIFICATIONS.round_results(status.end_date));
  notifications.push(NOTIFICATIONS.change_settings_until_end());

  useEffect(() => {
    isSubmittedVeriff(veriffStatusCode) && checkVeriffResult();
    return () => {
      clearInterval(intervalID.current);
    };
  }, [veriffStatusCode]);

  const checkVeriffResult = () => {
    clearInterval(intervalID.current);
    intervalID.current = setInterval(() => {
      dispatch(getVeriffStatus()).then(
        res =>
          res &&
          res.success &&
          res.data.code === VERIFF_CODES.verified &&
          dispatch(getUserProfile())
      );
    }, 2000);
  };

  const onSubmit = async (values, formik) => {
    const { setValues, setErrors, errors } = formik;
    const payload = {
      ...values,
    };

    if (localAccounts.length) {
      localAccounts.map(acc => {
        if (acc.type === ACCOUNT_TYPES.loan) {
          payload[acc.group] = round(acc.amount);
          if (acc.amount <= 0) {
            if (acc.group === LOAN_GROUPS.conservative.id) {
              payload.has_accepted_terms_a = false;
            }
            if (acc.group === LOAN_GROUPS.moderate.id) {
              payload.has_accepted_terms_b = false;
            }
          }
        }
      });
    }

    confirmInvestment(payload).then(res => {
      if (res && res.success) {
        setValues({ ...values, ...payload });
        dispatch(
          setInvestmentConfirmation({
            ...investmentConfirmation,
            hasConfirmed: true,
            has_accepted_terms_a: payload.has_accepted_terms_a,
            has_accepted_terms_b: payload.has_accepted_terms_b,
          })
        );
        dispatch(getBalance());
        return dispatch(getInvestingStatus());
      }

      if (res && res.errors) {
        const err = {};
        setErrorMessages(
          Object.keys(res.errors).reduce((errors, key) => {
            if (!['conservative_terms', 'moderate_terms'].includes(key)) {
              errors[key] = res.errors[key];
            }
            return errors;
          }, {})
        );

        Object.keys(res.errors).map(key => {
          if (['conservative_terms', 'moderate_terms'].includes(key)) {
            if (key === 'conservative_terms') {
              err.has_accepted_terms_a = res.errors[key];
            }
            if (key === 'moderate_terms') {
              err.has_accepted_terms_b = res.errors[key];
            }
          }
        });
        return setErrors({ ...errors, ...err });
      }
      setErrorMessages([]);
    });
  };

  let disabledGroupA = true;
  let disabledGroupB = true;

  localAccounts.map(acc => {
    if (acc.amount > 0 && acc.group === LOAN_GROUPS.conservative.id) {
      disabledGroupA = false;
    }

    if (acc.amount > 0 && acc.group === LOAN_GROUPS.moderate.id) {
      disabledGroupB = false;
    }
  });

  const disabled = !(
    profile &&
    isPassedVeriff(profile) &&
    isVerifiedPhoneNumber(profile) &&
    profile.status === USER_STATUSES.verified
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        has_accepted_terms_a: investmentConfirmation.has_accepted_terms_a,
        has_accepted_terms_b: investmentConfirmation.has_accepted_terms_b,
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <form className="inv-review" onSubmit={handleSubmit}>
          <h1 className="f-42 f-500">
            Review and save your investment settings
          </h1>

          {profile && isSubmittedVeriff(veriffStatusCode) && (
            <WarningBlock withBorder className="inv-review__warning_veriff">
              <div className="f-16 f-400">
                We are currently checking your profile information, it may take
                up to two business days. <br />
                We will notify you by email when the results are ready.
              </div>
            </WarningBlock>
          )}

          <NotificationBlock
            className="inv-review__notifications"
            notifications={notifications}
          />
          <InvestingSummary
            status={status}
            isReview
            className="inv-review__summary"
            onStrategyEdit={() => {
              Analytics.wizardStep3SettingsEditStrategy();
              dispatch(
                setInvestmentConfirmation({
                  ...investmentConfirmation,
                  hasConfirmed: false,
                })
              );
              setStep(1);
            }}
            onLoansEdit={() => {
              Analytics.wizardStep3SettingsEditAllocation();
              dispatch(
                setInvestmentConfirmation({
                  ...investmentConfirmation,
                  hasConfirmed: false,
                })
              );
              setStep(2);
            }}
          />

          {(!disabledGroupA || !disabledGroupB) && (
            <div className="inv-review__agreement">
              {!disabledGroupB && (
                <Checkbox
                  name="has_accepted_terms_b"
                  isChecked={values.has_accepted_terms_b}
                  disabled={disabledGroupB}
                  onChange={() =>
                    setFieldValue(
                      'has_accepted_terms_b',
                      !values.has_accepted_terms_b
                    )
                  }
                  error={
                    errors.has_accepted_terms_b &&
                    touched.has_accepted_terms_b &&
                    errors.has_accepted_terms_b
                  }
                  render={() => (
                    <span
                      className={classnames(
                        'inv-review__agreement-text f-16',
                        errors.has_accepted_terms_b &&
                          touched.has_accepted_terms_b &&
                          'error',
                        values.has_accepted_terms_b && 'checked',
                        disabledGroupB && 'disabled'
                      )}
                    >
                      I have read, understood and accept the{' '}
                      <MyLink href={APP_LINKS.termOfUse} isExternal>
                        Terms of use
                      </MyLink>{' '}
                      , terms and conditions of the{' '}
                      <MyLink
                        href={APP_LINKS.subordinatedFinancialAgreement}
                        isExternal
                      >
                        Subordinated Financing Agreement
                      </MyLink>{' '}
                      (Group B loans) and{' '}
                      <MyLink
                        href={APP_LINKS.subordinatedGuaranteeLetter}
                        isExternal
                      >
                        Guarantee Letter
                      </MyLink>
                      , and I agree to enter into agreements with the Businesses
                      and their Founders as a result of the current round.
                    </span>
                  )}
                />
              )}

              {!disabledGroupA && (
                <Checkbox
                  name="has_accepted_terms_a"
                  isChecked={values.has_accepted_terms_a}
                  disabled={disabledGroupA}
                  onChange={() =>
                    setFieldValue(
                      'has_accepted_terms_a',
                      !values.has_accepted_terms_a
                    )
                  }
                  error={
                    errors.has_accepted_terms_a &&
                    touched.has_accepted_terms_a &&
                    errors.has_accepted_terms_a
                  }
                  render={() => (
                    <span
                      className={classnames(
                        'inv-review__agreement-text f-16',
                        errors.has_accepted_terms_a &&
                          touched.has_accepted_terms_a &&
                          'error',
                        values.has_accepted_terms_a && 'checked',
                        disabledGroupA && 'disabled'
                      )}
                    >
                      I have read, understood and accept the{' '}
                      <MyLink href={APP_LINKS.termOfUse} isExternal>
                        Terms of use
                      </MyLink>{' '}
                      , terms and conditions of the{' '}
                      <MyLink
                        href={APP_LINKS.unsubordinatedFinancialAgreement}
                        isExternal
                      >
                        Financing Agreement
                      </MyLink>{' '}
                      (Group A loans), and I agree to conclude it with the
                      Businesses and their Founders as a result of the current
                      round.
                    </span>
                  )}
                />
              )}
            </div>
          )}

          <div className="inv-review__founders">
            {status.option === STRATEGIES.standard && (
              <Dropdown title="Consumer Goods Brands batch">
                <ParticipatingStartupsList />
              </Dropdown>
            )}
            {status.option === STRATEGIES.priority && (
              <PriorityFoundersGroupedList />
            )}
          </div>

          {errorMessages && (
            <div className="inv-review__warning">
              {Object.keys(errorMessages).map(key => (
                <WarningBlock key={key} className="inv-review__warning_item">
                  <div className="f-16 f-500">{errorMessages[key][0]}</div>
                </WarningBlock>
              ))}
            </div>
          )}

          {profile && isSubmittedVeriff(veriffStatusCode) && (
            <WarningBlock
              withBorder
              className="inv-review__warning_veriff_bottom"
            >
              <div className="f-16 f-400">
                We are currently checking your profile information, it may take
                up to two business days. <br />
                We will notify you by email when the results are ready.
              </div>
            </WarningBlock>
          )}

          <div className="inv-review__buttons">
            <RoundButton
              label="Go back"
              onClick={() => setStep(2)}
              className="inv-review__buttons-back"
            />
            <RoundButton
              label="Save settings"
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              disabled={disabled}
              className="inv-review__buttons-continue"
            />
          </div>

          {status.committed_amount === 0 &&
            !isExpiredResetDate(status.editable_until) && (
              <div className="inv-review__reset">
                <div>
                  <h2 className="f-26 f-500">Changed your mind?</h2>
                  <p className="f-16 f-400">
                    You can decide not to participate in the round before{' '}
                    {resetInvestingExpireDate(
                      status.editable_until,
                      status.end_date
                    )}
                  </p>
                </div>
                <button
                  type="button"
                  className="f-16 f-400 hov"
                  onClick={onReset}
                >
                  Reset investing
                </button>
              </div>
            )}
        </form>
      )}
    </Formik>
  );
};

export default withScroll(InvReview);
