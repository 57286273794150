import { APP_LINKS } from '../../helpers/links';

export const NAV_ROUTES = [
  { label: 'Dashboard', path: APP_LINKS.dashboard },
  { label: 'Investing', path: APP_LINKS.investing },
  { label: 'Cash', path: APP_LINKS.cash },
  { label: 'Loans', path: APP_LINKS.loans },
  { label: 'Brands', path: APP_LINKS.startups },
  { label: 'Activity', path: APP_LINKS.activity },
  { label: 'Invite friends', path: APP_LINKS.affiliate },
  { label: 'Investor\'s questions', path: APP_LINKS.clientQuestions },
];
