import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { VERIFF_CODES } from '../../../helpers/constants';
import { RoundButton } from '../../../components/UI/RoundButton';
import { WarningIcon } from '../../../components/UI/Icons';
import Analytics from '../../../analytics';
import { STEPS } from '../index';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
} from '../../../helpers/user';
import s from '../index.module.scss';

export const VerifyIdentityStep = ({ passed, setPassed, onVerifyIdentity }) => {
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const { veriffStatusCode } = useSelector(state => state.profileStore);
  const active = passed.includes(STEPS.verify_identity);
  const completeLater =
    passed.includes(STEPS.add_cash) && !passed.includes(STEPS.verify_identity);

  return (
    <li
      id={`vpw_${STEPS.verify_identity}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400'
          )}
        >
          02
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_verify, 'f-26 f-400')}>
          <span
            onClick={() =>
              completeLater &&
              setPassed([STEPS.account_create, STEPS.verify_identity])
            }
            className={classnames(
              s.list_item_title,
              completeLater && s.list_item_title_underlined
            )}
          >
            Verify your identity
          </span>
          {completeLater && (
            <RoundButton
              className={s.list_item_verify_btn}
              label="Complete verification"
              onClick={() =>
                setPassed([STEPS.account_create, STEPS.verify_identity])
              }
            />
          )}
        </div>

        {veriffStatusCode !== VERIFF_CODES.verified && (
          <div
            className={classnames(
              s.list_item_content,
              active && s.list_item_content_active,
              'f-16-24 f-400'
            )}
          >
            <div className={s.list_item_content_inner}>
              {[VERIFF_CODES.submitted, VERIFF_CODES.reSubmitted].includes(
                veriffStatusCode
              ) ? (
                <div className="f-16 f-400">
                  Your identity is being verified by Veriff. It takes only a
                  couple of minutes.
                </div>
              ) : (
                <>
                  {[VERIFF_CODES.notVerified, VERIFF_CODES.expired].includes(
                    veriffStatusCode
                  ) && (
                    <div className={s.veriff}>
                      <WarningIcon />
                      <span className="f-16 f-500">
                        Your identity verification attempt was unsuccessful.
                        <br />
                        Please use only original documents for verification,
                        photos or scans of documents are not accepted.
                        <br />
                        If you need help, you can always contact our support
                        team.
                      </span>
                    </div>
                  )}

                  {[VERIFF_CODES.new, VERIFF_CODES.started].includes(
                    veriffStatusCode
                  ) && (
                    <div className="f-16 f-400">
                      You will be asked to fill in the information that can help
                      us verify you. The process is quick and easy.
                    </div>
                  )}

                  <div className="f-16 f-400" style={{ marginTop: '8px' }}>
                    Provide all necessary information about yourself and verify
                    your identity.
                    <br />
                    The personal information you provide will remain strictly
                    confidential. It is required for further investment
                    operations and not displayed to other users.
                  </div>
                  <div className={s.veriff_buttons}>
                    {hasRequiredPersonalSectionFields(profile) &&
                      hasRequiredProfileSectionFields(profile) && (
                        <RoundButton
                          label="Complete later"
                          onClick={() => {
                            setPassed([STEPS.account_create, STEPS.add_cash]);
                            Analytics.verificationWizardCompleteLater();
                          }}
                          className={s.veriff_button_later}
                        />
                      )}

                    <RoundButton
                      label="Verify identity"
                      fillBackground
                      fullWidth
                      onClick={onVerifyIdentity}
                      className={s.veriff_button_identity}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </li>
  );
};
