import React from 'react';
import classnames from 'classnames';
import { ReactComponent as IconTrustpilot } from '../../../assets/icons/icon_trustpilot.svg';
import s from './index.module.scss';

export const TrustpilotReviewButton = ({ onClick, className }) => {
  return (
    <div
      className={classnames('trustpilot-widget', s.wrapper, className)}
      data-locale="en-GB"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="61faa1aa4e2b01025a9e808a"
      data-style-height="52px"
      data-style-width="100%"
      onClick={onClick}
    >
      <a
        href="https://uk.trustpilot.com/review/scrambleup.com"
        target="_blank"
        rel="noopener"
        className={classnames('f-16 f-400', s.link)}
      >
        Review us on
        <IconTrustpilot />
        Trustpilot
      </a>
    </div>
  );
};
