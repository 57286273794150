import React from 'react';
import classnames from 'classnames';
import WarningBlock from '../../UI/WarningBlock';
import s from './index.module.scss';

export const MinInvestAmountWarning = ({ className }) => (
  <WarningBlock withBorder className={classnames(s.wrapper, className)}>
    <div className={classnames('f-16 f-400')}>
      The minimum investment amount for US customers is €1&apos;000, due to EU
      and US regulatory requirements that impose additional reporting
      obligations to the relevant authorities.
    </div>
  </WarningBlock>
);
