import React, { useCallback, useEffect, useState } from 'react';
import * as classnames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/icon_arrow_line.svg';
import { debounce, prettyMoney } from '../../../../helpers/utils';
import { RoundButton } from '../../../UI/RoundButton';
import { getInvestmentCalculationData } from '../../../../store/services/investingServices';
import EarningGraphic from '../../../EarningGraphic';
import Preloader from '../../../Preloader';
import Analytics from '../../../../analytics';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import s from './index.module.scss';

export const EarningResult = ({ formikBag, onSubmit, joinDisabled }) => {
  const { loan, topUp, amount } = formikBag.values;
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = {
      initial_top_up: amount || 0,
      monthly_top_up: topUp || 0,
      loan_group: loan,
    };
    setLoading(true);
    updateGraph(params);
  }, [amount, topUp, loan]);

  const updateGraph = useCallback(
    debounce(async params => {
      Analytics.investingJoinCheckCalculator();
      const res = await getInvestmentCalculationData(params);
      if (res && res.success) {
        setGraphData(res.data);
      }
      setLoading(false);
    }, 500),
    []
  );

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div className="f-18-24 f-500">My portfolio after 10 years:</div>
        <div className={s.total}>
          <ArrowIcon className={s.arrow} />
          <div className={s.portfolio}>
            <div className={classnames(s.amount_value, 'f-42 f-500')}>
              {prettyMoney(graphData?.total_portfolio)}
            </div>
            <div className={classnames(s.amount_desc, 'f-20', 'f-400')}>
              with Scramble
            </div>
          </div>
          <div className={classnames(s.vs, 'f-20', 'f-400')}>vs.</div>
          <div className={s.topup}>
            <div className={classnames(s.amount_value, 'f-42 f-500')}>
              {prettyMoney(graphData?.total_top_up)}
            </div>
            <div className={classnames(s.amount_desc, 'f-20', 'f-400')}>
              only savings
            </div>
          </div>
        </div>
        <div className={s.graph}>
          {loading ? (
            <Preloader style={{ height: '400px' }} />
          ) : graphData ? (
            <EarningGraphic graphData={graphData} />
          ) : (
            <div>Please check network connection...</div>
          )}
        </div>
        <div className={s.benefits}>
          {loan === LOAN_GROUPS.conservative.id ? (
            <ul className={s.list}>
              <li>Get your earnings right after the end of the round</li>
              <li>Triple-secured downside protection</li>
              <li>No fees on investor capital</li>
            </ul>
          ) : (
            <ul className={s.list}>
              <li>
                Get your earnings and loan principal in full in the 6th month
              </li>
              <li>Single-secured downside protection</li>
              <li>No fees on investor capital</li>
            </ul>
          )}
        </div>
        <div className={s.desc}>
          Great plan! Make your money work – let it bring you a decent passive
          income.
        </div>
      </div>
      {onSubmit && (
        <div className={s.buttons}>
          <RoundButton
            label={() => <span>Invest now</span>}
            disabled={joinDisabled}
            fillBackground
            onClick={() => {
              Analytics.investingRegLongtermCalculator();
              onSubmit && onSubmit();
            }}
            className={s.button}
          />
        </div>
      )}
    </div>
  );
};
