import React from 'react';
import { MyLink } from '../../components/MyLink';
import { APP_LINKS } from '../../helpers/links';
import s from './index.module.scss';

const BonusBannerModal = () => (
  <div className={s.modal}>
    <h1 className="f-32 f-500">
      Maximize your first earnings with a €5 bonus for every €100 investment
    </h1>
    <section className="f-16-24">
      <h2 className="f-20 f-500">How does it work?</h2>
      <div>
        Every <span className="f-500">€100</span> initial invested gets you an
        additional €5 bonus:
      </div>
      <ul>
        <li>
          an investment of less than <span className="f-500">€100</span> will
          not get you a bonus,
        </li>
        <li>
          an investment over <span className="f-500">€100</span> will get you a
          total of <span className="f-500">€5</span> bonus,
        </li>
        <li>
          an investment over <span className="f-500">€200</span> will get you a
          total of <span className="f-500">€10</span> bonus,
        </li>
        <li>
          over <span className="f-500">€300</span> - a{' '}
          <span className="f-500">€15</span> bonus, and so on and so forth.
        </li>
      </ul>
    </section>
    <section className="f-16-24">
      <h2 className="f-20 f-500">Who is the promotion for?</h2>
      <div>
        The promotion is only valid for new customers who have not previously
        invested on the Scramble platform. The promotion is only valid for the
        first investment.
      </div>
    </section>
    <section className="f-16-24">
      <h2 className="f-20 f-500">What happens if I invest my active bonus?</h2>
      <div>
        Invested bonuses do not participate in the calculation of the promotion.
        Only your personal funds that have been added to your Scramble account
        or received as payouts and earnings on the platform are taken into
        account.
      </div>
    </section>
    <section className="f-16-24">
      <h2 className="f-20 f-500">When will I get my bonuses?</h2>
      <div>
        Bonuses will be accrued to your Scramble cash account after the current
        round is finished, on April 18th, 2023.
      </div>
    </section>
    <section className="f-16-24">
      <h2 className="f-20 f-500">Can I withdraw my bonuses?</h2>
      <div>
        You can use the acquired bonuses for your future investments on
        Scramble, however, you cannot cash them out.
      </div>
    </section>
    <section className="f-16-24">
      <h2 className="f-20 f-500">For how long can I use them?</h2>
      <div>
        You can use your bonuses for up to 90 days from the moment they are
        added to your Scramble account.
      </div>
    </section>
    <section className="f-16-24">
      <h2 className="f-20 f-500">How long will this offer last?</h2>
      <div>
        This promotion start on April 1st, 2023, and ends on April 17th, 2023.
      </div>
    </section>

    <div className={s.bottom}>
      By investing on the Scramble platform, you acknowledge that you have read,
      understood and accepted the{' '}
      <MyLink isExternal href={APP_LINKS.docBonusProgram}>
        Terms and Conditions
      </MyLink>{' '}
      of this promotion.
    </div>
  </div>
);

export default BonusBannerModal;
