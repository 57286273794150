import React from 'react';
import { prettyFloatMoney } from '../../../helpers/utils';
import { REFERRAL_FRIEND_STATUSES } from '../index';
import Tooltip from '../../../components/Tooltip';
import './index.scss';

const ReferralTable = ({ data }) => (
  <div className="referral_table__wrap">
    <div className="referral_table__inner">
      <div className="referral_table">
        <div className="referral_table__thead">
          <div className="referral_table__thead_col">
            <div className="referral_table__thead_col_tooltip">
              <span>User</span>
              <Tooltip
                position="bottom left"
                renderContent={() => (
                  <div className="referral_table__tooltip f-16  f-400">
                    Users registered using your link.
                  </div>
                )}
              />
            </div>
          </div>
          <div className="referral_table__thead_col">
            <div className="referral_table__thead_col_tooltip">
              <span>User status</span>
              <Tooltip
                position="bottom left"
                renderContent={() => (
                  <div className="referral_table__tooltip f-16  f-400">
                    Keep track of your referral status:
                    <div>
                      <span className="f-500">Registered</span> – the user
                      registered but has not invested yet, which means you will
                      not receive a reward.
                    </div>
                    <div>
                      <span className="f-500">Invested</span> – the user has
                      invested funds within 60 days, so you will soon be
                      rewarded.
                    </div>
                    <div>
                      <span className="f-500">Invested less</span> – the user
                      has invested funds less than the minimum amount, which
                      means you will not receive a reward.
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="referral_table__thead_col">
            <div className="referral_table__thead_col_tooltip">
              <span>Days left</span>
              <Tooltip
                position="bottom left"
                renderContent={() => (
                  <div className="referral_table__tooltip f-16  f-400">
                    According to the terms of the program, you will receive a
                    reward if users invest funds within 60 days after
                    registration. Here you will find the number of days left to
                    make investments by the users.
                  </div>
                )}
              />
            </div>
          </div>
          <div className="referral_table__thead_col">
            <div className="referral_table__thead_col_tooltip">
              <span>Your reward</span>
              <Tooltip
                position="bottom left"
                renderContent={() => (
                  <div className="referral_table__tooltip f-16  f-400">
                    The amount of your earnings.
                  </div>
                )}
              />
            </div>
          </div>
          <div className="referral_table__thead_col">
            <div className="referral_table__thead_col_tooltip">
              <span>Rewards status</span>
              <Tooltip
                position="bottom left"
                className="last"
                renderContent={() => (
                  <div className="referral_table__tooltip f-16  f-400">
                    Here you will find the actual or expected date the cash
                    rewards become available in your Scramble cash account.
                    According to the terms of the program, you will receive your
                    reward in 61 days after users registration.
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div className="referral_table__tbody">
          <div className="referral_table__tbody_row_summary">
            <div className="referral_table__tbody_col">
              <p className="f-18 f-500">
                Total: <span>{data.total_count}</span>
              </p>
            </div>
            <div className="referral_table__tbody_col">
              <span className="f-18 f-500">—</span>
            </div>
            <div className="referral_table__tbody_col">
              <span className="f-18 f-500">—</span>
            </div>
            <div className="referral_table__tbody_col tl">
              <span className="f-18 f-500">
                +{prettyFloatMoney(data.total_commission)}
              </span>
            </div>
            <div className="referral_table__tbody_col tl">
              <span className="f-18 f-500">—</span>
            </div>
          </div>

          {data.list.map(item => (
            <div className="referral_table__tbody_row" key={item.investor_id}>
              <div className="referral_table__inner_row">
                <div className="referral_table__tbody_col">
                  <span className="f-18 f-400">{item.investor_id}</span>
                </div>
                <div className="referral_table__tbody_col">
                  <span className="f-18 f-400">
                    {REFERRAL_FRIEND_STATUSES[item.friend_status]}
                  </span>
                </div>
                <div className="referral_table__tbody_col">
                  <div className="f-18 f-400 tl">
                    {item.remaining_period
                      ? `${item.remaining_period} days`
                      : '-'}
                  </div>
                </div>
                <div className="referral_table__tbody_col tl">
                  <span className="f-18 f-400">
                    {prettyFloatMoney(item.commission)}
                  </span>
                </div>
                <div className="referral_table__tbody_col">
                  <span className="f-18 f-400">{item.commission_status}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default ReferralTable;
