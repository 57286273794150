import React from 'react';
import { connect } from 'react-redux';
import * as qs from 'qs';
import {
  getBalance,
  getNotificationInfo,
} from '../../store/actions/dashboardActions';
import Stepper from '../Stepper';
import Preloader from '../../components/Preloader';
import InvStartProcess from './InvStartProcess';
import {
  getInvestingStatus,
  resetInvesting,
  setInvestingStatus,
  setInvestmentConfirmation,
} from '../../store/actions/investActions';
import InvSelectStrategy, { STRATEGIES } from './InvSelectStrategy';
import InvAllocateFunds from './InvAllocateFunds';
import InvPreRound from './InvPreRound';
import InvReady from './InvReady';
import InvResult from './InvResult';
import InvHold from './InvHold';
import InvReview from './InvReview';
import InvVeriff from './InvVeriff';
import {
  getAllocateInfo,
  setLocalAccounts,
} from '../../store/actions/fundActions';
import { getFounderInfo } from '../../store/actions/commonActions';
import {
  getMissingProfileDetails,
  getUserProfile,
  getVeriffStatus,
} from '../../store/actions/profileActions';
import { USER_STATUSES, VERIFF_CODES } from '../../helpers/constants';
import MiniFaqs, { MINIFAQ_SOURCES } from '../MiniFaqs';
import Analytics from '../../analytics';
import MobileStepper from '../MobileStepper';
import './index.scss';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
  isVerifiedByAdmin,
  isVerifiedPhoneNumber,
} from '../../helpers/user';

export const WIZARD_REDIRECTS = {
  strategy: 'strategy-selection',
  allocate: 'funds-allocation',
};

const STEPS = [
  { label: 'Select strategy' },
  { label: 'Allocate cash' },
  { label: 'Review the settings' },
];

class InvestingModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: -1,
      loading: true,
    };
  }

  componentDidMount() {
    this.props.getMissingProfileDetails();
    this.props.getUserProfile();
    this.props.getVeriffStatus();
    this.props.getInvestingStatus().then(res => {
      this.props.setInvestmentConfirmation({
        hasConfirmed: res.data.has_accepted_necessary_terms,
        has_accepted_terms_a: res.data.has_accepted_terms_a,
        has_accepted_terms_b: res.data.has_accepted_terms_b,
      });

      if (res && res.success) {
        let step = res.data.step;
        if (res.data.step < 4) {
          const { to, strategy } = qs.parse(
            this.props.location.search.replace('?', '')
          );
          if (
            strategy &&
            (strategy === STRATEGIES.priority ||
              strategy === STRATEGIES.standard)
          ) {
            this.props.setInvestingStatus({ ...res.data, option: strategy });
          }
          to === WIZARD_REDIRECTS.strategy && (step = 1);
          to === WIZARD_REDIRECTS.allocate &&
            res.data.option !== STRATEGIES.not_selected &&
            (step = 2);
          to && this.props.history.replace(this.props.location.pathname);
        }
        this.setState(prevState => ({ ...prevState, step, loading: false }));
      } else {
        this.setState(prevState => ({ ...prevState, loading: false }));
      }

      this.props.getAllocateInfo().then(response => {
        if (response && response.success) {
          if (!this.props.localAccounts.length) {
            this.props.setLocalAccounts(response.data.accounts);
          }
        }
      });
    });
    this.props.getNotificationInfo();
  }

  setStep = step => {
    this.setState(prevState => ({ ...prevState, step }));
  };

  onResetInvesting = () => {
    this.props.resetInvesting().then(res => {
      if (res && res.success) {
        Analytics.wizardResetSettings();
        this.props.getBalance();
        this.props.getAllocateInfo();
        this.setState(prevState => ({
          ...prevState,
          step: res.data.step,
        }));
      }
    });
  };

  render() {
    const { step, loading } = this.state;
    const { investmentConfirmation, innerWidth, veriffStatusCode } = this.props;
    const { data: status } = this.props.investingStatus;
    const { data: profile } = this.props.profile;

    const enableWizard = !!(
      profile &&
      hasRequiredProfileSectionFields(profile) &&
      hasRequiredPersonalSectionFields(profile) &&
      isVerifiedPhoneNumber(profile) &&
      profile.status !== USER_STATUSES.suspended
    );

    return (
      <div className="investing-module">
        <div className="container-inner">
          {loading && <Preloader className="investing-module__preloader" />}
          {!loading && status && (
            <>
              {step > 0 &&
                step < 4 &&
                !(step === 3 && investmentConfirmation.hasConfirmed) &&
                (innerWidth < 992 ? (
                  <MobileStepper
                    active={step}
                    options={STEPS}
                    className="investing-module__progressbar"
                  />
                ) : (
                  <Stepper
                    active={step}
                    options={STEPS}
                    className="investing-module__progressbar"
                  />
                ))}
              {step === -1 && <InvPreRound status={status} />}
              {step === 0 && (
                <InvStartProcess
                  status={status}
                  setStep={this.setStep}
                  disabled={!enableWizard}
                />
              )}
              {step === 1 && (
                <InvSelectStrategy
                  status={status}
                  setStep={this.setStep}
                  disabled={!enableWizard}
                />
              )}
              {step === 2 && (
                <InvAllocateFunds
                  status={status}
                  setStep={this.setStep}
                  disabled={!enableWizard}
                />
              )}
              {step === 3 &&
                (investmentConfirmation.hasConfirmed ? (
                  <InvReady
                    status={status}
                    setStep={this.setStep}
                    onReset={this.onResetInvesting}
                  />
                ) : [
                    VERIFF_CODES.submitted,
                    VERIFF_CODES.reSubmitted,
                    VERIFF_CODES.verified,
                  ].includes(veriffStatusCode) || isVerifiedByAdmin(profile) ? (
                  <InvReview
                    status={status}
                    setStep={this.setStep}
                    onReset={this.onResetInvesting}
                  />
                ) : (
                  <InvVeriff
                    status={status}
                    setStep={this.setStep}
                    onSuccess={() => this.props.getVeriffStatus()}
                  />
                ))}
              {step === 4 && <InvHold status={status} />}
              {step === 5 && <InvResult status={status} />}
            </>
          )}

          <MiniFaqs source={MINIFAQ_SOURCES.investing} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  balance: state.dashboardStore.balance,
  investingStatus: state.investStore.investingStatus,
  founderInfo: state.commonStore.founderInfo,
  innerWidth: state.commonStore.innerWidth,
  veriffStatusCode: state.profileStore.veriffStatusCode,
  profile: state.profileStore.profile,
  localAccounts: state.fundStore.localAccounts,
  investmentConfirmation: state.investStore.investmentConfirmation,
});

const mapDispatchToProps = dispatch => ({
  getInvestingStatus: () => dispatch(getInvestingStatus()),
  getNotificationInfo: () => dispatch(getNotificationInfo()),
  setInvestingStatus: status => dispatch(setInvestingStatus(status)),
  getFounderInfo: id => dispatch(getFounderInfo(id)),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
  setInvestmentConfirmation: data => dispatch(setInvestmentConfirmation(data)),
  setLocalAccounts: data => dispatch(setLocalAccounts(data)),
  getAllocateInfo: () => dispatch(getAllocateInfo()),
  resetInvesting: () => dispatch(resetInvesting()),
  getBalance: () => dispatch(getBalance()),
  getUserProfile: () => dispatch(getUserProfile()),
  getVeriffStatus: () => dispatch(getVeriffStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestingModule);
