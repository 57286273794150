import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const Stepper = ({ active, options, className }) => (
  <div className={classnames('stepper__wrap', className)}>
    <ul className="stepper">
      {options.map((item, index) => {
        const step = index + 1;
        return (
          <li
            key={item.label}
            className={classnames(
              'stepper__step',
              active >= step && 'stepper__step-active'
            )}
          >
            <div className="stepper__circle">
              <div className="stepper__circle-content">
                <p className="stepper__circle-number f-42 f-500">
                  {step <= 9 ? `0${step}` : step}
                </p>
                <p className="stepper__circle-title f-16">{item.label}</p>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  </div>
);

export default Stepper;
