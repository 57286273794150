export const SEO_EVENTS = {
  i_registration_create: 'i_registration_create',
  i_registration_create_google: 'i_registration_create_google',
  i_registration_create_apple: 'i_registration_create_apple',
  i_login: 'i_login',
  i_login_google: 'i_login_google',
  i_login_apple: 'i_login_apple',
  i_registration_contacts: 'i_registration_contacts',
  i_registration_profile: 'i_registration_profile',
  i_registration_veriff: 'i_registration_veriff',
  i_registration_veriff_skip_later: 'i_registration_veriff_skip_later',
  i_registration_veriff_skip_dontwant: 'i_registration_veriff_skip_dontwant',
  i_investing_start: 'i_investing_start',
  i_investing_select_strategy: 'i_investing_select_strategy',
  i_investing_allocate_cash: 'i_investing_allocate_cash',
  i_investing_transfer_cash: 'i_investing_transfer_cash',
  i_investing_edit_strategy: 'i_investing_edit_strategy',
  i_investing_edit_cash_allocation: 'i_investing_edit_cash_allocation',
  i_investing_reset_settings: 'i_investing_reset_settings',
  i_verificationwiz_profile: 'i_verificationwiz_profile',
  i_verificationwiz_contacts: 'i_verificationwiz_contacts',
  i_verificationwiz_veriff: 'i_verificationwiz_veriff',
  i_verificationwiz_verifylater: 'i_verificationwiz_verifylater',
  i_verificationwiz_veriff_new_session: 'i_verificationwiz_veriff_new_session',
  i_verificationwiz_add_funds: 'i_verificationwiz_add_funds',
  i_verificationwiz_investing: 'i_verificationwiz_investing',
  i_restore_password: 'i_restore_password',
  i_loan_details_open: 'i_loan_details_open',
  i_loan_download_agreement: 'i_loan_download_agreement',
  i_profile_profile: 'i_profile_profile',
  i_logout: 'i_logout',
  i_profile_contacts: 'i_profile_contacts',
  i_profile_veriff: 'i_profile_veriff',
  i_withdraw: 'i_withdraw',
  u_veriff_success: 'u_veriff_success',
  u_veriff_error: 'u_veriff_error',
  investing_calculateloan_mobile: 'investing_calculateloan_mobile',
  investing_join_calculator: 'investing_join_calculator',
  investing_calculateAloan: 'investing_calculateAloan',
  investing_calculateBloan: 'investing_calculateBloan',
  investing_join_tooltip_earnings: 'investing_join_tooltip_earnings',
  investing_join_tooltip_nar: 'investing_join_tooltip_nar',
  investing_join_tooltip_term: 'investing_join_tooltip_term',
  investing_join_tooltip_repayments: 'investing_join_tooltip_repayments',
  investing_join_tooltip_skin: 'investing_join_tooltip_skin',
  investing_join_checkcalculator: 'investing_join_checkcalculator',
  investing_reg_longterm_calculator: 'investing_reg_longterm_calculator',
  investing_join_repaymentschedule_A: 'investing_join_repaymentschedule_A',
  investing_join_repaymentschedule_B: 'investing_join_repaymentschedule_B',
  investing_join_progressbar: 'investing_join_progressbar',
  investing_join_startupdetails: 'investing_join_startupdetails',
  investing_setsaved_affiliate_fbshare: 'investing_setsaved_affiliate_fbshare',
  investing_setsaved_affiliate_linshare:
    'investing_setsaved_affiliate_linshare',
  investing_setsaved_affiliate_twishare:
    'investing_setsaved_affiliate_twishare',
  investing_setsaved_affiliate_copylink:
    'investing_setsaved_affiliate_copylink',
  investing_results_affiliate_fbshare: 'investing_results_affiliate_fbshare',
  investing_results_affiliate_linshare: 'investing_results_affiliate_linshare',
  investing_results_affiliate_twishare: 'investing_results_affiliate_twishare',
  investing_results_affiliate_copylink: 'investing_results_affiliate_copylink',
  investing_results_gototrustpilot: 'investing_results_gototrustpilot',
  investing_influ_video_start: 'investing_influ_video_start',
};
