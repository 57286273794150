import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import Notify from '../../components/Notification';
import Analytics from '../../analytics';

// Get list of investors with set strategy and allocated funds
export const getParticipatingInvestors = params => {
  return axios
    .get(Pathes.Investing.participatingInvestors + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get list of founders registered for active fundraising
export const getParticipatingFounders = params => {
  return axios
    .get(Pathes.Investing.participatingFounders + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get list of founders for standard allocation strategy
export const getStandardFounders = params => {
  return axios
    .get(Pathes.Investing.standardFounders + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get detailed list of founders registered for active fundraising in specific risk group
export const getDetailedFounders = (groupID, params) => {
  return axios
    .get(Pathes.Investing.detailedFounders(groupID) + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get list of founders registered for the next fundraising
export const getFutureFounders = params => {
  return axios
    .get(Pathes.Investing.futureFounders + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get list of founders for priority allocation strategy
export const getPrioritySelections = params => {
  return axios
    .get(Pathes.Investing.prioritySelections + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Set investment strategy
export const setInvestmentStrategy = payload => {
  return axios
    .post(Pathes.Investing.strategy, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Analytics.wizardStep1SelectStrategy();
        return { data, success: true };
      }

      if (status === 406) {
        Notify.error({ text: message });
      }

      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get list of priority founders
export const getPriorityFounders = params => {
  return axios
    .get(Pathes.Investing.priorityFounders + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get list of priority founders
export const getNonPriorityFounders = params => {
  return axios
    .get(Pathes.Investing.nonPriorityFounders + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get happy founders in latest fundraising
export const getHappyFoundersLatestFR = params => {
  return axios
    .get(Pathes.Investing.latestHappyFounders + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Get list of founders registered for active fundraising in specific risk group
export const getFoundersInLoanGroup = (groupID, params) => {
  return axios
    .get(Pathes.Investing.foundersByLoanGroup(groupID) + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Update investor reinvest status
export const updateReinvestStatus = payload => {
  return axios
    .put(Pathes.Funds.reinvestStatus, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Turn auto invest on/off
export const updateAutoInvestStatus = payload => {
  return axios
    .put(Pathes.Funds.autoInvestStatus, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: 'Autoinvest settings saved' });
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

export const getInvestmentGroups = () => {
  return axios
    .get(Pathes.Investing.loanGroups)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Investment confirmation
export const confirmInvestment = payload => {
  return axios
    .post(Pathes.Investing.investmentConfirmation, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);

      if (status === 200) {
        Notify.success({ text: message });
        return { data, success: true };
      }

      if ([422, 400].includes(status)) {
        Notify.error({ text: message });
        // data.errors &&
        //   Object.keys(data.errors).map(key =>
        //     Notify.error({ text: data.errors[key][0] })
        //   );
        return { errors: data.errors, success: false };
      }

      throw new Error(message);
    })
    .catch(e => {
      Notify.error({ text: e.message });
      return { error: e.message, success: false };
    });
};

// Get data for investment complex calculator graph
export const getInvestmentCalculationData = params => {
  return axios
    .get(Pathes.Investing.investmentCalculator + getQuery(params))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);

      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => {
      return { error: e.message, success: false };
    });
};
