import React from 'react';
import FoundersActive from './active';
import FoundersAll from './all';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import './index.scss';

const FoundersPage = () => {
  return (
    <div className="founders-page">
      <div className="container-inner">
        <h1 className="founders-page__title f-42 f-500">Founders</h1>
        <FoundersActive />
        <FoundersAll />

        <MiniFaqs source={MINIFAQ_SOURCES.founders} />
      </div>
    </div>
  );
};

export default FoundersPage;
