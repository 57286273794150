// export const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#\$%\^&\*])(?=.{8,})");
export const MONEY_REGEX = /^(?:[$]|)[1-9]*[0-9]+(\.[0-9]{1,2})?$/g;
export const CYRILLIC_REGEX = /[\u0400-\u04FF]/;
export const HELP_URL_REGEX =
  /https:\/\/(test-promo.|promo.|staging-investor.)?scrambleup.com\/help/gm;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
export const DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/g;

export const DATE_FORMAT_DD_MMMM = 'DD MMMM';
export const DATE_FORMAT_D_MMMM = 'D MMMM';
export const DATE_FORMAT_D = 'D';
export const DATE_FORMAT_M = 'M';
export const DATE_FORMAT_DD_MMM_YYYY = 'DD MMM YYYY';
export const DATE_FORMAT_MMM_DD = 'MMM DD';
export const DATE_FORMAT_MMM_D = 'MMM D';
export const DATE_FORMAT_DD_MMM = 'DD MMM';
export const DATE_REQUEST = 'YYYY-MM-DD';
export const DATE_FORMAT_DD_MMMM_YYYY = 'DD MMMM, YYYY';
export const DATE_FORMAT_MM_YYYY = 'MM/YYYY';
export const DATE_FORMAT_YYYY = 'YYYY';
export const DATE_FORMAT_DD_MM_YYYY = 'DD.MM.YYYY';

export const PHOTO_ALLOWED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];
export const BANK_STATEMENT_ALLOWED_FORMATS = ['application/pdf'];

export const ALLOWED_FILE_SIZES = {
  '1mb': 1048576,
  '12mb': 12582912,
};
