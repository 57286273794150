import React from 'react';
import ThemesModule from '../../containers/ThemesModule';
import './index.scss';

const ThemesPage = props => (
  <div className="themes-page">
    <div  className="container-inner">
      <h1 className="themes-page__title f-42">Investment themes</h1>
      <p className="themes-page__desc f-24">Select your preferred investment themes. In the next fundraising, your funds will be allocated among the founders who fit your selected themes. Each founder can qualify for more than one theme.</p>
      <ThemesModule {...props} />
    </div>
  </div>
);

export default ThemesPage;