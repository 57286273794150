import React from 'react';
import * as classnames from 'classnames';
import Avatar from '../../UI/Avatar';
import {getFullname} from '../../../helpers/utils';
import './index.scss';

const UserCard = ({ user, onClick, className }) => {
  const fullname = getFullname(user.first_name, user.last_name)
  return (
    <div className={classnames("user-card", "hov", onClick && "user-card__pointer", className)} onClick={onClick}>
      <Avatar src={user.photo && user.photo.file} alt={fullname} />
      <div className="user-card__content">
        <h5 className="user-card__title f-500 f-18 line2tl">{fullname}</h5>
        <p className="user-card__desc f-14 tl">{user.company ? (typeof user.company === "string" ? user.company : user.company.name) : ''}}</p>
      </div>
    </div>
  );
};

export default UserCard;