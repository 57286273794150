import * as React from 'react';
import SignIn from '../../containers/SignIn';
import BasicLayout from '../../layouts/BasicLayout';

const SignInPage = () => (
  <BasicLayout>
    <div className="container">
      <SignIn />
    </div>
  </BasicLayout>
);

export default SignInPage;
