import React from 'react';
import {connect} from 'react-redux';
import Preloader from '../../components/Preloader';
import {getParticipatingInvestors} from '../../store/actions/investorActions';
import InvestorBigCard from '../../components/Cards/InvestorBigCard';

class InvestorsActive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    this.props.getParticipatingInvestors({page: 1, limit: 1000})
      .finally(this.setState(prevState => ({...prevState, loading: false})));
  }

  render() {
    const {investors} = this.props;
    const hasInvestors = !!(investors && investors.list.length);

    return (
      <div className="investors-active">
        <h2 className="f-26 f-500">Investors in this round</h2>
        {this.state.loading
          ? <Preloader className="investors-active__preloader" />
          : hasInvestors
            ? (
              <div className="investors-active__list">
                {investors.list.map(investor => (
                  <InvestorBigCard
                    key={investor.id}
                    investor={investor}
                    disablePrivateNumber={true}
                    className="investors-active__item"
                    active
                  />
                ))}
              </div>
            ) : (
          <div className="investors-active__empty">
            <span className="investors-active__empty-title f-16 f-400">No active investors yet.</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investors: state.investorStore.participatingInvestors,
});

const mapDispatchToProps = dispatch => ({
  getParticipatingInvestors: params => dispatch(getParticipatingInvestors(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestorsActive);