import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import config from '../../../config';
import { MyLink } from '../../../components/MyLink';
import { getAgreementsByFundraisingID } from '../../../store/actions/loanActions';
import s from './index.module.scss';

export const FinanceAgreements = ({ fundraisingID, info, group }) => {
  const dispatch = useDispatch();
  const { agreementsByFundraisingID } = useSelector(state => state.loanStore);

  useEffect(() => {
    dispatch(getAgreementsByFundraisingID(fundraisingID, { group }));
  }, [fundraisingID, group]);

  const { data: agreements } = agreementsByFundraisingID;

  return (
    <div className={classnames(s.contracts, info.insurance_strategy)}>
      <div className={s.header}>
        <h2 className="f-26 f-500">Financing agreements</h2>
        {/* <RoundLink */}
        {/*   label="Download all files (pdf)" */}
        {/*   path={`${config.apiURL}fundraisings/${fundraisingID}/agreements/`} */}
        {/*   className={s.download} */}
        {/*   fillBackground */}
        {/*   isExternal */}
        {/* /> */}
      </div>

      {agreements?.length ? (
        <div className={s.table}>
          <div className={s.thead}>
            <div className={s.thead_col}>Loan type</div>
            <div className={s.thead_col}>Loan number</div>
            <div className={s.thead_col}>Financing agreement</div>
            {/* <div className={s.thead_col}>Surety agreement</div> */}
          </div>
          <div className={s.tbody}>
            {agreements.map(agreement => (
              <div className={s.tbody_row} key={agreement.id}>
                <div className={s.tbody_col}>{agreement.risk_group_title}</div>
                <div className={s.tbody_col}>{`Loan #${agreement.id}`}</div>
                <div className={s.tbody_col}>
                  <MyLink
                    href={`${config.apiURL}loans/${agreement.id}/agreement/`}
                    isExternal
                  >
                    Download
                  </MyLink>
                </div>
                {/* <div className={s.tbody_col}> */}
                {/*   <MyLink */}
                {/*     href={`${config.apiURL}loans/${agreement.id}/surety_agreement/`} */}
                {/*     isExternal */}
                {/*   > */}
                {/*     Download */}
                {/*   </MyLink> */}
                {/* </div> */}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={classnames(s.empty_message, 'f-16')}>
          No Financing agreements yet.
        </div>
      )}
    </div>
  );
};
