import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getLoanTerms } from '../../../store/actions/loanActions';
import { GROUP_DETAIL } from './data';
import BackButton from '../../../components/UI/BackButton';
import { canGoBack } from '../../../helpers/utils';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

const LoanGroupDetailPage = ({ history }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { innerWidth } = useSelector(state => state.commonStore);
  const { loanTerms } = useSelector(state => state.loanStore);

  useEffect(() => {
    dispatch(getLoanTerms());
  }, []);

  const {
    title,
    termsSection,
    fundsUsageSection,
    fundsProtectionSection,
    repaymentSection,
    agreementSection,
  } = GROUP_DETAIL[pathname](loanTerms.data, innerWidth);

  return (
    <div className="loan_group_detail_page">
      <div className="container-inner">
        <BackButton
          onClick={() =>
            canGoBack(history)
              ? history.goBack()
              : history.push(APP_LINKS.helpLoanTerms)
          }
        />
        <h1 className="f-42 f-500">{title}</h1>
        {termsSection}
        {fundsUsageSection}
        {fundsProtectionSection}
        {repaymentSection}
        <div className="loan_group_detail_page__fees f-16-24 f-400">
          <b>No fees on investor capital</b>: annual management, transaction, or
          profit-sharing fees for the investors.
        </div>
        {agreementSection}
      </div>
    </div>
  );
};

export default LoanGroupDetailPage;
