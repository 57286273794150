import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GROUP_DETAIL } from './data';
import BackButton from '../../../components/BackButton';
import { canGoBack } from '../../../helpers/utils';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

export const LoanGroupDetailPage = ({ history }) => {
  const { pathname } = useLocation();
  const { innerWidth } = useSelector(state => state.commonStore);

  const {
    title,
    termsSection,
    protectionSection,
    repaymentSection,
    agreementSection,
  } = GROUP_DETAIL[pathname](innerWidth);

  return (
    <div className="loan_group_detail_v1_page">
      <div className="container-inner">
        <BackButton
          onClick={() =>
            canGoBack(history)
              ? history.goBack()
              : history.push(APP_LINKS.helpLoanTermsV1)
          }
        />
        <h1 className="f-42 f-500">{title}</h1>
        {termsSection}
        {protectionSection}
        {repaymentSection}
        {agreementSection}
      </div>
    </div>
  );
};

export default LoanGroupDetailPage;
