import React, { useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import WarningBlock from '../../UI/WarningBlock';
import {
  PROCESSING_TIME,
  STRIPE_PAYMENT_TYPES,
} from '../../Forms/PaymentForm/PaymentMethod';
import { prettyFloatMoney } from '../../../helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../helpers/common';
import { getPendingTransactions } from '../../../store/actions/fundActions';

const P_METHODS = {
  [STRIPE_PAYMENT_TYPES.sepa_debit]: 'SEPA Direct Debit',
  [STRIPE_PAYMENT_TYPES.sofort]: 'Sofort',
};

export const PendingTransactionsWarning = ({
  transactionDetail,
  className,
}) => {
  const { pendingTransactions } = useSelector(state => state.fundStore);
  const dispatch = useDispatch();
  const netAmount = transactionDetail?.net_amount;
  let content = null;

  useEffect(() => {
    dispatch(getPendingTransactions());
  }, []);

  const transactions = transactionDetail
    ? transactionDetail.pending_transactions
    : pendingTransactions || [];

  const methods = [...new Set(transactions.map(i => i.payment_method))];

  if (netAmount) {
    content = (
      <div className="f-16 f-400">
        Your payment of{' '}
        <span className="f-500">{prettyFloatMoney(netAmount)}</span> was
        successfully transferred to your Scramble account.
      </div>
    );
  } else {
    // Check if transactions are single or same
    if (transactions.length === 1 || methods.length === 1) {
      if (transactions.length === 1) {
        const singleTransaction = transactions[0];
        content = (
          <div className="f-16 f-400">
            Your payment of{' '}
            <span className="f-500">
              {prettyFloatMoney(singleTransaction.pending_amount)}
            </span>{' '}
            created {moment().format(DATE_FORMAT_DD_MMM_YYYY)} from the{' '}
            {P_METHODS[singleTransaction.payment_method]} is in process.
            Processing time is typically{' '}
            {PROCESSING_TIME[singleTransaction.payment_method]} (but up to 14)
            business days. As soon as you receive cash into your Scramble
            account, we will send you an email.
          </div>
        );
      } else {
        content = (
          <div className="f-16 f-400">
            <div>
              Your payments from the {P_METHODS[transactions[0].payment_method]}{' '}
              are in process:
            </div>
            <ul className="scr-circle-list" style={{ marginTop: '8px' }}>
              {transactions.map((item, idx) => (
                <li key={idx}>
                  <span className="f-500">
                    {prettyFloatMoney(item.pending_amount)}
                  </span>{' '}
                  created{' '}
                  {moment(item.created_at).format(DATE_FORMAT_DD_MMM_YYYY)};
                </li>
              ))}
            </ul>
            <div style={{ marginTop: '8px' }}>
              Processing time is typically{' '}
              {PROCESSING_TIME[transactions[0].payment_method]} (but up to 14)
              business days. As soon as you receive cash into your Scramble
              account, we will send you an email.
            </div>
          </div>
        );
      }
    }

    if (methods.length > 1) {
      content = (
        <div className="f-16 f-400">
          <div>Your payments are in process:</div>
          <ul className="scr-circle-list" style={{ marginTop: '8px' }}>
            {transactions.map((item, idx) => (
              <li key={idx}>
                <span className="f-500">
                  {prettyFloatMoney(item.pending_amount)}
                </span>{' '}
                created{' '}
                {moment(item.created_at).format(DATE_FORMAT_DD_MMM_YYYY)} from
                the {P_METHODS[item.payment_method]}. Processing time is
                typically{' '}
                {item.payment_method === STRIPE_PAYMENT_TYPES.sepa_debit
                  ? PROCESSING_TIME.sepa_debit
                  : PROCESSING_TIME.sofort}{' '}
                (but up to 14) business days;
              </li>
            ))}
          </ul>
          <div style={{ marginTop: '8px' }}>
            As soon as you receive cash into your Scramble account, we will send
            you an email.
          </div>
        </div>
      );
    }
  }

  if (!content) {
    return null;
  }

  return (
    <WarningBlock withBorder className={classnames(className)} isSuccess>
      {content}
    </WarningBlock>
  );
};
