import React from 'react';
import {RoundButton} from '../../UI/RoundButton';
import './index.scss';

const StandardConfirm = ({onAccept, onDecline, onClose}) => (
  <div className="standard-confirm">
    <div className="standard-confirm__title f-42 f-500">Are you sure?</div>
    <div className="standard-confirm__buttons">
      <RoundButton
        type="button"
        label="Yes"
        fullWidth
        fillBackground
        onClick={onAccept}
        className="swap-confirm__accept"
      />
      <RoundButton
        type="button"
        label="No"
        fullWidth
        className="swap-confirm__reject"
        onClick={() => {
          onDecline ? onDecline() : onClose();
        }}
      />
    </div>
  </div>
);

export default StandardConfirm;