import React from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const HeightToWidthRatio = ({
  width = '50%',
  ratio = '100%',
  className,
  children,
}) => {
  return (
    <div
      className={classnames(s.ratio, className)}
      style={{ width: '100%', maxWidth: width }}
    >
      <div className={classnames(s.ratio_inner)} style={{ paddingTop: ratio }}>
        <div className={s.ratio_content}>{children}</div>
      </div>
    </div>
  );
};
