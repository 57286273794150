import React, { useEffect } from 'react';
import { scrollTop } from '../../../helpers/utils';
import VeriffForm from '../../../components/Forms/VeriffForm';
import { STRATEGIES } from '../InvSelectStrategy';
import Dropdown from '../../../components/Dropdown';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import PriorityFoundersGroupedList from '../InvAllocateFunds/priority';
import s from './index.module.scss';

const InvVeriff = ({ status, setStep, onSuccess }) => {
  useEffect(() => {
    scrollTop();
  }, []);

  const onBack = () => {
    setStep(2);
  };

  return (
    <div className={s.wrapper}>
      <VeriffForm
        disableSkip
        onBack={onBack}
        onSuccess={onSuccess}
        backLabel="Go back"
      >
        <div className={s.founders}>
          {status.option === STRATEGIES.standard && (
            <Dropdown title="Consumer Goods Brands batch">
              <ParticipatingStartupsList />
            </Dropdown>
          )}

          {status.option === STRATEGIES.priority && (
            <PriorityFoundersGroupedList />
          )}
        </div>
      </VeriffForm>
    </div>
  );
};

export default InvVeriff;
