import moment from 'moment';
import { LOAN_GROUPS } from './constants';
import { round } from './utils';

const MODERATE = 9;
const CONSERVATIVE = 5;

export const calculateGroupA = (amount, options = {}) => {
  const { month = 6, endDate, useMonthPrefix } = options;
  const earning = (Number(amount) * CONSERVATIVE) / 100;
  const schedule = [
    {
      month: endDate
        ? moment(endDate).add(1, 'day').format('DD.MM.YYYY')
        : `After the round`,
      earnings: earning,
      principal: round((Number(amount) * 1.588235294) / 100),
      total: round((Number(amount) * 1.588235294) / 100 + earning),
    },
    ...new Array(month).fill('').map((_, index) => {
      let month = '';
      if (useMonthPrefix) {
        let prefix = 'th';
        if (index === 0) {
          prefix = 'st';
        }
        if (index === 1 || index === 2) {
          prefix = 'd';
        }
        month = `${index + 1}${prefix} month`;
      } else {
        month = moment()
          .add(index + 1, 'months')
          .format('05.MM.YYYY');
      }

      return {
        month,
        earnings: 0,
        principal: round(
          (Number(amount) * (index === 5 ? 74.88235294 : 4.705882353)) / 100
        ),
        total: round(
          (Number(amount) * (index === 5 ? 74.88235294 : 4.705882353)) / 100
        ),
      };
    }),
  ];

  const lastEl = schedule[schedule.length - 1];
  lastEl.principal =
    amount -
    5 * round((Number(amount) * 4.705882353) / 100) -
    schedule[0].principal;

  lastEl.total = lastEl.principal + lastEl.earnings;

  return {
    amount,
    group: 'Group A',
    total_earning: earning,
    total_principal: amount,
    total: Number(amount) + Number(earning),
    list: schedule,
  };
};

export const calculateGroupB = (amount, options = {}) => {
  const { month = 6, useMonthPrefix } = options;
  const earning = (Number(amount) * MODERATE) / 100;
  return {
    amount,
    group: 'Group B',
    total_earning: earning,
    total_principal: amount,
    total: Number(amount) + Number(earning),
    list: new Array(month).fill('').map((_, index) => {
      let month = '';
      if (useMonthPrefix) {
        let prefix = 'th';
        if (index === 0) {
          prefix = 'st';
        }
        if (index === 1 || index === 2) {
          prefix = 'd';
        }
        month = `${index + 1}${prefix} month`;
      } else {
        month = moment()
          .add(index + 1, 'months')
          .format('05.MM.YYYY');
      }

      return {
        month,
        earnings: index === 5 ? earning : 0,
        principal: index === 5 ? amount : 0,
        total: index === 5 ? Number(amount) + Number(earning) : 0,
      };
    }),
  };
};

export const mergeCalculations = (data, endDate) => {
  const calculations = data.map(item => {
    if (item.groupID === LOAN_GROUPS.conservative.id) {
      return calculateGroupA(item.amount, { endDate });
    }
    if (item.groupID === LOAN_GROUPS.moderate.id) {
      return calculateGroupB(item.amount);
    }
  });

  if (!calculations[1]) {
    return calculations[0];
  }

  const groupA = calculations[0];
  const groupB = {
    ...calculations[1],
    list: [
      {
        month: endDate
          ? moment(endDate).add(1, 'day').format('DD.MM.YYYY')
          : `After the round`,
        earnings: 0,
        principal: 0,
        total: 0,
      },
      ...calculations[1].list,
    ],
  };

  const totalAmount = groupA.amount + groupB.amount;
  const totalGroup = `${groupA.group} and ${groupB.group}`;
  const totalEarning = groupA.total_earning + groupB.total_earning;

  return {
    amount: totalAmount,
    group: totalGroup,
    total_earning: totalEarning,
    total_principal: totalAmount,
    total: Number(totalAmount) + Number(totalEarning),
    list: new Array(7).fill('').map((_, index) => {
      const earnings =
        groupA.list[index].earnings + groupB.list[index].earnings;

      const principal =
        groupA.list[index].principal + groupB.list[index].principal;

      const total = groupA.list[index].total + groupB.list[index].total;

      const month =
        index === 0
          ? moment(endDate).add(1, 'day').format('DD.MM.YYYY')
          : moment().add(index, 'months').format('05.MM.YYYY');

      return {
        month,
        earnings,
        principal,
        total,
      };
    }),
  };
};
