import React from 'react';
import { useSelector } from 'react-redux';
import s from './index.module.scss';

export const ProgressLine = ({ percent }) => {
  const { innerWidth } = useSelector(state => state.commonStore);
  const isMobile = innerWidth < 700;

  const style = {};
  if (percent < 50) {
    if (percent > 20) {
      style.right = isMobile ? '-20px' : '-3px';
    } else {
      style.right = isMobile ? '-46px' : '-57px';
    }
  } else if (percent < 100) {
    style.right = isMobile ? '-21px' : '-3px';
  } else {
    style.right = isMobile ? '-1px' : '-1px';
  }

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div
          className={s.progress}
          style={{ width: `${percent > 100 ? 100 : percent}%` }}
          data-percent={percent}
        />
      </div>
      <div
        className={s.pointer}
        style={{ width: `${percent > 100 ? 100 : percent}%` }}
        data-percent={percent}
      >
        <span className="f-20 f-400" style={style}>
          {percent}%
        </span>
      </div>
    </div>
  );
};
