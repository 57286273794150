import React from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { MODAL_TYPES } from '../../ModalProvider';
import { setModals } from '../../../store/actions/commonActions';
import s from './index.module.scss';

export const BonusBanner = ({ className }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.bonus_banner,
      })
    );
  };

  return (
    <div className={classnames(s.wrapper, className)}>
      <StarIcon />
      <div className={classnames(s.content, 'f-20')}>
        Maximize your first earnings with a{' '}
        <span className="f-500">€5 bonus</span> for every{' '}
        <span className="f-500">€100 investment</span>.{' '}
        <button type="button" onClick={onClick} className={s.learn}>
          Learn more
        </button>
      </div>
    </div>
  );
};

const StarIcon = () => (
  <svg
    width="58"
    height="58"
    style={{ minWidth: '58px' }}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.1454 29C57.1454 44.7473 44.5738 57.5 29.0819 57.5C13.5899 57.5 1.01831 44.7473 1.01831 29C1.01831 13.2527 13.5899 0.5 29.0819 0.5C44.5738 0.5 57.1454 13.2527 57.1454 29Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M30.2642 15.2493L33.6581 22.2752C33.7525 22.4707 33.892 22.6398 34.0647 22.768C34.2373 22.8961 34.4378 22.9794 34.649 23.0108L42.2368 24.1376C42.4798 24.1737 42.7081 24.2785 42.8959 24.4403C43.0837 24.6021 43.2234 24.8143 43.2993 25.053C43.3751 25.2917 43.3841 25.5473 43.3252 25.7909C43.2662 26.0346 43.1417 26.2565 42.9658 26.4316L37.4743 31.9004C37.1638 32.2096 37.0229 32.6547 37.0953 33.0904L38.3915 40.8129C38.433 41.0601 38.4059 41.3144 38.3134 41.5467C38.2209 41.7791 38.0665 41.9804 37.8679 42.1279C37.6692 42.2753 37.4342 42.363 37.1893 42.3811C36.9444 42.3992 36.6994 42.3469 36.4821 42.2301L29.6944 38.5834C29.5054 38.4821 29.2952 38.4292 29.0818 38.4292C28.8684 38.4292 28.6582 38.4821 28.4692 38.5834L21.6816 42.2301C21.4642 42.3469 21.2193 42.3992 20.9744 42.3811C20.7295 42.363 20.4944 42.2753 20.2958 42.1279C20.0971 41.9804 19.9428 41.7791 19.8502 41.5467C19.7577 41.3144 19.7306 41.0601 19.7721 40.8129L21.0683 33.0904C21.1044 32.8755 21.0888 32.6549 21.0227 32.4475C20.9567 32.2402 20.8423 32.0524 20.6893 31.9004L15.1978 26.4316C15.0219 26.2565 14.8974 26.0346 14.8385 25.7909C14.7796 25.5473 14.7885 25.2917 14.8644 25.053C14.9402 24.8143 15.08 24.6021 15.2677 24.4403C15.4555 24.2785 15.6838 24.1737 15.9269 24.1376L23.5147 23.0108C23.7258 22.9794 23.9264 22.8961 24.099 22.768C24.2716 22.6398 24.4112 22.4707 24.5056 22.2752L27.8994 15.2493C28.3863 14.2502 29.7813 14.2502 30.2642 15.2493Z"
      fill="black"
    />
  </svg>
);
