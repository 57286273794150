import { METADATA } from '../../helpers/metadata';
import { GET_QUESTIONS_LIST } from '../types/questionTypes';

const initialState = {
  questions: { ...METADATA.default, data: null },
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS_LIST.REQUEST:
      return {
        ...state,
        questions: { ...state.questions, ...METADATA.request },
      };
    case GET_QUESTIONS_LIST.SUCCESS:
      return {
        ...state,
        questions: { ...METADATA.success, data: action.payload },
      };
    case GET_QUESTIONS_LIST.FAILURE:
      return {
        ...state,
        questions: {
          ...state.questions,
          ...METADATA.error,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default questionReducer;
