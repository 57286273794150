import React from 'react';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import RepaymentScheduleModule from '../../containers/RepaymentScheduleModule';
import './index.scss';

const LoansSchedulePage = () => (
  <div className="loans_schedule_page">
    <RepaymentScheduleModule />
    <MiniFaqs source={MINIFAQ_SOURCES.loans} />
  </div>
);

export default LoansSchedulePage;
