import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserProfile,
  getVeriffStatus,
} from '../../store/actions/profileActions';
import { getUserLocation, logoutUser } from '../../store/actions/userActions';
import { getInvestingStatus } from '../../store/actions/investActions';
import ProfileHeader from '../../containers/ProfileModule/ProfileHeader';
import { RoundButton } from '../../components/UI/RoundButton';
import {
  FUNDS_ORIGIN,
  PLANNED_MONTHLY_INVESTMENT,
  USER_STATUSES,
  VERIFF_CODES,
} from '../../helpers/constants';
import { INVESTOR_TYPES } from '../../components/Forms/InvestorTypeForm';
import VerifyForm, { SOURCES } from '../../components/VerifyForm';
import { APP_LINKS } from '../../helpers/links';
import { MODAL_TYPES } from '../../components/ModalProvider';
import { setModals } from '../../store/actions/commonActions';
import WarningBlock from '../../components/UI/WarningBlock';
import { WarningIcon, SuccessIcon } from '../../components/UI/Icons';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { sendVerificationCode } from '../../store/services/userServices';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
  isPassedVeriff,
  isVerifiedPhoneNumber,
} from '../../helpers/user';
import './index.scss';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { profile, veriffStatusCode } = useSelector(
    state => state.profileStore
  );
  const { data: user } = profile;

  const preSavedForms = useRef({});

  const hasMissingProfileDetails = useMemo(
    () => !hasRequiredProfileSectionFields(user),
    [user]
  );

  const hasMissingPersonalInfo = useMemo(
    () => !hasRequiredPersonalSectionFields(user),
    [user]
  );

  const hasNotVerifiedPhoneNumber = useMemo(
    () => !isVerifiedPhoneNumber(user),
    [user]
  );

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getUserLocation());
    dispatch(getInvestingStatus());
    dispatch(getVeriffStatus());
  }, []);

  useEffect(() => {
    const chat = document.getElementById('tidio-chat');
    if (chat) {
      chat.style.display = 'none';
    }
    return () => {
      if (chat) {
        chat.style.display = 'unset';
      }
    };
  }, []);

  const onLogout = () =>
    dispatch(logoutUser(APP_LINKS.profile.replace('/', '')));

  const onChangePassword = () =>
    dispatch(setModals({ type: MODAL_TYPES.change_password }));

  const onAvatarEdit = () =>
    dispatch(setModals({ type: MODAL_TYPES.profile_avatar }));

  const onProfileEdit = () =>
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_detail,
        preSaved: preSavedForms.current[MODAL_TYPES.profile_detail],
        onOutsideClick: formValues => {
          preSavedForms.current[MODAL_TYPES.profile_detail] = formValues;
        },
      })
    );
  const onPersonalEdit = () =>
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_personal,
        preSaved: preSavedForms.current[MODAL_TYPES.profile_personal],
        onOutsideClick: formValues => {
          preSavedForms.current[MODAL_TYPES.profile_personal] = formValues;
        },
      })
    );

  const onInvestorTypeEdit = () =>
    dispatch(
      setModals({
        type: MODAL_TYPES.investor_type,
        preSaved: preSavedForms.current[MODAL_TYPES.investor_type],
        onOutsideClick: formValues => {
          preSavedForms.current[MODAL_TYPES.investor_type] = formValues;
        },
      })
    );

  const onPhoneVerify = async () => {
    await sendVerificationCode(user?.phone);
    dispatch(
      setModals({
        type: MODAL_TYPES.phone_verification,
      })
    );
  };

  return (
    <div className="profile_page">
      <div className="container-inner">
        <ProfileHeader onPhotoChange={onAvatarEdit} />

        {user?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="profile_page__suspend" />
        ) : (
          (hasMissingProfileDetails ||
            hasMissingPersonalInfo ||
            !isVerifiedPhoneNumber(user) ||
            !isPassedVeriff(user)) && (
            <WarningBlock className="profile_page__warning" withBorder>
              <div className="f-16 f-400">
                Provide your details to start investing.
              </div>
            </WarningBlock>
          )
        )}

        <section className="profile_page__details">
          <div className="profile_page__section_header">
            <h2 className="f-26 f-500">Profile details</h2>
            {hasMissingProfileDetails && <WarningIcon />}
          </div>

          <div className="profile_page__box">
            <div className="profile_page__box_col">
              <div className="profile_page__first_name">
                <div className="f-14 f-400">First name</div>
                <div className="f-16 f-400">{user?.first_name || '-'}</div>
              </div>
              <div className="profile_page__last_name">
                <div className="f-14 f-400">Last name</div>
                <div className="f-16 f-400">{user?.last_name || '-'}</div>
              </div>
            </div>
            <div className="profile_page__box_col">
              <div className="profile_page__company">
                <div className="f-14 f-400">Company</div>
                <div className="f-16 f-400">
                  {user?.investor?.company_name || '-'}
                </div>
              </div>
              <div className="profile_page__position">
                <div className="f-14 f-400">Position</div>
                <div className="f-16 f-400">
                  {user?.investor?.position || '-'}
                </div>
              </div>
            </div>
            <div className="profile_page__box_col">
              {user && (
                <RoundButton
                  label="Edit profile details"
                  onClick={onProfileEdit}
                  fullWidth
                  className="profile_page__edit"
                />
              )}
            </div>
          </div>
        </section>

        <section className="profile_page__personal">
          <div className="profile_page__section_header">
            <h2 className="f-26 f-500">Personal information</h2>
            {(hasMissingPersonalInfo || hasNotVerifiedPhoneNumber) && (
              <WarningIcon />
            )}
          </div>

          <div className="profile_page__box">
            <div className="profile_page__box_col">
              <div className="profile_page__phone">
                <div className="f-14 f-400">Phone number</div>
                <div className="profile_page__phone_value">
                  <div className="f-16 f-400">{user?.phone || '-'}</div>
                  {isVerifiedPhoneNumber(user) && (
                    <SuccessIcon className="profile_page__phone_verified" />
                  )}
                  {user?.phone &&
                    !isVerifiedPhoneNumber(user) &&
                    !['RU', 'BY'].includes(user?.country_code) && (
                      <RoundButton
                        className="profile_page__phone_verify"
                        label={() => 'Verify'}
                        fillBackground
                        onClick={onPhoneVerify}
                      />
                    )}
                </div>
              </div>
              <div className="profile_page__citizenship">
                <div className="f-14 f-400">Citizenship</div>
                <div className="f-16 f-400">{user?.citizenship || '-'}</div>
              </div>
              <div className="profile_page__funds_origin">
                <div className="f-14 f-400">Origin of funds</div>
                <div className="f-16 f-400">
                  {FUNDS_ORIGIN[user?.investor?.origin_of_funds] || '-'}
                </div>
              </div>
              <div className="profile_page__investment">
                <div className="f-14 f-400">Planned monthly investment</div>
                <div className="f-16 f-400">
                  {PLANNED_MONTHLY_INVESTMENT[
                    user?.investor?.monthly_investment
                  ] || '-'}
                </div>
              </div>
            </div>
            <div className="profile_page__box_col">
              <div className="profile_page__country">
                <div className="f-14 f-400">Residence country</div>
                <div className="f-16 f-400">{user?.country || '-'}</div>
              </div>
              <div className="profile_page__city">
                <div className="f-14 f-400">Residence city</div>
                <div className="f-16 f-400">{user?.city || '-'}</div>
              </div>
              <div className="profile_page__address">
                <div className="f-14 f-400">Residence address</div>
                <div className="f-16 f-400">{user?.address || '-'}</div>
              </div>
              <div className="profile_page__postal">
                <div className="f-14 f-400">Residence postal code</div>
                <div className="f-16 f-400">{user?.postal_code || '-'}</div>
              </div>
            </div>
            <div className="profile_page__box_col">
              {user && (
                <RoundButton
                  label="Edit personal information"
                  onClick={onPersonalEdit}
                  fullWidth
                  className="profile_page__edit"
                />
              )}
            </div>
          </div>
        </section>

        {user?.country_code === 'GB' && (
          <section className="profile_page__investor_type">
            <div className="profile_page__section_header">
              <h2 className="f-26 f-500">Type of investor</h2>
              {!user.investor.investor_type && <WarningIcon />}
            </div>

            <div className="profile_page__investor_type_container">
              <div className="profile_page__investor_type_content">
                {user.investor.investor_type ? (
                  <>
                    <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                      {INVESTOR_TYPES[user.investor.investor_type].title}
                    </h2>
                    <p className="f-16 f-400">
                      {INVESTOR_TYPES[user.investor.investor_type].description}
                    </p>
                  </>
                ) : (
                  <div className="profile_page__investor_type_empty">
                    Select your type of investor.
                  </div>
                )}
              </div>
              <div className="profile_page__investor_type_edit">
                <RoundButton
                  label="Edit"
                  fullWidth
                  onClick={onInvestorTypeEdit}
                  className="profile_page__edit"
                />
              </div>
            </div>
          </section>
        )}

        {user && user.status !== USER_STATUSES.suspended && (
          <section className="profile_page__veriff">
            <div className="profile_page__section_header">
              <h2 className="f-26 f-500">
                {veriffStatusCode === VERIFF_CODES.verified
                  ? 'Identity verification'
                  : 'Verify your identity'}
              </h2>
              {veriffStatusCode !== VERIFF_CODES.verified && <WarningIcon />}
            </div>

            <div className="profile_page__box">
              <div className="profile_page__box_col">
                {veriffStatusCode === VERIFF_CODES.verified ? (
                  <div className="profile_page__veriff_status">
                    <div className="f-14 f-400">Status</div>
                    <div className="f-16 f-400">
                      Your identity was successfully verified
                    </div>
                  </div>
                ) : [VERIFF_CODES.submitted, VERIFF_CODES.reSubmitted].includes(
                    veriffStatusCode
                  ) ? (
                  <div className="profile_page__veriff_status">
                    <div className="f-14 f-400">Status</div>
                    <div className="f-16 f-400">
                      Your identity is being verified by Veriff. It takes only a
                      couple of minutes.
                    </div>
                  </div>
                ) : (
                  <div className="profile_page__veriff_form">
                    <VerifyForm source={SOURCES.profile} />
                  </div>
                )}
              </div>
            </div>
          </section>
        )}

        <section className="profile_page__buttons">
          {user && (
            <RoundButton
              label="Change password"
              onClick={onChangePassword}
              fullWidth
              className="profile_page__reset_password"
            />
          )}
          <RoundButton
            label="Log out"
            onClick={onLogout}
            fullWidth
            className="profile_page__logout"
          />
        </section>
      </div>
    </div>
  );
};

export default ProfilePage;
