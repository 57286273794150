import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import axios from '../axios-api';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import { logoutUser } from './actions/userActions';
import themeReducer from './reducers/themeReducer';
import commonReducer from './reducers/commonReducer';
import dashboardReducer from './reducers/dashboardReducer';
import profileReducer from './reducers/profileReducer';
import loanReducer from './reducers/loanReducer';
import activityReducer from './reducers/activityReducer';
import userReducer, {
  initialState as userStoreInitialState,
} from './reducers/userReducer';
import investReducer, {
  initialState as investStoreInitialState,
} from './reducers/investReducer';
import fundReducer, {
  initialState as fundStoreInitialState,
} from './reducers/fundReducer';
import founderReducer from './reducers/founderReducer';
import investorReducer from './reducers/investorReducer';
import helpReducer from './reducers/helpReducer';
import companyReducer from './reducers/companyReducer';
import referralReducer from './reducers/referralReducer';
import startupReducer from './reducers/startupReducer';
import questionReducer from './reducers/questionReducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  userStore: userReducer,
  themeStore: themeReducer,
  commonStore: commonReducer,
  dashboardStore: dashboardReducer,
  profileStore: profileReducer,
  fundStore: fundReducer,
  loanStore: loanReducer,
  activityStore: activityReducer,
  investStore: investReducer,
  founderStore: founderReducer,
  startupStore: startupReducer,
  investorStore: investorReducer,
  helpStore: helpReducer,
  companyStore: companyReducer,
  referralStore: referralReducer,
  questionStore: questionReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    diff: true,
    collapsed: true,
    titleFormatter: action => `[action] ${action.type}`,
    level: { prevState: false, nextState: false, error: false },
  });
  middleware.push(logger);
}

const enhancers = composeEnhancers(applyMiddleware(...middleware));

let persistedState;
const savedData = loadFromLocalStorage();

if (savedData) {
  persistedState = {
    userStore: {
      ...userStoreInitialState,
      ...savedData.userStore,
    },
    investStore: {
      ...investStoreInitialState,
      ...savedData.investStore,
    },
    fundStore: {
      ...fundStoreInitialState,
      ...savedData.fundStore,
    },
  };
}

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveToLocalStorage({
    userStore: {
      user: store.getState().userStore.user,
      token: store.getState().userStore.token,
    },
    investStore: {
      investmentConfirmation:
        store.getState().investStore.investmentConfirmation,
    },
    fundStore: {
      allocateInfo: store.getState().fundStore.allocateInfo,
      localAccounts: store.getState().fundStore.localAccounts,
    },
  });
});

axios.interceptors.request.use(config => {
  try {
    const token =
      store.getState().userStore.temp_token || store.getState().userStore.token;
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
  } catch (e) {
    // do nothing, user is not logged in
  }
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch(logoutUser());
    }
    return error.response
      ? error.response
      : { data: { message: error.message } };
  }
);

export default store;
