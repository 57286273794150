import {METADATA} from '../../helpers/metadata';
import {SET_PARTICIPATING_FOUNDERS, SET_INACTIVE_FOUNDERS, GET_FOUNDER_FR_STATS} from '../types/founderTypes';

const initialState = {
  participatingFounders: null,
  inactiveFounders: null,
  founderFundraisingStats: {...METADATA.default, data: null},
};

const founderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTICIPATING_FOUNDERS:
      return { ...state, participatingFounders: action.payload };
    case SET_INACTIVE_FOUNDERS:
      return { ...state, inactiveFounders: action.payload };
    case GET_FOUNDER_FR_STATS.REQUEST:
      return { ...state, founderFundraisingStats: { ...state.founderFundraisingStats, ...METADATA.request }};
    case GET_FOUNDER_FR_STATS.SUCCESS:
      return { ...state, founderFundraisingStats: { ...state.founderFundraisingStats, ...METADATA.success, data: action.payload }};
    case GET_FOUNDER_FR_STATS.FAILURE:
      return { ...state, founderFundraisingStats: { ...METADATA.error, error: action.error, data: null }};

    default:
      return state;
  }
};

export default founderReducer;