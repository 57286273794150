import React from 'react';
import qs from 'qs';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { YoutubeEmbed } from './youtube';
import { InstagramEmbed } from './instagram';
import { HeightToWidthRatio } from '../HeightToWidthRatio';
import s from './index.module.scss';
import Analytics from '../../analytics';

export const InfluencerVideo = ({ className }) => {
  const location = useLocation();
  const profile = useSelector(state => state.profileStore.profile.data);
  const countryCode = profile?.country_code;
  const showOnlyFor = ['GR', 'RU', 'BY', 'KZ', 'KG', 'TJ', 'UZ'];
  const { simsimvideo } = qs.parse(location.search.replace('?', ''));

  const renderVideoBasedOnLocation = React.useCallback(
    countryCode => {
      switch (countryCode) {
        case 'GR':
          return <YoutubeEmbed embedId="xlXU6v-wFW0" className={s.youtube} />;
        case 'RU':
        case 'BY':
        case 'KZ':
        case 'KG':
        case 'TJ':
        case 'UZ':
          return (
            <HeightToWidthRatio
              width="500px"
              ratio="170%"
              className={s.instagram_wrapper}
            >
              <InstagramEmbed
                link="https://instagram.com/tv/CiZceO0v-a7/embed"
                className={s.instagram}
              />
            </HeightToWidthRatio>
          );
        default:
          return null;
      }
    },
    [countryCode]
  );

  const onClick = () => {
    Analytics.investingInfluencerVideoStart();
  };

  if (!showOnlyFor.includes(simsimvideo || countryCode)) {
    return null;
  }

  return (
    <div className={classnames(s.wrapper, className)}>
      <h2 className="f-26 f-500">Our investors experience</h2>
      <p className={classnames(s.desc, 'f-16 f-400')}>
        Watch this video, in which our investors share their experiences with
        Scramble.
      </p>
      <div className={s.content} onClick={onClick}>
        {renderVideoBasedOnLocation(simsimvideo || countryCode)}
      </div>
    </div>
  );
};
