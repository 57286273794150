import React from 'react';
import classnames from 'classnames';
import RadioButton from '../../../UI/RadioButton';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import RangeMoneyField from '../../../UI/MoneyField/range';
import s from './index.module.scss';

const MIN_AMOUNT = 10;
const MAX_AMOUNT = 5000;

export const EarningCalculator = ({ className, formikBag }) => {
  const { values, setFieldValue, handleSubmit } = formikBag;
  return (
    <form onSubmit={handleSubmit} className={classnames(s.form, className)}>
      <RangeMoneyField
        name="amount"
        label="My investment"
        onChange={(_, value) => setFieldValue('amount', value || MIN_AMOUNT)}
        value={values.amount.toString()}
        min={MIN_AMOUNT}
        max={MAX_AMOUNT}
        minStep={MIN_AMOUNT}
        maxStep={MAX_AMOUNT}
        inputOptions={{
          allowDecimal: false,
        }}
      />

      {/* <div className={s.range}> */}
      {/*   <div className={s.range_amounts}> */}
      {/*     <div>{prettyMoney(MIN_AMOUNT || 10)}</div> */}
      {/*     <div style={{ textAlign: 'right' }}>{prettyMoney(MAX_AMOUNT)}</div> */}
      {/*   </div> */}
      {/* </div> */}
      <div className={s.groups}>
        <h6>Invest in loan</h6>
        <div className={s.groups_list}>
          <RadioButton
            value={LOAN_GROUPS.conservative.id}
            name="loan"
            checked={values.loan === LOAN_GROUPS.conservative.id}
            onChange={() => setFieldValue('loan', LOAN_GROUPS.conservative.id)}
          >
            <span className={s.loan_label}>Group A</span>
          </RadioButton>

          <RadioButton
            value="conservative"
            name="loan"
            onChange={() => setFieldValue('loan', LOAN_GROUPS.moderate.id)}
            checked={values.loan === LOAN_GROUPS.moderate.id}
          >
            <span className={s.loan_label}>Group B</span>
          </RadioButton>
        </div>
      </div>
    </form>
  );
};
