import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { getLastRoute, removeLastRoute } from '../../../helpers/utils';
import { CONTACT_EMAIL } from '../../../helpers/constants';
import { APP_LINKS } from '../../../helpers/links';
import { VerificationCodeInput } from '../../UI/VerificationCodeInput';
import { RoundButton } from '../../UI/RoundButton';
import { CountdownHour } from '../../CountdownHour';
import { MyLink } from '../../MyLink';
import { submitLoginCode } from '../../../store/actions/userActions';
import {
  getLoginCodeStatus,
  resendLoginCode,
} from '../../../store/services/userServices';
import s from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object({
  code: Yup.array(),
});

const SMS_LIMIT = 3;

export const TwoFactorAuthForm = () => {
  const { token } = useSelector(state => state.userStore);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [expireTime, setExpireTime] = useState(null);
  const [status, setStatus] = useState({
    count: 0,
    last_created_at: null,
    phone_number: '',
  });

  useEffect(() => {
    if (token) {
      redirect();
    } else {
      getStatus();
    }
  }, [token]);

  useEffect(() => {
    if (status.last_created_at) {
      const currentTime = moment();
      const expireTime = moment(status.last_created_at).add(
        status.count >= SMS_LIMIT ? 1 : 1,
        'minutes'
      );

      currentTime.isBefore(expireTime)
        ? setExpireTime(expireTime.toISOString())
        : setExpireTime(null);
    } else {
      setExpireTime(null);
    }
  }, [status]);

  const getStatus = async () => {
    const res = await getLoginCodeStatus();
    res && res.success && setStatus(res.data);
  };

  const resendCode = async () => {
    const res = await resendLoginCode();
    if (res && res.success) {
      setStatus({ ...status, ...res.data.data });
    }
  };

  const onCounterFinish = () => {
    setExpireTime(null);
  };

  const onSubmit = async ({ code }, { setErrors }) => {
    const res = await dispatch(submitLoginCode({ code: code.join('') }));
    res?.success && redirect();

    if (res?.error) {
      setErrors({ code: res.error });
    }
  };

  const redirect = () => {
    const lastRoute = getLastRoute();
    history.push(lastRoute || APP_LINKS.dashboard);
    removeLastRoute();
  };

  const canResend = !(status.count >= SMS_LIMIT && !expireTime);

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        code: [],
      }}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <form className={classnames(s.wrapper)} onSubmit={handleSubmit}>
          <h1 className={classnames(s.title, 'f-500')}>
            Two-step authentication
          </h1>
          <div className={classnames(s.desc, 'f-18-24 f-400')}>
            To continue, please enter the 6-digit verification code sent to your
            phone{' '}
            <span className="f-500">
              ending in{' '}
              {status.phone_number.substring(status.phone_number.length - 4)}
            </span>
            .
          </div>

          {canResend && (
            <div className={classnames(s.desc, 'f-18-24 f-400')}>
              {status.count < SMS_LIMIT ? (
                <>
                  Didn&apos;t receive a code?{' '}
                  {expireTime ? (
                    <CountdownHour
                      endTime={expireTime}
                      onFinish={onCounterFinish}
                      label={time => `Resend code in ${time}`}
                      className={s.countdown}
                    />
                  ) : (
                    status.count < SMS_LIMIT && (
                      <button
                        type="button"
                        onClick={async () => {
                          await resendCode();
                          setFieldValue('code', []);
                        }}
                        className={classnames(s.button_resend, 'underline')}
                      >
                        Resend
                      </button>
                    )
                  )}
                </>
              ) : (
                expireTime && (
                  <CountdownHour
                    endTime={expireTime}
                    onFinish={onCounterFinish}
                    label={time => `Code expires in ${time}`}
                    className={s.countdown}
                  />
                )
              )}
            </div>
          )}

          {status.count >= SMS_LIMIT && (
            <div className="f-18-24 f-400" style={{ marginTop: '8px' }}>
              If you have any technical problems, please contact our support
              team at{' '}
              <MyLink href={APP_LINKS.email} isExternal underline>
                {CONTACT_EMAIL}
              </MyLink>
              .
            </div>
          )}

          <VerificationCodeInput
            values={values.code}
            className={s.input}
            error={errors.code && touched.code}
            onChange={value => setFieldValue('code', value.split(''))}
            onComplete={() => {
              handleSubmit();
            }}
          />

          {errors.code && touched.code && (
            <div className={classnames(s.error_message, 'f-16-24 f-400')}>
              {errors.code}
            </div>
          )}

          <div
            className={classnames(
              s.buttons,
              location.pathname === APP_LINKS.dashboard && s.buttons_row
            )}
          >
            <RoundButton
              label="Verify"
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              withArrow
              className={s.button_submit}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
