import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import ImageDesktop from '../../assets/images/how_choose_startups.svg';
import ImageMobile from '../../assets/images/how_choose_startups_m.svg';
import s from './index.module.scss';

export const ChooseStartups = () => {
  const { innerWidth } = useSelector(state => state.commonStore);
  return (
    <section className={s.wrapper}>
      <h2 className="f-26 f-500">How we choose startups</h2>
      <p className={classnames(s.desc, 'f-16 f-400')}>
        Every round we bring our customers a carefully vetted batch of startups.
        To acquire funding on Scramble, each company must demonstrate strong
        consumer affection for the product, have a qualified co-founders team,
        and not be in a distressed financial situation.
      </p>

      {innerWidth < 700 ? (
        <img
          src={ImageMobile}
          alt="choose"
          className={classnames(s.item_image, 'mobile')}
        />
      ) : (
        <img
          src={ImageDesktop}
          alt="choose"
          className={classnames(s.item_image, 'desktop')}
        />
      )}
    </section>
  );
};
