import React, { useEffect, useRef, useState } from 'react';
import * as classnames from 'classnames';
import { Calendar } from 'react-modern-calendar-datepicker';
import { InputDateTextField } from '../InputDateTextField';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { padNumber } from '../../../helpers/utils';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './index.scss';

const DATE_MASK = '99/99/9999';

const DateSelect = ({
  label,
  name,
  value,
  initialValue,
  onChange,
  onReset,
  error,
  className,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false), show);

  const handleInputChange = e => {
    const val = e.target.value;
    setInputValue(val);
    if (val.length === 10) {
      const date = val.split('/').reduce((acc, val, index) => {
        if (index === 0) {
          acc.day = Number(val);
        }
        if (index === 1) {
          acc.month = Number(val);
        }
        if (index === 2) {
          acc.year = Number(val);
        }
        return acc;
      }, {});
      onChange(date);
    }
  };

  useEffect(() => {
    value
      ? setInputValue(
          `${padNumber(value.day, 2, '')}/${padNumber(
            value.month,
            2,
            ''
          )}/${padNumber(value.year, 2, '')}`
        )
      : setInputValue('');
  }, [value]);

  const onFocus = e => {
    e.target.placeholder = 'dd/mm/yyyy';
    setShow(true);
  };

  const onBlur = e => {
    e.target.placeholder = ' ';
  };

  return (
    <div
      className={classnames('date-select', error && 'error', className)}
      ref={ref}
    >
      <InputDateTextField
        name={name}
        label={label}
        mask={DATE_MASK}
        value={inputValue}
        onChange={handleInputChange}
        onClear={() => {
          setInputValue('');
          onReset();
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <div
        className={classnames('date-select__calendar-wrap', show && 'active')}
      >
        <Calendar
          value={value || initialValue}
          onChange={date => {
            setShow(false);
            onChange(date);
          }}
          shouldHighlightWeekends
          renderFooter={() => (
            <div className="date-select__reset-wrap">
              <button
                type="button"
                className="date-select__reset f-14"
                onClick={() => {
                  setShow(false);
                  onReset();
                }}
              >
                Reset
              </button>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default DateSelect;
