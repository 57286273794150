import {METADATA} from '../../helpers/metadata';
import {GET_FINAL_THEMES, GET_PARENT_THEMES, GET_THEME_DETAIL, SET_PARENT_THEME} from '../actions/actionTypes';
import {DEFAULT_ALL} from '../../helpers/constants';

const initialState = {
  parentThemes: { ...METADATA.default, data: null },
  finalThemes: { ...METADATA.default, data: null },
  themeDetail: { ...METADATA.default, data: null },
  selectedTheme: DEFAULT_ALL,
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARENT_THEMES.REQUEST:
      return { ...state, parentThemes: { ...state.parentThemes, ...METADATA.request }};
    case GET_PARENT_THEMES.SUCCESS:
      return { ...state, parentThemes: { ...state.parentThemes, ...METADATA.success, data: action.themes }};
    case GET_PARENT_THEMES.FAILURE:
      return { ...state, parentThemes: { ...state.parentThemes, ...METADATA.error, error: action.error }};

    case GET_FINAL_THEMES.REQUEST:
      return { ...state, finalThemes: { ...state.finalThemes, ...METADATA.request }};
    case GET_FINAL_THEMES.SUCCESS:
      return { ...state, finalThemes: { ...state.finalThemes, ...METADATA.success, data: action.themes }};
    case GET_FINAL_THEMES.FAILURE:
      return { ...state, finalThemes: { ...state.finalThemes, ...METADATA.error, error: action.error }};

    case GET_THEME_DETAIL.REQUEST:
      return { ...state, themeDetail: { ...state.themeDetail, ...METADATA.request }};
    case GET_THEME_DETAIL.SUCCESS:
      return { ...state, themeDetail: { ...state.themeDetail, ...METADATA.success, data: action.theme }};
    case GET_THEME_DETAIL.FAILURE:
      return { ...state, themeDetail: { ...state.themeDetail, ...METADATA.error, error: action.error }};

    case SET_PARENT_THEME:
      return { ...state, selectedTheme: action.theme };

    default:
      return state;
  }
};

export default themeReducer;