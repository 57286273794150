export const GroupAExample = {
  total_flat_fee: 50,
  total_principal: 1000,
  total: 1050,
  list: [
    {
      date: '2022-01-05',
      fee: 50,
      principal: 15.88,
      total: 65.88,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-07-05',
      fee: 0,
      principal: 748.87,
      total: 748.87,
    },
  ],
};

export const EXAMPLE_A_DATA_12 = {
  total: 1080,
  total_flat_fee: 80,
  total_principal: 1000,
  list: [
    {
      date: '2022-01-05',
      fee: 50,
      principal: 15.88,
      total: 65.88,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-07-05',
      fee: 30,
      principal: 66.47,
      total: 96.47,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-01-05',
      fee: 0,
      principal: 447.15,
      total: 447.15,
    },
  ],
};

export const EXAMPLE_A_DATA_18 = {
  total: 1094,
  total_flat_fee: 94,
  total_principal: 1000,
  list: [
    {
      date: '2022-01-05',
      fee: 50,
      principal: 15.88,
      total: 65.88,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-07-05',
      fee: 30,
      principal: 66.47,
      total: 96.47,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-01-05',
      fee: 14,
      principal: 75.3,
      total: 89.3,
    },
    {
      date: '2023-02-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-03-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-04-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-05-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-06-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-07-05',
      fee: 0,
      principal: 136.6,
      total: 136.6,
    },
  ],
};

export const EXAMPLE_A_DATA_24 = {
  total: 1094,
  total_flat_fee: 94,
  total_principal: 1000,
  list: [
    {
      date: '2022-01-05',
      fee: 50,
      principal: 15.88,
      total: 65.88,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-07-05',
      fee: 30,
      principal: 66.47,
      total: 96.47,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-01-05',
      fee: 14,
      principal: 75.3,
      total: 89.3,
    },
    {
      date: '2023-02-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-03-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-04-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-05-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-06-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-07-05',
      fee: 0,
      principal: 85.88,
      total: 85.88,
    },
    {
      date: '2023-08-05',
      fee: 0,
      principal: 47.05,
      total: 47.05,
    },
    {
      date: '2023-09-05',
      fee: 0,
      principal: 3.67,
      total: 3.67,
    },
  ],
};

export const GroupBExample = {
  total_flat_fee: 135,
  total_principal: 1500,
  total: 1635,
  list: [
    {
      date: '2022-02-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-07-05',
      fee: 135,
      principal: 1500,
      total: 1635,
    },
  ],
};

export const EXAMPLE_B_DATA_12 = {
  total: 1800,
  total_flat_fee: 300,
  total_principal: 1500,
  list: [
    {
      date: '2022-02-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-07-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-01-05',
      fee: 300,
      principal: 1500,
      total: 1800,
    },
  ],
};

export const EXAMPLE_B_DATA_18 = {
  total: 2040,
  total_flat_fee: 540,
  total_principal: 1500,
  list: [
    {
      date: '2022-02-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-07-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-01-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-02-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-03-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-04-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-05-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-06-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-07-05',
      fee: 540,
      principal: 1500,
      total: 2040,
    },
  ],
};

export const EXAMPLE_B_DATA_24 = {
  total: 2370,
  total_flat_fee: 870,
  total_principal: 1500,
  list: [
    {
      date: '2022-02-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-07-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-01-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-02-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-03-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-04-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-05-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-06-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-07-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-08-05',
      fee: 0,
      principal: 0,
      total: 0,
    },
    {
      date: '2023-09-05',
      fee: 0,
      principal: 370,
      total: 370,
    },
    {
      date: '2023-10-05',
      fee: 25,
      principal: 375,
      total: 400,
    },
    {
      date: '2023-11-05',
      fee: 25,
      principal: 375,
      total: 400,
    },
    {
      date: '2023-12-05',
      fee: 20,
      principal: 380,
      total: 400,
    },
    {
      date: '2024-01-05',
      fee: 800,
      principal: 0,
      total: 800,
    },
  ],
};
