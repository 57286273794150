import React from 'react';
import {ArrowLongNext} from '../UI/Icons';
import './index.scss';

const BackButton = ({ onClick }) => (
  <button type="button" onClick={onClick} className="back-button">
    <ArrowLongNext />
    <span className="f-16 f-500">Back</span>
  </button>
);

export default BackButton;