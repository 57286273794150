import React from 'react';
import * as classnames from 'classnames';
import Avatar from '../../UI/Avatar';
import {getFullname, getNameInitials, prettyJob} from '../../../helpers/utils';
import './index.scss';

const InvestorBigCard = ({investor, active, disablePrivateNumber, className}) => {
  const job = prettyJob(investor.company_name, investor.position);

  return (
    <div className={classnames("investor-big-card", className)} >
      <Avatar
        src={investor.photo && investor.photo.file}
        alt={getFullname(investor.first_name, investor.last_name)}
        nameInitials={getNameInitials(investor.first_name, investor.last_name)}
        className="investor-big-card__avatar"
        active={active}
        size={140}
        uniqueID={!disablePrivateNumber && investor.unique_id}
      />
      {job ? (
        <>
          <p className="f-18 f-500 tl" style={{lineHeight: '20px'}}>{investor.first_name} {investor.last_name}</p>
          <p className="investor-big-card__job f-14 f-400 line2tl" style={{lineHeight: '16px'}}>{job}</p>
        </>
      ) : (
        <>
          <p className="f-18 f-500 tl" style={{lineHeight: '20px'}}>{investor.first_name}</p>
          <p className="f-18 f-500 tl" style={{lineHeight: '20px'}}>{investor.last_name}</p>
        </>
      )}
    </div>
  );
};

export default InvestorBigCard;