import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { MyLink } from '../MyLink';
import ShareButtons from '../ShareButtons';
import { CopyRoundButton } from '../UI/CopyRoundButton';
import config from '../../config';
import { ACCEPT_PARTNER_TERMS_SOURCES } from '../../pages/AffiliatePage';
import { APP_LINKS } from '../../helpers/links';
import Analytics from '../../analytics';
import {
  acceptPartnerTermsOfUse,
  getReferralCode,
} from '../../store/services/referralService';
import s from './index.module.scss';

const InviteFriends = ({ analyticSource }) => {
  const [code, setCode] = useState(null);

  useEffect(() => {
    getReferralCode().then(res => res && res.success && setCode(res.data));
  }, []);

  if (!code) {
    return null;
  }

  const sharedText = `Investing in secured loans has never been easier. Sign up today and get a €5 cash bonus to start growing your passive income`;
  const inviteLink =
    code && config.mainPromoDomain + '/round?ref=' + code.code;

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">Invite your friends</h2>

      <div className={s.block}>
        <div className={s.desc}>
          Invite your friends to join Scramble and get attractive rewards from
          their investment!{' '}
          <MyLink href={APP_LINKS.affiliate} underline>
            More details →
          </MyLink>
        </div>

        <div className={s.social}>
          <div>Share you link via social media</div>
          <ShareButtons
            url={inviteLink}
            options={{
              facebook: {
                quote: sharedText,
              },
              twitter: {
                title: sharedText,
              },
              linkedin: {
                title: sharedText,
                source: inviteLink,
              },
            }}
            onClick={name => {
              switch (name) {
                case 'facebook':
                  Analytics.investingInviteFriends(
                    `investing_${analyticSource}_affiliate_fbshare`
                  );
                  break;
                case 'linkedin':
                  Analytics.investingInviteFriends(
                    `investing_${analyticSource}_affiliate_linshare`
                  );
                  break;
                case 'twitter':
                  Analytics.investingInviteFriends(
                    `investing_${analyticSource}_affiliate_twishare`
                  );
                  break;
                default:
                  break;
              }
              acceptPartnerTermsOfUse({
                has_accepted: true,
                source: ACCEPT_PARTNER_TERMS_SOURCES.shareBySocial,
                extra_data: {},
              });
            }}
            className={s.social_buttons}
          />
        </div>

        <div className={s.share}>
          <div>Or copy the link below and send it to your friends</div>
          <div className={s.share_content}>
            <div className={classnames(s.share_value, 'f-16 f-500')}>
              {inviteLink}
            </div>
            <CopyRoundButton
              text={inviteLink}
              label="Copy link"
              successLabel="Link copied"
              onClick={() => {
                Analytics.investingInviteFriends(
                  `investing_${analyticSource}_affiliate_copylink`
                );
                acceptPartnerTermsOfUse({
                  has_accepted: true,
                  source: ACCEPT_PARTNER_TERMS_SOURCES.copyButton,
                  extra_data: {},
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
