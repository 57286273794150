import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as classnames from 'classnames';
import { RoundButton } from '../../components/UI/RoundButton';
import { prettyFloatMoney } from '../../helpers/utils';
import { STRATEGIES } from '../InvestingModule/InvSelectStrategy';
import { LoansPreSchedule } from '../InvestingModule/InvReady/schedule';
import { DATE_FORMAT_DD_MMM } from '../../helpers/common';
import {
  getAllocateInfo,
  setLocalAccounts,
} from '../../store/actions/fundActions';
import { ACCOUNT_TYPES } from '../../helpers/constants';
import {
  getInvestingResults,
  setInvestingStatus,
  setInvestmentConfirmation,
} from '../../store/actions/investActions';
import './index.scss';

class InvestingSummary extends Component {
  componentDidMount() {
    const { status, isReadyStep } = this.props;

    this.props.getAllocateInfo().then(res => {
      if (res && res.success && isReadyStep) {
        this.props.setLocalAccounts(res.data.accounts);
      }
    });

    if (status.step === 5) {
      this.props.getInvestingResults();
    }
  }

  render() {
    const {
      status,
      onLoansEdit,
      onStrategyEdit,
      localAccounts,
      investingResult,
      isReadyStep,
      className,
    } = this.props;
    const isDisabled = status.step === 666;
    const isResult = status.step === 5;
    const hasReachedGoal = status.total_remaining_amount <= 0;
    const totalAllocated = localAccounts.reduce((total, account) => {
      if (account.type === ACCOUNT_TYPES.loan) {
        total += account.amount;
      }
      return total;
    }, 0);

    const hasAllocated = !!totalAllocated;
    const groups = [];
    isResult
      ? investingResult.data &&
        investingResult.data.groups
          .filter(group => group.is_active)
          .map(group =>
            groups.push({ title: group.title, amount: group.invested_in_group })
          )
      : localAccounts
          .filter(account => account.type === ACCOUNT_TYPES.loan)
          .map(account =>
            groups.push({ title: account.group_title, amount: account.amount })
          );

    return (
      <div
        className={classnames(
          'investing_summary',
          isDisabled && 'disabled',
          className
        )}
      >
        <div className="investing_summary__content">
          <div className="investing_summary__strategy">
            <div className="investing_summary__strategy-header">
              <div className="investing_summary__strategy-approach">
                <p className="f-16">Investment strategy</p>
                <p className="f-26 f-500">
                  {status.option === STRATEGIES.not_selected && '-'}
                  {status.option === STRATEGIES.standard && 'Standard strategy'}
                  {status.option === STRATEGIES.priority && 'Priority strategy'}
                </p>
                {status.option === STRATEGIES.priority && (
                  <div className="investing_summary__strategy-priority f-16 f-400">
                    Your future success return will be 1% less for the
                    prioritization of some founders and their respective
                    businesses over others in the same batch.
                  </div>
                )}
              </div>
              {onStrategyEdit &&
                status.step < 4 &&
                status.option !== STRATEGIES.not_selected &&
                !hasReachedGoal && (
                  <RoundButton
                    label="Edit"
                    type="button"
                    onClick={onStrategyEdit}
                    className="investing_summary__strategy-edit"
                  />
                )}
            </div>
          </div>

          <div className="investing_summary__summary">
            <div className="investing_summary__baskets">
              {!!groups.length && (
                <div className="investing_summary__baskets-list">
                  {groups.map((group, index) => (
                    <div
                      className="investing_summary__baskets-item"
                      key={index}
                    >
                      <p className="f-16">
                        Allocated to {group.title.replace('Group', 'group')}
                      </p>
                      <p className="f-26 f-500 tl">
                        {group.amount ? prettyFloatMoney(group.amount) : '—'}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              {isResult ? (
                <div className="investing_summary__result-header">
                  <div className="investing_summary__result-invested">
                    <p className="f-16">Invested</p>
                    <p className="f-42 f-500 tl">
                      {prettyFloatMoney(
                        investingResult.data &&
                          investingResult.data.total_invested
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="investing_summary__baskets-header">
                  <div className="investing_summary__baskets-earmarked">
                    <p className="f-16">Total allocated for investing</p>
                    <p className="f-42 f-500 tl">
                      {prettyFloatMoney(totalAllocated)}
                    </p>
                  </div>
                  {onLoansEdit &&
                    status.step < 4 &&
                    hasAllocated &&
                    !hasReachedGoal && (
                      <RoundButton
                        type="button"
                        label="Edit"
                        onClick={onLoansEdit}
                        className="investing_summary__baskets-edit"
                      />
                    )}
                </div>
              )}

              {onLoansEdit &&
                status.step < 4 &&
                hasAllocated &&
                !hasReachedGoal && (
                  <RoundButton
                    type="button"
                    label="Edit"
                    onClick={onLoansEdit}
                    className="investing_summary__baskets-edit bottom"
                    fullWidth
                  />
                )}

              {isReadyStep && (
                <>
                  <div className="investing_summary__description f-16 f-400">
                    <div style={{ marginTop: '10px' }}>
                      Once the round is over and its results are processed,
                      loans are automatically created and issued to businesses
                      based on your investment settings. After that, you
                      can&apos;t refuse to issue the loans that have already
                      been distributed amongst businesses.
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      Check your earnings and repayment schedule of the loans
                      that will be created after the end of the round according
                      to the current investment settings.
                    </div>
                  </div>
                  <LoansPreSchedule />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investingResult: state.investStore.investingResult,
  localAccounts: state.fundStore.localAccounts,
});

const mapDispatchToProps = dispatch => ({
  getInvestingResults: () => dispatch(getInvestingResults()),
  getAllocateInfo: () => dispatch(getAllocateInfo()),
  setInvestingStatus: status => dispatch(setInvestingStatus(status)),
  setLocalAccounts: data => dispatch(setLocalAccounts(data)),
  setInvestmentConfirmation: data => dispatch(setInvestmentConfirmation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestingSummary);
