import React from 'react';
import Analytics from '../../analytics';
import VeriffForm from '../../components/Forms/VeriffForm';

const WizardVeriffModal = ({ onSuccess, onBack }) => {
  const handleSuccess = () => {
    Analytics.verificationWizardVeriff();
    onSuccess && onSuccess();
  };
  return <VeriffForm disableSkip onBack={onBack} onSuccess={handleSuccess} />;
};

export default WizardVeriffModal;
