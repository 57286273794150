import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import { GET_QUESTIONS_LIST } from '../types/questionTypes';

const getQuestionListRequest = () => ({ type: GET_QUESTIONS_LIST.REQUEST });
const getQuestionListSuccess = payload => ({
  type: GET_QUESTIONS_LIST.SUCCESS,
  payload,
});
const getQuestionListFailure = error => ({
  type: GET_QUESTIONS_LIST.FAILURE,
  error,
});

export const getQuestionList = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getQuestionListRequest());
    return axios
      .get(Pathes.Help.forumMessages + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);

        if (status === 200) {
          const prevData = getState().questionStore.questions.data;
          if (!isNext || !prevData) {
            dispatch(getQuestionListSuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getQuestionListSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getQuestionListFailure(e.message)));
  };
};

export const createNewQuestion = payload => {
  return () => {
    return axios
      .post(Pathes.Help.forumMessages, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 201) {
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => {
        return { message: e.message, success: false };
      });
  };
};
