import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { MyLink } from '../../MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { CONTACT_EMAIL } from '../../../helpers/constants';
import WarningBlock from '../../UI/WarningBlock';
import s from './index.module.scss';

export const SuspendResidenceWarning = ({ className }) => {
  const profile = useSelector(state => state.profileStore.profile.data);

  return (
    <WarningBlock withBorder className={classnames(s.wrapper, className)}>
      {['RU', 'BY'].includes(profile?.country_code) ? (
        <div className={classnames(s.text, 'f-16 f-400')}>
          <div>
            Please be informed that due to recent EU restrictive regulations
            imposed upon several Russian and Belarusian financial institutions,
            Scramble has temporarily suspended onboarding of new clients from
            Russia and Belarus.
          </div>

          <div>
            In order to be verified on the platform and be able to enter into
            future agreements with startups, please provide us with the document
            proving your residence address outside of Russia/Belarus by writing
            an email to{' '}
            <MyLink href={APP_LINKS.email} underline isExternal>
              {CONTACT_EMAIL}
            </MyLink>
            . The acceptable documents of residence address outside of
            Russia/Belarus are considered to be:
            <ul className="scr-circle-list">
              <li>utility bill issued on your name;</li>
              <li>
                bank statement issued by the bank outside of Russia/Belarus with
                the name and address indicated on it;
              </li>
              <li>
                residence permit in a country other than Russia/Belarus with the
                address indicated on it.
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className={classnames(s.text, 'f-16 f-400')}>
          Please be informed that your Scramble account has been suspended. All
          the details of the account suspension were sent to your email address.
          In case of any questions please contact Scramble support via{' '}
          <MyLink href={`mailto:${CONTACT_EMAIL}`} isExternal underline>
            {CONTACT_EMAIL}
          </MyLink>
        </div>
      )}
    </WarningBlock>
  );
};
