import React from 'react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { TIMEZONE_ESTONIA } from './constants';
import { DATE_FORMAT_DD_MMMM_YYYY } from './common';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);

export const roundStarts = (date, format = 'DD MMM, HH:mm') => {
  if (!date) {
    return '';
  }
  const timezone = moment.tz.guess();
  const roundTime = momentTZ.tz(date, TIMEZONE_ESTONIA).add(12, 'hours');
  return `${momentTZ.tz(roundTime, timezone).format(format)} GMT`;
};

export const resultProcess = (date, format = 'DD MMM') => {
  return date ? moment(date).add(1, 'day').format(format) : '';
};

export const remainingTime = date => {
  return (
    <span>
      <ReactTimeAgo date={date} locale="en-US" timeStyle="twitter" />
    </span>
  );
};

export const resetInvestingExpireDate = date =>
  moment(date).format(DATE_FORMAT_DD_MMMM_YYYY);

export const isExpiredResetDate = date => {
  if (!date) {
    return false;
  }
  return moment(date).isBefore();
};
