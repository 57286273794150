import * as React from 'react';
import * as classnames from 'classnames';
import PropTypes from 'prop-types';
import { CYRILLIC_REGEX } from '../../../helpers/common';
import { ReactComponent as ClearIcon } from '../../../assets/images/icon-clear.svg';
import './index.scss';

export const InputTextField = ({
  label,
  name,
  value,
  onChange,
  className,
  onClear,
  error,
  allowCyrillic,
  ...other
}) => {
  const handleOnChange = e => {
    if (!allowCyrillic && CYRILLIC_REGEX.test(e.target.value)) {
      return;
    }
    onChange(e);
  };

  return (
    <div
      className={classnames(
        'input-text-field',
        error && 'input-text-field_error',
        className
      )}
    >
      <div className="input-text-field__input-group">
        <input
          type="text"
          id={name}
          name={name}
          value={value}
          placeholder=" "
          onChange={handleOnChange}
          className={classnames(
            'input-text-field__input',
            value && 'input-text-field__input-filled'
          )}
          {...other}
        />
        <label className="input-text-field__label" htmlFor={name}>
          {label}
        </label>
        {onClear && (
          <ClearIcon
            onClick={onClear}
            className={classnames(
              'input-text-field__clear',
              !value && 'input-text-field__clear-hidden'
            )}
          />
        )}
      </div>
      <div
        className={classnames(
          'input-text-field__error',
          error && 'input-text-field__error_visible'
        )}
      >
        {error}
      </div>
    </div>
  );
};

InputTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  className: PropTypes.string,
};
