import * as React from 'react';
import BasicLayout from '../../layouts/BasicLayout';
import { TwoFactorAuthForm } from '../../components/Forms/TwoFactorAuthForm';

const TwoFactorAuthPage = () => (
  <BasicLayout>
    <div className="container">
      <TwoFactorAuthForm />
    </div>
  </BasicLayout>
);

export default TwoFactorAuthPage;
