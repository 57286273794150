import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import Decimal from 'decimal.js-light';
import RadioButton from '../../../UI/RadioButton';
import { prettyFloatMoney } from '../../../../helpers/utils';
import { getStripeCalculatedAmount } from '../../../../store/services/stripeServices';
import IconBankAccount from '../../../../assets/icons/icon_bank_logo.svg';
import IconCreditCard from '../../../../assets/icons/icon_card_logo.svg';
import IconGiroPay from '../../../../assets/icons/icon_giropay_logo.svg';
import IconBancontact from '../../../../assets/icons/icon_bancontact_logo.svg';
import IconBlik from '../../../../assets/icons/icon_blik_logo.svg';
import IconEps from '../../../../assets/icons/icon_eps_logo.svg';
import IconFpx from '../../../../assets/icons/icon_fpx_logo.svg';
import IconIdeal from '../../../../assets/icons/icon_ideal_logo.svg';
import IconP24 from '../../../../assets/icons/icon_p24_logo.svg';
import IconSofort from '../../../../assets/icons/icon_sofort_logo.svg';
import IconSepa from '../../../../assets/icons/icon_sepa_logo.svg';
import s from '../index.module.scss';

export const STRIPE_PAYMENT_TYPES = {
  bank: 'bank',
  card: 'card',
  giropay: 'giropay',
  sepa_debit: 'sepa_debit',
  bancontact: 'bancontact',
  blik: 'blik',
  eps: 'eps',
  fpx: 'fpx',
  ideal: 'ideal',
  p24: 'p24',
  sofort: 'sofort',
};

export const PROCESSING_TIME = {
  sepa_debit: 5,
  sofort: 2,
};

export const PaymentMethod = ({ item, amount, formikBag }) => {
  const [commission, setCommission] = useState(0);
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const { values, setValues } = formikBag;

  const calculateComission = (amount, fixedCommission, commission) => {
    const am1 = new Decimal(amount).add(fixedCommission);
    const am2 = new Decimal(commission).div(100);
    const am3 = new Decimal(1).sub(am2);
    return am1.div(am3).sub(amount).toFixed(2, 8);
  };

  const comissions = {
    eu: calculateComission(amount, 0.25, 1.4),
    uk: calculateComission(amount, 0.25, 2.5),
    international: calculateComission(amount, 0.25, 2.9),
  };

  useEffect(() => {
    calculate(amount);
  }, [amount]);

  const calculate = () => {
    getStripeCalculatedAmount({ amount, payment_method: item.type }).then(
      res => {
        if (res && res.success) {
          setCommission(new Decimal(res.data.amount).sub(amount).toNumber());
        }
      }
    );
  };

  const getIcon = item => {
    if (item.icon) {
      return <img src={item.icon} alt={item.title} height={23} />;
    }
    switch (item.type) {
      case STRIPE_PAYMENT_TYPES.bank:
        return <img src={IconBankAccount} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.card:
        return <img src={IconCreditCard} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.giropay:
        return <img src={IconGiroPay} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.sepa_debit:
        return <img src={IconSepa} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.bancontact:
        return <img src={IconBancontact} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.blik:
        return <img src={IconBlik} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.eps:
        return <img src={IconEps} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.fpx:
        return <img src={IconFpx} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.ideal:
        return <img src={IconIdeal} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.p24:
        return <img src={IconP24} alt={item.title} width={32} />;
      case STRIPE_PAYMENT_TYPES.sofort:
        return <img src={IconSofort} alt={item.title} width={32} />;
      default:
        return null;
    }
  };

  const getCommissionInfo = (type, commission) => {
    const fee = commission
      ? `Transfer fee is ${prettyFloatMoney(commission)}`
      : 'No transfer fees';

    switch (type) {
      case STRIPE_PAYMENT_TYPES.bank:
        return `${fee}. Processing time is 1-3 business days.`;
      case STRIPE_PAYMENT_TYPES.sepa_debit:
      case STRIPE_PAYMENT_TYPES.sofort:
        return `${fee}. Processing time is typically ${PROCESSING_TIME[type]} (but up to 14) business days.`;
      default:
        return `${fee}. Processing immediately.`;
    }
  };

  const daysLeft =
    moment(fundraisingDates?.end_date).diff(moment(), 'days') || 0;

  return (
    <RadioButton
      key={item.type}
      className={classnames(
        s.method_item,
        values.method === item.type && 'selected'
      )}
      value={item.type}
      name="method"
      checked={values.method === item.type}
      onChange={() =>
        setValues({
          ...values,
          method: item.type,
          commission: 0,
          hasConfirmed: false,
        })
      }
    >
      <>
        <h5 className={classnames(s.method_title, 'f-20 f-500')}>
          {getIcon(item)}
          <span>{item.title}</span>
        </h5>
        <div className={classnames(s.method_item_commission, 'f-16 f-500')}>
          {getCommissionInfo(item.type, commission)}
        </div>
        {item.type === STRIPE_PAYMENT_TYPES.card && (
          <div className={classnames(s.method_commission_card, 'f-14 f-400')}>
            The amount of transfer fee depends on your card issuer:
            <ul className="scr-circle-list">
              <li>
                {prettyFloatMoney(comissions.eu)} for European Economic Area
                cards{' '}
              </li>
              <li>{prettyFloatMoney(comissions.uk)} for UK cards</li>
              <li>
                {prettyFloatMoney(comissions.international)} for international
                cards{' '}
              </li>
            </ul>
            Please note that in some cases your bank may charge additional
            transfer fees, so the amount that will be transferred to your
            Scramble account may be less.
          </div>
        )}

        {daysLeft < 14 &&
          [
            STRIPE_PAYMENT_TYPES.sepa_debit,
            STRIPE_PAYMENT_TYPES.sofort,
          ].includes(item.type) &&
          PROCESSING_TIME[item.type] > daysLeft &&
          fundraisingDates?.is_active && (
            <div className={classnames(s.method_commission_card, 'f-14 f-400')}>
              <div>
                Please note:{' '}
                {daysLeft < 1
                  ? `the round ends soon.`
                  : `the round ends after ${daysLeft} ${
                      daysLeft === 1 ? 'day' : 'days'
                    }.`}
              </div>
              <div>
                If you choose this payment method, your transaction will
                probably be processed after the end of the round. We recommend
                you to choose alternative payment methods with immediate
                processing to participate in the current round.
              </div>
            </div>
          )}
      </>
    </RadioButton>
  );
};
