import axios from '../../axios-api';
import Notify from '../../components/Notification';
import Pathes from '../../helpers/pathes';
import {GET_FINAL_THEMES, GET_PARENT_THEMES, GET_THEME_DETAIL, SET_PARENT_THEME} from './actionTypes';
import {getMessage} from '../../helpers/utils';
import {DEFAULT_ALL, THEME_STATES} from '../../helpers/constants';

export const getParentThemesRequest = () => ({ type: GET_PARENT_THEMES.REQUEST });
export const getParentThemesSuccess = themes => ({type: GET_PARENT_THEMES.SUCCESS, themes});
export const getParentThemesFailure = error => ({type: GET_PARENT_THEMES.FAILURE, error});

export const getFinalThemesRequest = () => ({ type: GET_FINAL_THEMES.REQUEST });
export const getFinalThemesSuccess = themes => ({type: GET_FINAL_THEMES.SUCCESS, themes});
export const getFinalThemesFailure = error => ({type: GET_FINAL_THEMES.FAILURE, error});

export const getThemeDetailRequest = () => ({ type: GET_THEME_DETAIL.REQUEST });
export const getThemeDetailSuccess = theme => ({type: GET_THEME_DETAIL.SUCCESS, theme});
export const getThemeDetailFailure = error => ({type: GET_THEME_DETAIL.FAILURE, error});

export const getParentThemes = () => {
  return dispatch => {
    dispatch(getParentThemesRequest());
    return axios.get(Pathes.Themes.parent).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getParentThemesSuccess(data));
          return data;
        }

        Notify.error({text: message})
        throw new Error(message)
      }).catch(e =>  dispatch(getParentThemesFailure(e.message)));
  };
};

export const getFinalThemes = () => {
  return (dispatch, getState) => {
    const catID = getState().themeStore.selectedTheme;
    let query = '';
    if (catID && catID !== DEFAULT_ALL) {
      query = `?category_id=${catID}`
    }
    dispatch(getFinalThemesRequest());
    return axios.get(Pathes.Themes.final + query).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getFinalThemesSuccess(data));
          return data;
        }

        Notify.error({ text: message })
        throw new Error(message)
      }).catch(e =>  dispatch(getFinalThemesFailure(e.message)));
  };
};

export const getThemeDetail = id => {
  return dispatch => {
    dispatch(getThemeDetailRequest());
    return axios.get(Pathes.Themes.detail(id)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getThemeDetailSuccess(data));
          return data;
        }

        Notify.error({ text: message })
        throw new Error(message)
      }).catch(e =>  dispatch(getThemeDetailFailure(e.message)));
  };
};

export const setParentTheme = theme => {
  return dispatch => dispatch({ type: SET_PARENT_THEME, theme });
}

export const toggleFavouriteThemes = (themes, action, params = {}) => {
  return (dispatch, getState) => {
    return axios.post(Pathes.Themes.toggleTheme, { themes, action })
      .then(res => {
        const {status, data} = res;
        const message = getMessage(data);
        if (status === 200) {
          const currentThemes = getState().themeStore.finalThemes;
          const currentThemeDetail = getState().themeStore.themeDetail;
          if (params.updateList && currentThemes.data) {
            const list = currentThemes.data.map(theme => {
              if (!themes.includes(theme.id)) { return theme; }
              return {...theme, is_included: action === THEME_STATES.like };
            });
            dispatch(getFinalThemesSuccess(list));
          }

          if (params.updateDetail && currentThemeDetail.data) {
            dispatch(getThemeDetailSuccess({...currentThemeDetail.data, is_included: action === THEME_STATES.like }))
          }

          Notify.success({ text: message });
          return { ...data, success: true };
        }
        throw new Error(message)
      }).catch(e => ({ error: e.message }));
  }
}