import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { WIZARD_REDIRECTS } from '../../containers/InvestingModule';
import FounderDetailCard from '../../components/Cards/FounderDetailCard';
import { getFounderDetail } from '../../store/services/userServices';
import Preloader from '../../components/Preloader';
import { BackButton } from '../../components/UI/Buttons';
import { canGoBack } from '../../helpers/utils';
import { getFounderFundraisingStatus } from '../../store/actions/founderActions';
import { APP_LINKS } from '../../helpers/links';
import './index.scss';

class FounderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.founderID = props.match.params.id;
    this.state = {
      id: this.founderID,
      detail: null,
      loading: true,
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    const { to, strategy } = qs.parse(location.search.replace('?', ''));
    this.redirect = to;
    this.strategy = strategy;
    this.redirect && history.replace(location.pathname);
    this.getDetails(this.founderID);
  }

  getDetails = founderID => {
    this.setState(prevState => ({ ...prevState, loading: true }));
    this.props.getFounderFundraisingStatus(this.founderID);
    getFounderDetail(founderID).then(res =>
      res && res.success
        ? this.setState(prevState => ({
            ...prevState,
            detail: res.data,
            loading: false,
          }))
        : this.setState(prevState => ({
            ...prevState,
            detail: null,
            loading: false,
          }))
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const founderID = this.props.match.params.id;
      this.setState(prevState => ({
        ...prevState,
        id: founderID,
        loading: true,
      }));
      this.getDetails(founderID);
      const main = document.querySelector('main');
      main && main.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }
  }

  onBack = () => {
    const { history } = this.props;
    if (
      this.redirect &&
      Object.keys(WIZARD_REDIRECTS).map(key =>
        WIZARD_REDIRECTS[key].includes(this.redirect)
      )
    ) {
      return history.push(
        `/investing?to=${this.redirect}${
          this.strategy ? `&strategy=${this.strategy}` : ''
        }`
      );
    }
    canGoBack(history) ? history.goBack() : history.push(APP_LINKS.startups);
  };

  render() {
    const { detail, loading } = this.state;
    const { fundraising } = this.props;

    return (
      <div className="founder-detail-page">
        <div className="container-inner">
          <div className="founder-detail-page__back">
            <BackButton onClick={this.onBack} />
          </div>
          {loading ? (
            <Preloader className="founder-detail-page__preloader" />
          ) : (
            <FounderDetailCard founder={detail} fundraising={fundraising} />
          )}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  fundraising: state.founderStore.founderFundraisingStats.data,
});

export const mapDispatchToProps = dispatch => ({
  getFounderFundraisingStatus: founderID =>
    dispatch(getFounderFundraisingStatus(founderID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FounderDetailPage);
