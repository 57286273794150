import { padNumber } from '../helpers/utils';

export const useFacebookEvent = (eventType, data) => {
  try {
    const params = {};
    if (data.user) {
      params.user_id = padNumber(data.user.id);
    }
    window.fbq('trackCustom', eventType, params);
    console.info('FB Event --> ', eventType);
    return eventType;
  } catch (e) {
    console.error('Facebook Pixel not initialised');
  }
};
