import * as React from 'react';
import * as classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RadioButton from '../../UI/RadioButton';
import { RoundButton } from '../../UI/RoundButton';
import { RoundLink } from '../../UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import s from './index.module.scss';

export const INVESTOR_TYPES = {
  restricted_investor: {
    title: 'Restricted investor (Everyday investor)',
    description:
      'I qualify as a restricted investor because: in the twelve months preceding, I have not invested more than 10% of my net assets in unlisted shares or unlisted debt securities, and I undertake that in the twelve months following I will not invest more than 10% of my net assets in unlisted shares or unlisted debt securities.',
  },
  advised_investor: {
    title: 'Advised investor',
    description:
      'I am a client of a regulated advisor that has assessed me as suitable for this investment.',
  },
  sophisticated_investor: {
    title: 'Sophisticated Investor',
    description:
      'I have certification from a qualifying firm confirming that I have been assessed by that firm as being sufficiently knowledgeable to understand the risks associated with engaging in investment activity.',
  },
  high_net_worth_investor: {
    title: 'High Net Worth Investor',
    description:
      'I qualify as HNWI because I had, throughout the financial year immediately preceding, an annual income to the value of £100,000 or more; or I held, throughout the financial year immediately preceding, net assets to the value of £250,000 or more.',
  },
};

const VALIDATION_SCHEMA = Yup.object({
  investor_type: Yup.string(),
});

const InvestorTypeForm = ({ initialValues, onSubmit, onCancel, onBack }) => {
  const location = useLocation();
  const profile = useSelector(state => state.profileStore.profile.data);
  const isSignUp = location.pathname === APP_LINKS.signUp;
  const isWizard = location.pathname === APP_LINKS.dashboard;

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          investor_type: profile?.investor?.investor_type || '',
        }
      }
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form className="" onSubmit={handleSubmit}>
          <h1 className={classnames(s.title, 'f-32 f-500')}>
            Select your type of investor
          </h1>
          <p className={classnames(s.subtitle, 'f-16 f-400')}>
            In accordance with the applicable EU and UK legislation, we need to
            obtain more information on the type of investor based within the UK.
          </p>

          <div className={s.radio_buttons}>
            {Object.keys(INVESTOR_TYPES).map(key => (
              <RadioButton
                key={key}
                value={key}
                name="investor_type"
                checked={values.investor_type === key}
                className={s.radio_item}
                onChange={() => setFieldValue('investor_type', key)}
              >
                <h5 className="f-20 f-500">{INVESTOR_TYPES[key].title}</h5>
                <p className="f-16 f-400">{INVESTOR_TYPES[key].description}</p>
              </RadioButton>
            ))}
          </div>

          {isSignUp ? (
            <div className={s.signup_buttons}>
              <RoundButton
                label="Continue"
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                withArrow
                className={s.signup_button}
              />
              <RoundLink
                label="Continue Later"
                path={APP_LINKS.dashboard}
                className={s.signup_link}
              />
            </div>
          ) : (
            <div className={s.buttons}>
              {onCancel && (
                <RoundButton
                  label={onBack ? 'Back' : 'Cancel'}
                  type="button"
                  fullWidth
                  onClick={() =>
                    (onBack && onBack(values)) || (onCancel && onCancel())
                  }
                  className={s.button}
                />
              )}
              <RoundButton
                label={isWizard ? 'Continue' : 'Save'}
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                className={s.button}
                style={{ maxWidth: isWizard ? '325px' : '150px' }}
              />
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default InvestorTypeForm;
