import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  SET_USER,
  SET_USER_LOCATION,
  UPDATE_USER,
} from '../actions/actionTypes';

export const initialState = {
  user: null,
  token: null,
  loading: false,
  userLocation: null,
  loginError: null,
  registerError: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER.REQUEST:
    case LOGIN_USER.REQUEST:
      return { ...state, loading: true };
    case REGISTER_USER.SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        temp_token: null,
        registerError: null,
        loading: false,
      };
    case REGISTER_USER.FAILURE:
      return { ...state, registerError: action.error, loading: false };
    case LOGIN_USER.SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        temp_token: action.payload.temp_token,
        user: action.payload.user,
        loginError: null,
        loading: false,
      };
    case LOGIN_USER.FAILURE:
      return { ...state, loginError: action.error, loading: false };
    case LOGOUT_USER:
      return { ...state, user: null, token: null, loading: false };
    case SET_USER:
    case UPDATE_USER:
      return { ...state, user: action.user };
    case SET_USER_LOCATION:
      return { ...state, userLocation: action.location };

    default:
      return state;
  }
};

export default userReducer;
