import React from 'react';
import * as classnames from 'classnames';
import { ReactComponent as IconBack } from '../../../assets/icons/icon_back_arrow.svg';
import './index.scss';

const BackButton = ({ onClick, className }) => (
  <button
    type="button"
    onClick={onClick}
    className={classnames('back_button f-16 f-500', className)}
  >
    <IconBack />
    <span>Back</span>
  </button>
);

export default BackButton;
