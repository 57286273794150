import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextareaField } from '../../../components/UI/TextareaField';
import { RoundButton } from '../../../components/UI/RoundButton';
import {
  createNewQuestion,
  getQuestionList,
} from '../../../store/actions/questionActions';
import Notify from '../../../components/Notification';
import { USER_STATUSES } from '../../../helpers/constants';
import { RoundLink } from '../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import s from './index.module.scss';

const LIMIT = 300;

export const NewQuestion = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const { user } = useSelector(state => state.userStore);

  const createMessage = async () => {
    const payload = {
      reply_to: null,
      comment_text: text,
    };

    const res = await dispatch(createNewQuestion(payload));
    if (res?.success) {
      Notify.success({ text: 'Your question submitted successfully' });
      dispatch(getQuestionList({ page: 1, limit: 1000 }));
      setText('');
    }
  };

  return (
    <div className={s.wrapper}>
      <h2 className="h2">
        Didn’t find the answer you were looking for? Ask your question.
      </h2>

      {user.status === USER_STATUSES.verified ? (
        <>
          <div className={s.description}>Type you question here:</div>
          <TextareaField
            value={text}
            onChange={e =>
              e.target.value.length <= LIMIT && setText(e.target.value)
            }
            placeholder="e.g. when I receive my repayments?"
            className={s.textarea}
          />
          <div className={s.textarea_limit}>
            {LIMIT - text.length} characters left
          </div>
          <RoundButton
            label={() => 'Submit your question'}
            fullWidth
            fillBackground
            onClick={createMessage}
            className={s.link}
          />
        </>
      ) : (
        <>
          <div className={s.description}>
            To create your own question, please verify yourself
          </div>
          <RoundLink
            label="Verify yourself"
            fullWidth
            fillBackground
            className={s.button}
            path={APP_LINKS.profile}
          />
        </>
      )}
    </div>
  );
};
