import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { GET_FOUNDER_INFO, SET_INNER_WIDTH, SET_MODALS } from './actionTypes';
import { getMessage } from '../../helpers/utils';

export const getFounderInfoRequest = () => ({ type: GET_FOUNDER_INFO.REQUEST });
export const getFounderInfoSuccess = founder => ({type: GET_FOUNDER_INFO.SUCCESS, founder});
export const getFounderInfoFailure = error => ({type: GET_FOUNDER_INFO.FAILURE, error});

export const getFounderInfo = id => {
  return dispatch => {
    dispatch(getFounderInfoRequest());
    return axios.get(Pathes.Common.founderInfo(id)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getFounderInfoSuccess(data));
          return data;
        }

        throw new Error(message)
      }).catch(e =>  dispatch(getFounderInfoFailure(e.message)));
  };
};

export const setInnerWidth = width => {
  return dispatch => dispatch({ type: SET_INNER_WIDTH, width });
};

export const setModals = (value, closeOther) => {
  return (dispatch, getState) => {
    if (Array.isArray(value)) {
      return dispatch({ type: SET_MODALS, modals: value });
    }
    if (closeOther) {
      return dispatch({ type: SET_MODALS, modals: [value] });
    }
    const { modals } = getState().commonStore;
    return dispatch({ type: SET_MODALS, modals: [...modals, value] });
  };
};
