import React from 'react';
import moment from 'moment';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../helpers/common';
import { prettyFloatMoney } from '../../../helpers/utils';
import s from './index.module.scss';

export const Header = props => {
  const { start_date, end_date, loan_amount, outstanding } = props.info;

  return (
    <div className={s.header}>
      <div>
        <div className="f-16">Started</div>
        <div className="f-22 f-500">
          {start_date ? moment(start_date).format(DATE_FORMAT_DD_MM_YYYY) : '-'}
        </div>
      </div>

      <div>
        <div className="f-16">End date</div>
        <div className="f-22 f-500">
          {end_date ? moment(end_date).format(DATE_FORMAT_DD_MM_YYYY) : '-'}
        </div>
      </div>

      <div>
        <div className="f-16">Loan total</div>
        <div className="f-22 f-500 tl">{prettyFloatMoney(loan_amount)}</div>
      </div>

      <div>
        <div className="f-16">Outstanding</div>
        <div className="f-22 f-500 tl">{prettyFloatMoney(outstanding)}</div>
      </div>
    </div>
  );
};
