import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StartupCard } from '../../../../components/Cards/StartupCard/v3';
import { getParticipatingStartups } from '../../../../store/actions/investActions';
import s from './participate.module.scss';

export const ParticipatingStartupsList = ({ onMoreDetails }) => {
  const dispatch = useDispatch();
  const { participatingStartups: startups } = useSelector(
    state => state.investStore
  );

  useEffect(() => {
    dispatch(getParticipatingStartups({ limit: 1000, page: 1 }));
  }, []);

  return (
    <div className={s.container}>
      <div className={s.list}>
        {startups.data?.list.map(startup => (
          <StartupCard
            key={startup.id}
            startup={startup}
            className={s.item}
            onMoreDetails={onMoreDetails}
          />
        ))}
      </div>
    </div>
  );
};
